import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import GardenArchitecture from '@/views/GardenArchitecture/GardenArchitecture'
import GardenDetails from '@/views/GardenArchitecture/GardenDetails'
import IndustryTrends from '@/views/industry/IndustryTrends'
import TrendsDetails from '@/views/industry/TrendsDetails'
import FindDesign from '@/views/findDesign/findDesign'
import designerDetails from '@/views/findDesign/designerDetails'
import costEngineer from '@/views/costEngineer/costEngineer'
import costEngineerDetails from '@/views/costEngineer/costEngineerDetails'
import findMaterials from '@/views/findMaterials/findMaterials'
import findMaterialsDetails from '@/views/findMaterials/findMaterialsDetails'
import worker from '@/views/worker/worker'
import LandscapeArchitecture from '@/views/LandscapeArchitecture/LandscapeArchitecture'
import CasePresentation from '@/views/CasePresentation/CasePresentation'
import FindService from '@/views/FindService/FindService'
import FindServiceDetails from '@/views/FindService/FindServiceDetails'
import FindMaintenance from '@/views/FindMaintenance/FindMaintenance'
import CasePresentationDetails from '@/views/CasePresentation/CasePresentationDetails'
import workerDetails from '@/views/worker/workerDetails'
import ArchitectureDetails from '@/views/LandscapeArchitecture/ArchitectureDetails'
import Events from '@/views/Events/events'
import EventsDetail from '@/views/Events/eventsDetail'
import WorksDetail from '@/views/Events/matchDetailPage/worksDetail'
import index from '@/views/index'
import home from '@/views/home'
import ForgotPasswordPage from "@/views/ForgotPasswordPage/ForgotPasswordPage";
import shoppingCart from "@/views/shoppingCart/index";
import settleIn from "@/views/settleIn/index";
import settleInE from "@/views/settleIn/settleIn";
import Mine from "@/views/mine/index";
import PersonalData from "@/views/mine/personalData";
import MessageManagement from "@/views/mine/msg/messageManagement";
import MessageDetail from "@/views/mine/msg/messageDetail";
import Auth from "@/views/mine/auth/index";
import PassWorld from "@/views/mine/passWorld/passWorld";
import MyMatch from "@/views/mine/myMatch/index";
import Mobile from "@/views/mobile";
import icpImg from "@/views/icpImg/index"
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/icpImg',
    name: 'icpImg',
    component: icpImg
  },
  {
    path: '/Mobile',
    name: 'Mobile',
    component: Mobile,
    meta: { title: '首页', noCache: true }
  },

  {
    path: '/',
    component: home,
    hidden:true,
    children: [
      {
        path: '/HomeView',
        name: 'HomeView',
        component: HomeView,
        meta: { title: '首页', noCache: true }
      },
      {
        path: '/GardenArchitecture',
        name: 'GardenArchitecture',
        component: GardenArchitecture,
        meta: { title: '园林入驻公司', noCache: false }
      },
      {
        path: '/FindDesign',
        name: 'FindDesign',
        component: FindDesign,
        meta: { title: '找设计', noCache: true },
      },
      {
        path: '/designerDetails',
        name: 'designerDetails',
        component: designerDetails,
        meta: { title: '设计师详情', noCache: true },
      },
      {
        path: '/costEngineer',
        name: 'costEngineer',
        component: costEngineer,
        meta: { title: '找造价', noCache: true },
      },
      {
        path: '/costEngineerDetails',
        name: 'costEngineerDetails',
        component: costEngineerDetails,
        meta: { title: '造价师详情', noCache: true },
      },
      {
        path: '/findMaterials',
        name: 'findMaterials',
        component: findMaterials,
        meta: { title: '找材料', noCache: true },
      },
      {
        path: '/findMaterialsDetails',
        name: 'findMaterialsDetails',
        component: findMaterialsDetails,
        meta: { title: '找材料详情', noCache: true },
      },
      {
        path: '/worker',
        name: 'worker',
        component: worker,
        meta: { title: '找工人', noCache: true },
      },
      {
        path: '/FindService',
        name: 'FindService',
        component: FindService,
        meta: { title: '找服务', noCache: true },
      },
      {
        path: '/FindServiceDetails',
        name: 'FindServiceDetails',
        component: FindServiceDetails,
        meta: { title: '服务详情', noCache: true },
      },
      {
        path: '/FindMaintenance',
        name: 'FindMaintenance',
        component: FindMaintenance,
        meta: { title: '找养护', noCache: true },
      },
      {
        path: '/IndustryTrends',
        name: 'IndustryTrends',
        component: IndustryTrends,
        meta: { title: '招采信息', noCache: true }
      },
      {
        path: '/Events',
        name: 'Events',
        component: Events,
        meta: { title: '赛事活动', noCache: true }
      },
      {
        path: '/TrendsDetails',
        name: 'TrendsDetails',
        component: TrendsDetails,
      },
      {
        path: '/LandscapeArchitecture',
        name: 'LandscapeArchitecture',
        component: LandscapeArchitecture,
        meta: { title: '风景园林学院', noCache: true }
      },
      {
        path: '/CasePresentation',
        name: 'CasePresentation',
        component: CasePresentation,
        meta: { title: '案例展示', noCache: true },
      },
      {
        path: '/eventsDetail',
        name: 'eventsDetail',
        component: EventsDetail,
      },
      {
        path: '/worksDetail',
        name: 'worksDetail',
        component: WorksDetail,
        meta: { title: '作品详情', noCache: true },
      },
      {
        path: '/CasePresentationDetails',
        name: 'CasePresentationDetails',
        component: CasePresentationDetails,
      },
      {
        path: '/workerDetails',
        name: 'workerDetails',
        component: workerDetails,
      },
      {
        path: '/ArchitectureDetails',
        name: 'ArchitectureDetails',
        component: ArchitectureDetails,
      },
      {
        path: '/GardenDetails',
        name: 'GardenDetails',
        component: GardenDetails,
      },
      {
        path: '/shoppingCart',
        name: 'shoppingCart',
        component: shoppingCart,
        meta: { title: '我的购物车', noCache: true },
      },
      {
        path: '/settleIn',
        name: 'settleIn',
        component: settleIn,
        meta: { title: '', noCache: true },
      },
      {
        path: '/settleInE',
        name: 'settleInE',
        component: settleInE,
        meta: { title: '', noCache: true },
      },
    ]

  },
  {
    path: '/mine',
    name: 'mine',
    component: Mine,
    children: [
      {
        path: '/personalData',
        name: 'personalData',
        component: PersonalData,
      },
      {
        path: '/messageManagement',
        name: 'messageManagement',
        component: MessageManagement,
      },
      {
        path: '/messageDetail',
        name: 'messageDetail',
        component: MessageDetail,
      },
      {
        path: '/auth',
        name: 'auth',
        component: Auth,
      },
      {
        path: '/passWorld',
        name: 'passWorld',
        component: PassWorld,
      },
      {
        path: '/myMatch',
        name: 'myMatch',
        component: MyMatch,
      },
    ]
  },
  {
    path: '/index',
    component: index,
    children: [
      {
        path: '/ForgotPasswordPage',
        name: 'ForgotPasswordPage',
        component: ForgotPasswordPage,

      },
    ]
  },

]



const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,

})

export default router
