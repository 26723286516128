<template>
    <div class="icpImg">
        <img src="../../assets/image/fjylICP.png" alt="">
    </div>
</template>

<script>
    export default {
        data(){}
    }
</script>

<style lang="scss">
.icpImg{
    width: 100%;
    height: 100%;
}
</style>