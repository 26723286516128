<template>
  <div class="CasePresentationDetails">
    <div class="PresentationDetails">
      <div class="TrendsDetailsLeft">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="padding-left: 20px">
          <el-breadcrumb-item :to="{ path: '/HomeView' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/CasePresentation' }"
            >案例展示</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{ caseDetail.caseName }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="DetailsBox">
          <p class="pTitle">{{ caseDetail.caseName }}</p>
          <div class="informationBox">
            <div class="informationLeft">
              <p>{{ caseDetail.createTime }}</p>
              <p>
                <i class="el-icon-view"></i>
                <span style="margin-left: 8px">{{ caseDetail.browseNum }}</span>
              </p>
            </div>
            <div class="informationBoxRight">
              <i class="iconfont icon-wujiaoxingkong"></i>
              <!--          <i class="iconfont icon-wujiaoxing-"></i>  选中-->
              <span style="margin-left: 5px">收藏</span>
            </div>
          </div>
          <div class="classification">
            <p>
              <span>房屋类型</span>
              <span>{{ getHouseType(caseDetail.houseType) }}</span>
            </p>
            <p>
              <span>房屋风格</span>
              <span>{{ getDardensStyle(caseDetail.gardensStyle) }}</span>
            </p>
            <p>
              <span>面积</span>
              <span>{{ caseDetail.area + "㎡" }}</span>
            </p>
            <p>
              <span>房屋预算</span>
              <span>{{ caseDetail.budget }}</span>
            </p>
          </div>
          <div
            style="padding: 0 20px; margin-bottom: 50px"
            class="htmlDiv"
            v-html="htmlDiv"
          ></div>
        </div>
      </div>
      <div class="TrendsDetailsRight">
        <div v-if="infoPersonalOrCompanyList">
          <div class="HotSearchBox" v-if="[2,3,4,5].includes(bizType)">
            <div class="imgBox" @click="goPersionDetail">
              <img :src="infoPersonalOrCompanyList.img" alt="" />
            </div>
            <p class="name">{{ infoPersonalOrCompanyList.name }}</p>
            <p class="more" @click="goPersionDetail">
              <span>更多案例</span>
              <span>
                {{ infoPersonalOrCompanyList.caseNums }} 套<i
                  style="color: #188252"
                  class="el-icon-arrow-right"
                ></i
              ></span>
            </p>
          </div>
          <div class="companyInfo" v-else>
            <div class="companyLog" @click="GoCompanyDetails">
              <img :src="infoPersonalOrCompanyList.img" alt="" />
            </div>
            <div class="companyNmae">
              <p @click="GoCompanyDetails">
                {{ infoPersonalOrCompanyList.name }}
              </p>
            </div>
            <div class="pingfen">
              <el-rate
                v-model="value"
                disabled
                :colors="[
                  '#F6461D',
                  '#F6461D',
                  '#F6461D',
                  '#F6461D',
                  '#F6461D',
                ]"
                text-color="#F6461D"
                score-template="{value}"
              >
              </el-rate>
            </div>
            <div class="identification">
              <div class="type_f">
                <i class="el-icon-success"></i><span>企业认证</span>
              </div>
            </div>
            <!-- <div class="address">
            <i class="el-icon-location-outline"></i
            ><span>新城 - 长乐中路 -熙景园林装饰工程</span>
          </div> -->
          </div>
        </div>
        <!-- <div class="noCompany" v-else>
          <p>暂无所属公司，设计师，造价师信息</p>
        </div> -->
        <WantQuote style="margin-left: 35px" />
        <advertisementLeft
          v-if="wideUrl"
          :wideUrl="wideUrl"
          @click.native="goRoute"
          style="margin-top: 20px; margin-left: 35px"
        />
      </div>
    </div>
    <p class="recommend">其他案例推荐</p>
    <div class="seckill-goods" v-if="caseDetailList">
      <ul class="clearfix">
        <li
          style="cursor: pointer"
          v-for="item in caseDetailList"
          :key="item.id"
          @click="GoDetails(item)"
        >
          <div class="ListTop">
            <img :src="item.coverImg" alt="" />
          </div>
          <div class="ListBottom">
            <div class="ListLeft">
              <p class="pTitle">{{ item.caseName }}</p>
              <p class="pTitle2">
                {{
                  getHouseType(item.houseType) +
                  "丨" +
                  getDardensStyle(item.gardensStyle) +
                  "丨" +
                  item.area +
                  "㎡"
                }}
              </p>
            </div>
            <p class="ListRight">{{ item.budget }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="caseDetail" v-else>
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
import WantQuote from "@/components/wantQuote/wantQuote";
import AdvertisementLeft from "@/components/advertisement/advertisementLeft";
import { getDicts } from "@/request/dict/data";
import { selectDictLabel, selectDictLabels, handleMoney } from "@/utils/weihua";
import { getCaseDetail, getInfoPersonalOrCompany } from "@/request/case";
import { getHomePageConstructCaseList, getBanner } from "@/request/home/home";
export default {
  name: "CasePresentationDetails",
  components: { AdvertisementLeft, WantQuote },
  data() {
    return {
      value: 5,
      name: "",
      id: "",
      caseDetail: [],
      caseDetailList: [],
      houseTypeList: [],
      dardensStyleList: [],
      htmlDiv: "",
      wideUrl: null,
      bizId: null,
      bizType: null,
      infoPersonalOrCompanyList: [],
      url: null,
    };
  },
  activated() {
    this.getBBannerList();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    } else {
      this.$router.push({
        path: "/",
      });
    }
    this.getCaseDetalisList(this.id);
    getDicts("msk_sys_house_type").then((res) => {
      this.houseTypeList = res.data;
    });
    getDicts("msk_sys_gardens_style").then((res) => {
      this.dardensStyleList = res.data;
    });
  },
  created() {},
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 7 }).then((res) => {
        if (res.data.length != 0) {
          this.wideUrl = res.data[0].filePath;
          this.url = res.data[0].routeContent;
        } else {
          this.wideUrl = null;
          this.url = null;
        }
      });
    },
    goRoute() {
      if(this.url){
      window.open(this.url,"_blank");

      }else{
        return 
      }
    },
    getCaseDetalisList(id) {
      getCaseDetail(id).then((res) => {
        if (res.code == 200) {
          this.caseDetail = res.data;
          this.caseDetail.budget = handleMoney(res.data.budget);
          this.bizType = res.data.personIdentity;
          this.bizId = res.data.bizId;
          this.getnfoPersonalOrCompanyList();
          this.htmlDiv = res.data.caseDetail.replace(
            /\<img/gi,
            '<img style="width:98%;height:auto;margin:10px 0 10px 10px" '
          );
        }
      });
      getHomePageConstructCaseList().then((res) => {
        if (res.code == 200) {
          // this.caseDetailList = res.rows.slice(0,6);
          const arr = res.rows.slice(0, 6);
          arr.forEach((item) => {
            item.budget = handleMoney(item.budget);
          });
          this.caseDetailList = arr;
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    getnfoPersonalOrCompanyList() {
      getInfoPersonalOrCompany({
        personIdentity: this.bizType,
        bizId: this.bizId,
      }).then((res) => {
        if (res.data) {
          this.infoPersonalOrCompanyList = res.data;
        }
      });
    },
    goPersionDetail() {
      if (this.bizType == 2) {
        this.$router.push({
          name: "designerDetails",
          query: { id: this.bizId, personIdentity: this.bizType },
        });
      } else if (this.bizType == 3) {
        this.$router.push({
          name: "costEngineerDetails",
          query: { id: this.bizId,personIdentity: this.bizType  },
        });
      }else if (this.bizType == 1||this.bizType == 5||this.bizType == 6||this.bizType == 7||this.bizType == 8||this.bizType == 10||this.bizType == 11||this.bizType == 12) {
        this.$router.push({
          name: "GardenDetails",
          query: { id: this.bizId ,personIdentity: this.bizType },
        });
      } else {
        this.$router.push({
          path: "/workerDetails",
          query: {
            id: this.bizId,
            personIdentity: this.bizType 
          },
        });
      }
    },
    GoCompanyDetails() {
      this.$router.push({
        path: "/GardenDetails",
        query: {
          id: this.bizId,
        },
      });
    },
    getHouseType(value) {
      return selectDictLabel(this.houseTypeList, value);
    },
    getDardensStyle(value) {
      return selectDictLabel(this.dardensStyleList, value);
    },
    GoDetails(e) {
      this.$router.push({
        path: "/CasePresentationDetails",
        query: {
          id: e.id,
        },
      });
      this.$router.go(0);
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
::v-deep .HotSearchBox {
  width: 300px !important;
}
.CasePresentationDetails {
  width: 1320px;
  margin: 0 auto;

  .PresentationDetails {
    display: flex;
    justify-content: space-between;
    .TrendsDetailsLeft {
      width: 75%;
      margin-top: 20px;
      margin-right: 20px;
      background-color: #FFFFFF;
      padding-top: 20px;
      .DetailsBox {

        margin-top: 20px;

        .pTitle {
          font-size: 26px;
          font-weight: 500;
          color: #4a4d4b;
          width: 95%;
          margin: 30px auto 0;
        }

        .informationBox {
          width: 95%;
          margin: 20px auto 0;
          padding-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .informationLeft {
            display: flex;
            align-items: center;

            p:nth-child(2) {
              margin: 0 36px;
            }

            p {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }
          }

          .informationBoxRight {
            font-size: 12px;
            cursor: pointer;
            font-weight: 400;
            color: #999999;

            .icon-wujiaoxingkong {
              font-size: 12px;
              cursor: pointer;
              font-weight: 400;
              color: #999999;
            }
          }
        }

        .classification {
          width: 95%;
          margin: 0 auto 20px;
          height: 42px;
          background: #fafafa;
          border: 1px solid #ebebeb;
          display: flex;
          justify-content: space-around;
          align-items: center;

          p {
            margin: 0 10px;

            span:nth-child(1) {
              font-size: 12px;
              color: #adb3b0;
              margin-right: 10px;
            }

            span:nth-child(2) {
              font-size: 12px;
              color: #636665;
            }
          }
        }
      }
    }

    .TrendsDetailsRight {
      width: 26%;
      margin-top: 20px;

      .HotSearchBox {
        width: 294px;
        margin-left: 35px;
        height: 295px;
       background-color: #FFFFFF;
        margin-bottom: 20px;
        .imgBox {
          cursor: pointer;
          margin-top: 30px;
          text-align: center;
          height: 146px;
          img {
            width: 119px;
            height: 146px;
          }
        }
        .name {
          margin-top: 18px;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          color: #4a4c4b;
          margin-bottom: 10px;
        }
        .type {
          font-size: 12px;
          margin-top: 8px;
          margin-bottom: 18px;
          text-align: center;
          font-weight: 400;
          color: #949997;
        }
        .more {
          cursor: pointer;
          text-align: center;
          span:nth-child(1) {
            font-size: 14px;
            color: #949997;
          }
          span:nth-child(2) {
            font-size: 14px;
            cursor: pointer;
            color: #188252;
            .el-icon-arrow-right {
              font-size: 14px;
              color: #188252;
            }
          }
        }
      }
      .noCompany {
        width: 300px;
        height: 300px;
        margin: 0 0 20px 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        p {
          line-height: 300px;
          color: #999;
        }
      }
      .companyInfo {
        height: 240px;
        width: 294px;
        margin-left: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 4px;
        background-color: #FFFFFF;
        margin-bottom: 40px;
        .companyLog {
          cursor: pointer;
          width: 100px;
          height: 100px;
          margin-bottom: 20px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .companyNmae {
          cursor: pointer;
          p {
            cursor: pointer;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }
        .pingfen {
          height: 42px;
          display: flex;
          margin-bottom: 10px;
          span {
            margin-left: 10px;
            line-height: 20px;
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #f66a1d;
          }
        }
        .identification {
          display: flex;
          margin-bottom: 10px;
          .type_f {
            width: 79px;
            height: 21px;
            background: linear-gradient(-90deg, #fae3cd, #e5bc98);
            border-radius: 10px;
            text-align: center;
            i {
              width: 11px;
              height: 13px;
            }
            span {
              margin-left: 4px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #3b3834;
            }
          }
          .type_t {
            margin-left: 9px;
            width: 65px;
            height: 21px;
            background: linear-gradient(-90deg, #faf2cd, #eee154);
            border-radius: 10px;
            text-align: center;
            i {
              width: 11px;
              height: 13px;
            }
            span {
              margin-left: 4px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #3b3834;
            }
          }
        }

        .address {
          display: flex;
          i {
            color: #2a9261;
            line-height: 20px;
          }
          span {
            margin-left: 10px;
            display: inline-block;
            /* 文字过多换行显示，现在强行让文字在一行显示 */
            white-space: nowrap;
            /* 然后然溢出的部分文字隐藏 */
            overflow: hidden;
            /* 文字溢出显示用省略号。  */
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .recommend {
    font-size: 20px;
    font-weight: bold;
    color: #4a4d4b;
    margin: 40px 0 26px 0;
  }
  .seckill-goods {
    display: inline-block;

    .clearfix {
      margin-left: -24px;
      li {
        width: 414px;
        margin-top: 20px;
        float: left;
        flex: 1;
        margin-left: 24px;
        background-color: #FFFFFF;
        .ListTop {
          width: 414px;
          height: 240px;
          cursor: pointer;
          img {
            width: 414px;
            height: 240px;
          }
        }

        .ListBottom {
          display: flex;
          cursor: pointer;
          .ListLeft {
            width: 60%;
            padding-left: 20px;
            .pTitle {
              margin-top: 20px;
              font-size: 14px;
              cursor: pointer;
              overflow: hidden;
              color: #000000;
              width: 100%;
              display: -webkit-box; /*弹性伸缩盒子*/
              -webkit-box-orient: vertical; /*子元素垂直排列*/
              -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
              text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
            }
            .pTitle:hover {
              margin-top: 20px;
              font-size: 14px;
              cursor: pointer;
              overflow: hidden;
              color: #188252;
              width: 100%;
              display: -webkit-box; /*弹性伸缩盒子*/
              -webkit-box-orient: vertical; /*子元素垂直排列*/
              -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
              text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
            }
            .pTitle2 {
              padding-top: 10px;
              padding-bottom: 10px;
              font-size: 14px;
              color: #999999;
            }
          }

          .ListRight {
            width: 30%;
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #ee6a31;
            text-align: right;
            padding-right: 20px;
          }
        }
      }
    }
  }
  .caseDetail {
    width: 100%;
    height: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      text-align: center;
      font-size: 20px;
      color: #999999;
    }
  }
}
</style>
