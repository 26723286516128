import request from '@/request/request'

// 登录方法
export function login(username, password, code, uuid,loginSource,loginType) {
  const data = {
    username,
    password,
    code,
    uuid,loginSource,loginType
  }
  return request({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    headers:{
      Authorization:sessionStorage.getItem("token")
    },
    method: 'get'
  })
}
//判断是否入驻
export function isSettle(data) {
  return request({
    url: '/common/getIsSettle',
    method: 'post',
    headers:{
      Authorization:sessionStorage.getItem("token")
    },
    data:data
  })
}
// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}
//点击获取手机验证码
export function getCode(data){
  return request({
    url:"/api/web/sys/sendAliCode",
    method:"post",
    data:data
  })
}
// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
//注册
export function subRegister(data){
  return request({
    url:"/api/web/sys/register",
    method:"post",
    data:data
  })
}

//登录
export function getLogin(data){
  return request({
    url:"/login",
    method:"post",
    data:data
  })
}
//OSS

export function getOssToken(){
  return request({
    url:"api/web/aliOss/sys/getAccessToken/fengjingyuanlin",
    method:"get",
    withCredentials: false,
  })
}
//重置密码密码验证提交
export function subPassWord(data){
  return request({
    url:"api/web/sys/modifyPassword",
    method:"post",
    data:data
  })
}
//重置密码验证手机号码
export function replaPassWord(data){
  return request({
    url:"api/web/sys/forgetPassword",
    method:"post",
    data:data
  })
}