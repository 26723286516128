<template>
  <div class="searchBox">
    <div class="searchBoxTitle" @click="routeHome">
<!--      <span>风景园林云平台</span>-->
<!--      <span></span>-->
      <img src="../../assets/image/logo.png" alt="">
    </div>
    <div class="searchBoxInput">
      <div class="searchBoxInputBox">
        <el-cascader
          v-model="value"
          :options="options"
          @change="handleChange($event)"
        ></el-cascader>
        <p style="color: #ebebeb">|</p>
        <el-input v-model="searchInfo" @keyup.enter.native="seatch" placeholder="请输入搜索内容"></el-input>
      </div>
      <el-button @click="seatch">搜索</el-button>
      <!-- <el-button @click="releaseRequir">发布需求</el-button> -->
    </div>

    <div class="searchBoxPhone">
      <p>24小时客服热线</p>
      <p>029-85233571</p>
    </div>
  </div>
</template>

<script>
import { getSeatch } from "@/request/home/home";
export default {
  name: "searchBox",
  data() {
    return {
      placeholder: "请输入",
      value: ["1"],
      searchInfo: "",
      type: "1",
      serviceType: null,
      options: [
        {
          value: "1",
          label: "建筑公司",
        },
        {
          value: "2",
          label: "找设计",
        },
        {
          value: "3",
          label: "找造价",
        },
        {
          value: "4",
          label: "找工人",
        },
        {
          value: "5",
          label: "找材料",
        },
        {
          value: "6",
          label: "找服务",
          children: [
            {
              value: "1",
              label: "园林设计",
            },
            {
              value: "2",
              label: "园林施工",
            },
            {
              value: "3",
              label: "园林造价",
            },
            {
              value: "4",
              label: "维护养护",
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      if (
        to.name == "FindDesign" ||
        to.name == "FindService" ||
        to.name == "GardenArchitecture"
      ) {
        if (JSON.parse(localStorage.getItem("type"))) {
          localStorage.removeItem("type");
        }
      }
    },
  },
  // watch: {
  //   $route(to, from) {
  //     if (to.path == "/HomeView") {
  //       this.value = ["1"];
  //       this.searchInfo = null;
  //       this.serviceType = null;
  //     }
  //   },
  // },
  created() {
    if (this.$route.query.type) {
      this.value = [];
      this.value.push(this.$route.query.type);
      this.searchInfo = this.$route.query.searchInfo;
      this.$route.query.serviceType
        ? this.value.push(this.$route.query.serviceType)
        : null;
    } else {
      this.value = ["1"];
      this.searchInfo = null;
      this.serviceType = null;
    }
  },
  methods: {
    routeHome() {
      this.$router.push({ path: "/HomeView" });
    },
    handleChange(e) {
      if (e[6]) {
        this.type = e[0];
        this.serviceType = e[1];
      } else {
        this.type = e[0];
      }
    },
    seatch() {
      if (this.searchInfo && this.type) {
        switch (this.type) {
          //建筑公司
          case '1':
            this.$router.push({
              name: "GardenArchitecture",
              query: { type: this.type, searchInfo: this.searchInfo },
            });
            this.$router.go(0);
            break;
          //设计
          case '2':
            this.$router.push({
              name: "FindDesign",
              query: { id: 2, type: this.type, searchInfo: this.searchInfo },
            });
            this.$router.go(0);
            break;
          //造价
          case '3':
            this.$router.push({
              name: "costEngineer",
              query: { id: 3, type: this.type, searchInfo: this.searchInfo },
            });
            this.$router.go(0);
            break;
          //工人
          case '4':
            this.$router.push({
              name: "worker",
              query: { id: 4, type: this.type, searchInfo: this.searchInfo },
            });
            this.$router.go(0);
            break;
          //草料
          case '5':
            this.$router.push({
              name: "findMaterials",
              query: { id: 5, type: this.type, searchInfo: this.searchInfo },
            });
            this.$router.go(0);
            break;
          //服务
          case "6":
            this.$router.push({
              name: "FindService",
              query: {
                id: 6,
                type: this.type,
                searchInfo: this.searchInfo,
                serviceType: this.serviceType ? this.serviceType : "",
              },
            });
            this.$router.go(0);
            break;
        }
      }
    },
    //发布需求
    releaseRequir() {
      this.$message({
        message: "功能开发中,敬请期待",
        type: "success",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.searchBox {
  width: 1320px;
  margin: 0 auto;
  background: #ffffff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .searchBoxTitle {
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
    font-style: italic;

    span:nth-child(1) {
      color: #188252;
    }

    span:nth-child(2) {
      color: #3c4340;
    }
  }

  .searchBoxInput {
    display: flex;

    .searchBoxInputBox {
      display: flex;
      align-items: center;
      border: 2px solid #188252;

      ::v-deep .el-input__inner {
        border: 0;
      }
    }

    ::v-deep .el-button {
      background: #188252;
      border-radius: 0;
      border: 1px solid #188252;
      color: #ffffff;
    }
  }

  .searchBoxPhone {
    p:nth-child(1) {
      font-size: 16px;
      text-align: right;
      color: #333333;
    }
    p:nth-child(2) {
      font-size: 20px;
      color: #f66a1d;
    }
  }
}
</style>
