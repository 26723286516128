import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@store'
import ElementUI from 'element-ui';
import disableDrag from "@/disableDrag";


//引入CSS初始设置
import '@/assets/css/base.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/icon/iconfont.css'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.mixin(disableDrag);

const isDebug_mode = false;
Vue.config.debug = isDebug_mode;
Vue.config.devtools = isDebug_mode;
Vue.config.productionTip = isDebug_mode;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
