export default {
    mounted() {
        this.disableDragEvent();
    },
    methods: {
        disableDragEvent() {
            var outer = document.getElementsByTagName("img");
            outer = Array.from(outer);
            outer.forEach(function (item) {
                item.ondragstart = dragover;
            });
            function dragover(event) {
                // 阻止默认行为，变成可放置的
                event.preventDefault();
            }
        },
    },
};