<template>
  <div class="mine">
    <div class="top">
      <NavCon @mouseover.native="GoIsShow" />
    </div>
    <div class="topHome">
      <div class="btnHome" @click="goHome">
        <img
          src="../../assets/image/logo.png"
          alt=""
        />
      </div>
      <div class="border"></div>
      <div class="settleTit">个人中心</div>
    </div>
    <div class="conTent">
      <el-container>
        <el-aside width="200px" style="background-color: white; height: 500px">
          <el-menu
            active-text-color="#188252"
            :default-active="activeIndex"
            @open="handleOpen"
            :unique-opened="true"
            :default-openeds="default_openeds_array"
            router
            @select="handleSelect"
          >
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-postcard"></i>个人中心</template
              >
              <el-menu-item-group>
                <el-menu-item index="/personalData"> 个人资料 </el-menu-item>
                <el-menu-item index="/messageManagement">
                  消息管理
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-postcard"></i>账号管理</template
              >
              <el-menu-item-group>
                <el-menu-item index="/auth"> 认证管理 </el-menu-item>

                <el-menu-item index="/passWorld"> 密码设置 </el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-submenu index="3">
              <template slot="title">
                <i class="el-icon-edit"></i>赛事管理
              </template>
              <el-menu-item-group>
                <el-menu-item index="/myMatch"> 我的赛事 </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>

        <el-container
          style="
            margin-left: 20px;
            background-color: #fff;
            background-repeat: no-repeat;
            background-size: 100% 100%;
          "
        >
          <el-main>
            <div style="width: 100%; margin: 0 auto">
              <router-view></router-view>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </div>
    <div class="footer">
      <footerPage />
    </div>
  </div>
</template>

<script>
import NavCon from "@/components/navcon/navCon";
import footerPage from "@/components/footerPage/footerPage";
export default {
  components: { NavCon, footerPage },
  data() {
    return {
      activeIndex: "/personalData",
      default_openeds_array:["1","2","3"]
    };
  },
  created() {},
  beforeMount(){
    this.$router.push({path:"/personalData"})
  },
  methods: {
    handleSelect(key, keyPath) {  
      this.$router.push({path:key})
    },
    handleOpen(e) {
      this.default_openeds_array.push(e);
    },
    goHome() {
      this.$router.push({ path:"/HomeView"});
    },
    GoIsShow() {
      this.$refs.Navigation;
    },
  },
};
</script>

<style lang="scss" scoped>
.mine {
  width: 100%;
  .topHome {
    width: 1350px;
    margin: 0 auto;
    height: 80px;
    background: #ffffff;
    box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.05);
    padding: 0 15%;
    display: flex;
    .btnHome {
      width: 150px;
      height: 30px;
      line-height: 55px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .border {
      width: 2px;
      height: 30px;
      background-color: #e6e6e6;
      margin: 0 30px;
      align-self: center;
    }
    .settleTit {
      line-height: 80px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #4d4d4d;
    }
  }
  .conTent {
    width: 1350px;
    margin: 0 auto;
    background-color: #f5f5f5;
    padding: 20px 15%;
  }
}
</style>