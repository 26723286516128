import request from '@/request/request'

//招采信息列表
export function getIndustryTrendsList(data){
    return request({
        url:"/api/web/recommend/list",
        method:"get",
        params:data
    })
}
//招采信息详情
export function getIndustryTrendsDetail(id){
    return request({
        url:"/api/web/recommend/"+id,
        method:"get",
    })
}