<template>
  <div class="Navigation">
    <div class="NavigationMenu">
      <div
        class="NavigationMenuBox"
        v-for="itemZero in MenuList"
        @mouseenter="isShow(itemZero)"
        @mouseleave="IsShowType = false"
        :key="itemZero.id"
      >
        <div
          class="MenuItemAll"
          :class="itemZero.IsColor ? 'MenuItemTrue' : 'MenuItemFalse'"
        >
          <span @click="GoRouter(itemZero)" v-if="itemZero.id !== 3">{{
            itemZero.name
          }}</span>
          <span @mouseenter="IsShowType = true" v-if="itemZero.id === 3"
            >{{ itemZero.name }}
          </span>
          <div
            class="MenuItemChildrenBox"
            v-if="IsShowType"
            @mouseleave="IsShowType = false"
          >
            <div
              class="MenuItemChildren"
              v-for="itemOne in itemZero.children"
              :key="itemOne.id"
              
            >
            <!-- @mouseover="GoRouter1" -->
              <span @click="GoRouterChildren(itemZero, itemOne)">
                {{ itemOne.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="WeXinQrCode">
        <img src="../../assets/image/WeiXinQrCode.png" alt="" />
        <div class="weXinQrCodeBig">
          <div class="title">更多功能</div>
          <div class="title_con">请访问小程序</div>
          <div class="code_img">
            <img
              class="codeImg"
              src="../../assets/image/weixinCode.png"
              alt=""
            />
          </div>
          <div class="title_bom">预约、下单，扫码登录小程序</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      IsShowType: false,
      MenuList: [
        {
          path: "/HomeView",
          name: "首页",
          id: 1,
          children: [],
          IsColor: true,
          IsShow: false,
        },
        {
          path: "/GardenArchitecture",
          name: "入驻公司",
          id: 2,
          children: [],
          IsColor: false,
          IsShow: false,
        },
        {
          path: "",
          name: "专业服务",
          id: 3,
          children: [
            {
              path: "/FindDesign",
              name: "找设计",
              id: 2,
              IsColor: false,
              IsShow: true,
            },

            {
              path: "/costEngineer",
              name: "找造价",
              id: 3,
              IsColor: false,
              IsShow: false,
            },
            {
              path: "/findMaterials",
              name: "找材料",
              id: 5,
              IsColor: false,
              IsShow: false,
            },
            {
              path: "/worker",
              name: "找工人",
              id: 4,
              IsColor: false,
              IsShow: false,
            },
            // {
            //   path: "/FindMaintenance",
            //   name: "找养护",
            //   id: 5,
            // },
            {
              path: "/FindService",
              name: "找服务",
              id: 6,
            },
          ],
          IsColor: false,
          IsShow: false,
        },
        {
          path: "/CasePresentation",
          name: "案例展示",
          id: 4,
          children: [],
          IsColor: false,
          IsShow: false,
        },
        {
          path: "/events",
          name: "赛事活动",
          id: 5,
          children: [],
          IsColor: false,
          IsShow: false,
        },
        {
          path: "/industryTrends",
          name: "行业动态",
          id: 6,
          children: [],
          IsColor: false,
          IsShow: false,
        },
        // {
        //   path: "/LandscapeArchitecture",
        //   name: "风景园林学院",
        //   id: 7,
        //   children: [],
        //   IsColor: false,
        //   IsShow: false,
        // },
      ],
    };
  },
  created() {
    let str = location.href;
    let path = str.split("#")[1];
    this.MenuList.forEach((itemFor) => {
      itemFor.IsColor = itemFor.path === path;
    });
  },
  methods: {
    isShow(e) {
      if (e.id == 3) {
        e.IsShow = true;
      }
    },
    isShow1() {
      this.MenuList[2].IsShow = true;
    },
    GoRouter(item) {
      if (item.children.length === 0) {
        this.MenuList.forEach((itemFor) => {
          itemFor.IsColor = false;
        });
        this.MenuList[2].IsShow = false;
        item.IsColor = true;
        // if (item.id == 5) {
        //   this.$message({
        //     message: "功能升级中，后续敬请期待...",
        //     type: "success",
        //   });
        // } else {
        this.$router.push({
          path: item.path,
        });
        // }
      } else {
        item.IsShow = true;
      }
    },
    GoRouterChildren(eOne, eTwo) {
      this.MenuList.forEach((itemFor) => {
        itemFor.IsColor = false;
      });
      eOne.IsColor = true;
      eOne.IsShow = false;
      this.$router.push({
        path: eTwo.path,
        query: { id: eTwo.id },
      });
    },
    GoRouter1() {
      this.MenuList[2].IsShow = false;
    },
    childAction(e) {
      this.MenuList[2].IsShow = false;
    },
  },
};
</script>
<style lang="scss">
.Navigation {
  width: 100vw;
  height: 76px;
  background: #ffffff;
  -webkit-box-shadow: 0px 10px 20px 5px rgb(0 0 0 / 3%);

  .NavigationMenu {
    width: 1320px;
    margin: 0 auto;
    line-height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .NavigationMenuBox {
      //margin-right: 76px;
      max-width: 120px;
      .MenuItemTrue {
        color: #188252;
        font-size: 16px;
        font-weight: bold;
      }

      .MenuItemFalse {
        color: #4c4c4c;
        font-size: 16px;
      }

      .MenuItemAll {
        position: relative;
        display: flex;
        align-items: center;

        .MenuItemChildrenBox {
          padding: 0;
          z-index: 9999;
          position: absolute;
          background-color: #fff;
          top: 70px;
          width: 110px;

          .MenuItemChildren {
            margin: 20px 0 20px 0;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            text-align: center;
          }

          .MenuItemChildren:hover {
            color: #188252;
          }
        }
      }
    }

    .NavigationMenuBox:hover {
      cursor: pointer;
    }

    .WeXinQrCode {
      width: 250px;
      height: 76px;
      display: flex;
      margin-left: 135px;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      img {
        cursor: pointer;
      }
      img:hover + .weXinQrCodeBig {
        display: block;
        z-index: 999;
      }
      .weXinQrCodeBig {
        display: none;

        width: 159px;
        height: 214px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        position: absolute;
        top: 76px;
        left: 92px;
        padding: 30px;
        .title {
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .title_con {
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .code_img {
          margin: 0 auto;
          width: 153px;
          height: 156px;
          .codeImg {
            width: 100%;
            height: 100%;
          }
        }
        .title_bom {
          font-size: 12px;
          font-weight: 400;
          color: #f66a1d;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
