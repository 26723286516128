<template>
  <div class="workerDetails">
    <div class="workerDetailsLeft">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="myrou" :to="{ path: '/HomeView' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item class="myrou" :to="{ name: 'worker',query:{id:4} }"
          >找工人</el-breadcrumb-item
        >
        <el-breadcrumb-item>工人详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="DetailsBoxOne">
        <div class="DetailsBoxOneLeft">
          <div class="OneLeftImg">
            <img :src="workerDetailList.personalImg" alt="" />
          </div>
          <div class="OneLeftText">
            <p>{{ workerDetailList.personalName }}</p>
            <p>施工队长</p>
            <el-rate
              v-model="value"
              disabled
              :colors="['#F6461D', '#F6461D', '#F6461D', '#F6461D', '#F6461D']"
              text-color="#F6461D"
              score-template="{value}"
            >
            </el-rate>
          </div>
        </div>
        <div class="DetailsBoxOneRight">
          <div class="wujiaoxing" @click="follow(workerDetailList)">
            <!-- <i class="iconfont icon-wujiaoxing-"></i> -->
            <p class="shareP">{{ workerDetailList.isCollect == 0 ? "收藏" : "已收藏" }}</p>
          </div>
          <!-- <div class="share">
            <i class="el-icon-share"></i>
            <p class="shareP">分享</p>
          </div> -->
        </div>
      </div>
      <div class="DetailsBoxTwo">
        <p class="introduce">施工团队介绍</p>
        <ul class="introduceUl">
          <li>
            <span>成立年限</span>
            <span>{{
              getYearNum(
                workerDetailList.jobStartTime,
                workerDetailList.jobEndTime
              ) + year
            }}</span>
          </li>
          <li>
            <span>团队成员</span>
            <span>{{ workerDetailList.teamNum + "人" }}</span>
          </li>
        </ul>
        <ul class="introduceUl">
          <li>
            <span>竣工案例</span>
            <span>{{ workerDetailList.caseNums + "套" }}</span>
          </li>
          <!-- <li>
            <span>在施工地</span>
            <span>10</span>
          </li> -->
        </ul>
        <div class="introduceDiv">
          <span>所在区域</span>
          <span>{{ workerDetailList.detailArea }}</span>
        </div>
        <div class="introduceDiv">
          <span>团队实力</span>
          <span>{{ workerDetailList.teamPowerful }}</span>
        </div>
        <!-- <div class="introduceDiv">
          <span>服务范围</span>
          <span>风景园林水电木工、瓦工。</span>
        </div> -->
        <p class="IsButton" @click="IsButton">立即咨询</p>
      </div>
      <el-dialog
        title="联系客服"
        :show-close="false"
        width="400px"
        :visible.sync="dialogTableVisible"
      >
        <div class="inquiry">
          <div class="DialogTop">
            <div class="title">24小时客服电话</div>
            <div class="title1">029-85233571</div>
            <div class="img">
              <img src="../../assets/image/baojia03.png" alt="" />
            </div>
            <div class="bom">扫一扫添加客服微信</div>
          </div>
          <p class="colseBox" @click="DialogClose">
            <i class="el-icon-close"></i>
          </p>
        </div>
      </el-dialog>
      <el-tabs
        class="ServiceTopBoxTabs"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="上架服务" name="1">
          <div class="seckill-goods" v-if="serviceList.length != 0">
            <ul class="clearfix">
              <li
                v-for="item in serviceList"
                :key="item.id"
                @click="goServerDetail(item.id)"
              >
                <div class="ListTop">
                  <img :src="item.coverImg" alt="" />
                </div>
                <div class="ListBottom">
                  <div class="ListLeft">
                    <p class="pTitle">{{ item.serviceName }}</p>
                    <p class="pTitle2" style="color: #f66a1d">
                      {{ item.servicePrice + getPrizeType(item.priceType) }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="emptyList" v-else>
            <p>暂无数据</p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="施工案例" name="2">
          <div class="seckill-goods" v-if="engCase.length != 0">
            <ul class="clearfix">
              <li
                v-for="item in engCase"
                :key="item.id"
                @click="goCaseDetail(item)"
              >
                <div class="ListTop">
                  <img :src="item.coverImg" alt="" />
                </div>
                <div class="ListBottom">
                  <div class="ListLeft">
                    <p class="pTitle">{{ item.caseName }}</p>
                    <p class="pTitle2">
                      {{
                        getHouseType(item.houseType) +
                        "丨" +
                        getDardensStyle(item.gardensStyle) +
                        "丨" +
                        item.area +
                        "㎡"
                      }}
                    </p>
                  </div>
                  <p class="ListRight">{{ item.budget + "万" }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="emptyList" v-else>
            <p>暂无数据</p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="workerDetailsRight">
      <WantQuote style="margin-left: 35px" />
      <advertisementLeft
        :wideUrl="wideUrl"
        @click.native="goRoute"
        style="margin-top: 20px; margin-left: 35px"
      />
    </div>
  </div>
</template>

<script>
import WantQuote from "@/components/wantQuote/wantQuote";
import AdvertisementLeft from "@/components/advertisement/advertisementLeft";
import { getFindDesign, getFindDesignDetail } from "@/request/findDesign";
import { getDicts } from "@/request/dict/data";
import { getCaseList } from "@/request/case";
import { getConstructService } from "@/request/findServer";
import { selectDictLabel, selectDictLabels, handleMoney } from "@/utils/weihua";
import { getBanner, collection, unCollection } from "@/request/home/home";
export default {
  name: "workerDetails",
  components: { AdvertisementLeft, WantQuote },
  data() {
    return {
      dialogTableVisible: false,
      id: "",
      year: null,
      value: 5,
      workerDetailList: [],
      serviceList: [],
      engCase: [],
      houseTypeList: [],
      dardensStyleList: [],
      prizeTypeList: [],
      activeName: "1",
      wideUrl: null,
      url: null,
    };
  },
  created() {
    this.getBBannerList();
    this.getDetail();
    this.getCaseList();
    this.getServersList();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    } else {
      this.$router.push({
        path: "/",
      });
    }
    getDicts("msk_sys_house_type").then((res) => {
      this.houseTypeList = res.data;
      this.houseTypeList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
    getDicts("msk_price_type").then((res) => {
      this.prizeTypeList = res.data;
    });
    getDicts("msk_sys_gardens_style").then((res) => {
      this.dardensStyleList = res.data;
      this.dardensStyleList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
  },
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 7 }).then((res) => {
        this.wideUrl = res.data[0].filePath;
        this.url = res.data[0].routeContent;
      });
    },
    goRoute() {
      if(this.url){
      window.open(this.url,"_blank");

      }else{
        return 
      }
    },
    getDetail() {
      const id = this.$route.query.id;
      getFindDesignDetail(id).then((res) => {
        this.workerDetailList = res.data;
      });
    },
    getYearNum(startTime, endTime) {
      if (startTime && endTime) {
        var timestr = startTime.replace(/-/g, "-");
        var startTime1 = new Date(timestr);
        var timestr1 = endTime.replace(/-/g, "-");
        var endTime1 = new Date(timestr1);
        var y = endTime1.getFullYear();
        var y1 = startTime1.getFullYear();
        var m = endTime1.getMonth() + 1;
        var m1 = startTime1.getMonth() + 1;
        if (y == y1) {
          this.year = "年";
          return 1;
        } else {
          this.year = "年";
          return y - y1;
        }
      } else {
        return;
      }
    },
    //设计案例
    getCaseList() {
      const bizId = this.$route.query.id;
      getCaseList({ bizId }).then((res) => {
        const arr = res.rows.slice(0, 6);
        arr.forEach((item) => {
          item.budget = handleMoney(item.budget);
        });
        this.engCase = arr;
      });
    },
    goCaseDetail(e) {
      this.$router.push({
        path: "/CasePresentationDetails",
        query: {
          id: e.id,
        },
      });
    },
    //上架服务
    getServersList() {
      const bizId = this.$route.query.id;
      getConstructService({ bizId }).then((res) => {
        this.serviceList = res.rows.slice(0, 6);
      });
    },
    follow(e) {
      const data = {
        bizId: this.$route.query.id,
        bizType: 4,
      };
      if (e.isCollect == 0) {
        collection(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "收藏成功，请登录小程序查看",
              type: "success",
            });
            this.getDetail()
          }
        });
      } else {
        unCollection(data).then((res) => {
          if(res.code == 200){
            this.$message({
              message: "取消成功",
              type: "success",
            });
            this.getDetail()
          }
          
        });
      }

      // this.$message({
      //   message: "功能开发中,敬请期待",
      //   type: "success",
      // });
    },
    goServerDetail(e) {
      this.$router.push({ name: "FindServiceDetails", query: { id: e } });
    },
    IsButton() {
      const token = sessionStorage.getItem("token");
      if (token) {
        this.dialogTableVisible = true;
      } else {
        this.$message({
          message: "您还没有登录，请登录后查看",
          type: "success",
        });
        this.$router.push({ path: "/HomeView" });
        sessionStorage.setItem("loading", true);
        this.$router.go(0);
      }
    },
    DialogClose() {
      this.dialogTableVisible = false;
    },
    getHouseType(value) {
      return selectDictLabel(this.houseTypeList, value);
    },
    getPrizeType(value) {
      return selectDictLabel(this.prizeTypeList, value);
    },
    getDardensStyle(value) {
      return selectDictLabel(this.dardensStyleList, value);
    },
    GoDetails(e) {},
    handleClick() {},
  },
};
</script>
<style scoped>
.myrou /deep/ .el-breadcrumb__inner {
  font-weight: 400;
}
</style>
<style lang="scss">
.el-dialog__body {
  padding: 0 20px;
}
.inquiry {
  width: 347px;
  height: 401px;
  .DialogTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
    }
    .title1 {
      font-size: 30px;
      font-family: DIN;
      font-weight: 500;
      color: #f66a1d;
      line-height: 50px;
    }
    .img {
      width: 258px;
      height: 258px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bom {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .colseBox {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: -30px;
    border: 1px solid #bdbdbd;
    border-radius: 50%;
    i {
      font-size: 24px;
      color: #bdbdbd;
    }
  }
}
.workerDetails {
  width: 1320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .emptyList {
    width: 100%;
    height: 300px;
    p {
      font-size: 24px;
      color: #bdbdbd;
      line-height: 300px;
      text-align: center;
    }
  }
  .seckill-goods {
    display: inline-block;

    .clearfix {
      margin-left: -25px;
      li {
        width: 315px;
        margin-top: 20px;
        float: left;
        flex: 1;
        margin-left: 25px;
        border: 1px solid #e6e6e6;
        .ListTop {
          cursor: pointer;
          width: 315px;
          height: 153px;
          img {
            width: 315px;
            height: 153px;
          }
        }
        .pTitle {
          font-size: 14px;
          cursor: pointer;
          overflow: hidden;
          width: 95%;
          margin: 20px auto 0;
          color: #000000;
          display: -webkit-box; /*弹性伸缩盒子*/
          -webkit-box-orient: vertical; /*子元素垂直排列*/
          -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
        }
        .ListBottom {
          display: flex;
          height: 75px;
          cursor: pointer;
          .ListLeft {
            width: 80%;
            padding-left: 20px;
            .pTitle {
              margin-top: 20px;
              font-size: 14px;
              cursor: pointer;
              overflow: hidden;
              color: #000000;
              width: 100%;
              display: -webkit-box; /*弹性伸缩盒子*/
              -webkit-box-orient: vertical; /*子元素垂直排列*/
              -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
              text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
            }
            .pTitle:hover {
              margin-top: 20px;
              font-size: 14px;
              cursor: pointer;
              overflow: hidden;
              color: #188252;
              width: 100%;
              display: -webkit-box; /*弹性伸缩盒子*/
              -webkit-box-orient: vertical; /*子元素垂直排列*/
              -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
              text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
            }
            .pTitle2 {
              padding-top: 10px;
              padding-bottom: 10px;
              font-size: 14px;
              color: #999999;
            }
          }

          .ListRight {
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #ee6a31;
            text-align: right;
            padding-right: 20px;
          }
        }
        .ListBottom2 {
          width: 100%;
          height: 75px;
          margin: 0 auto;
          font-size: 14px;
          color: #f66a1d;
          .pTitle {
            font-size: 14px;
            cursor: pointer;
            overflow: hidden;
            margin: 20px auto 0;
            width: 90%;
            color: #000000;
            display: -webkit-box; /*弹性伸缩盒子*/
            -webkit-box-orient: vertical; /*子元素垂直排列*/
            -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
            text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
          }
        }
      }
    }
  }

  .workerDetailsLeft {
    width: 75%;
    margin-top: 20px;
    background-color: #FFFFFF;
    padding: 20px;
    .DetailsBoxOne {
      border: 1px solid #ebebeb;
      margin-top: 20px;
      height: 115px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .DetailsBoxOneLeft {
        display: flex;
        margin-left: 20px;

        .OneLeftImg {
          width: 80px;
          height: 80px;
          margin-right: 22px;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }

        .OneLeftText {
          p:nth-child(1) {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            display: inline-block;
          }

          p:nth-child(2) {
            display: inline-block;
            width: 67px;
            height: 22px;
            margin-top: 12px;
            margin-left: 10px;
            background: #e0ece7;
            text-align: center;
            line-height: 22px;
            border-radius: 11px;
            font-size: 12px;
            color: #188252;
          }
        }

        .RightTextCenter {
          margin: 12px 0;

          p {
            width: 250px;

            span:nth-child(1) {
              font-size: 14px;
              display: inline-block;

              color: #a8a8a8;
            }

            span:nth-child(2) {
              color: #4d4d4d;
              font-size: 14px;
            }
          }
        }
      }

      .DetailsBoxOneRight {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .wujiaoxing {
          height: 34px;
          width: 100px;
          margin-right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(-70deg, #f6461d, #f66a1d);

          .shareP {
            cursor: pointer;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fff;
          }
        }

        .share {
          padding-left: 15px;
          border-left: 1px solid #ebebeb;
          text-align: center;

          .el-icon-share {
            color: #949997;
            font-size: 14px;
            cursor: pointer;
          }

          .shareP {
            font-size: 12px;
            color: #adb2b0;
          }
        }
      }
    }

    .DetailsBoxTwo {
      border: 1px solid #ebebeb;
      margin: 20px 0 20px 0;
      padding: 20px;

      .introduce {
        font-size: 16px;
        font-weight: bold;
        color: #4d4d4d;
        margin-bottom: 30px;
      }
      .introduceUl {
        display: flex;
        li {
          width: 350px;
          margin-bottom: 20px;
          span:nth-child(1) {
            font-size: 14px;
            color: #a8a8a8;
          }
          span:nth-child(2) {
            font-size: 14px;
            color: #4d4d4d;
            margin-left: 15px;
          }
        }
      }
      .introduceDiv {
        //display: flex;
        margin-bottom: 20px;
        span:nth-child(1) {
          font-size: 14px;
          color: #a8a8a8;
          display: inline-block;
          width: 70px;
        }
        span:nth-child(2) {
          //display: inline-block;
          word-break: break-all;
        }
      }
      .IsButton {
        width: 194px;
        cursor: pointer;
        height: 50px;
        background: #f66a1d;
        border-radius: 3px;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 50px;
      }
    }

    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__item.is-active {
      font-size: 16px;
      color: #188252;
      font-weight: 600;
    }

    .el-tabs__active-bar {
      background-color: #188252;
      height: 0;
      border-radius: 2px;
    }

    .el-tabs__item {
      font-size: 16px;
      color: #4a4a4a;
    }

    .el-tabs__item:hover {
      font-size: 16px;
      color: #188252;
    }

    .el-tabs__nav-wrap::after {
      height: 0;
      border-color: transparent !important;
    }
  }

  .workerDetailsRight {
    width: 25%;
    margin-top: 20px;
  }
}
</style>
