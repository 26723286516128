<template>
  <div class="settleIn">
    <div class="topHome">
      <div class="btnHome" @click="goHome">
        <img
          src="../../assets/image/logo2.png"
          alt=""
        />
      </div>
      <div class="border"></div>
      <div class="settleTit">入驻平台</div>
    </div>
    <div class="settleForm">
      <designSettle v-if="isShow == 2||isShow == 3||isShow == 4||isShow == 5" />
      <enterpriseSettle v-if="isShow != 2&&isShow != 3&&isShow != 4&&isShow != 5" />
    </div>
  </div>
</template>

<script>
import designSettle from "./designSettle.vue";
import enterpriseSettle from "./enterpriseSettle.vue";
export default {
  components: { designSettle, enterpriseSettle },
  data() {
    return {
      isShow: 2,
    };
  },
  activated() {
    this.isShow = this.$route.query.isShow;
  },
  created() {
    this.isShow = this.$route.query.isShow;
  },
  methods: {
    goHome(){
      this.$router.push({name:"HomeView"})
    },
  },
};
</script>

<style lang="scss" scoped>
.settleIn {
  width: 100%;
  background-color: #fafafa;
  .topHome {
    width: 1320px;
    height: 80px;
    margin: 0 auto 50px;
    display: flex;
    align-items: center;
    .btnHome {
      height: 50px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .border {
      width: 2px;
      height: 30px;
      background-color: #e6e6e6;
      margin: 0 30px;
      align-self: center;
    }
    .settleTit {
      line-height: 80px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #4d4d4d;
    }
  }
}
</style>