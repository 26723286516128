<template>
  <div class="messageManagement">
    <div class="title">
      <p class="message">认证管理</p>
    </div>
    <div class="authImg" v-if="auth == 0">
      <div class="imgBox" @click="goSettle">
        <img src="../../../assets/image/authAdministration.png" alt="" />
      </div>
    </div>
    <div class="authBox" v-else>
      <div class="authTitle" v-if="auditStatus == 3">
        <img src="../../../assets/image/authIcon.png" alt="" />
        <p class="titleCon">身份实名认证成功!</p>
      </div>
      <div class="authTitle2" v-if="auditStatus == 2">
        <div style="display: flex;
      align-items: center;">
          <img src="../../../assets/image/authIcon_1.png" alt="" />
          <p class="titleCon2">
            身份实名认证审核失败(驳回原因：{{
              isPeoCompany == "1"
                ? companyList.auditOpinion
                : peopleList.auditOpinion
            }})
          </p>
        </div>
        <p class="authBtn" @click="goAuth">重新申请</p>
      </div>
      <div class="authTitle1" v-if="auditStatus == 1">
        <img src="../../../assets/image/authIcon_2.png" alt="" />
        <p class="titleCon1">身份实名认证审核中</p>
      </div>
      <el-descriptions
        v-if="isPeoCompany == 1"
        style="margin-bottom: 30px"
        title="基本信息"
        :column="1"
        :border="true"
        :labelStyle="{
          'text-align': 'left',
          background: '#F6F7FB',
          height: '50px',
        }"
      >
        <el-descriptions-item
          label="认证类型"
          label-class-name="my-label"
          content-class-name="my-content"
          labelStyle="width: 150px"
        >
          企业
        </el-descriptions-item>
        <el-descriptions-item label="企业类型" labelStyle="width: 150px">
          {{ companyFarmat(companyList) }}
        </el-descriptions-item>
        <el-descriptions-item label="企业名称" labelStyle="width: 150px">
          {{ companyList.companyName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="统一社会信用代码"
          labelStyle="width: 150px"
        >
          {{ companyList.socialCreditCode }}
        </el-descriptions-item>
        <el-descriptions-item
          label="营业执照原件图片"
          labelStyle="width: 150px"
        >
          <el-image
            style="width: 100px; height: 100px"
            :src="companyList.businessLicense"
            :preview-src-list="[companyList.businessLicense]"
          ></el-image>
        </el-descriptions-item>
        <el-descriptions-item label="法人名称" labelStyle="width: 150px">
          {{ companyList.legalName }}
        </el-descriptions-item>
        <el-descriptions-item label="法人联系方式" labelStyle="width: 150px">
          {{ companyList.legalPhone }}
        </el-descriptions-item>
        <el-descriptions-item label="身份证号码" labelStyle="width: 150px">
          {{ companyList.identityCardNo }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        v-if="isPeoCompany == 1"
        title="银行信息"
        :column="1"
        :border="true"
        :labelStyle="{
          'text-align': 'left',
          background: '#F6F7FB',
          height: '50px',
          width: '200px',
        }"
      >
        <el-descriptions-item
          label="银行名称"
          label-class-name="my-label"
          content-class-name="my-content"
          labelStyle="width: 150px"
        >
          {{ companyList.openBankName }}
        </el-descriptions-item>
        <el-descriptions-item label="开户行" labelStyle="width: 150px">
          {{ companyList.openBankInfo }}
        </el-descriptions-item>
        <el-descriptions-item label="账户名称" labelStyle="width: 150px">
          {{ companyList.openAccountName }}
        </el-descriptions-item>
        <el-descriptions-item label="银行账号" labelStyle="width: 150px">
          {{ companyList.openBankAccount }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        v-if="isPeoCompany == 2"
        style="margin-bottom: 30px"
        title="基本信息"
        :column="1"
        :border="true"
        :labelStyle="{
          'text-align': 'left',
          background: '#F6F7FB',
          height: '50px',
        }"
      >
        <el-descriptions-item
          label="认证类型"
          label-class-name="my-label"
          content-class-name="my-content"
          labelStyle="width: 150px"
        >
          {{ getPersonIdentity(peopleList.personIdentity) }}
        </el-descriptions-item>
        <el-descriptions-item label="设计师名称" labelStyle="width: 150px">
          {{ peopleList.personalName }}
        </el-descriptions-item>
        <el-descriptions-item label="手机号码" labelStyle="width: 150px">
          {{ peopleList.personalPhone }}
        </el-descriptions-item>
        <el-descriptions-item label="身份证号码" labelStyle="width: 150px">
          {{ peopleList.identityCardNo }}
        </el-descriptions-item>
        <el-descriptions-item label="联系邮箱" labelStyle="width: 150px">
          {{ peopleList.contactEmail }}
        </el-descriptions-item>
        <el-descriptions-item label="地址" labelStyle="width: 150px">
          {{ peopleList.detailArea }}
        </el-descriptions-item>
        <el-descriptions-item label="详细地址" labelStyle="width: 150px">
          {{ peopleList.detailAddress }}
        </el-descriptions-item>
        <el-descriptions-item label="服务区域" labelStyle="width: 150px">
          {{ peopleList.serviceArea }}
        </el-descriptions-item>
        <el-descriptions-item label="工作年限" labelStyle="width: 150px">
          {{ getYearNum(peopleList.jobStartTime, peopleList.jobEndTime) }}年
        </el-descriptions-item>
        <el-descriptions-item label="自我介绍" labelStyle="width: 150px">
          {{ peopleList.personalIntroduction }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        title="银行信息"
        v-if="isPeoCompany == 2"
        :column="1"
        :border="true"
        :labelStyle="{
          'text-align': 'left',
          background: '#F6F7FB',
          height: '50px',
          width: '200px',
        }"
      >
        <el-descriptions-item
          label="银行名称"
          label-class-name="my-label"
          content-class-name="my-content"
          labelStyle="width: 150px"
        >
          {{ peopleList.openBankName }}
        </el-descriptions-item>
        <el-descriptions-item label="开户行" labelStyle="width: 150px">
          {{ peopleList.openBankInfo }}
        </el-descriptions-item>
        <el-descriptions-item label="账户名称" labelStyle="width: 150px">
          {{ peopleList.openAccountName }}
        </el-descriptions-item>
        <el-descriptions-item label="银行账号" labelStyle="width: 150px">
          {{ peopleList.openBankAccount }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import NavCon from "@/components/navcon/navCon";
import { getAuthInfo } from "@/request/mine";
import { getDicts } from "@/request/dict/data";
import {
  selectDictLabel,
  selectDictLabels,
  CheckSocialCreditCode,
} from "@/utils/weihua";
export default {
  components: { NavCon },
  data() {
    return {
      auth: null,
      isPeoCompany: null,
      peopleList: [],
      companyList: [],
      companyTypeList: [],
      auditStatus: null,
    };
  },
  created() {
    this.isAuth();
    getDicts("msk_sys_check_in").then((res) => {
      this.companyTypeList = res.data;
    });
  },
  methods: {
    isAuth() {
      const userId = sessionStorage.getItem("userId");
      getAuthInfo(userId)
        .then((res) => {
          if (res.code == 200) {
            if (res.data.type == 1 && res.data.auditStatus == 1) {
              this.auditStatus = 1;
              this.auth = 1;
              this.isPeoCompany = 1;
              this.companyList = res.data.baseConstructCompany;
            } else if (res.data.type == 1 && res.data.auditStatus == 2) {
              this.auditStatus = 2;
              this.companyList = res.data.baseConstructCompany;
              this.auth = 2;
              this.isPeoCompany = 1;
            } else if (res.data.type == 1 && res.data.auditStatus == 3) {
              this.auditStatus = 3;
              this.companyList = res.data.baseConstructCompany;
              this.auth = 3;
              this.isPeoCompany = 1;
            } else if (res.data.type == 2 && res.data.auditStatus == 1) {
              this.auditStatus = 1;
              this.auth = 1;
              this.isPeoCompany = 2;
              this.peopleList = res.data.baseConstructPersonal;
            } else if (res.data.type == 2 && res.data.auditStatus == 2) {
              this.auditStatus = 2;
              this.peopleList = res.data.baseConstructPersonal;
              this.auth = 2;
              this.isPeoCompany = 2;
            } else if (res.data.type == 2 && res.data.auditStatus == 3) {
              this.auditStatus = 3;
              this.peopleList = res.data.baseConstructPersonal;
              this.auth = 3;
              this.isPeoCompany = 2;
            } else if (res.data.type == 3) {
              this.auth = 0;
            }
          } else if (res.code == 500) {
            this.$message({
              message: "您还没有入驻信息",
            });
            this.auth = 0;
          }
        })
        .catch(() => {
          this.auth = 0;
          return;
          // this.$message({
          //   message: res.msg,
          //   type: "warning",
          // });
        });
    },
    goAuth() {
      this.$router.push({ path: "/settleIn" });
    },
    getYearNum(startTime, endTime) {
      var timestr = startTime.replace(/-/g, "/");
      var startTime1 = new Date(timestr);
      var timestr1 = endTime.replace(/-/g, "/");
      var endTime1 = new Date(timestr1);
      var y = endTime1.getFullYear();
      var y1 = startTime1.getFullYear();
      var m = endTime1.getMonth() + 1;
      var m1 = startTime1.getMonth() + 1;
      if (y === y1) {
        this.year = "年";
        return "1";
      } else {
        this.year = "年";
        return y - y1;
      }
      // let start = startTime.getFullYear()
      // let end = endTime.getFullYear()
      // return year = end - start;
    },
    companyFarmat(row, column) {
      return selectDictLabel(this.companyTypeList, row.companyIdentity);
    },

    goSettle() {
      this.$router.push({ name: "settleIn" });
      localStorage.setItem("isSettle", 1);
    },
    getPersonIdentity(value) {
      if (value == 2) {
        return "设计师";
      } else if (value == 3) {
        return "造价师";
      } else if (value == 5) {
        return "培训讲师";
      } else {
        return "施工队";
      }
    },
    goHome() {
      this.$router.push({ path: "/HomeView" });
    },
    back() {
      this.$router.push({ name: "messageManagement" });
    },
    GoIsShow() {
      this.$refs.Navigation;
    },
  },
};
</script>

<style lang="scss" scoped>
.messageManagement {
  width: 100%;
  padding: 30px 0px;
  .title {
    padding: 0 0 20px 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    .message {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
  .authImg {
    padding: 20px 30px;

    .imgBox {
      cursor: pointer;
      width: 891px;
      height: 192px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .authBox {
    padding: 10px 40px;
    .authTitle {
      padding: 0 14px;
      display: flex;
      align-items: center;
      height: 50px;
      background: #eff8ec;
      margin-bottom: 30px;
      .titleCon {
        margin-left: 10px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2a9261;
      }
    }
    .authTitle2 {
      padding: 0 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      background: #fef0f0;
      margin-bottom: 30px;
      .titleCon2 {
        word-wrap: break-word;
        word-break: break-all;
        width: 700px;
        margin-left: 10px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: red;
      }
    }
    .authTitle1 {
      padding: 0 14px;
      display: flex;
      align-items: center;
      height: 50px;
      background: #fdf6ec;
      margin-bottom: 30px;
      .titleCon1 {
        margin-left: 10px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #e6a23c;
      }
    }
    .authBtn {
      cursor: pointer;
      width: 100px;
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      background-color: #f46c6c;
      color: #fff;
      text-align: center;
    }
  }
}
</style>