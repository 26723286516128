import request from '@/request/request'


//赛事列表
export function getActivityList(data) {
    return request({
        url: "/api/web/activity/list",
        method: "get",
        params: data
    })
}

//赛事详情
export function getActivityDetail(id) {
    return request({
        url: "/api/web/activity/" + id,
        method: "get",
    })
}

//学员认证
export function studentAuth(data) {
    return request({
        url: "/system/auth/pc/add",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "post",
        data: data
    })
}

// 是否认证
export function getRaceStudentInfo(userId) {
    return request({
        url: "/system/auth/getRaceStudentInfo/" + userId,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get"

    })
}

//上传作品

export function uploadWorks(data) {
    return request({
        url: "/system/production/pc/add",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "post",
        data: data
    })
}
//全部作品

export function getAllList(data) {
    return request({
        url: "/api/web/production/list",
        method: "get",
        params: data
    })
}

//作品详情
export function getProductionDetail(id) {
    return request({
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        url: "/system/production/getProductionInfo/" + id,
        method: "get",
    })
}
//点赞
export function agree(data) {
    return request({
        url: "/system/agree/add",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "post",
        data: data
    })
}

//获奖公示列表

export function getPrizeList(data) {
    return request({
        url: "/api/web/prize/awardList",
        method: "get",
        params: data
    })
}

//赛事资料下载
export function ossUpload(data) {
    return request({
        url: "/api/web/oss/downOSSFile",
        method: "post",
        data: data
    })
}
export function checkUniqueness(data) {
    return request({
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        url: "/system/auth/checkUniqueness",
        method: "post",
        data: data
    })
}