<template>
  <div class="upload">
    <div class="uploadTitle">上传作品</div>
    <div class="formBox">
      <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="100px"
          class="demo-form"
      >
        <el-form-item label="作品名称" prop="productName">
          <el-input
              style="width: 50%"
              maxlength="50"
              v-model="form.productName"
          ></el-input>
          <p style="width: 100%;background-color: #F2F6FC;margin-top: 10px;text-indent: 10px;color: #909399">
            填写作品名称时请备注点位名称及编号“例如：青龙绿带公园 经开区——凤城十路（明光路-文景路）北侧 DK001”</p>
        </el-form-item>
        <el-form-item label="参赛作品" prop="fileList" style="width: 80%">
          <div style="display: flex">
            <div
                v-for="(item, index) in form.fileList"
                :key="index"
                style="
                width: 184px;
                height: 184px;
                margin-right: 20px;
                position: relative;
              "
            >
              <img
                  v-if="item.url"
                  style="width: 184px; height: 184px"
                  :src="item.url"
                  alt=""
              />
              <i
                  class="el-icon-delete"
                  style="
                  position: absolute;
                  right: 0;
                  top: 3px;
                  cursor: pointer;
                  font-size: 14px;
                  width:  30px;
                  height: 30px;
                  border-radius: 50%;
                  text-align: center;
                  line-height: 30px;
                  background-color: #F56C6C;
                  color: #FFFFFF;
                "
                  @click="handleRemove(item)"
              ></i>
            </div>

            <el-upload
                action=""
                :limit="4"
                :file-list="form.fileList"
                style="width: 184px; height: 184px"
                class="avatar-uploader"
                accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
                :show-file-list="false"
                :before-upload="beforeUploadOne"
                :http-request="UploadImageOne"
            >
              <i
                  v-if="form.fileList.length < 4"
                  class="el-icon-plus avatar-uploader-icon"
                  style="width: 184px; height: 184px"
              ></i>
            </el-upload>
          </div>

          <p style="width: 100%;background-color: #F2F6FC;margin-top: 10px;text-indent: 10px;color: #909399">
            参赛作品分辨率控制在100dpi以上，文件大小不超过50M，仅支持jpg，png，jpeg格式</p>
        </el-form-item>
        <el-form-item label="作品封面图" prop="coverImg" style="width: 80%">
          <div style="display: flex">
            <el-upload
                action=""
                style="width: 184px; height: 184px"
                class="avatar-uploader"
                accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
                :show-file-list="false"
                :before-upload="beforeUploadTwo"
                :http-request="UploadImageTwo"
            >
              <img
                  v-if="form.coverImg"
                  style="width: 184px; height: 184px"
                  :src="form.coverImg"
                  alt=""
              />
              <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                  style="width: 184px; height: 184px"
              ></i>
            </el-upload>
          </div>
          <p style="width: 100%;background-color: #F2F6FC;margin-top: 10px;text-indent: 10px;color: #909399">
            封面图尺寸大小为600x400px，仅支持jpg，png，jpeg格式</p>
        </el-form-item>
        <el-form-item label="上传作品包" style="width: 80%">
          <el-progress v-if="percent !=0" :text-inside="true" :percentage="percent" :stroke-width="26"></el-progress>
          <el-tag
              :key="form.productPackage.url"
              closable
              v-if="form.productPackage != ''"
              @close="closeFileName"
              type="success">
            {{ form.productPackage.fileName }}
          </el-tag>
          <el-upload
              action=""
              class="avatar-uploader"
              accept=".zip,.rar,.ta,.gz,.zip,.7z"
              :show-file-list="false"
              :before-upload="beforeUploadThere"
              :http-request="UploadImageThere"
          >
            <el-button
                slot="trigger"
                v-if="form.productPackage == '' && percent == 0"
                size="small"
                type="primary"
            >上传作品包
            </el-button
            >
          </el-upload>
          <p style="width: 100%;background-color: #F2F6FC;margin-top: 10px;text-indent: 10px;color: #909399">
            文件大小为200M以下，仅支持.zip,.rar,.ta,.gz,.zip,.7z格式</p>
        </el-form-item>
        <el-form-item label="作品简介" prop="productIntroduction">
          <el-input
              type="textarea"
              placeholder="作品简介不少于200字"
              minlength='200'
              rows="6"
              v-model="form.productIntroduction"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
              style="background: #219560"
              type="primary"
              @click="submitForm('ruleForm')"
          >确认提交
          </el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import OSS from "ali-oss";
import {getOssToken} from "@/request/login";
import {getRaceStudentInfo, uploadWorks} from "@/request/activity";

export default {
  data() {
    return {
      OssForm: {},
      percent: 0,
      form: {
        fileList: [],
        productIntroduction: null,
        introduction: "",
        coverImg: null,
        productName: "",
        productPackage: '',
        delivery: false,
        baseRaceStudentProductionImgs: [],
        studentId: null,
        raceId: null,
      },
      rules: {
        productName: [
          {
            max: 50,
            required: true,
            message: "作品名称不能为空,最多50字",
            trigger: "blur",
          },
        ],
        fileList: [
          {
            required: true,
            message: "参赛作品不能为空",
            trigger: "blur",
          },
        ],
        coverImg: [
          {
            required: true,
            message: "作品封面图不能为空",
            trigger: "blur",
          },
        ],
        productPackage: [
          {
            required: true,
            message: "作品包不能为空",
            trigger: "blur",
          },
        ],
        productIntroduction: [
          {
            min: 200,
            required: true,
            message: "作品简介不能为空且不少于200字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.OssForm = JSON.parse(sessionStorage.getItem("aliOss"));
    // this.getAuth()
  },
  methods: {
    getAuth() {
      const userId = sessionStorage.getItem("userId");
      getRaceStudentInfo(userId).then((res) => {
        if (res.data.flag == 1) {
          let StudentAuth = ''
          if (res.data.baseRaceStudentAuth.isAuth == 0) {
            StudentAuth = 2
          } else {
            StudentAuth = res.data.baseRaceStudentAuth.isAuth
          }
          sessionStorage.setItem("StudentAuth", StudentAuth);
          if (res.data.baseRaceStudentAuth.id) {
            sessionStorage.setItem("studentId", res.data.baseRaceStudentAuth.id);
          }
        } else {
          this.$message({
            message: "请先进行学生认证",
            type: "warning",
          });
          sessionStorage.setItem("StudentAuth", 0);
        }
      });
    },
    UploadImageOne(options) {
      try {
        let nowDate = new Date();
        let dateTime = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
        };
        let that = this;
        let client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: that.OssForm.accessKeyId,
          accessKeySecret: that.OssForm.accessKeySecret,
          stsToken: that.OssForm.token,
          bucket: "fengjingyuanlin",
          timeout: 600000,
          secure: true
        });
        let file = options.file; // 拿到 file
        let extendName = file.name.substring(file.name.lastIndexOf(".") + 1);
        let fileName = file.name.substr(0, file.name.lastIndexOf("."));
        let date = new Date().getTime();
        let fileNames = `${date}_${file.size}_${Math.round(
            Math.random() * 10000
        )}`;
        let folderName =
            "image/" +
            dateTime.year +
            "-" +
            dateTime.month +
            "-" +
            dateTime.date +
            "/" +
            fileNames +
            "." +
            extendName;
        client.put(folderName, file).then((res) => {
          if (res.res.statusCode === 200) {
            this.form.fileList.push({url: res.url});
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    },
    beforeUploadOne(file) {
      //图片大小
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message({
          message: "上传图片的大小不能超过 50M!",
          type: "warning",
        });
        return false;
      }
      return isLt2M;
    },
    beforeUploadTwo(file) {
      //图片大小
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message({
          message: "上传图片的大小不能超过 10M!",
          type: "warning",
        });
        return false;
      }
      return isLt2M;
    },
    UploadImageTwo(options) {
      let allOssForm = {};
      getOssToken().then((res) => {
        if (res.code === 0) {
          allOssForm = res.data;

          try {
            let nowDate = new Date();
            let dateTime = {
              year: nowDate.getFullYear(),
              month: nowDate.getMonth() + 1,
              date: nowDate.getDate(),
            };
            let that = this;
            let client = new OSS({
              region: "oss-cn-beijing",
              accessKeyId: allOssForm.accessKeyId,
              accessKeySecret: allOssForm.accessKeySecret,
              stsToken: allOssForm.token,
              bucket: "fengjingyuanlin",
              timeout: 600000,
              secure: true
            });
            let file = options.file; // 拿到 file
            let extendName = file.name.substring(
                file.name.lastIndexOf(".") + 1
            );
            let fileName = file.name.substr(0, file.name.lastIndexOf("."));
            let date = new Date().getTime();
            let fileNames = `${date}_${file.size}_${Math.round(
                Math.random() * 10000
            )}`;
            let folderName =
                "image/" +
                dateTime.year +
                "-" +
                dateTime.month +
                "-" +
                dateTime.date +
                "/" +
                fileNames +
                "." +
                extendName;

            client.put(folderName, file).then((res) => {
              if (res.res.statusCode === 200) {
                this.form.coverImg = res.url;
              } else {
                options.onError("上传失败");
              }
            });
          } catch (e) {
            options.onError("上传失败");
          }
        }
      });
    },

    beforeUploadThere(file) {
      //图片大小
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        this.$message({
          message: "上传文件的大小不能超过 200M!",
          type: "warning",
          offset: 100
        });
        return false;
      }
      return isLt2M;
    },
    UploadImageThere(options) {
      let allOssForm = {};
      getOssToken().then((res) => {
        if (res.code === 0) {
          allOssForm = res.data;

          try {
            let nowDate = new Date();
            let dateTime = {
              year: nowDate.getFullYear(),
              month: nowDate.getMonth() + 1,
              date: nowDate.getDate(),
            };
            let that = this;
            let client = new OSS({
              region: "oss-cn-beijing",
              accessKeyId: allOssForm.accessKeyId,
              accessKeySecret: allOssForm.accessKeySecret,
              stsToken: allOssForm.token,
              bucket: "fengjingyuanlin",
              timeout: 600000,
              secure: true
            });
            let file = options.file; // 拿到 file
            let extendName = file.name.substring(
                file.name.lastIndexOf(".") + 1
            );
            let fileName = file.name.substr(0, file.name.lastIndexOf("."));
            let date = new Date().getTime();
            let fileNames = `${date}_${file.size}_${Math.round(
                Math.random() * 10000
            )}`;
            let folderName =
                "image/" +
                dateTime.year +
                "-" +
                dateTime.month +
                "-" +
                dateTime.date +
                "/" +
                fileNames +
                "." +
                extendName;

            client.multipartUpload(folderName, file, {
              // 进度条的配置项
              progress: function (percentage) { // 获取进度条的值
                that.percent = percentage * 100
                that.percent = Number(that.percent.toFixed(2))
              },
              // 每传输完一个分片 进度条会走一个百分比 不设置的话由于partsize过大 可能导致很长时间进度条都看不到效果
              partSize: 102400 // 指定上传的每个分片的大小，范围为100 KB~5 GB。单个分片默认大小为1 * 1024 * 1024（即1 MB）
            }).then((res) => {
              if (res.res.statusCode === 200) {
                let url = res.res.requestUrls[0].split('?uploadId=')[0]
                that.form.productPackage = {
                  url: url,
                  fileName: fileName
                }
                that.percent = 0
              } else {
                options.onError("上传失败");
              }
            });
          } catch (e) {
            options.onError("上传失败");
          }
        }
      });
    },
    closeFileName() {
      this.form.productPackage = ''
    },
    handleRemove(e) {
      let index = this.form.fileList.indexOf(e);
      if (index > -1) {
        this.form.fileList.splice(index, 1);
      }
    },
    reset() {

    },
    submitForm() {
      let that = this;

      this.$refs.form.validate((valid) => {
        if (valid) {
          if (that.form.fileList) {
            that.form.fileList.forEach((item, index) => {
              that.form.baseRaceStudentProductionImgs.push({
                procuctImg: item.url,
                indexNo: index,
              });
            });
          }
          that.form.studentId = sessionStorage.getItem("studentId");
          that.form.raceId = that.$route.query.id;

          that.$confirm("您确定作品信息无误，进行提交吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
              .then(() => {
                that.form.productPackage = JSON.stringify(that.form.productPackage)

                uploadWorks(that.form).then((res) => {
                  if (res.code == 200) {
                    that.$message({
                      message: "上传作品成功",
                      type: "success",
                      offset: 100
                    });
                    setTimeout(() => {
                      that.form.productName = ''
                      that.form.fileList = []
                      that.form.coverImg = ''
                      that.form.productPackage = ''
                      that.form.productIntroduction = ''
                      sessionStorage.setItem("activeName", 4);
                    }, 2000)
                  }
                });
              })
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  padding-top: 30px;
  width: 100%;
  background-color: #fff;

  .uploadTitle {
    width: 60%;
    margin: 0 auto;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #4d4d4d;
  }

  .formBox {
    width: 60%;
    margin: 0 auto;
    padding: 30px 0;

    .choose {
      position: relative;
      width: 380px;
    }

    .info-style {
      width: 100%;
      text-align: center;
      margin-top: 12%;
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 380px;
      height: 180px;
      line-height: 180px;
      text-align: center;
      border: 1px dashed #d9d9d9;
    }

    .avatar {
      width: 380px;
      height: 180px;
      display: block;
    }

    .el-dialog__wrapper {
      position: absolute;
      background: #8c939d;
      opacity: 0.3;
    }
  }
}
</style>
