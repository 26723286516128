<template>
  <div class="passWorld">
    <div class="title">
      <p class="message">我的赛事</p>
    </div>
    <div class="matchBox" v-if="auth == 0">
      <div class="box">
        <div class="left">
          <div class="img">
            <img src="../../../assets/image/matchAuth.png" alt="" />
          </div>
          <div class="noAuthTxt">
            <p class="noAuthTitle">您暂时没有参加赛事活动~~</p>
            <p class="noAuthCon">
              前往<span @click="match">赛事中心</span> 参加吧
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="matchList" v-if="auth == 1">
      <div
        class="match"
        v-for="item in myRaceList"
        :key="item.id"
        @click="goWorkDetail(item)"
      >
        <div class="img">
          <img v-if="item.coverImg" :src="item.coverImg" alt="" />
        </div>
        <div class="matchName">
          <p>{{ item.productIntroduction }}</p>
        </div>
        <div class="matchView">
          <p class="viewNum">
            <i class="el-icon-view"></i><span>{{ item.browseNum }}</span>
          </p>
          <p class="likes">
            <img src="../../../assets/image/thumbs-up.png" alt="" />
            <span>{{
              item.agreeNum
            }}</span>
          </p>
        </div>
        <div class="matchTitName">
          <p>{{ item.productName }}</p>
        </div>
      </div>
    </div>
    <Pagination
      v-show="Total > 0"
      :total="Total"
      style="text-align: center; margin: 0 auto"
      :page.sync="formInline.pageNum"
      :limit.sync="formInline.pageSize"
      @pagination="PageApi"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/Pagination";
import { getmyWorkList } from "@/request/mine";
export default {
  components: { Pagination },
  data() {
    return {
      auth: null,
      show: "false",
      formInline: {
        pageNum: 1,
        pageSize: 6,
      },
      Total: 0,
      myRaceList: [],
    };
  },
  created() {
    this.PageApi();
  },
  methods: {
    PageApi() {
      getmyWorkList().then((res) => {
        if (res.code == 200 && res.total != 0) {
          this.auth = 1;
          this.myRaceList = res.rows;
          this.Total = res.total;
        } else {
          this.auth = 0;
        }
      });
    },
    goWorkDetail(e) {
      this.$router.push({
        name: "worksDetail",
        query: { id: e.id, raceId: e.raceId },
      });
    },
    goHome() {
      this.$router.push({ path:"/HomeView"});
    },
    GoIsShow() {
      this.$refs.Navigation;
    },
    match() {
      this.$router.push({ name: "Events" });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .btn-prev {
  padding: 0 5px !important;
}
::v-deep .btn-next {
  padding: 0 5px !important;
}
.passWorld {
  width: 100%;
  padding: 30px 0px;
  .title {
    padding: 0 0 20px 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    .message {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
  .matchBox {
    padding: 20px 30px;
    .box {
      margin: 0 auto;
      width: 400px;
      height: 132px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      .left {
        display: flex;
      }
      .img {
        width: 69px;
        height: 45px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .noAuthTxt {
        margin-left: 20px;

        .noAuthTitle {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #4d4d4d;
          line-height: 20px;
        }
        .noAuthCon {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          span {
            cursor: pointer;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #2a9261;
          }
        }
      }
    }
  }
  .matchList {
    width: 100%;
    padding: 20px 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .match {
      width: 24%;
      margin: 0 10px 20px 30px;
      .img {
        cursor: pointer;
        width: 284px;
        height: 189px;
        border-radius: 10px;
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      .matchName {
        margin-bottom: 10px;
        p {
          white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #3d3d3d;
          line-height: 20px;
        }
      }
      .matchView {
        display: flex;
        margin-bottom: 10px;
        .viewNum {
          margin-right: 16px;
          i {
            font-size: 14px;
            color: #999999;
          }
          span {
            margin-left: 5px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
        .likes {
          height: 14px;
          img {
            width: 14px;
            height: 14px;
            line-height: 1px;
            margin-bottom: 2px;
          }
          span {
            margin-left: 5px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }
      .matchTitName {
        display: flex;
        height: 26px;
        P {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2a9261;
        }
      }
    }
  }
}
</style>