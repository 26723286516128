import request from '@/request/request'

//设计师/造价师列表
export function getFindDesign(data){
    return request({
        url:"/api/web/personal/list",
        method:"get",
        params:data
    })
}

//设计师/造价师详情
export function getFindDesignDetail(id){
    return request({
        url:"/api/web/personal/" + id,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method:"get",
    })
}

//找材料列表
export function getFindShopGoods(data){
    return request({
        url:"/api/web/shopGoods/baseShopGoods/list",
        method:"get",
        params:data
    })
}
//材料分类

export function getFindShopGoodsType(data){
    return request({
        url:"/api/web/shopGoods/baseShopGoodsType/list",
        method:"get",
        params:data
    })
}
//材料详情
export function getFindShopDetail(id){
    return request({
        url:"/api/web/shopGoods/baseShopGoods/"+id,
        method:"get",
    })
}