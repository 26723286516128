<template>
  <div class="advertisementLeft" v-if="wideUrl">
    <img :src="wideUrl" alt="" />
  </div>
</template>

<script>

export default {
  name: "advertisementLeft",
  props: {
    wideUrl: String,
  },
  data() {
    return {
    };
  },
  created() {},
  methods: {
    
  },
};
</script>

<style scoped lang="scss">
.advertisementLeft {
  width: 300px;
  height: 245px;

  img {
    width: 302px;
    height: 245px;
  }
}
</style>
