import request from '@/request/request'

//个人入驻下一步
export function nextSettleIn(data){
    return request({
        url:"/api/web/personal/verification",
        method:"post",
        data:data
    })
}

//个人入驻提交
export function subSettleIn(data){
    return request({
        url:"/system/personal/pc/add",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method:"post",
        data:data
    })
}

//公司入驻下一步
export function nextCompanySettleIn(data){
    return request({
        url:"/api/web/company/verification",
        method:"post",
        data:data
    })
}
//公司入驻提交
export function subCompanySettleIn(data){
    return request({
        url:"/system/company/pc/add",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method:"post",
        data:data
    })
}