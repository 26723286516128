
<template>
  <div class="navpageTion">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      prev-text="上一页"
      next-text="下一页"
      :page-sizes="pageSizes"
      :page-size.sync="pageSize"
      :layout="layout"
      :background="background"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
// import { scrollTo } from '@/utils/scroll-to'
export default {
  props: {
    layout: {
      type: String,
      default: "prev, pager, next",
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50];
      },
    },
    total: {
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 20,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    background: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      },
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("pagination", { page: this.currentPage, limit: val });
      // if (this.autoScroll) {
      //     scrollTo(0, 800)
      // }
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { page: val, limit: this.pageSize });
      // if (this.autoScroll) {
      //     scrollTo(0, 800)
      // }
    },
  },
};
</script>
<style lang="scss">
.navpageTion {
  .el-pagination {
    font-weight: normal;

    .el-pager {
      color: #219560;
      .active {
        color: #219560;
      }
      li:hover{
        color: #219560
      }
    }
  }
  
}
</style>
