<template>
  <div class="studentAuth">
    <div class="studentAuthBox">
      <div class="studentAuthTitle">学生认证</div>
      <div class="studentAutMsg">
        <el-alert
          title="温馨提示：报名信息填写后不能修改，请谨慎填写哦！"
          :closable="false"
          type="warning"
          show-icon
        >
        </el-alert>
      </div>
      <div class="formBox" >
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="100px"
          class="demo-form"
        >
          <el-form-item label="学生名称" prop="studentName">
            <el-input style="width: 20%" v-model="form.studentName" :disabled="studentAuthDisable"></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="email">
            <el-input
              type=""
              style="width: 20%" :disabled="studentAuthDisable"
              v-model="form.email"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="contractPhone">
            <el-input
              maxlength="11"
              style="width: 20%" :disabled="studentAuthDisable"
              v-model="form.contractPhone"
            ></el-input>
          </el-form-item>
          <el-form-item label="就读学校" prop="school">
            <el-input
              style="width: 20%"
              maxlength="60" :disabled="studentAuthDisable"
              v-model="form.school"
            ></el-input>
          </el-form-item>
          <el-form-item label="专业班级" prop="majorClass">
            <el-input
              style="width: 20%"
              maxlength="60" :disabled="studentAuthDisable"
              v-model="form.majorClass"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号码" prop="identityNo">
            <el-input
              style="width: 20%" :disabled="studentAuthDisable"
              v-model="form.identityNo"
              maxlength="18"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="身份证照片"
            style="width: 80%"
            ref="fileListRef"
          >
            <div style="display: flex">
              <div
                v-for="(item, index) in form.fileList"
                :key="index"
                style="
                  width: 184px;
                  height: 184px;
                  margin-right: 20px;
                  position: relative;
                "
              >
                <img
                  v-if="item.url"
                  style="width: 184px; height: 184px"
                  :src="item.url"
                  alt=""
                />
                <i
                  class="el-icon-delete"
                  style="
                    position: absolute;
                    right: 0;
                    top: 3px;
                    cursor: pointer;
                    font-size: 14px;
                    color: #000000;
                  "
                  @click="handleRemove(item)"
                ></i>
              </div>

              <el-upload
                action=""
                style="width: 184px; height: 184px"
                class="avatar-uploader"
                :disabled="studentAuthDisable"
                accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
                :show-file-list="false"
                :before-upload="beforeUploadOne"
                :http-request="UploadImageOne"
              >
                <i
                  v-if="form.fileList.length < 2"
                  class="el-icon-plus avatar-uploader-icon"
                  style="width: 184px; height: 184px"
                ></i>
              </el-upload>
            </div>
            <p style="font-size: 12px; color: #ccc">
              支持.jpg,.png,.jpeg，图片大小不超过2M
            </p>
          </el-form-item>
          <el-form-item
            label="学生证"
            prop="certImg"
            style="width: 80%"
            ref="certImgRef"
          >
            <div style="display: flex">
              <div
                v-if="form.certImg"
                style="
                  width: 184px;
                  height: 184px;
                  margin-right: 20px;
                  position: relative;
                "
              >
                <img
                  v-if="form.certImg"
                  style="width: 184px; height: 184px"
                  :src="form.certImg"
                  alt=""
                />
                <i
                  class="el-icon-delete"
                  style="
                    position: absolute;
                    right: 0;
                    top: 3px;
                    cursor: pointer;
                    font-size: 14px;
                    color: #000000;
                  "
                  @click="handleRemove1(form.certImg)"
                ></i>
              </div>

              <el-upload
                action=""
                style="width: 184px; height: 184px"
                class="avatar-uploader"
                :disabled="studentAuthDisable"
                accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
                :show-file-list="false"
                :before-upload="beforeUploadTwo"
                :http-request="UploadImageTwo"
              >
                <i
                  v-if="!form.certImg"
                  class="el-icon-plus avatar-uploader-icon"
                  style="width: 184px; height: 184px"
                ></i>
              </el-upload>
            </div>
            <p style="font-size: 12px; color: #ccc">
              支持.jpg,.png,.jpeg，图片大小不超过2M
            </p>
          </el-form-item>
          <el-form-item v-if="IsAuthstudentAuth != 2">
            <el-button
              style="background: #219560"
              type="primary"
              @click="submitForm('ruleForm')"
              >提交认证</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getOssToken } from "@/request/login";
import { studentAuth, checkUniqueness } from "@/request/activity";
import OSS from "ali-oss";
export default {
  data() {
    var checkIdNum = (rule, value, callback) => {
      const reg =
        /(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/;
      if (!value) {
        return callback(new Error("证件号码不能为空"));
      } else if (!reg.test(value)) {
        return callback(new Error("证件号码不正确"));
      } else {
        callback();
      }
    };
    return {
      OssForm: {},
      IsAuthstudentAuth:2,
      studentAuthDisable:false,
      AuthTitle:"",
      form: {
        studentAuth: 0,
        studentName: null,
        email: null,
        contractPhone: null,
        school: null,
        majorClass: null,
        introductionWorks: null,
        identityNo: null,
        fileList: [],
        certImg: null,
      },
      rules: {
        studentName: [
          {
            min: 2,
            max: 30,
            required: true,
            message: "学生名称不能为空,且为2-30位",
          },
          {
            validator: function (rule, value, callback) {
              var regex = /^[a-zA-Z\u4e00-\u9fa5]+$/;
              if (!regex.test(value)) {
                callback(new Error("请输入正确格式的姓名"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "请输入正确的电子邮箱",
            trigger: ["blur", "change"],
          },
        ],
        contractPhone: [
          {
            required: true,
            message: "手机号码不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: ["blur", "change"],
          },
        ],
        school: [
          {
            max: 60,
            required: true,
            message: "就读学校不能为空,不能超过60个字",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[\u4e00-\u9fa5]+$/,
            message: "请输入正确的学校名称",
            trigger: ["blur", "change"],
          },
        ],
        majorClass: [
          {
            max: 60,
            required: true,
            message: "专业班级不能为空，不能超过60个字",
            trigger: ["blur", "change"],
          },
        ],
        identityNo: [
          {
            required: true,
            message: "身份证号码不能为空",
            trigger: ["blur", "change"],
          },
          {
            validator: checkIdNum,
            trigger: ["blur", "change"],
          },
        ],
        fileList: [
          {
            required: true,
            message: "身份证照片不能为空",
            trigger: ["blur", "change"],
          },
        ],
        certImg: [
          {
            required: true,
            message: "学生证不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.IsAuthstudentAuth = sessionStorage.getItem('StudentAuth')
    this.studentAuthDisable = this.IsAuthstudentAuth == 2;
    if(this.IsAuthstudentAuth == 2){
      this.AuthTitle = '学员已提交认证，请等待审核！'
      this.studentAuthDisable = true
    }else {
      this.studentAuthDisable = false
      this.AuthTitle = '填写并提交报名信息后即可上传参赛作品 。温馨提示：报名信息填写后不能修改，请谨慎填写哦！'
    }

    // this.OssForm = JSON.parse(sessionStorage.getItem("aliOss"));
    getOssToken().then((res) => {
      if (res.code === 0) {
        this.OssForm = res.data;
      }
    });
  },
  methods: {
    beforeUploadOne(file) {
      //图片大小
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message({
          message: "上传图片的大小不能超过 2M!",
          type: "warning",
        });
        return false;
      }
      return isLt2M;
    },
    UploadImageOne(options) {
      if (this.form.fileList >= 2) {
        return false;
      }
      try {
        let nowDate = new Date();
        let dateTime = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
        };
        let that = this;
        let client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: that.OssForm.accessKeyId,
          accessKeySecret: that.OssForm.accessKeySecret,
          stsToken: that.OssForm.token,
          bucket: "fengjingyuanlin",
          timeout: 600000,
          secure: true,
        });
        let file = options.file; // 拿到 file
        let extendName = file.name.substring(file.name.lastIndexOf(".") + 1);
        let fileName = file.name.substr(0, file.name.lastIndexOf("."));
        let date = new Date().getTime();
        let fileNames = `${date}_${file.size}_${Math.round(
          Math.random() * 10000
        )}`;
        let folderName =
          "image/" +
          dateTime.year +
          "-" +
          dateTime.month +
          "-" +
          dateTime.date +
          "/" +
          fileNames +
          "." +
          extendName;

        client.put(folderName, file).then((res) => {
          if (res.res.statusCode === 200) {
            that.form.fileList.push({ url: res.url });
            if (that.form.fileList.length != 0) {
              that.$refs.fileListRef.validateState = "success";
            }
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    },
    beforeUploadTwo(file) {
      //图片大小
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message({
          message: "上传图片的大小不能超过 2M!",
          type: "warning",
        });
        return false;
      }
      return isLt2M;
    },
    UploadImageTwo(options) {
      let allOssForm = {};
      getOssToken().then((res) => {
        if (res.code === 0) {
          allOssForm = res.data;
          try {
            let nowDate = new Date();
            let dateTime = {
              year: nowDate.getFullYear(),
              month: nowDate.getMonth() + 1,
              date: nowDate.getDate(),
            };
            let that = this;
            let client = new OSS({
              region: "oss-cn-beijing",
              accessKeyId: allOssForm.accessKeyId,
              accessKeySecret: allOssForm.accessKeySecret,
              stsToken: allOssForm.token,
              bucket: "fengjingyuanlin",
              timeout: 600000,
              secure: true,
            });
            let file = options.file; // 拿到 file
            let extendName = file.name.substring(
              file.name.lastIndexOf(".") + 1
            );
            let fileName = file.name.substr(0, file.name.lastIndexOf("."));
            let date = new Date().getTime();
            let fileNames = `${date}_${file.size}_${Math.round(
              Math.random() * 10000
            )}`;
            let folderName =
              "image/" +
              dateTime.year +
              "-" +
              dateTime.month +
              "-" +
              dateTime.date +
              "/" +
              fileNames +
              "." +
              extendName;

            client.put(folderName, file).then((res) => {
              if (res.res.statusCode === 200) {
                that.form.certImg = res.url;
                if (that.form.certImg) {
                  that.$refs.certImgRef.validateState = "success";
                }
              } else {
                options.onError("上传失败");
              }
            });
          } catch (e) {
            options.onError("上传失败");
          }
        }
      });
    },
    handleRemove(e) {
      let index = this.form.fileList.indexOf(e);
      if (index > -1) {
        this.form.fileList.splice(index, 1);
      }
      if (this.form.fileList.length != 0) {
        this.$refs.fileListRef.validateState = "success";
      }else{
        this.$refs.fileListRef.validateState = "error";
      }
    },
    handleRemove1(e) {
      this.form.certImg = "";
      this.$refs.certImgRef.validateState = "error";
    },
    studentAuth() {
      this.$emit("studentAuth", this.studentAuth);
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.userId = sessionStorage.getItem("userId");
          if (this.form.fileList[0]) {
            this.form.identityFrontImg = this.form.fileList[0].url;
          } else if (this.form.fileList[1]) {
            this.form.identityBackImg = this.form.fileList[1].url;
          }
          this.$confirm("您确定信息无误，进行提交吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              checkUniqueness({
                identityNo: this.form.identityNo,
                contractPhone: this.form.contractPhone,
              }).then((res) => {
                if (res.code == 200) {
                  studentAuth(this.form).then((res) => {
                    if (res.code == 200) {
                      sessionStorage.setItem("studentId", res.studentId);
                      this.$message({
                        message: "认证成功",
                        type: "success",
                      });
                      this.IsAuthstudentAuth = 2
                      this.AuthTitle = '学员已提交认证，请等待审核！'
                      this.studentAuthDisable = true
                      sessionStorage.setItem("StudentAuth", 2);
                      sessionStorage.setItem("activeName", 3);
                      sessionStorage.setItem("activenum", 1);
                    }
                  });
                }
              });
            })
            .catch(() => {});
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.studentAuth {
  width: 100%;
  background-color: #fff;

  .studentAuthBox {
    width: 60%;
    margin: 0 auto;
  }
  .studentAuthTitle {
    padding: 25px 0;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #4d4d4d;
    border-bottom: 1px solid #ebebeb;
  }
  .studentAutMsg {
    padding-top: 25px;
  }
  .formBox {
    width: 1320px;
    padding: 30px 0;
    margin: 0 auto;
    .choose {
      position: relative;
      width: 380px;
    }
    .info-style {
      width: 100%;
      text-align: center;
      margin-top: 12%;
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 380px;
      height: 180px;
      line-height: 180px;
      text-align: center;
      border: 1px dashed #d9d9d9;
    }
    .avatar {
      width: 380px;
      height: 180px;
      display: block;
    }
    .el-dialog__wrapper {
      position: absolute;
      background: #8c939d;
      opacity: 0.3;
    }
  }
}
</style>