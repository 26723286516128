<template>
    <div class="mobile">
      <img src="../assets/image/mobile.jpg" alt="">
    </div>
</template>

<script>
export default {
  name: "mobile"
}
</script>

<style scoped lang="scss">
.mobile{
    img{
      width: 100%;
    }
}
</style>