<template>
  <div class="passWorld">
    <div class="title">
      <p class="message">密码设置</p>
    </div>
    <div class="form">
      <div class="phone">
        <p>您的手机号码：</p>
        <span>{{ form.userName }}</span>
      </div>
      <el-form ref="form" :model="form" :rules="rules" label-width="100">
        <el-form-item label="" prop="code">
          <el-input
            v-model="form.code"
            style="width: 300px"
            placeholder="请输入验证码"
          ></el-input>
          <button
            @click.prevent="getCode(0)"
            class="code-btn"
            type="button"
            :disabled="!show"
          >
            <span v-show="show">获取验证码</span>
            <span v-show="!show" class="count">{{ count }} s</span>
          </button>
        </el-form-item>
        <el-form-item style="width: 500px" prop="password">
          <el-input
            type="password"
            placeholder="请输入新密码"
            v-model="form.password"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item style="width: 500px" prop="newPassWorld">
          <el-input
            type="password"
            placeholder="请再次输入新密码"
            v-model="form.newPassWorld"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <p class="Submit" @click="Submit">确认修改</p>
  </div>
</template>

<script>
import {
  getCode,
  subRegister,
  getLogin,
  logout,
  getOssToken,
  getInfo,
} from "@/request/login";
import { encrypt, decrypt } from "@/request/jsencrypt";
import { subPassWorld } from "@/request/mine";
import NavCon from "@/components/navcon/navCon";
export default {
  components: { NavCon },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value == "" || value == null) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      count: "",
      show: "false",
      phone: null,
      form: {
        userName: null,
        code: null,
        password: null,
        newPassWorld: null,
        sendType: 3,
      },
      rules: {
        password: [
          {
            min: 8,
            max: 20,
            required: true,
            message: "密码不能为空,且必须在8-20位",
            trigger: "blur",
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/,
            message: "密码至少8位，要求必须字母加数字",
          },
        ],

        newPassWorld: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUseridData();
  },
  methods: {
    getUseridData() {
      getInfo().then((res) => {
        this.form.userName = res.user.phonenumber;
      });
    },
    // 获取短信验证码
    getCode(n) {
      if (n == 0) {
        //axios请求
        getCode({
          userName: this.form.userName,
          sendType: 3,
        }).then((res) => {
          if (res.data.flag == 1) {
            this.$message({
              message: res.msg + "2分钟内有效",
              type: "success",
            });
            this.code = res.data.code;
          } else if (res.data.flag == 2) {
            this.$message({
              message: res.msg,
              type: "warning",
            });
            this.code = res.data.code;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      } else if (n == 1) {
        getCode({ userName: this.loginForm.userName }).then((res) => {
          if (res.data.flag == 1) {
            this.$message({
              message: res.msg + "2分钟内有效",
              type: "success",
            });
            this.code = res.data.code;
          } else if (res.data.flag == 2) {
            this.$message({
              message: res.msg,
              type: "warning",
            });
            this.code = res.data.code;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      }

      // 验证码倒计时
      if (!this.timer) {
        this.count = 120;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 120) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    reset() {
      this.form = {
        userName: null,
        code: null,
        password: null,
        newPassWorld: null,
      };
    },
    Submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.password = encrypt(this.form.password);
          subPassWorld(this.form)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "密码修改成功",
                  type: "success",
                });
                this.reset();
                this.getUseridData();
              }
            })
            .catch(() => {
              this.form.password = decrypt(this.form.password);
              this.$message({
                message: res.msg,
                type: "warning",
              });
            });
        }
      });
    },
    goHome() {
      this.$router.push({ path: "/HomeView" });
    },
    GoIsShow() {
      this.$refs.Navigation;
    },
  },
};
</script>

<style lang="scss" scoped>
.passWorld {
  width: 100%;
  padding: 30px 0px;
  .title {
    padding: 0 0 20px 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    .message {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
  .form {
    padding: 10px 30px;
    .phone {
      display: flex;
      margin-bottom: 10px;
      p {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
      span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .Submit {
    cursor: pointer;
    margin-left: 30px;
    width: 100px;
    height: 40px;
    background: #2a9261;
    text-align: center;
    line-height: 40px;
    color: #fff;
  }
}
.code-btn {
  width: 92px;
  height: 36px;
  background: #f0f0f0;
  border-radius: 3px;
  position: absolute;
  top: 2px;
  left: 206px;
  z-index: 222;
  color: #219560;
  font-size: 14px;
  border: none;
  cursor: pointer;
}
</style>