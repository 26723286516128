<template>
  <div class="designSettle">
    <div class="tishi">
      <el-alert :title="titleText" :closable="false" type="warning" show-icon>
      </el-alert>
    </div>
    <div class="stepBox">
      <div class="stepTitle">{{ title }}</div>
      <div class="steps-box">
        <!-- 步骤条 -->
        <div
          class="Article-steps"
          :class="stepList.length <= activeIndex ? 'step-over' : ''"
        >
          <!-- 步骤条背景进度条 -->
          <div class="line">
            <span
              class="plan"
              :style="`width:${
                activeIndex * (100 / (stepList.length - 1)) -
                100 / (stepList.length - 1) / 2
              }%`"
            ></span>
          </div>
          <!-- 每步部分 -->
          <span
            class="step"
            v-for="(i, index) in stepList"
            :key="index"
            :class="
              activeIndex == i.stepIndex || i.stepIndex <= activeIndex
                ? 'step-active'
                : ''
            "
          >
            <span class="step-num">
              <span class="num">{{ i.stepIndex }}</span>
            </span>
            <p class="title">{{ i.title }}</p>
          </span>
        </div>
      </div>
    </div>
    <div class="form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="130px"
        v-if="activeIndex === 1"
      >
        <el-form-item label="企业类型" prop="companyType">
          <el-select
            style="width: 50%"
            v-model="form.companyType"
            filterable
            clearable
            placeholder="请选择企业类型"
          >
            <el-option
              v-for="item in companyTypeList"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业名称" prop="companyName">
          <el-input
            style="width: 50%"
            :maxlength="50"
            v-model="form.companyName"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业信用代码" prop="socialCreditCode">
          <el-input
            style="width: 50%"
            v-model="form.socialCreditCode"
            placeholder="请输入企业信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业联系电话" prop="companyPhone">
          <el-input
            disabled
            style="width: 50%"
            v-model="form.companyPhone"
            placeholder="请输入企业联系电话"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照原件" prop="businessLicense">
          <el-checkbox-group
            v-model="form.businessLicense"
            v-show="false"
          ></el-checkbox-group>
          <el-upload
            class="avatar-uploader"
            action=""
            accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
            :http-request="UploadImageOne"
            :before-upload="beforeUploadOne"
            :show-file-list="false"
          >
            <img
              v-if="form.businessLicense"
              :src="form.businessLicense"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <p class="ButtonActive" @click="nextStep(1)">下一步</p>
        </el-form-item>
      </el-form>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        v-if="activeIndex === 2"
      >
        <el-form-item label="法人名称" prop="legalName">
          <el-input
            style="width: 50%"
            v-model="form.legalName"
            placeholder="请输入法人名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人联系方式" prop="legalPhone">
          <el-input
            style="width: 50%"
            v-model="form.legalPhone"
            maxlength="11"
            placeholder="请输入法人联系方式"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="identityCardNo">
          <el-input
            style="width: 50%"
            :maxlength="18"
            v-model="form.identityCardNo"
            placeholder="请输入身份证号码"
          ></el-input>
        </el-form-item>
        
        <br />
        <el-form-item label="服务区域" prop="serviceArea" ref="serviceAreaRef">
          <el-checkbox-group
            v-model="form.serviceArea"
            v-show="false"
          ></el-checkbox-group>
          <el-cascader
            placeholder="请选择省市区"
            v-model="selectedOptions"
            :options="options"
            clearable
            filterable
            @change="handleChanger"
            style="width: 50%"
            popper-class="AreaClass"
          >
          </el-cascader>
        </el-form-item>
        <br />
        <el-form-item label="服务类型" prop="serviceLabel">
          <el-select
            value-key="dictValue"
            style="width: 50%"
            v-model="form.serviceLabel"
            multiple
            filterable
            clearable
            placeholder="请选择服务类型"
            @change="chnage"
          >
            <el-option
              v-for="item in serverList"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司成员数量" prop="designerNum">
          <el-input
            style="width: 50%"
            maxlength="4"
            v-model="form.designerNum"
            @input="
              form.designerNum = form.designerNum
                .replace(/[^\d]/g, '')
                .replace(/^0{1,}/g, '')
            "
            placeholder="请输入公司成员数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司介绍" prop="companyIntroduction">
          <el-input
            style="width: 50%"
            type="textarea"
            maxlength="500"
            v-model="form.companyIntroduction"
            placeholder="请输入1-500字的公司介绍"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传公司logo" prop="companyLogo">
          <el-checkbox-group
            v-model="form.companyLogo"
            v-show="false"
          ></el-checkbox-group>
          <el-upload
            action=""
            class="avatar-uploader"
            accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
            :show-file-list="false"
            :http-request="UploadImageTwo"
            :before-upload="beforeUploadOne"
          >
            <img
              v-if="form.companyLogo"
              :src="form.companyLogo"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color: #999">
            建议尺寸100x100px,jpg,.JPG,.jpeg,.JPEG,.PNG,.png格式，大小不超过1M
          </p>
        </el-form-item>
        <el-form-item label="上传封面图" prop="coverImg">
          <el-checkbox-group
            v-model="form.coverImg"
            v-show="false"
          ></el-checkbox-group>
          <el-upload
            class="upload-demo"
            drag
            accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
            action=""
            :http-request="UploadImageThere"
            :show-file-list="false"
            :before-upload="beforeUploadOne"
          >
            <i class="el-icon-upload" v-if="form.coverImg == ''"></i>
            <div class="el-upload__text" v-if="form.coverImg == ''">
              点击或将文件拖拽到这里上传
            </div>
            <img
              style="width: 100%; height: 100%"
              :src="form.coverImg"
              v-if="form.coverImg != ''"
              alt=""
            />
          </el-upload>
          <p style="color: #999">
            建议尺寸438x300px,.jpg,.JPG,.jpeg,.JPEG,.PNG,.png格式
          </p>
        </el-form-item>
        <el-form-item label="上传封面轮播图" prop="fileList">
          <el-checkbox-group
            v-model="form.fileList"
            v-show="false"
          ></el-checkbox-group>
          <div style="display: flex; margin-bottom: 20px">
            <div
              v-for="(item,index) in form.fileList"
              class="ImgSwiperBox"
              style="
                width: 124px;
                height: 124px;
                margin-right: 20px;
                position: relative;
              "
              :key="index"
            >
              <img
                style="width: 124px; height: 124px"
                :src="item.carouselImg"
                alt=""
              />
              <div  style="width: 30px;height: 30px;background-color: #FFFFFF;border-radius: 50%;position: absolute;right: 0;top: 0;    text-align: center; line-height: 30px;">
                <i
                  class="el-icon-delete"
                  style="font-size: 16px; color: #fa4543"
                  @click="handleRemove(item)"
                ></i>
              </div>
            </div>

            <el-upload
              action=""
              :limit="4"
              v-if="form.fileList.length != 4"
              style="width: 124px; height: 124px"
              class="avatar-uploader"
              accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
              :show-file-list="false"
              :before-upload="beforeUploadOne"
              :http-request="UploadImageFour"
            >
              <i
                v-if="form.fileList.length != 4"
                class="el-icon-plus avatar-uploader-icon"
                style="width: 124px; height: 124px"
              ></i>
            </el-upload>
          </div>
          <p style="color: #999">
            建议尺寸438x300px,.jpg,.JPG,.jpeg,.JPEG,.PNG,.png格式,最多上传4张
          </p>
        </el-form-item>
        <div
          style="
            margin: 20px 30px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          "
        >
          银行信息
        </div>
        <el-form-item label="银行名称" prop="openBankName">
          <el-input
            style="width: 50%"
            maxlength="50"
            v-model="form.openBankName"
            placeholder="请输入银行名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="openBankInfo">
          <el-input
            style="width: 50%"
            maxlength="50"
            v-model="form.openBankInfo"
            placeholder="请输入开户行"
          ></el-input>
        </el-form-item>
        <el-form-item label="账户名称" prop="openAccountName">
          <el-input
            style="width: 50%"
            maxlength="50"
            v-model="form.openAccountName"
            placeholder="请输入账户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账户" prop="openBankAccount">
          <el-input
            style="width: 50%"
            maxlength="19"
            v-model="form.openBankAccount"
            placeholder="请输入银行账户"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div style="display: flex; align-items: center">
            <p class="ButtonActive" @click="backStep">上一步</p>
            <p class="ButtonActive" @click="nextStep(2)">提 交</p>
          </div>
        </el-form-item>
      </el-form>
      <div class="ThereBox" v-if="activeIndex === 3">
        <img src="../../assets/image/SuccessImg.png" alt="" />
        <div class="Right">
          <p>恭喜您提交成功! 请耐心等待1-3个工作日通知您审核结果哦~</p>
          <p style="cursor: pointer">
            <span>{{ TimeNumber }}秒后自动返回首页</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CodeToText1, regionData1, TextToCode1 } from "@/utils/address";
import OSS from "ali-oss";
import { nextCompanySettleIn, subCompanySettleIn } from "@/request/settleIn";
import { getDicts } from "@/request/dict/data";
import {
  selectDictLabel,
  selectDictLabels,
  CheckSocialCreditCode,
} from "@/utils/weihua";
import { getOssToken } from "@/request/login";
export default {
  components: { Steps: () => import("@/components/Steps") },
  data() {
    let checkIdentityCardNo = function (rule, value, callback) {
      if (!value) {
        callback(new Error("企业信用代码不能为空"));
      } else {
        if (!CheckSocialCreditCode(value)) {
          callback(new Error("企业信用代码不合法"));
        } else {
          callback();
        }
      }
    };
    var checkIdNum = (rule, value, callback) => {
      const reg =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (!value) {
        return callback(new Error("证件号码不能为空"));
      } else if (!reg.test(value)) {
        return callback(new Error("证件号码不正确"));
      } else {
        callback();
      }
    };
    let addressRule = function (rule, value, callback) {
      if (!value) {
        callback(new Error("请输入详细地址"));
      } else if (!/^[\u4e00-\u9fa5]*$/.test(value)) {
        return callback(new Error("详细地址格式不正确，请重新输入"));
      } else {
        callback();
      }
    };
    let openBankAccount = function (rule, value, callback) {
      if (!value) {
        callback();
      } else if (!/^[0-9]*$/.test(value)) {
        return callback(new Error("格式不正确，请重新输入"));
      } else if (value.length > 19 || value.length < 9) {
        return callback(new Error("格式不正确，请重新输入"));
      }
      {
        callback();
      }
    };
    let openBankName = function (rule, value, callback) {
      if (!value) {
        callback();
      } else if (!/^[\u4e00-\u9fa5]*$/.test(value)) {
        return callback(new Error("格式不正确，请重新输入"));
      } else {
        callback();
      }
    };
    return {
      title: "",
      personIdentity: null,
      //当前位置
      activeIndex: 1,
      TimeNumber: 3,
      serverList: [],
      companyTypeList: [],
      //步骤条步数
      timer: null,
      timer2: null,
      OssForm: {},
      stepList: [
        {
          stepIndex: 1,
          title: "填写基本信息",
        },
        {
          stepIndex: 2,
          title: "详细资料",
        },
        {
          stepIndex: 3,
          title: "提交成功",
        },
      ],
      titleText: "",
      //省市区初始化数据
      options: regionData1,
      selectedOptions: [],
      form: {
        companyType: null,
        companyName: null,
        companyPhone: sessionStorage.getItem("phone"),
        socialCreditCode: null,
        businessLicense: null,
        legalName: null,
        legalPhone: null,
        identityCardNo: null,
        serviceArea: null,
        serviceLabel: [],
        designerNum: null,
        companyIdentity: null,
        companyLogo: null,
        coverImg: null,
        fileList: [],
        companyIntroduction: null,
        openBankName: null,
        openBankInfo: null,
        openAccountName: null,
        openBankAccount: null,
        baseConstructCompanyCarouselImgList: [],
      },
      rules: {
        companyType: [
          {
            required: true,
            message: "企业类型不能为空",
            trigger: ["change", "blur"],
          },
        ],
        companyName: [
          {
            required: true,
            message: "企业名称不能为空",
            trigger: ["change", "blur"],
          },
        ],
        socialCreditCode: [
          {
            required: true,
            validator: checkIdentityCardNo,
            trigger: ["change", "blur"],
          },
        ],
        businessLicense: [
          {
            required: true,
            message: "营业执照不能为空",
            trigger: ["change", "blur"],
          },
        ],
        designerNum: [
          {
            required: true,
            message: "请输入公司成员数量，必须为数字",
            trigger: ["change", "blur"],
          },
        ],
        companyLogo: [
          {
            required: true,
            message: "请上传公司logo",
            trigger: ["change", "blur"],
          },
        ],
        coverImg: [
          {
            required: true,
            message: "请上传封面图",
            trigger: ["change", "blur"],
          },
        ],
        fileList: [
          {
            required: true,
            message: "请上传轮播图",
            trigger: ["change", "blur"],
          },
        ],
        legalName: [
          {
            min: 2,
            max: 30,
            required: true,
            message: "请输入2-30位的法人名称",
            trigger: ["change", "blur"],
          },
          {
            validator: function (rule, value, callback) {
              var regex = /^[a-zA-Z\u4e00-\u9fa5]+$/;
              if (!regex.test(value)) {
                callback(new Error("请输入正确格式的姓名"));
              } else {
                callback();
              }
            },
            trigger: ["change", "blur"],
          },
        ],
        legalPhone: [
          { required: true, message: "联系方式不能为空", trigger: ["blur"] },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: ["change", "blur"],
          },
        ],
        identityCardNo: [
          { required: true, message: "身份证不能为空", trigger: ["blur"] },
          {
            validator: checkIdNum,
            trigger: ["blur"],
          },
        ],
        serviceArea: [
          { required: true, message: "服务区域不能为空", trigger: ["change","blur"] },
        ],
        serviceLabel: [
          { required: true, message: "服务类型不能为空", trigger: ["change"] },
        ],
        companyIntroduction: [
          {
            max: 500,
            required: true,
            message: "公司介绍不能为空",
            trigger: ["change", "blur"],
          },
        ],
        openBankName: [
          {
            required: false,
            validator: openBankName,
            trigger: ["change", "blur"],
          },
        ],
        openBankInfo: [
          {
            required: false,
            validator: openBankName,
            trigger: ["change", "blur"],
          },
        ],
        openAccountName: [
          {
            required: false,
            validator: openBankName,
            trigger: ["change", "blur"],
          },
        ],
        openBankAccount: [
          {
            required: false,
            validator: openBankAccount,
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal != oldVal) {
        this.reset();
      }
    },
  },
  activated() {
    this.form.companyIdentity = this.$route.query.isShow;
    this.personIdentity = this.$route.query.isShow;
    this.switchLabel();
    this.activeIndex = 1;
    this.form.companyPhone = sessionStorage.getItem("phone");
    console.log(this.form.companyPhone)
  },
  created() {
    this.form.companyIdentity = this.$route.query.isShow;
    this.personIdentity = this.$route.query.isShow;
    this.switchLabel();
    this.form.companyPhone = sessionStorage.getItem("phone");
    getOssToken().then((res) => {
      if (res.code === 0) {
        this.OssForm = res.data;
      }
    });
    getDicts("msk_sys_server_type").then((res) => {
      this.serverList = res.data;
    });
    getDicts("msk_company_status").then((res) => {
      this.companyTypeList = res.data;
    });
  },
  methods: {
    switchLabel() {
      if (this.form.companyIdentity == 1) {
        this.title = "园林建筑公司入驻";
        this.titleText =
          "具备城市园林绿化工程设计、造价、材料、施工、养护等一体化服务的企业";
      } else if (this.form.companyIdentity == 6) {
        this.title = "园林材料公司入驻";
        this.titleText =
          "具备园林常用的四大类材料，园林常用的地材材料、铺装材料、安装材料、苗木花卉等的企业。";
      } else if (this.form.companyIdentity == 7) {
        this.title = "园林养护公司入驻";
        this.titleText =
          "具备园林绿化养护操作规程及园林绿化养护质量标准，拥有专业的园艺师保质保量完成养护服务的企业。";
      } else if (this.form.companyIdentity == 8) {
        this.title = "园林培训公司入驻";
        this.titleText =
          "具备风景园林行业专业培训课程规划及专业培训讲师的企业。";
      } else if (this.form.companyIdentity == 10) {
        this.title = "园林造价公司入驻";
        this.titleText =
          "熟悉园林市场材料价格，了解准确的市场价格和预算价格的风景园林行业专业工程造价服务的企业。";
      } else if (this.form.companyIdentity == 11) {
        this.title = "园林设计公司入驻";
        this.titleText =
          "具备生态学、园林植物与观赏园艺、风景园林规划与设计等方面服务的企业。";
      } else {
        this.title = "园林施工公司入驻";
        this.titleText =
          "具备园林绿化工程相关篆刻、雕塑、插花工艺、石刻、打磨等特殊工艺技法服务人才类型丰富的企业。";
      }
    },
    //省市区选择后，form表单数据保存
    handleChanger(arr) {
      if (
        CodeToText1[arr[1]] == undefined &&
        CodeToText1[arr[2]] == undefined
      ) {
        this.form.serviceArea = CodeToText1[arr[0]];
      } else if (
        CodeToText1[arr[1]] != undefined &&
        CodeToText1[arr[2]] == undefined
      ) {
        this.form.serviceArea = CodeToText1[arr[0]] + "-" + CodeToText1[arr[1]];
      } else {
        this.form.serviceArea =
          CodeToText1[arr[0]] +
          "-" +
          CodeToText1[arr[1]] +
          "-" +
          CodeToText1[arr[2]];
      }
      if (this.form.serviceArea) {
        this.$refs.serviceAreaRef.validateState = "success";
      } else {
        this.$refs.serviceAreaRef.validateState = "error";
      }
    },
    // 省市区选择后，form表单数据保存
    handleChange(value) {
      var arr = value;
      if (CodeToText1[arr[0]] === "全部") {
        this.form.serviceArea = CodeToText1[arr[0]];
      } else if (CodeToText1[arr[1]] === "全部") {
        this.form.serviceArea = CodeToText1[arr[1]];
      } else {
        this.form.serviceArea =
          CodeToText1[arr[0]] +
          "-" +
          CodeToText1[arr[1]] +
          "-" +
          CodeToText1[arr[2]];
      }
    },
    UploadImageOne(options) {
      try {
        let nowDate = new Date();
        let dateTime = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
        };
        let that = this;
        let client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: that.OssForm.accessKeyId,
          accessKeySecret: that.OssForm.accessKeySecret,
          stsToken: that.OssForm.token,
          bucket: "fengjingyuanlin",
          timeout: 600000,
          secure: true,
        });
        let file = options.file; // 拿到 file
        let extendName = file.name.substring(file.name.lastIndexOf(".") + 1);
        let fileName = file.name.substr(0, file.name.lastIndexOf("."));
        let date = new Date().getTime();
        let fileNames = `${date}_${file.size}_${Math.round(
          Math.random() * 10000
        )}`;
        let folderName =
          "image/" +
          dateTime.year +
          "-" +
          dateTime.month +
          "-" +
          dateTime.date +
          "/" +
          fileNames +
          "." +
          extendName;

        client.put(folderName, file).then((res) => {
          if (res.res.statusCode === 200) {
            this.form.businessLicense = res.url;
            // this.listCoverImgFileList = []
            // this.listCoverImgFileList.push({
            //   imgUrl: res.url,
            //   imgName: fileName,
            //   imgType:  'image/' + extendName,
            //   imgSize: that.fileSizeOne,
            // });
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    },
    UploadImageTwo(options) {
      try {
        let nowDate = new Date();
        let dateTime = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
        };
        let that = this;
        let client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: that.OssForm.accessKeyId,
          accessKeySecret: that.OssForm.accessKeySecret,
          stsToken: that.OssForm.token,
          bucket: "fengjingyuanlin",
          timeout: 600000,
          secure: true,
        });
        let file = options.file; // 拿到 file
        let extendName = file.name.substring(file.name.lastIndexOf(".") + 1);
        let fileName = file.name.substr(0, file.name.lastIndexOf("."));
        let date = new Date().getTime();
        let fileNames = `${date}_${file.size}_${Math.round(
          Math.random() * 10000
        )}`;
        let folderName =
          "image/" +
          dateTime.year +
          "-" +
          dateTime.month +
          "-" +
          dateTime.date +
          "/" +
          fileNames +
          "." +
          extendName;
        client.put(folderName, file).then((res) => {
          if (res.res.statusCode === 200) {
            this.form.companyLogo = res.url;
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    },
    UploadImageThere(options) {
      try {
        let nowDate = new Date();
        let dateTime = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
        };
        let that = this;
        let client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: that.OssForm.accessKeyId,
          accessKeySecret: that.OssForm.accessKeySecret,
          stsToken: that.OssForm.token,
          bucket: "fengjingyuanlin",
          timeout: 600000,
          secure: true,
        });
        let file = options.file; // 拿到 file
        let extendName = file.name.substring(file.name.lastIndexOf(".") + 1);
        let fileName = file.name.substr(0, file.name.lastIndexOf("."));
        let date = new Date().getTime();
        let fileNames = `${date}_${file.size}_${Math.round(
          Math.random() * 10000
        )}`;
        let folderName =
          "image/" +
          dateTime.year +
          "-" +
          dateTime.month +
          "-" +
          dateTime.date +
          "/" +
          fileNames +
          "." +
          extendName;

        client.put(folderName, file).then((res) => {
          if (res.res.statusCode === 200) {
            this.form.coverImg = res.url;
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    },
    UploadImageFour(options) {
      try {
        let nowDate = new Date();
        let dateTime = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
        };
        let that = this;
        let client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: that.OssForm.accessKeyId,
          accessKeySecret: that.OssForm.accessKeySecret,
          stsToken: that.OssForm.token,
          bucket: "fengjingyuanlin",
          timeout: 600000,
          secure: true,
        });
        let file = options.file; // 拿到 file
        let extendName = file.name.substring(file.name.lastIndexOf(".") + 1);
        let fileName = file.name.substr(0, file.name.lastIndexOf("."));
        let date = new Date().getTime();
        let fileNames = `${date}_${file.size}_${Math.round(
          Math.random() * 10000
        )}`;
        let folderName =
          "image/" +
          dateTime.year +
          "-" +
          dateTime.month +
          "-" +
          dateTime.date +
          "/" +
          fileNames +
          "." +
          extendName;

        client.put(folderName, file).then((res) => {
          if (res.res.statusCode === 200) {
            this.form.fileList.push({
              carouselImg: res.url,
            });
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    },
    chnage(val) {
      console.log(val);
      this.$set(this.form, "serviceLabel", val);
      this.$forceUpdate();
    },
    handleRemove(e) {
      let index = this.form.fileList.indexOf(e);
      if (index > -1) {
        this.form.fileList.splice(index, 1);
      }
    },
    beforeUploadOne(file) {
      //图片大小
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片的大小不能超过 2M!");
        return false;
      }
      return isLt2M;
    },
    reset() {
      this.form = {
        companyType: null,
        companyName: null,
        socialCreditCode: null,
        businessLicense: null,
        legalName: null,
        legalPhone: null,
        identityCardNo: null,
        serviceArea: null,
        serviceLabel: [],
        companyIntroduction: null,
        openBankName: null,
        openBankInfo: null,
        openAccountName: null,
        openBankAccount: null,
      };
      this.selectedOptions = [];
    },
    backStep() {
      if (this.activeIndex === 2) {
        this.activeIndex--;
        this.$refs.form.clearValidate();
      }
    },
    nextStep(n) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let _this = this;
          if (n == 1) {
            nextCompanySettleIn(_this.form).then((res) => {
              if (res.code == 200) {
                _this.next();
              } else {
                _this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
          } else {
            if (_this.form.serviceLabel) {
              _this.form.baseConstructCompanyServiceLabelList = [];
              let arr = [];
              _this.serverList.forEach((item) => {
                _this.form.serviceLabel.forEach((item1) => {
                  if (item1 == item.dictValue) {
                    arr.push(item);
                  }
                });
              });
              arr.forEach((item) => {
                _this.form.baseConstructCompanyServiceLabelList.push({
                  serviceLabel: item.dictValue,
                  indexNo: item.dictValue,
                });
              });
            }
            _this.form.baseConstructCompanyCarouselImgList =
              _this.form.fileList;

            subCompanySettleIn(_this.form).then((res) => {
              if (res.code == 200) {
                _this.next();
                _this.reset();
                _this.selectedOptions = null;
              } else {
                _this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
          }
          // this.next();
        }
      });
    },

    next() {
      this.activeIndex += 1;
      if (this.activeIndex === 3) {
        this.timer2 = setInterval(() => {
          if (this.TimeNumber === 0) {
            this.TimeNumber = 3;
            this.activeIndex = 1;
            clearInterval(this.timer2);
            this.timer2 = null;

            this.$router.push({
              path: "/HomeView",
            });
          } else {
            this.TimeNumber -= 1;
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader {
  width: 124px;
  height: 124px;
  display: flex;
  border: 1px dashed #ccc;
}
.avatar-uploader .el-upload {
  border: 1px dashed #ccc;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 124px;
  height: 124px;
  line-height: 124px;
  text-align: center;
}
.avatar {
  width: 124px;
  height: 124px;
  display: block;
  border: 1px dashed #ccc;
}
.designSettle {
  width: 1320px;
  margin: 0 auto;
  min-height: 600px;
  background-color: #fff;
  padding-top: 30px;
  .tishi {
    width: 1100px;
    margin: 0 auto 50px auto;
  }
  .stepBox {
    .stepTitle {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 700;
      color: #333333;
      line-height: 24px;
      margin-bottom: 10px;
      text-align: center;
    }
    .steps-box {
      user-select: none;
      width: 800px;
      height: 100px;
      padding-top: 50px;
      margin: 0 auto;
      position: relative;
      // <!-- 步骤条背景进度条 -->
      .line {
        display: block;
        margin: 0 auto;
        position: absolute;
        top: 75px;
        left: 15%;
        background: #ccc;
        width: 592px;
        height: 2px;
        z-index: 1;
        overflow: hidden;

        .plan {
          position: absolute;
          top: 0;
          left: 0;
          height: 2px;
          transition: 0.5s;
          background: #219560;
        }
      }

      .Article-steps {
        display: flex;
        justify-content: space-between;

        .step {
          z-index: 999;
          text-align: center;
          width: 200px;

          .title {
            font-size: 14px;
            color: #c0c0c0;
            margin-top: 5px;
          }

          .step-num {
            width: 30px;
            height: 30px;
            display: inline-block;
            line-height: 30px;
            background: #c0c0c0;
            text-align: center;
            border-radius: 50%;
            margin-top: 10px;

            //clip-path: polygon(50% 0, 100% 85%, 0 85%);
            color: white;
            //font-weight: bold;
            transition: 0.5s;

            .num {
              transition: 0.5s;
              display: inline-block;
            }
          }
        }
      }

      //当前所在位置样式
      .step-active {
        .step-num {
          background: #219560 !important;
          //transform: rotate(90deg);
          .num {
            //transform: rotate(-90deg);
          }
        }

        .title {
          color: #219560 !important;
        }
      }

      //全部完成样式
      .step-over {
        .plan {
          background: #219560 !important;
        }

        .step-num {
          background: #219560 !important;
        }

        .title {
          color: #219560 !important;
        }
      }
    }
  }
  .form {
    width: 60%;
    margin: 0 auto;
    .ButtonActive {
      margin: 0 auto;
      width: 138px;
      height: 38px;
      background: #219560;
      border-radius: 3px;
      font-size: 14px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .ThereBox {
    width: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .Right {
      margin-left: 10px;

      p {
        font-size: 14px;
        color: #4d4d4d;

        span:nth-child(2) {
          color: #219560;
          cursor: pointer;
          margin-left: 5px;
          display: inline-block;
        }
      }
    }
  }
}
</style>