<template>
  <div class="ForgotPasswordPage">
    <p style="height: 20px"></p>
    <div class="ForgotPasswordBox">
      <div class="PasswordTop">
        <div class="steps-box">
          <!-- 步骤条 -->
          <div
            class="Article-steps"
            :class="stepList.length <= activeIndex ? 'step-over' : ''"
          >
            <!-- 步骤条背景进度条 -->
            <div class="line">
              <span
                class="plan"
                :style="`width:${
                  activeIndex * (100 / (stepList.length - 1)) -
                  100 / (stepList.length - 1) / 2
                }%`"
              ></span>
            </div>
            <!-- 每步部分 -->
            <span
              class="step"
              v-for="(i, index) in stepList"
              :key="index"
              :class="
                activeIndex == i.stepIndex || i.stepIndex <= activeIndex
                  ? 'step-active'
                  : ''
              "
            >
              <span class="step-num">
                <span class="num">{{ i.stepIndex }}</span>
              </span>
              <p class="title">{{ i.title }}</p>
            </span>
          </div>
        </div>
      </div>
      <div class="PasswordBottom">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="120px"
          v-if="activeIndex === 1"
        >
          <el-form-item label="手机号码" prop="phone">
            <div class="pr">
              <el-input
                style="width: 283px"
                v-model="form.phone"
                maxlength="11"
                placeholder="请输入手机号码"
              ></el-input>
              <button
                type="button"
                @click.prevent="getCode()"
                class="code-btn"
                :disabled="!show"
              >
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{ count }} s</span>
              </button>
            </div>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input
              style="width: 283px"
              v-model="form.code"
              placeholder="请输入验证码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <p class="ButtonActive" @click="nextStep(1)">下一步</p>
          </el-form-item>
        </el-form>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="120px"
          v-if="activeIndex === 2"
        >
          <el-form-item label="新密码" prop="password">
            <el-input
              type="password"
              show-password
              style="width: 283px"
              v-model="form.password"
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="newPassword">
            <el-input
              type="password"
              show-password
              style="width: 283px"
              v-model="form.newPassword"
              placeholder="请确认新密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <p class="ButtonActive" @click="nextStep(2)">提交</p>
          </el-form-item>
        </el-form>
        <div class="ThereBox" v-if="activeIndex === 3">
          <img src="../../assets/image/SuccessImg.png" alt="" />
          <div class="Right">
            <p>重置密码成功！请牢记你的手机号码和密码。</p>
            <p style="cursor: pointer">
              <span>{{ TimeNumber }}秒后自动返回首页</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCode,
  replaPassWord,
  subPassWord,
  subRegister,
  getLogin,
  logout,
} from "@/request/login";
import { encrypt, decrypt } from "@/request/jsencrypt";
export default {
  name: "ForgotPasswordPage",
  components: {
    Steps: () => import("@/components/Steps"),
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      //当前位置
      activeIndex: 1,
      TimeNumber: 5,
      //步骤条步数
      timer: null,
      timer2: null,
      stepList: [
        {
          stepIndex: 1,
          title: "验证手机号码",
        },
        {
          stepIndex: 2,
          title: "重置密码",
        },
        {
          stepIndex: 3,
          title: "重置密码成功",
        },
      ],
      form: {
        phone: null,
        code: null,
        password: null,
        newPassword: null,
        loginPort: null,
        sendType: 3,
      },
      show: "false",
      count: "",
      rules: {
        phone: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        password: [
          {
            min: 8,
            max: 20,
            required: true,
            message: "密码不能为空,且必须在8-20位",
            trigger: "blur",
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/,
            message: "密码至少8位，要求必须字母加数字",
          },
        ],
        newPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  created() {
    this.activeIndex = 1;
  },
  methods: {
    goHome() {
      this.$router.push({ path: "/HomeView" });
    },
    nextStep(n) {
      this.form.loginPort = 9;
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (n === 1) {
            let formData = new FormData();
            formData.append("loginPort", this.form.loginPort);
            formData.append("phone", this.form.phone);
            formData.append("code", this.form.code);
            replaPassWord(formData)
              .then((res) => {
                if (res.data.flag != 2) {
                  this.next();
                } else {
                  this.$message({
                    message: res.msg,
                    type: "warning",
                  });
                }
              })
              .catch(() => {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              });
          } else {
            if (this.form.password == this.form.newPassword) {
              this.form.password = encrypt(this.form.password);
              let formData = new FormData();
              formData.append("loginPort", this.form.loginPort);
              formData.append("password", this.form.password);
              formData.append("phone", this.form.phone);
              subPassWord(formData).then((res) => {
                if (res.data.flag == 0) {
                  this.next();
                } else {
                  this.$message({
                    message: res.msg,
                    type: "warning",
                  });
                }
              });
            } else {
              this.$message({
                message: "两次输入的密码不一致，请重新输入",
                type: "success",
              });
            }
          }
        }
      });
    },
    next() {
      this.activeIndex += 1;
      if (this.activeIndex === 3) {
        this.timer2 = setInterval(() => {
          if (this.TimeNumber === 0) {
            this.TimeNumber = 3;
            this.activeIndex = 1;
            clearInterval(this.timer2);
            this.timer2 = null;
            this.$router.push({
              path: "/HomeView",
            });
          } else {
            this.TimeNumber -= 1;
          }
        }, 1000);
      }
    },
    //点击上一步
    lastStep() {
      this.activeIndex -= 1;
    },
    getCode() {
      //axios请求
      if (this.form.phone) {
        getCode({ userName: this.form.phone, sendType: 3 }).then((res) => {
          if (res.data.flag == 1) {
            this.$message({
              message: res.msg + "2分钟内有效",
              type: "success",
            });
          } else if (res.data.flag == 2) {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      } else {
        this.$message.error("请输入手机号码");
        return;
      }
      // 验证码倒计时
      if (!this.timer) {
        this.count = 120;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 120) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss">
.ForgotPasswordPage {
  height: calc(60vh);
  width: 100%;
  background-color: #f5f5f5;

  .PasswordBottom {
    width: 445px;
    margin: 40px auto;

    .ThereBox {
      display: flex;
      align-items: center;

      .Right {
        margin-left: 10px;
        p {
          font-size: 14px;
          color: #4d4d4d;

          span:nth-child(2) {
            color: #219560;
            cursor: pointer;
            margin-left: 5px;
            display: inline-block;
          }
        }
      }
    }

    .ButtonActive {
      width: 138px;
      height: 38px;
      background: #219560;
      border-radius: 3px;
      font-size: 14px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      cursor: pointer;
    }

    .pr {
      position: relative;

      .DiaLogCheckbox {
        display: flex;
        margin-top: 20px;
        align-items: center;

        p {
          margin-left: 5px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

    .el-input__inner {
      height: 50px;
      border: 0;
      background: #f6f8f9;
    }

    .code-btn {
      width: 92px;
      height: 36px;
      background: #ffffff;
      border-radius: 3px;
      position: absolute;
      top: 7px;
      right: 60px;
      z-index: 222;
      color: #219560;
      font-size: 14px;
      border: none;
      cursor: pointer;
    }
  }

  .ForgotPasswordBox {
    width: 1320px;
    margin: 0 auto;
    height: 493px;
    background: #ffffff;
    border-radius: 10px;

    .PasswordTop {
      height: 169px;
      border-bottom: 1px solid #e6e6e6;

      .steps-box {
        user-select: none;
        width: 800px;
        height: 400px;
        padding-top: 50px;
        margin: 0 auto;
        position: relative;
        // <!-- 步骤条背景进度条 -->
        .line {
          display: block;
          margin: 0 auto;
          position: absolute;
          top: 75px;
          left: 14%;
          background: #ccc;
          width: 600px;
          height: 2px;
          z-index: 1;
          overflow: hidden;

          .plan {
            position: absolute;
            top: 0;
            left: 0;
            height: 2px;
            transition: 0.5s;
            background: #219560;
          }
        }

        .Article-steps {
          display: flex;
          justify-content: space-between;

          .step {
            z-index: 999;
            text-align: center;
            width: 200px;

            .title {
              font-size: 14px;
              color: #c0c0c0;
              margin-top: 5px;
            }

            .step-num {
              width: 30px;
              height: 30px;
              display: inline-block;
              line-height: 30px;
              background: #c0c0c0;
              text-align: center;
              border-radius: 50%;
              margin-top: 10px;

              //clip-path: polygon(50% 0, 100% 85%, 0 85%);
              color: white;
              //font-weight: bold;
              transition: 0.5s;

              .num {
                transition: 0.5s;
                display: inline-block;
              }
            }
          }
        }

        //当前所在位置样式
        .step-active {
          .step-num {
            background: #219560 !important;
          }

          .title {
            color: #219560 !important;
          }
        }

        //全部完成样式
        .step-over {
          .plan {
            background: #219560 !important;
          }

          .step-num {
            background: #219560 !important;
          }

          .title {
            color: #219560 !important;
          }
        }
      }
    }
  }
}
</style>
