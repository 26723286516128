<template>
  <div class="awardAnnoun">
    <div
      class="awardAnnounBox"
      v-if="prizeList.length != 0 "
    >
      <div class="title">获奖公示</div>
      <div class="toupercase">AWARD PUBLICI T Y</div>
      <div class="firstPrize" v-for="item in prizeList" :key="item.id">
        <div class="firstPrizeTitle">
          <div class="prizeLeft">
            <div class="img">
              <img src="../../../assets/image/prizeImg.png" alt="" />
            </div>
            <p class="prizeName">{{ item.prizeItemName }}</p>
            <p class="prizeInfo">{{ item.prizeName }}</p>
          </div>
          <div class="prizeRiht">
            <div class="prizeNum">
              {{ item.prizeUserNum + "名" }}
            </div>
          </div>
        </div>
        <div class="prizeBox">
          <div v-if="item.baseRaceStudentProductions.length != 0 &&stageName === 3">
            <div
              class="prizeInfoBox"
              v-for="itemer in item.baseRaceStudentProductions"
              :key="itemer.id"
            >
              <div class="prizeInfoLeft">
                <div class="img">
                  <img :src="itemer.coverImg" alt="" />
                </div>
                <div class="userName">{{ itemer.studentName }}</div>  &nbsp;&nbsp; &nbsp;|
                <div class="userName">{{ itemer.productNo }}</div>
                <div class="userWorkName">{{ itemer.productName }}</div>
              </div>
              <div class="prizeInfoRight" @click="goWorkDetail(itemer)">
                <div class="btn">查看详情</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="noData" v-else>
      <p>暂无公示信息</p>
    </div>
  </div>
</template>

<script>
import { getPrizeList } from "@/request/activity";
export default {
  props: { stageName:Number },
  data() {
    return {
      prizeList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const raceId = this.$route.query.id;

      getPrizeList({ raceId: raceId }).then((res) => {
        if (res.data) {
          this.prizeList = res.data;
        }
      });
    },
    goWorkDetail(e) {
      sessionStorage.setItem("activeName", 2);
      this.$router.push({ name: "worksDetail", query: { id: e.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.awardAnnoun {
  width: 100%;
  background-color: #f5f7f6;
  padding-top: 50px;
  margin: 0 auto;
  .noData {
    width: 1320px;
    height: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    background: #ffffff;
    border-radius: 10px;
    p {
      color: #999;
      font-size: 30px;
      line-height: 500px;
    }
  }
  .awardAnnounBox {
    width: 1320px;
    margin: 0 auto;
    .title {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      line-height: 50px;
      text-align: center;
    }
    .toupercase {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #127648;
      text-align: center;
      margin-bottom: 40px;
    }
    .firstPrize {
      background: #ffffff;
      border-radius: 10px;
      margin-bottom: 100px;
      .firstPrizeTitle {
        height: 82px;
        border-bottom: 1px solid #ebebeb;
        display: flex;
        align-items: center;
        .prizeLeft {
          width: 1250px;
          display: flex;
          line-height: 82px;
          .img {
            margin-left: 20px;
            width: 20px;
            height: 24px;
            line-height: 52px;
          }
          img {
            width: 20px;
            height: 24px;
          }
          .prizeName {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #3d3d3d;
            line-height: 81px;
            margin-left: 20px;
          }
          .prizeInfo {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 81px;
            margin-left: 40px;
          }
        }
        .prizeRiht {
          justify-self: flex-end;
        }
      }

      .prizeBox {
        padding: 0 20px;
        width: 1280px;
        display: flex;
        margin-bottom: 100px;
        .noData {
          width: 100%;
          height: 82px;
          background: #ffffff;
          p {
            text-align: center;
            line-height: 82px;
            color: #ccc;
          }
        }
        .prizeInfoBox:last-child {
          border-bottom: none;
        }
        .prizeInfoBox {
          height: 82px;
          background-color: #fff;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f0f0f0;
          .prizeInfoLeft {
            width: 1200px;
            display: flex;
            line-height: 82px;
            .img {
              width: 26px;
              height: 26px;
              line-height: 54px;
              margin-left: 20px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .userName {
              margin-left: 20px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              line-height: 82px;
            }
            .userWorkName {
              margin-left: 40px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              line-height: 82px;
            }
          }
          .prizeInfoRight {
            width: 80px;
            height: 36px;
            background: #219560;
            border-radius: 3px;
            cursor: pointer;
            .btn {
              text-align: center;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 36px;
            }
          }
        }
      }
    }
  }
}
</style>