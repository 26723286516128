<template>
  <div class="matchDetailPage">
    <div class="eventInfo">
      <div class="eventBox">
        <div class="eventTitle">
          <div class="title"><p>当前赛事活动资料</p></div>
          <div class="upload" v-if="eventDetailsList.dataImg">
            <p @click="downLoad(eventDetailsList.dataImg)">下载</p>
          </div>
        </div>
        <div class="eventContent">
          <p v-html="eventDetailsList.raceDataDescription"></p>
        </div>
      </div>
    </div>
    <div class="activity_info">
      <div class="activity_infoTitle">
        <p class="title">活动介绍</p>
        <p class="aupcase">Activity introduction</p>
      </div>
      <div class="activity_Ctontent">
        <p class="content" v-html="eventDetailsList.raceIntroduction"></p>
      </div>
    </div>
    <div class="competitionSchedule">
      <div class="schedule_infoTitle">
        <p class="title">大赛赛程</p>
        <p class="aupcase">EVEN TPROCESS</p>
      </div>
      <div class="schedule_info">
        <div class="info_fh" v-for="(item, index) in scheduleList" :key="index">
          <div class="img">
            <img :src="item.scheduleImg" alt=""/>
          </div>
          <div class="text">
            <p>{{ item.text }}</p>
          </div>
          <div class="scheduleTime">
            <p>{{ item.scheduleTime }}</p>
          </div>
        </div>
        <!-- <div class="fotImg">
            <img src="../../../assets/image/scheduleIcon_04.png" alt="">
        </div> -->
      </div>
    </div>
    <div class="prizeSet">
      <div class="prize_infoTit">
        <p class="title">奖项设置</p>
        <p class="aupcase">AWARD SE TT ING</p>
      </div>
      <div class="prize_info">
        <div
            class="prize_box"
            v-for="(item, index) in eventDetailsList.baseRacePrizes"
            :key="index"
        >
          <div class="prize_img">
            <img :src="item.prizeImg" alt=""/>
          </div>
          <p class="prizeName">{{ item.prizeItemName }}</p>
          <p class="prizeNum">奖品数量：{{ item.prizeUserNum }}</p>
          <p class="prizeInfo">{{ item.prizeName }}</p>
        </div>
      </div>
    </div>
    <div class="matchJudges">
      <div class="matchJudgesTit">
        <p class="title">赛事评委</p>
        <p class="aupcase">JURY LINEUP</p>
      </div>
      <div class="judges_info">
        <div
            class="judges_box"
            v-for="(item, index) in eventDetailsList.baseRaceJudges"
            :key="index"
        >
          <div class="judges_img">
            <img :src="item.judgesImg" alt=""/>
          </div>
          <div class="judgesName">{{ item.judgesName }}</div>
          <div class="judgesInfo">
            <p>{{ item.judgesIntroduction }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="activity_info">
      <div class="activity_infoTitle">
        <p class="title">赛事须知</p>
        <p class="aupcase">EVENT INSTRUCTIONS</p>
      </div>
      <div class="activity_Ctontent">
        <p class="content" v-html="eventDetailsList.activityNotice"></p>
      </div>
    </div>
  </div>
</template>

<script>
import {getActivityDetail, ossUpload} from "@/request/activity";
import OSS from "ali-oss";

export default {
  data() {
    return {
      eventDetailsList: [],
      scheduleList: [
        {
          scheduleImg: require("../../../assets/image/scheduleIcon_01.png"),
          text: "赛事投稿",
          scheduleTime: "",
        },
        {
          scheduleImg: require("../../../assets/image/scheduleIcon_03.png"),
          text: "赛事评审",
          scheduleTime: "",
        },
        {
          scheduleImg: require("../../../assets/image/scheduleIcon_02.png"),
          text: "获奖公示",
          scheduleTime: "",
        },
      ],
    };
  },
  activated() {
    this.getActivityDetails();
  },
  created() {
    this.OssForm = JSON.parse(sessionStorage.getItem("aliOss"));
  },
  methods: {
    getActivityDetails() {
      this.id = this.$route.query.id;
      getActivityDetail(this.id).then((res) => {
        let contentOne = res.data.raceDataDescription
        const regexOne = new RegExp('<img', 'gi')
        res.data.raceDataDescription = contentOne.replace(regexOne, `<img style="max-width: 100%; height: auto"`)
        let contentTwo = res.data.raceIntroduction
        const regexTwo = new RegExp('<img', 'gi')
        res.data.raceIntroduction = contentTwo.replace(regexTwo, `<img style="max-width: 100%; height: auto"`)
        let contentThere = res.data.activityNotice
        const regexThere = new RegExp('<img', 'gi')
        res.data.activityNotice = contentThere.replace(regexThere, `<img style="max-width: 100%; height: auto"`)
        this.eventDetailsList = res.data;


        if (this.eventDetailsList) {
          this.scheduleList[0].scheduleTime =
              res.data.baseRaceStages[0].raceTime[0] +
              "至" +
              res.data.baseRaceStages[0].raceTime[1];
          this.scheduleList[1].scheduleTime =
              res.data.baseRaceStages[1].raceTime[0] +
              "至" +
              res.data.baseRaceStages[1].raceTime[1];
          this.scheduleList[2].scheduleTime =
              res.data.baseRaceStages[2].raceTime[0] +
              "至" +
              res.data.baseRaceStages[2].raceTime[1];
        }
      });
    },
    downLoad(file) {
      console.log(file);
      this.$message.warning('文件开始下载')
      this.getBlob(JSON.parse(file).dataImg,  (blob)=> {
        this.saveAs(blob, JSON.parse(file).fileName);
      });
    },
    getBlob(url, cb) {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.send();
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement('a');
        let body = document.querySelector('body');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        // fix Firefox
        link.style.display = 'none';
        body.appendChild(link);
        link.click();
        body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      };

    }
  },
};
</script>

<style lang="scss" scoped>
.matchDetailPage {
  width: 100%;
  background-color: #f5f7f6;

  img {
    max-width: 100% !important;
    height: auto !important;
  }

  .title {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 23px;
    margin-bottom: 14px;

    .aupcase {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 300;
      color: #127648;
      line-height: 23px;
    }
  }

  .eventInfo {
    min-height: 232px;
    background: #f5f5f5;
    padding: 30px 0 20px 0;

    .eventBox {
      width: 1320px;
      min-height: 232px;
      background-color: #fff;
      border-radius: 10px;
      margin: 0 auto;

      .eventTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 30px;
        border-bottom: 1px solid #ebebeb;

        .title {
          height: 25px;

          p {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #3d3d3d;
            line-height: 38px;
          }
        }

        .upload {
          width: 70px;
          height: 38px;
          background: #219560;
          border-radius: 3px;
          text-align: center;

          p {
            cursor: pointer;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            line-height: 38px;
          }
        }
      }

      .eventContent {
        padding: 14px 30px;

        P {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #808080;
          line-height: 32px;

        }
      }
    }
  }

  .activity_info {
    width: 1320px;
    margin: 0 auto;
    padding: 20px 0;
    background-color: #f5f5f5;

    .activity_infoTitle {
      text-align: center;
      margin-bottom: 20px;
    }

    .activity_Ctontent {
      padding: 20px 0;
      background-color: #fff;
      border-radius: 10px;
      line-height: 3;

      .content {
        padding: 50px;
        line-height: 3;
        color: #333333;
      }
    }
  }

  .competitionSchedule {
    width: 1320px;
    margin: 0 auto;
    padding: 20px 0;
    background-color: #f5f7f6;

    .schedule_infoTitle {
      text-align: center;
      margin-bottom: 20px;
    }

    .schedule_info {
      height: 212px;
      padding: 20px;
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .info_fh {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        flex: 1;

        .img {
          width: 44px;
          height: 43px;
          margin-bottom: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .text {
          height: 30px;

          p {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            line-height: 30px;
          }
        }

        .scheduleTime {
          height: 30px;

          p {
            color: #333333;
            line-height: 30px;
          }
        }
      }
    }
  }

  .prizeSet {
    width: 1320px;
    margin: 0 auto;
    background-color: #f5f7f6;
    padding: 20px 0;

    .prize_infoTit {
      text-align: center;
      margin-bottom: 20px;
    }

    .prize_info {
      padding: 20px;
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .prize_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        flex: 1;

        .prize_img {
          width: 174px;
          height: 174px;
          position: relative;
          background: #f5f7f6;
          border-radius: 50%;
          margin-bottom: 10px;

          img {
            position: absolute;
            top: 18%;
            left: 19%;
            width: 62%;
            height: 64%;
          }
        }

        .prizeName {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
          line-height: 28px;
          margin-bottom: 10px;
        }

        .prizeNum {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #219560;
          line-height: 28px;
        }

        .prizeInfo {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          line-height: 24px;
        }
      }
    }
  }

  .matchJudges {
    width: 1320px;
    margin: 0 auto;
    background-color: #f5f7f6;
    padding: 20px 0;

    .matchJudgesTit {
      text-align: center;
      margin-bottom: 20px;
    }

    .judges_info {
      padding: 40px 20px;
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .judges_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;

        .judges_img {
          width: 174px;
          height: 174px;
          margin-bottom: 18px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .judgesName {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          line-height: 23px;
          margin-bottom: 19px;
        }

        .judgesInfo {
          p {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #808080;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>