<template>
  <div class="FindService">
    <div class="FindServiceTop">
      <div class="ServiceTopBox">
        <Breadcrumb class="ServiceTopBreadcrumb" />
        <el-tabs
          class="ServiceTopBoxTabs"
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane label="园林设计" name="1"></el-tab-pane>
          <el-tab-pane label="园林施工" name="2"></el-tab-pane>
          <el-tab-pane label="园林造价" name="3"></el-tab-pane>
          <el-tab-pane label="维护养护" name="4"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="FindServiceBottom">
      <div class="ServiceBottom">
        <div class="classificationFilter">
          <span class="serverTit" style="border-bottom: 0; border-right: 0"
            >服务区域</span
          >
          <ul class="serversType" style="border-bottom: 0">
            <li
              :class="IndexActiveOne === item.value ? 'txtActive' : 'txt'"
              v-for="item in TutorialTypeOne"
              :key="item.value"
              @click="GoServersTypeActiveOne(item)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <div class="classificationFilter">
          <span class="serverTit" style="border-bottom: 0; border-right: 0"
            >园林风格</span
          >
          <ul class="serversType" style="border-bottom: 0">
            <li
              :class="IndexActiveTwo === index ? 'txtActive' : 'txt'"
              v-for="(item, index) in houseTypeList"
              :key="item.dictValue"
              @click="GoServersTypeActiveTwo(index)"
            >
              {{ item.dictLabel }}
            </li>
          </ul>
        </div>
        <div class="classificationFilter">
          <span class="serverTit" style="border-bottom: 0; border-right: 0"
            >设计风格</span
          >
          <ul class="serversType" style="border-bottom: 0">
            <li
              :class="IndexActiveThere === index ? 'txtActive' : 'txt'"
              v-for="(item, index) in styleList"
              :key="item.dictValue"
              @click="GoServersTypeActiveThere(index)"
            >
              {{ item.dictLabel }}
            </li>
          </ul>
        </div>
        <div class="classificationFilter">
          <span class="serverTit" style="border-right: 0">商户类型</span>
          <ul class="serversType">
            <li
              :class="IndexActiveFour === index ? 'txtActive' : 'txt'"
              v-for="(item, index) in merchantTypeList"
              :key="item.dictValue"
              @click="GoServersTypeActiveFour(index)"
            >
              {{ item.dictLabel }}
            </li>
          </ul>
        </div>
        <!-- <div class="sort">
          <ul class="serversType">
            <li
              :class="sortIndex === index ? 'txtActive' : 'txt'"
              @click="sort(item.id)"
              v-for="(item, index) in sortList"
              :key="index"
            >
              {{ item.con }}
            </li> -->
            <!-- <div class="on">
              <a
                href="javascript:void(0)"
                data-category="price"
                data-orderby="asc"
                class="js_category"
                >价格
                <i class="angle_top"></i>
                <i class="angle_bottom"></i>
              </a>
            </div> -->
          <!-- </ul> -->
          <!-- <div class="checkBox">
            <el-checkbox-group
              v-model="checkedCities"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox v-for="city in cities" :label="city" :key="city">{{
                city
              }}</el-checkbox>
            </el-checkbox-group>
          </div> -->
        </div>
      <!-- </div> -->
      <div class="seckill-goods" v-if="LandscapeList.length != 0">
        <ul class="clearfix">
          <li v-for="item in LandscapeList" :key="item.id">
            <div class="ListTop" @click="FindServiceDetails(item)">
              <img :src="item.coverImg" alt="" />
            </div>
            <p class="pTitle">{{ item.serviceName }}</p>
            <div class="ListBottom">
              <span class="SpanTypeTwo" v-if="item.servicePersonType == 2"
                >企</span
              >
              <span class="SpanTypeOne" v-if="item.servicePersonType == 1"
                >个</span
              >
              <span class="SpanTwo">{{ item.servicePersonName }}</span>
            </div>
            <div class="ListBottomTwo">
              <p>{{ item.servicePrice + getPrizeType(item.priceType) }}</p>
              <p @click="FindServiceDetails(item)">立即咨询</p>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-else
        class="eyipe"
        style="width: 100%; height: 200px; text-align: center"
      >
        <p style="color: #ccc; font-size: 30px; line-height: 200px">暂无数据</p>
      </div>
    </div>
    <Pagination
      v-show="Total > 0"
      :total="Total"
      style="text-align: center; margin-top: 15px;margin-bottom: 30px"
      :page.sync="formInline.pageNum"
      :limit.sync="formInline.pageSize"
      @pagination="PageApi"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Pagination from "@/components/Pagination/Pagination";
import { getConstructService } from "@/request/findServer";
import { getDicts } from "@/request/dict/data";
import { selectDictLabel, selectDictLabels,handleMoney } from "@/utils/weihua";
import { getSeatch } from "@/request/home/home";
export default {
  name: "FindService",
  components: { Breadcrumb, Pagination },
  data() {
    return {
      typeList: [],
      IndexActiveOne: 0,
      serverTypeList: [],
      TutorialTypeOne: [],
      IndexActiveTwo: 0,
      houseTypeList: [],
      IndexActiveThere: 0,
      styleList: [],
      IndexActiveFour: 0,

      merchantTypeList: [],
      // checkedCities: ["企业认证"],
      // cities: ["企业认证", "保证金"],
      checkAll: false,
      isIndeterminate: true,
      activeName: "1",
      value: "",
      options: [],
      sortIndex: 0,
      sortList: [
        {
          id: 1,
          con: "综合排序",
        },
        {
          id: 2,
          con: "最新",
        },
      ],
      LandscapeList: [],
      dardensStyleList: [],
      prizeTypeList: [],
      formInline: {
        pageNum: 1,
        pageSize: 8,
        serviceType: "1",
        serviceArea: sessionStorage.getItem("cityTxt"),
        houseType: "",
        designStyle: "",
        bizType: "",
        pcSearch: 1,
        type: null,
        searchInfo: null,
        serviceType: null,
      },
      Total: 0,
      cityTxt:null,
    };
  },
  created() {
    this.cityTxt = sessionStorage.getItem("cityTxt")
    this.TutorialTypeOne = JSON.parse(sessionStorage.getItem("cityList"));
    this.TutorialTypeOne = this.TutorialTypeOne.cityList;
    this.TutorialTypeOne.unshift({
      label: "全部",
      value: 0,
    });
    // this.typeList = JSON.parse(localStorage.getItem("type"));
    if (this.$route.query.type) {
      this.formInline.type = this.$route.query.type;
      this.formInline.searchInfo = this.$route.query.searchInfo;
      this.formInline.serviceType = this.$route.query.serviceType;
      this.getSeatchList();
    } else {
      this.PageApi();
    }
    getDicts("msk_sys_server_type").then((res) => {
      this.serverTypeList = res.data;
    });
    getDicts("msk_sys_gardens_style").then((res) => {
      this.dardensStyleList = res.data;
      this.dardensStyleList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
    getDicts("msk_sys_house_type").then((res) => {
      this.houseTypeList = res.data;
      this.houseTypeList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
    getDicts("msk_sys_well_style").then((res) => {
      this.styleList = res.data;
      this.styleList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
    getDicts("msk_price_type").then((res) => {
      this.prizeTypeList = res.data;
    });
    getDicts("msk_sys_merchant_type").then((res) => {
      this.merchantTypeList = res.data;
      this.merchantTypeList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
  },
  deactivated() {
    localStorage.removeItem("type");
  },
  methods: {
    getSeatchList() {
      getSeatch(this.formInline).then((res) => {
        if (res.code == 200) {
          const arr = res.rows;
          arr.forEach((item) => {
            item.servicePrice = handleMoney(item.servicePrice);
          });
          this.LandscapeList = arr;
          this.Total = res.total;
        }
      });
    },
    getPrizeType(value) {
      return selectDictLabel(this.prizeTypeList, value);
    },
    PageApi() {
      getConstructService(this.formInline).then((res) => {
        const arr = res.rows;
          arr.forEach((item) => {
            item.servicePrice = handleMoney(item.servicePrice);
          });
          this.LandscapeList = arr;
        this.Total = res.total;
      });
    },
    GoServersTypeActiveOne(e) {
      this.IndexActiveOne = e.value;
      if (e.label == "全部") {
        this.formInline.serviceArea = sessionStorage.getItem("cityTxt");
      } else {
        this.formInline.serviceArea =this.cityTxt+"-"+ e.label;
      }
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.formInline.serviceType = this.$route.query.serviceType;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    GoServersTypeActiveTwo(i) {
      this.IndexActiveTwo = i;
      if (i === 0) {
        this.formInline.houseType = null;
      } else {
        this.formInline.houseType = this.IndexActiveTwo;
      }
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.formInline.serviceType = this.$route.query.serviceType;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    GoServersTypeActiveThere(i) {
      if (i === 0) {
        this.IndexActiveThere = i;
        this.formInline.designStyle = null;
      } else {
        this.IndexActiveThere = i;
        this.formInline.designStyle = this.IndexActiveThere;
      }
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.formInline.serviceType = this.$route.query.serviceType;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    sort(e) {
      this.sortIndex = e;
      this.formInline.pcSearch = this.sortIndex;
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.formInline.serviceType = this.$route.query.serviceType;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    GoServersTypeActiveFour(i) {
      this.IndexActiveFour = i;
      this.formInline.bizType = this.IndexActiveFour;
      this.PageApi();
    },
    handleClick(e) {
      this.formInline.serviceType = e.name;
      this.activeName = e.name;
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    FindServiceDetails(e) {
      this.$router.push({ name: "FindServiceDetails", query: { id: e.id } });
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
  },
};
</script>

<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .btn-prev {
  padding: 0 5px !important;
}
::v-deep .btn-next {
  padding: 0 5px !important;
}
</style>

<style lang="scss">
.FindService {
  width: 100%;
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #f64a1d;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #f64a1d;
    border-color: #f64a1d;
  }
  .FindServiceTop {
    width: 100%;

    .ServiceTopBox {
      width: 1320px;
      margin: 0 auto;

      .ServiceTopBreadcrumb {
        padding-top: 20px;
      }

      .ServiceTopBoxTabs {
        margin-top: 20px;
      }

      .el-tabs__header {
        margin: 0;
      }

      .el-tabs__item.is-active {
        font-size: 16px;
        color: #188252;
      }

      .el-tabs__active-bar {
        background-color: #188252;
        height: 3px;
        border-radius: 2px;
      }

      .el-tabs__item {
        font-size: 16px;
        color: #4a4a4a;
      }

      .el-tabs__item:hover {
        font-size: 16px;
        color: #188252;
      }

      .el-tabs__nav-wrap::after {
        height: 0;
        border-color: transparent !important;
      }
    }
  }
  .FindServiceBottom {
    width: 1320px;
    margin: 20px auto;
    .ServiceBottom {
      .classificationFilter {
        width: 100%;
        display: flex;

        .serverTit {
          display: inline-block;
          width: 92px;
          min-height: 44px;
          background: #f7f7f7;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #4a4d4b;
          padding-top: 20px;
          text-align: center;
        }

        .serversType {
          width: 100%;
          min-height: 44px;
          background: #ffffff;
          padding-left: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .txt {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            cursor: pointer;
            padding: 5px 20px;
            color: #636665;
          }

          .txtActive {
            background: #188252;
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: 300;
            cursor: pointer;
            color: #ffffff;
            padding: 5px 20px;

            border-radius: 20px;
          }

          .txt:hover {
            color: #188252;
          }
        }
      }
      .sort {
        width: 100%;
        height: 36px;
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        background: #f5f5f5;
        border: 1px solid #ebebeb;
        .serversType {
          width: 50%;
          display: flex;
          padding: 0 20px;
          li {
            cursor: pointer;
            margin-right: 30px;
          }

          .txt {
            line-height: 36px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #808080;
            cursor: pointer;
          }

          .txtActive {
            line-height: 36px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #188252;
            cursor: pointer;
          }

          .txt:hover {
            color: #188252;
          }
          .on {
            width: 56px;
            height: 36px;
            display: table-cell;
            position: relative;
          }

          .on a {
            display: block;
            overflow: hidden;
            width: 100%;
            height: 36px;
            line-height: 36px;
            text-align: center;
            color: #808080;
          }
          .on a:hover {
            line-height: 36px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #188252;
            cursor: pointer;
          }
          .angle_top {
            content: "";
            width: 0;
            height: 0;
            display: block;
            border-style: solid;
            border-color: transparent transparent #5e5e5e;
            position: absolute;
            transform: rotate(180deg);
            bottom: 10px;
            left: 46px;
          }

          .angle_bottom {
            content: "";
            width: 0;
            height: 0;
            display: block;
            border-style: solid;
            border-color: transparent transparent #5e5e5e;
            position: absolute;
            top: 10px;
            left: 46px;
          }
        }
        .checkBox {
          margin-right: 30px;
          height: 36px;
          line-height: 36px;
        }
      }
    }

    .seckill-goods {
      display: inline-block;
      width: 1323px;

      .clearfix {
        margin-left: -30px;

        li {
          width: 306px;
          margin-top: 20px;
          float: left;
          background-color: #FFFFFF;
          flex: 1;
          margin-left: 30px;

          .ListTop {
            cursor: pointer;
            width: 306px;
            height: 194px;

            img {
              width: 306px;
              height: 194px;
            }
          }

          .pTitle {
            font-size: 16px;
            margin-left: 15px;
            color: #333333;
            margin-top: 20px;
            cursor: pointer;
            overflow: hidden;
            width: 90%;
            display: -webkit-box; /*弹性伸缩盒子*/
            -webkit-box-orient: vertical; /*子元素垂直排列*/
            -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
            text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
          }

          .ListBottom {
            margin-top: 15px;
            padding-left: 15px;

            .SpanTypeOne {
              padding: 2px 4px;
              background: #ffefe5;
              border: 1px solid #eb8d67;
              border-radius: 2px;
              font-size: 12px;
              color: #f6611d;
            }

            .SpanTypeTwo {
              padding: 2px 4px;
              border: 1px solid #188252;
              background: #ebf7f2;
              border-radius: 2px;
              font-size: 12px;
              color: #188252;
            }

            .SpanTypeThere {
              padding: 2px 4px;
              background: #e4e7f3;
              border: 1px solid #5a73dd;
              border-radius: 2px;
              font-size: 12px;
              color: #5a73dd;
            }

            .SpanTwo {
              display: inline-block;
              font-size: 12px;
              color: #a8a8a8;
              margin-left: 5px;
            }
          }

          .ListBottomTwo {
            display: flex;
            align-items: center;
            margin: 15px;
            justify-content: space-between;

            p:nth-child(1) {
              font-size: 14px;
              color: #f66a1d;
            }

            p:nth-child(2) {
              width: 74px;
              cursor: pointer;
              height: 26px;
              background: linear-gradient(-70deg, #f6461d, #f66a1d);
              font-size: 14px;
              color: #ffffff;
              text-align: center;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
}
</style>
