<template>
  <div class="navCon">
    <div class="navConBox" style="font-size: 20px">
      <div class="navConBoxLocation">
        <p class="iconfont icon-dingweixiao LocationOne"></p>
        <p class="LocationOne">{{ "[" + cityTxt + "]" }}</p>
        <el-popover
          placement="bottom-start"
          v-model="visible"
          width="360"
          trigger="hover"
        >
          <div class="provinceBox">
            <div class="province">
              {{ province }}
            </div>
            <ul class="cityList">
              <li
                v-for="(item, index) in cityList"
                :key="index"
                @click="citySwitch(item)"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
          <p class="LocationTwo" @click="selecCity" slot="reference">
            <i class="el-icon-arrow-down el-icon--right"></i>切换
          </p>
        </el-popover>
      </div>
      <div class="navConBoxLogin">
        <div class="navConBoxLoginBox" v-if="tokenKey">
          <el-dropdown style="padding-right: 10px">
            <span class="el-dropdown-link">
              {{ nickName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="info">个人中心</el-dropdown-item>
              <el-dropdown-item @click.native="loginOut"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <span @click="goMsg" class="message">消息</span>
        </div>
        <div class="navConBoxLoginBox" v-if="!tokenKey">
          <span
            @click="OpenLogin"
            :class="LoginRegistration ? 'btnClear' : 'btn'"
            >登录</span
          >
          <span @click="DialogRegOpen" class="DialogRegOpen">注册</span>
        </div>
        <el-breadcrumb separator="|">
          <el-breadcrumb-item @click.native="goMine"
            >我的风景园林</el-breadcrumb-item
          >
          <el-breadcrumb-item @click.native="goShop"
            >商家中心</el-breadcrumb-item
          >
          <el-breadcrumb-item @click.native="goSettle"
            >我要入驻</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
    </div>
    <el-dialog
      :show-close="false"
      :visible.sync="LoginRegistration"
      width="444px"
      class="DialogBox"
    >
      <div class="DialogTop">
        <el-tabs
          class="ServiceTopBoxTabs"
          style="margin-left: 24px"
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane label="密码登录" name="1"></el-tab-pane>
          <el-tab-pane label="验证码登录" name="2"></el-tab-pane>
        </el-tabs>
        <p class="DialogTopRegistration">
          <span>还没有账号？</span>
          <span @click="DialogRegOpen">立即注册 ></span>
        </p>
      </div>
      <div class="InputBox" v-if="activeName == 1">
        <el-form ref="loginForm" :model="loginForm" :rules="rules1">
          <el-form-item prop="userName">
            <el-input
              maxlength="11"
              v-model="loginForm.userName"
              style="margin-bottom: 20px"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="passWord">
            <el-input
              type="password"
              v-model="loginForm.passWord"
              placeholder="请输入密码"
              @keyup.enter.native="login(1)"
            ></el-input>
          </el-form-item>
        </el-form>

        <p class="ForgotPassword" @click="GoForgotPass">忘记密码？</p>
        <p class="Login" @click="login(1)">点击登录</p>
      </div>
      <div class="InputBox" v-if="activeName == 2">
        <div class="pr">
          <el-form ref="loginForm" :model="loginForm" :rules="rules1">
            <el-form-item prop="userName">
              <el-input
                maxlength="11"
                v-model="loginForm.userName"
                style="margin-bottom: 20px"
                placeholder="请输入手机号码"
              ></el-input>
              <button
                @click="getCode(1)"
                class="code-btn"
                type="button"
                :disabled="!show"
              >
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{ count }} s</span>
              </button>
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                placeholder="请输入验证码"
                v-model="loginForm.code"
              ></el-input>
            </el-form-item>
          </el-form>
          <p class="ForgotPassword" @click="GoForgotPass">忘记密码？</p>
          <p class="Login" v-if="!loginLoading" @click="login(2)">点击登录</p>
          <p class="Login" v-else>登录中</p>
        </div>
      </div>
      <p class="colseBox" @click="DialogClose"><i class="el-icon-close"></i></p>
    </el-dialog>
    <!--注册-->
    <el-dialog
      :show-close="false"
      :visible.sync="Registration"
      width="444px"
      class="DialogBox"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="DialogTop2">
        <p
          style="
            font-size: 16px;
            color: #3b3d3c;
            margin-left: 24px;
            font-weight: 700;
          "
        >
          注册
        </p>
        <p class="DialogTopRegistration">
          <span>已有账号？</span>
          <span @click="OpenLogin">立即登录 ></span>
        </p>
      </div>

      <div class="InputBox">
        <div class="pr">
          <el-form ref="form" :model="form" :rules="rules" label-width="0px">
            <el-form-item label="" prop="userName">
              <el-input
                maxlength="11"
                v-model="form.userName"
                placeholder="请输入手机号码"
              ></el-input>
              <button
                @click.prevent="getCode(0)"
                class="code-btn"
                type="button"
                :disabled="!show1"
              >
                <span v-show="show1">获取验证码</span>
                <span v-show="!show1" class="count">{{ count1 }} s</span>
              </button>
            </el-form-item>
            <el-form-item label="" prop="code">
              <el-input
                v-model="form.code"
                placeholder="请输入验证码"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="nickName">
              <el-input
                v-model="form.nickName"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input
                type="password"
                v-model="form.password"
                placeholder="请输入密码"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="nextPassWord">
              <el-input
                type="password"
                v-model="form.nextPassWord"
                placeholder="请再次输入密码"
              ></el-input>
            </el-form-item>
          </el-form>

          <div class="DiaLogCheckbox">
            <el-checkbox v-model="checked"></el-checkbox>
            <p style="color: #999999">我已仔细阅读并同意</p>
            <p style="color: #219560" @click="agreement">《服务使用协议》</p>
            <p style="color: #219560" @click="Privacy">《隐私政策》</p>
          </div>
          <p class="Login" @click="submitForm">注册</p>
        </div>
      </div>
      <p class="colseBox" @click="DialogClose"><i class="el-icon-close"></i></p>
    </el-dialog>
  </div>
</template>

<script>
import { encrypt, decrypt } from "@/request/jsencrypt";
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
import {
  getCode,
  subRegister,
  getLogin,
  logout,
  getOssToken,
  getInfo,
  isSettle,
} from "@/request/login";
export default {
  name: "navCon",
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value == "" || value == null) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var password = (rule, value, callback) => {
      var ret = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/;
      if (value == "" || value == null) {
        callback(new Error("请输入密码"));
      } else if (!ret.test(value)) {
        callback(new Error("密码位不能低于8位，包含数字及字母!"));
      } else {
        callback();
      }
    };
    return {
      loginLoading: false,
      nickName: null,
      visible: false,
      cityTxt: null,
      province: null,
      tokenKey: null,
      LoginRegistration: false,
      Registration: false,
      activeName: "1",
      show: "false",
      show1: "false",
      count: "",
      count1: "",
      checked: false,
      code: null,
      options: regionData,
      cityOptions: [],
      cityList: [],
      phone: null,
      form: {
        phone: null,
        code: null,
        password: null,
        sendType: 1,
        nextPassWord: null,
        userName: null,
        nickName: null,
        userType: 9,
      },
      loginForm: {
        userName: null,
        passWord: null,
        loginType: null,
        sendType: 2,
        code: null,
        loginPort: 1,
      },
      rules: {
        userName: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        password: [
          {
            min: 8,
            max: 20,
            validator: password,
            trigger: "blur",
          },
        ],
        nextPassWord: [{ validator: validatePass2, trigger: "blur" }],
        nickName: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
      },

      rules1: {
        userName: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        passWord: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
    this.cityOptions = this.options[26];
    this.province = this.cityOptions.label;
    this.cityList = this.cityOptions.children;
    this.getCitySwitch();
    this.tokenKey = sessionStorage.getItem("token");
    if (sessionStorage.getItem("loading")) {
      this.LoginRegistration = Boolean(sessionStorage.getItem("loading"));
      sessionStorage.removeItem("loading");
    }
    getOssToken().then((res) => {
      if (res.code === 0) {
        sessionStorage.setItem("aliOss", JSON.stringify(res.data));
      }
    });
    this.getInfo();
  },
  //方法
  methods: {
    goSettle() {
      if (sessionStorage.getItem("token")) {
        this.$router.push({ path: "/settleIn" });
        localStorage.setItem("isSettle", 1);
      } else {
        this.$router.push({ path: "/HomeView" });
        this.LoginRegistration = true;
      }
    },
    info() {
      this.$router.push({ name: "personalData" });
    },
    goMine() {
      if (sessionStorage.getItem("token")) {
        this.$router.push({ name: "personalData" });
        localStorage.setItem("isSettle", 1);
      } else {
        this.$router.push({ path: "/HomeView" });
        this.LoginRegistration = true;
      }
    },
    GoForgotPass() {
      this.LoginRegistration = false;
      this.Registration = false;
      this.$router.push({
        path: "/ForgotPasswordPage",
      });
    },
    // 获取短信验证码
    getCode(n) {
      if (n == 0) {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(this.form.userName) == true) {
          getCode({
            userName: this.form.userName,
            sendType: 1,
          }).then((res) => {
            if (res.data.flag == 1) {
              // 验证码倒计时
              if (!this.timer1) {
                this.count1 = 120;
                this.show1 = false;
                this.timer1 = setInterval(() => {
                  if (this.count1 > 0 && this.count1 <= 120) {
                    this.count1--;
                  } else {
                    this.show1 = true;
                    clearInterval(this.timer);
                    this.timer1 = null;
                  }
                }, 1000);
              }
              this.$message({
                message: res.msg + "2分钟内有效",
                type: "success",
              });
              this.code = res.data.code;
            } else if (res.data.flag == 2) {
              this.$message({
                message: res.msg,
                type: "warning",
              });
              this.code = res.data.code;
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        } else {
          this.$message({
            message: "请输入正确的手机号码,",
            type: "warning",
          });
        }
      } else if (n == 1) {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(this.loginForm.userName) == true) {
          getCode({
            userName: this.loginForm.userName,
            sendType: 2,
          }).then((res) => {
            if (res.data.flag == 1) {
              // 验证码倒计时
              if (!this.timer) {
                this.count = 120;
                this.show = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= 120) {
                    this.count--;
                  } else {
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
              this.$message({
                message: res.msg + "2分钟内有效",
                type: "success",
              });
              this.code = res.data.code;
            } else if (res.data.flag == 2) {
              this.$message({
                message: res.msg,
                type: "warning",
              });
              this.code = res.data.code;
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        } else {
          this.$message({
            message: "请输入正确的手机号码",
            type: "warning",
          });
        }
      }
    },
    reset() {
      this.form = {
        phone: null,
        code: null,
        password: null,
        nextPassWord: null,
        userName: null,
        nickName: null,
      };
    },
    loginReset() {
      this.loginForm = {
        userName: null,
        passWord: null,
        loginType: null,
        code: null,
        loginPort: 1,
      };
    },
    //登录
    login(n) {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (n == 1) {
            this.loginLoading = true;
            this.loginForm.loginType = 1;
            this.loginForm.passWord = encrypt(this.loginForm.passWord);
            getLogin(this.loginForm)
              .then((res) => {
                if (res.code == 200) {
                  this.tokenKey = res.data;
                  sessionStorage.setItem("token", res.data);
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.loginReset();
                  this.getInfo();
                  this.DialogClose();
                  this.$router.push({ path: "/HomeView" });
                } else {
                  this.$message({
                    message: res.msg,
                    type: "warning",
                  });
                }
              })
              .catch(() => {
                this.loginForm.passWord = decrypt(this.loginForm.passWord);
                this.loginLoading = false;
              });
          } else if (n == 2) {
            this.loginLoading = true;
            this.loginForm.loginType = 2;
            getLogin(this.loginForm)
              .then((res) => {
                if (res.code == 200) {
                  this.tokenKey = res.data;
                  sessionStorage.setItem("token", res.data);
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.loginReset();
                  this.getInfo();
                  this.DialogClose();
                  this.$router.push({ path: "/HomeView" });
                } else {
                  this.$message({
                    message: res.msg,
                    type: "warning",
                  });
                }
              })
              .catch(() => {
                this.loginForm.passWord = decrypt(this.loginForm.passWord);
                this.loginLoading = false;
              });
          }
        }
      });
    },
    handleClick(tab,e){
      this.activeName = tab.name
      this.loginForm= {
        userName: null,
        passWord: null,
        loginType: null,
        sendType: 2,
        code: null,
        loginPort: 1,
      }
    },
    getInfo() {
      const token = sessionStorage.getItem("token");
      if (token) {
        getInfo().then((res) => {
          sessionStorage.setItem("userId", res.user.userId);
          sessionStorage.setItem("phone", res.user.phonenumber);
          // this.nickName = sessionStorage.getItem("nickName");
          this.nickName = res.user.nickName;
          this.phone = res.user.phonenumber;
        });
      }
    },
    //退出登录
    loginOut() {
      this.$confirm("确定退出登录?", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logout().then((res) => {
            sessionStorage.removeItem("token");
            this.$router.push({ path: "/HomeView" });
            this.$message({
              type: "success",
              message: "退出登录成功",
            });
            this.$router.go(0);
          });
        })
        .catch(() => {});
    },
    //功能正在开发中
    selecCity() {
      // this.$message({
      //   type: "success",
      //   message: "功能正在开发中",
      // });
    },
    getCitySwitch() {
      if (sessionStorage.getItem("cityTxt")) {
        this.cityTxt = sessionStorage.getItem("cityTxt");
      } else {
        var cityList = this.cityOptions.children[0].children;
        this.cityTxt = this.cityOptions.children[0].label;
        sessionStorage.setItem("cityList", JSON.stringify({ cityList }));
        sessionStorage.setItem("cityTxt", this.province+"-"+this.cityTxt);
      }
    },
    citySwitch(e) {
      this.visible = false;

      if (e) {
        var cityList = e.children;
        this.cityTxt = e.label;
        sessionStorage.setItem("cityList", JSON.stringify({ cityList }));
        sessionStorage.setItem("cityTxt", this.province+"-"+this.cityTxt);
        this.$router.push({ path: "/HomeView" });
        this.$router.go(0);
      }
    },
    go() {
      this.$message({
        message: "功能开发中,敬请期待",
        type: "success",
      });
    },
    goShop() {

      if (sessionStorage.getItem("token")) {
        isSettle({ phone: this.phone }).then((res) => {
          if (res.code == 200) {
            if (res.data.flag == 0) {
              this.$message({
                message: "您还未完成身份入驻，请入驻后重试",
                type: "warning",
              });
              return;
            } else if (res.data.flag != 0 && res.data.auditStatus == 1) {
              this.$message({
                message: "您已提交入驻申请，当前正在审批中，请于审批通过后重试",
                type: "success",
              });
              return;
            } else if (res.data.flag != 0 && res.data.auditStatus == 2) {
              this.$message({
                message: "您提交的入驻申请已被驳回，请修改资料后重新申请",
                type: "warning",
              });
              return;
            } else if (res.data.flag != 0 && res.data.auditStatus == 3) {
              window.open("https://fjyl.ysbuchart.com/index");
            }
          } else {
            return;
          }
        });
      } else {
        this.$router.push({ path: "/HomeView" });
        this.LoginRegistration = true;
      } 

    },
    submitForm() {
      if (this.checked == false) {
        this.$message({
          message: "请先同意《服务使用协议》《隐私政策》",
          type: "warning",
        });
      } else {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loginForm.userName = this.form.userName;
            this.loginForm.passWord = this.form.password;
            this.loginForm.code = this.form.code;
            this.loginForm.loginType = 1;
            this.form.userType = 9;
            this.form.password = encrypt(this.form.password);
            this.form.nextPassWord = encrypt(this.form.nextPassWord);
            subRegister(this.form)
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.reset();
                  this.LoginRegistration = false;
                  this.Registration = false;
                  this.loginForm.passWord = encrypt(this.loginForm.passWord);
                  getLogin(this.loginForm).then((res) => {
                    if (res.code == 200) {
                      this.tokenKey = res.data;
                      sessionStorage.setItem("token", res.data);
                      this.$message({
                        message: res.msg,
                        type: "success",
                      });
                      this.loginReset();
                      this.getInfo();
                    } else {
                      this.$message({
                        message: res.msg,
                        type: "warning",
                      });
                    }
                  });
                } else {
                  this.$message({
                    message: res.msg,
                    type: "error",
                  });
                }
              })
              .catch(() => {
                this.form.password = decrypt(this.form.password);
                this.form.nextPassWord = decrypt(this.form.nextPassWord);
              });
          } else {
            this.$message({
              message: "验证失败",
              type: "error",
            });
            return false;
          }
        });
      }
    },
    agreement() {
      window.open(
        "http://fengjingyuanlin.oss-cn-beijing.aliyuncs.com/image/2022-7-26/1658798646849_xieyi.pdf"
      );
    },
    Privacy() {
      window.open(
        "http://fengjingyuanlin.oss-cn-beijing.aliyuncs.com/image/2022-7-26/1658798879575_yinsi.pdf"
      );
    },
    DialogClose() {
      this.loginReset();
      this.reset();
      this.LoginRegistration = false;
      this.Registration = false;
    },
    OpenLogin() {
      this.LoginRegistration = true;
      this.Registration = false;
    },
    DialogRegOpen() {
      this.LoginRegistration = false;
      this.Registration = true;
    },
    goMsg() {
      this.$router.push({ path: "/messageManagement" });
    },

  },
};
</script>
<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
s ::v-deep input[type="number"] {
  -moz-appearance: textfield;
  line-height: 38px;
}
</style>
<style scoped>
::v-deep .navConBoxLoginBox {
  line-height: 40px;
}
</style>
<style lang="scss">
::v-deep .el-message-box__btns button:nth-child(2) {
  background-color: #219560;
  border: none;
}
.navCon .navConBox .navConBoxLogin .el-breadcrumb__inner {
  cursor: pointer;
}
.provinceBox {
  width: 100%;
  padding: 20px;
  .province {
    width: 46px;
    border-bottom: 2px solid #00af63;
    margin-bottom: 10px;
  }
  .cityList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50px;
      margin: 10px 20px;
      cursor: pointer;
    }
    li:hover {
      color: #00af63;
    }
  }
}
.navCon {
  width: 100%;
  height: 36px;
  background: #242424;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }

  .DialogBox {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 0 !important;
  }
  .DialogBox .el-dialog {
    margin-top: 0vh !important;
  }
  .colseBox {
    position: absolute;
    top: 0;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    right: -40px;
    text-align: center;
    cursor: pointer;
    i {
      color: #ccc;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .DialogTop {
    height: 80px;
    border-bottom: 1px solid #e6e6e6;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ServiceTopBoxTabs {
      .seckill-goods {
        display: inline-block;

        .clearfix {
          margin-left: -45px;

          li {
            width: 300px;
            margin-top: 20px;
            float: left;
            flex: 1;
            margin-left: 45px;
            border: 1px solid #e6e6e6;

            .ListTop {
              width: 300px;
              height: 153px;

              img {
                width: 300px;
                height: 153px;
              }
            }

            .pTitle {
              font-size: 14px;
              cursor: pointer;
              overflow: hidden;
              width: 95%;
              margin: 10px auto 0;
              color: #000000;
              display: -webkit-box; /*弹性伸缩盒子*/
              -webkit-box-orient: vertical; /*子元素垂直排列*/
              -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
              text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
            }

            .ListBottom {
              width: 95%;
              margin: 5px auto;
              font-size: 14px;
              color: #f66a1d;
              display: flex;
              justify-content: space-between;

              .ListLeft {
                .pTitle {
                  font-size: 14px;
                  cursor: pointer;
                  overflow: hidden;
                  color: #000000;
                  width: 100%;
                  display: -webkit-box; /*弹性伸缩盒子*/
                  -webkit-box-orient: vertical; /*子元素垂直排列*/
                  -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                  text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                }

                .pTitle:hover {
                  font-size: 14px;
                  cursor: pointer;
                  overflow: hidden;
                  color: #188252;
                  width: 100%;
                  display: -webkit-box; /*弹性伸缩盒子*/
                  -webkit-box-orient: vertical; /*子元素垂直排列*/
                  -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                  text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                }

                .pTitle2 {
                  padding-bottom: 10px;
                  font-size: 14px;
                  padding-top: 5px;
                  color: #999999;
                }
              }

              .ListRight {
                font-size: 16px;
                font-weight: bold;
                margin-top: 10px;
                color: #ee6a31;
                text-align: right;
                //padding-right: 20px;
              }
            }
          }
        }
      }
    }

    .DialogTopRegistration {
      margin-right: 42px;
      font-size: 12px;

      span:nth-child(1) {
        color: #969997;
      }

      span:nth-child(2) {
        color: #219560;
        cursor: pointer;
      }
    }

    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__item.is-active {
      font-size: 16px;
      color: #219560;
    }

    .el-tabs__active-bar {
      background-color: #188252;
      height: 0;
      border-radius: 2px;
    }

    .el-tabs__item {
      font-size: 16px;
      color: #949997;
      padding-left: 20px;
      padding-right: 0;
    }

    .el-tabs__nav-wrap::after {
      height: 0;
      border-color: transparent !important;
    }
  }
  .DialogTop2 {
    height: 80px;
    border-bottom: 1px solid #e6e6e6;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ServiceTopBoxTabs {
      .seckill-goods {
        display: inline-block;

        .clearfix {
          margin-left: -45px;

          li {
            width: 300px;
            margin-top: 20px;
            float: left;
            flex: 1;
            margin-left: 45px;
            border: 1px solid #e6e6e6;

            .ListTop {
              width: 300px;
              height: 153px;

              img {
                width: 300px;
                height: 153px;
              }
            }

            .pTitle {
              font-size: 14px;
              cursor: pointer;
              overflow: hidden;
              width: 95%;
              margin: 10px auto 0;
              color: #000000;
              display: -webkit-box; /*弹性伸缩盒子*/
              -webkit-box-orient: vertical; /*子元素垂直排列*/
              -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
              text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
            }

            .ListBottom {
              width: 95%;
              margin: 5px auto;
              font-size: 14px;
              color: #f66a1d;
              display: flex;
              justify-content: space-between;

              .ListLeft {
                .pTitle {
                  font-size: 14px;
                  cursor: pointer;
                  overflow: hidden;
                  color: #000000;
                  width: 100%;
                  display: -webkit-box; /*弹性伸缩盒子*/
                  -webkit-box-orient: vertical; /*子元素垂直排列*/
                  -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                  text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                }

                .pTitle:hover {
                  font-size: 14px;
                  cursor: pointer;
                  overflow: hidden;
                  color: #188252;
                  width: 100%;
                  display: -webkit-box; /*弹性伸缩盒子*/
                  -webkit-box-orient: vertical; /*子元素垂直排列*/
                  -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                  text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                }

                .pTitle2 {
                  padding-bottom: 10px;
                  font-size: 14px;
                  padding-top: 5px;
                  color: #999999;
                }
              }

              .ListRight {
                font-size: 16px;
                font-weight: bold;
                margin-top: 10px;
                color: #ee6a31;
                text-align: right;
                //padding-right: 20px;
              }
            }
          }
        }
      }
    }

    .DialogTopRegistration {
      margin-right: 46px;
      font-size: 12px;

      span:nth-child(1) {
        color: #969997;
      }

      span:nth-child(2) {
        color: #219560;
        cursor: pointer;
      }
    }

    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__item.is-active {
      font-size: 16px;
      color: #219560;
    }

    .el-tabs__active-bar {
      background-color: #188252;
      height: 0;
      border-radius: 2px;
    }

    .el-tabs__item {
      font-size: 16px;
      color: #949997;
      padding-left: 20px;
      padding-right: 0;
    }

    .el-tabs__nav-wrap::after {
      height: 0;
      border-color: transparent !important;
    }
  }
  .btnClear {
    cursor: pointer;
    pointer-events: none;
  }
  .DialogRegOpen {
    cursor: pointer;
  }
  .InputBox {
    width: 80%;
    margin: 10px auto;
    padding-bottom: 20px;
    .el-input__inner {
      height: 50px;
    }

    .ForgotPassword {
      font-size: 12px;
      color: #666666;
      margin-top: 20px;
      text-align: right;
      cursor: pointer;
    }

    .ForgotPassword:hover {
      color: #188252;
    }

    .Login {
      cursor: pointer;
      height: 50px;
      margin-top: 30px;
      width: 100%;
      background: #219560;
      border-radius: 3px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      line-height: 50px;
    }

    // 短信验证码
    .pr {
      position: relative;
      .DiaLogCheckbox {
        display: flex;
        margin-top: 20px;
        align-items: center;
        p {
          margin-left: 5px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

    .code-btn {
      width: 92px;
      height: 36px;
      background: #f0f0f0;
      border-radius: 3px;
      position: absolute;
      top: 7px;
      right: 10px;
      z-index: 222;
      color: #219560;
      font-size: 14px;
      border: none;
      cursor: pointer;
    }
  }
  .navConBox {
    max-width: 1332px;
    height: 36px;
    line-height: 36px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navConBoxLocation {
      display: flex;
      height: 36px;
      align-items: center;

      .LocationOne {
        color: #f66a1d;
        margin-right: 5px;
        font-size: 12px;
      }

      .LocationTwo {
        color: #ffffff;
        margin-right: 5px;
        font-size: 12px;
      }

      .LocationOne,
      .LocationTwo:hover {
        font-size: 12px;
        cursor: pointer;
      }

      .LocationThere {
        font-size: 12px;
        margin-left: 30px;
        color: #999999;
      }
    }

    .navConBoxLogin {
      display: flex;
      align-items: center;
      .el-breadcrumb__separator {
        font-weight: normal;
        color: #999;
      }
      .el-breadcrumb__inner {
        font-size: 12px;
        color: #999;
      }
      .navConBoxLoginBox {
        margin-right: 20px;
        .message {
          cursor: pointer;
        }
        span {
          display: inline-block;
          line-height: 44px;
          font-size: 12px;
          color: #f66a1d;
        }

        span:nth-child(2) {
          margin-left: 20px;
        }

        span:hover {
          cursor: pointer;
        }
      }

      // .el-breadcrumb {
      //   ::v-deep.el-breadcrumb__inner {
      //     color: #999999;
      //     font-size: 12px;
      //   }

      //   ::v-deep.el-breadcrumb__inner:hover {
      //     cursor: pointer;
      //     font-size: 12px;
      //   }

      //   ::v-deep.el-breadcrumb__separator {
      //     color: #999999;
      //     font-size: 12px;
      //   }
      // }
    }
  }
}
</style>
