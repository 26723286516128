<template>
  <div class="allWorks">
    <div class="works" v-if="worksList.length != 0">
      <div class="worksBox" v-for="(item, index) in worksList" :key="index">
        <div class="workImg" @click="worksDetail(item)">
          <img :src="item.coverImg" alt="" />
        </div>
        <div class="work_title">
          <p>{{ item.productName }}</p>
        </div>
        <div class="workView">
          <p class="viewNum">
            <i class="el-icon-view"></i><span>{{ item.browseNum }}</span>
          </p>
          <p class="likes">
            <img src="../../../assets/image/thumbs-up.png" alt="" /><span>{{
              item.agreeNum
            }}</span>
          </p>
        </div>
<!--        <div class="workUser">-->
<!--          <div class="userImg" >-->
<!--            <img v-if="item.personalImg" :src="item.personalImg" alt="" />-->
<!--            <img v-else src="../../../assets/image/tx.png" alt="" />-->
<!--          </div>-->
<!--          <p class="userName">{{ item.studentName }} </p>-->
<!--        </div>-->
        <div class="productNoDiv">
          <p class="productNoP"> 作品编码：{{ item.productNo }}</p>
        </div>
      </div>
    </div>
    <div class="noData" v-else><p>暂无作品数据</p></div>
    <Pagination
      v-show="Total > 0"
      :total="Total"
      style="text-align: center; margin: 0 auto"
      :page.sync="formInline.pageNum"
      :limit.sync="formInline.pageSize"
      @pagination="PageApi"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/Pagination";
import { getAllList } from "@/request/activity";
export default {
  components: { Pagination },
  data() {
    return {
      id: null,
      sortIndex: 1,
      formInline: {
        raceId:"",
        pageNum: 1,
        pageSize: 16,
      },
      Total: 0,
      worksList: [],
    };
  },
  activated() {
    this.formInline.raceId= this.$route.query.id;
    this.PageApi();
  },
  // created() {
  //   this.PageApi();
  // },
  methods: {
    PageApi() {
      getAllList(this.formInline).then((res) => {
        if (res.code == 200) {
          this.Total = res.total;
          this.worksList = res.rows;
        }
      });
    },
    worksDetail(e) {
      sessionStorage.setItem("activeName", 4);
      this.$router.push({
        name: "worksDetail",
        query: { id: e.id, raceId: this.$route.query.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.allWorks {
  width: 100%;
  background-color: #fff;
  padding-top: 50px;
  .noData{
    width: 1320px;
    height: 500px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
    p{
      color: #999;
      font-size: 30px;
      line-height: 500px;
    }
  }
  .works {
    width: 1320px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .worksBox {
      width: 300px;
      margin: 0 15px 20px 15px;
      .workImg {
        cursor: pointer;
        overflow: hidden;
        width: 300px;
        height: 200px;
        border-radius: 10px;
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .work_title {
        margin: 0 0 10px 10px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 20px;
        }
      }
      .workView {
        display: flex;
        margin:0 0 0 10px;
        .viewNum {
          margin-right: 16px;
          i {
            font-size: 14px;
            color: #999999;
          }
          span {
            margin-left: 5px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
        .likes {
          height: 14px;
          img {
            width: 14px;
            height: 14px;
            line-height: 1px;
            margin-bottom: 2px;
          }
          span {
            margin-left: 5px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }
      .workUser {
        display: flex;
        height: 26px;
        margin-left: 10px;
        .userImg {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .userName {
          margin-left: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 26px;
        }
      }
      .productNoDiv {
        height: 26px;
        margin-left: 10px;
        .productNoP {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 26px;
        }
      }
    }
  }
}
</style>
