<template>
  <div class="navbar clearfix">
    <el-breadcrumb
      class="breadcrumb-container"
      separator-class="el-icon-arrow-right"
    >
      <el-breadcrumb-item
        class="myrou"
        v-for="item in levelList"
        :key="item.path"
        :to="item.path"
        >{{ item.meta.title }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      levelList: [],
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      //$route.matched一个数组 包含当前路由的全部嵌套路径片断的路由记录
      let matched = this.$route.matched.filter((item) => item.name);
      matched = [{ path: "/HomeView", meta: { title: "首页" } }].concat(
        matched
      );
      // console.log(matched)
      this.levelList = matched;
    },
  },
};
</script>

<style scoped>
.myrou /deep/ .el-breadcrumb__inner {
  font-weight: 400;
}
</style>
</style>
