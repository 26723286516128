<template>
  <div class="TrendsDetails">
    <div class="TrendsDetailsLeft">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/HomeView' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/IndustryTrends' }"
          >招采信息</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          IndustryTrendsDetailList.documentName
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <p class="pTitle">{{ IndustryTrendsDetailList.documentName }}</p>
      <div class="informationBox">
        <div class="informationLeft">
          <p>{{ IndustryTrendsDetailList.createTime }}</p>
          <p>
            <i class="el-icon-view"></i>
            <span style="margin-left: 8px">{{
              IndustryTrendsDetailList.browserNum
            }}</span>
          </p>
          <p>
            分类：{{ getDynamicsType(IndustryTrendsDetailList.dynamicsType) }}
          </p>
        </div>
        <div class="informationBoxRight">
          <i class="iconfont icon-wujiaoxingkong"></i>
          <!--          <i class="iconfont icon-wujiaoxing-"></i>  选中-->
          <span style="margin-left: 5px">收藏</span>
        </div>
      </div>
      <div
        style="margin-top: 20px; white-space: pre-wrap"
        v-html="htmlDiv"
        class="text"
      ></div>
    </div>
    <div class="TrendsDetailsRight">
      <div class="HotSearchBox" style="margin-left: 35px">
        <div class="HotSearchTop">
          <i class="iconfont icon-huo"></i>
          <p>热门园林设计排行榜</p>
        </div>
        <div class="HotSearchBottom">
          <ul class="seatch_list">
            <li
              v-for="(item, index) in hotGardenArchitectureList"
              @click="gardenDetail(item)"
              :key="index"
            >
              <p>{{ item.companyName }}</p>
            </li>
          </ul>
        </div>
      </div>
      <WantQuote style="margin-left: 35px" />
      <advertisementLeft
        :wideUrl="wideUrl"
        @click.native="goRoute"
        style="margin-top: 20px; margin-left: 35px"
      />
    </div>
  </div>
</template>

<script>
import WantQuote from "@/components/wantQuote/wantQuote";
import AdvertisementLeft from "@/components/advertisement/advertisementLeft";
import { getIndustryTrendsDetail } from "@/request/IndustryTrends";
import { getDicts } from "@/request/dict/data";
import { selectDictLabel, selectDictLabels } from "@/utils/weihua";
import { getBanner } from "@/request/home/home";
import { getGardenArchitectureList } from "@/request/GardenArchitecture";
export default {
  name: "TrendsDetails",
  components: { AdvertisementLeft, WantQuote },
  data() {
    return {
      name: "",
      id: "",
      htmlDiv: "",
      IndustryTrendsDetailList: [],
      dynamicsTypeList: [],
      dynamicsType: null,
      wideUrl: null,
      hotGardenArchitectureList: [],
      formInline: {
        pageNum: 1,
        pageSize: 8,
      },
      url:null,
    };
  },
  watch: {},
  activated() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dynamicsType = this.$route.query.dynamicsType;
      let richtext = this.htmlDiv;
      const regex = new RegExp("<img", "gi");
      richtext = richtext.replace(regex, `<img style="max-width: 100%;"`);
      this.htmlDiv = richtext;
    } else {
      this.$router.push({
        path: "/",
      });
    }
    getDicts("industry_dynamics_type").then((res) => {
      this.dynamicsTypeList = res.data;
    });
    this.getBBannerList();
    this.getIndustryTrendsDetailList();
  },
  created() {
    getGardenArchitectureList(this.formInline).then((res) => {
      if (res.code == 200) {
        this.hotGardenArchitectureList = res.rows;
      }
    });
  },
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 7 }).then((res) => { 
        if(res.data.length !== 0){
          this.wideUrl = res.data[0].filePath;
          this.url = res.data[0].routeContent;
        }

      });
    },
    goRoute(){
     if(this.url){
      window.open(this.url,"_blank");

      }else{
        return 
      }
    },
    getIndustryTrendsDetailList() {
      getIndustryTrendsDetail(this.id).then((res) => {
        this.IndustryTrendsDetailList = res.data;
        this.htmlDiv = res.data.documentDetail.replace(
          /\<img/gi,
          '<img style="width:98%;height:auto;margin:10px 0 10px 10px" '
        );
      });
    },
    gardenDetail(e) {
      this.$router.push({
        path: "/GardenDetails",
        query: {
          id: e.id,
        },
      });
    },
    getDynamicsType(e) {
      return selectDictLabel(this.dynamicsTypeList, e);
    },
  },
};
</script>
<style scoped>
::v-deep .ql-align-justify > span {
  font-size: 16px !important;
}
::v-deep .text > p {
  font-size: 16px !important;
}
</style>
<style lang="scss">
.TrendsDetails {
  width: 1320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .TrendsDetailsLeft {
    width: 75%;
    background-color: #FFFFFF;
    margin-top: 20px;
    padding: 20px;
    margin-right: 20px;

    .pTitle {
      font-size: 30px;
      font-weight: 400;
      color: #000000;
      margin-top: 30px;
    }

    .informationBox {
      margin-top: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .informationLeft {
        display: flex;
        align-items: center;

        p:nth-child(2) {
          margin: 0 36px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }

      .informationBoxRight {
        font-size: 12px;
        cursor: pointer;
        font-weight: 400;
        color: #999999;

        .icon-wujiaoxingkong {
          font-size: 12px;
          cursor: pointer;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }

  .TrendsDetailsRight {
    width: 25%;
    margin-top: 20px;

    .HotSearchBox {
      width: 294px;
      height: 365px;
     background-color: #FFFFFF;
      margin-bottom: 20px;

      .HotSearchTop {
        width: 100%;
        background: linear-gradient(-53deg, #f66a1d, #ff6b53);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 59px;
        margin-bottom: 10px;

        .icon-huo {
          color: #ffffff;
          margin-left: 10px;
          margin-right: 5px;
          margin-top: 8px;
          width: 6%;
          height: 40%;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .HotSearchBottom {
        width: 80%;
        margin: 20px auto;

        .seatch_list {
          li {
            list-style: decimal;
            cursor: pointer;
            margin-top: 16px;
            p {
              width: 100%;
              cursor: pointer;
              overflow: hidden;
              display: -webkit-box; /*弹性伸缩盒子*/
              -webkit-box-orient: vertical; /*子元素垂直排列*/
              -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
              text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
            }
          }
          li:hover {
            list-style: decimal;
            cursor: pointer;
            margin-top: 20px;
            color: #188252;
            p {
              width: 100%;
              cursor: pointer;
              overflow: hidden;
              color: #188252;
              display: -webkit-box; /*弹性伸缩盒子*/
              -webkit-box-orient: vertical; /*子元素垂直排列*/
              -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
              text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
            }
          }
        }
      }
    }
  }
}
</style>
