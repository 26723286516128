<template>
  <div class="messageManagement">
    <div class="title">
      <p class="message">消息管理</p>
      <div class="btnBox">
        <p class="back" @click="back">返回上一级</p>
      </div>
    </div>
    <div class="msgDetailBox">
      <div class="msgDetailTitle">
        <div class="msgTitle">
          {{msgDetail.messageTitle}}
        </div>
        <div class="msgTime">{{msgDetail.createTime}}</div>
      </div>
      <div class="msgDetailContent">
        {{msgDetail.messageContent}}
      </div>
    </div>
  </div>
</template>

<script>
import NavCon from "@/components/navcon/navCon";
import { msgDetail } from "@/request/mine";
export default {
  components: { NavCon },
  data() {
    return {
      msgDetail:[]
    };
  },
  created() {
    this.msgDetailApi()
  },
  methods: {
    msgDetailApi(){
      const id  = this.$route.query.id
      msgDetail(id).then((res)=>{
        if(res.code == 200){
          this.msgDetail = res.data
        }
      })
    },
    goHome() {
      this.$router.push({ path:"/HomeView"});
    },
    back() {
      this.$router.push({ name: "messageManagement" });
    },
    GoIsShow() {
      this.$refs.Navigation;
    },
  },
};
</script>

<style lang="scss" scoped>
.messageManagement {
  width: 100%;
  padding: 30px 0px;
  .title {
    padding: 0 0 20px 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    .message {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
    .btnBox {
      margin-right: 30px;
      width: 70px;
      display: flex;
      .back {
        cursor: pointer;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2a9261;
      }
    }
  }
  .msgDetailBox {
    padding: 0 30px;
    .msgDetailTitle {
      border-bottom: 1px solid #ebebeb;
      .msgTitle {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #4d4d4d;
        line-height: 50px;
      }
      .msgTime {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #a8a8a8;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }
    .msgDetailContent {
      padding: 20px 0;
    }
  }
}
</style>