<template>
  <div class="findDesign">
    <div class="navBar">
      <Breadcrumb></Breadcrumb>
    </div>
    <div class="design">
      <!-- <div class="classificationFilter">
        <span class="serverTit" style="border-bottom: none; border-right: 0"
          >设计师级别</span
        >
        <ul class="serversType" style="border-bottom: none">
          <li
            :class="IndexActiveOne === index ? 'txtActive' : 'txt'"
            @click="GoServersTypeActive(index)"
            v-for="(item, index) in subject"
            :key="index"
          >
            {{ item.title }}
          </li>
        </ul>
      </div> -->

      <div class="classificationFilter classificationFilter_bom">
        <span class="serverTit" style="border-right: 0">擅长风格</span>
        <ul class="serversType">
          <li
            :class="IndexActiveTwo === item.dictValue ? 'txtActive' : 'txt'"
            v-for="(item, index2) in styleList"
            :key="index2"
            @click="GoTutorialLevActive(item.dictValue)"
          >
            {{ item.dictLabel }}
          </li>
        </ul>
      </div>
      <!-- <div class="sort">
        <ul class="serversType">
          <li
            :class="IndexActiveOne === index ? 'txtActive' : 'txt'"
            @click="sort(item.id)"
            v-for="(item, index) in sortList"
            :key="index"
          >
            {{ item.con }}
          </li>
        </ul>
      </div> -->
      <div class="designPeopleList" v-if="designPeopleList.length != 0">
        <div class="design_box">
          <div
            class="peopleInfo"
            v-for="(item, index1) in designPeopleList"
            :key="index1"
          >
            <div class="peoImg" @click="designerDetails(item)">
              <img :src="item.personalImg" alt="" />
              <p class="payMoney">预约设计</p>
            </div>
            <div class="peoContent">
              <span class="PeopleName">{{ item.personalName }}</span>
              <span class="yearsService" v-if="item.year >= 1"
                >{{ item.year + year }}工作经验</span
              >
              <span class="yearsService" v-else></span>
              <br />
              <div style="display: flex">
                <span class="small_text">擅长风格</span>
                <span class="big_txt">{{ getWellStyle(item.wellStyle) }}</span>
              </div>
              <div style="display: flex">
                <span class="small_text">设计案例</span>
                <span class="big_txt">{{ item.caseNums }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="noData" v-else>
        <p>暂无数据</p>
      </div>
      <Pagination
        v-show="Total > 0"
        :total="Total"
        style="text-align: center; margin: 30px auto"
        :page.sync="formInline.pageNum"
        :limit.sync="formInline.pageSize"
        @pagination="PageApi"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components//Breadcrumb/Breadcrumb.vue";
import Pagination from "@/components/Pagination/Pagination";
import { getDicts } from "@/request/dict/data";
import { selectDictLabel, selectDictLabels } from "@/utils/weihua";
import { getFindDesign } from "@/request/findDesign";
import { getSeatch } from "@/request/home/home";
export default {
  components: { Breadcrumb, Pagination },
  data() {
    return {
      sortIndex: 1,
      styleList: [],
      year: "年",
      formInline: {
        pageNum: 1,
        pageSize: 12,
        wellStyle: null,
        personIdentity: 2,
        serviceArea: sessionStorage.getItem("cityTxt"),
        type: null,
        searchInfo: null,
        pcSearch: 1,
      },
      Total: 0,
      sortList: [
        {
          id: 1,
          con: "综合排序",
        },
        {
          id: 2,
          con: "最新",
        },
      ],
      subject: [
        {
          id: 1,
          title: "全部",
        },
        {
          id: 2,
          title: "园林全包",
        },
        {
          id: 3,
          title: "园林全包",
        },
        {
          id: 4,
          title: "园林全包",
        },
        {
          id: 5,
          title: "园林全包",
        },
        {
          id: 6,
          title: "园林全包",
        },
        {
          id: 7,
          title: "园林全包",
        },
      ],
      IndexActiveOne: 0,
      IndexActiveTwo: "0",
      designPeopleList: [],
      cityTxt: null,
    };
  },
  activated() {
    this.cityTxt = sessionStorage.getItem("cityTxt");
    this.formInline.personIdentity = this.$route.query.id;
    if (this.$route.query.type) {
      this.formInline.type = this.$route.query.type;
      this.formInline.searchInfo = this.$route.query.searchInfo;
      this.getSeatchList();
    } else {
      this.PageApi();
    }
  },
  created() {
    // this.formInline.personIdentity = this.$route.query.id;
    // if (this.$route.query.type) {
    //   this.formInline.type = this.$route.query.type;
    //   this.formInline.searchInfo = this.$route.query.searchInfo;
    //   this.getSeatchList();
    // } else {
    //   this.PageApi();
    // }
    getDicts("msk_sys_well_style").then((res) => {
      this.styleList = res.data;
      this.styleList.unshift({
        dictLabel: "全部",
        dictValue: "0",
      });
    });
  },
  mounted() {
    window.addEventListener("load", () => {
      localStorage.removeItem("type");
    });
  },
  destroyed() {
    localStorage.removeItem("type");
  },
  methods: {
    getSeatchList() {
      getSeatch(this.formInline).then((res) => {
        if (res.code == 200) {
          this.designPeopleList = res.rows;
          this.Total = res.total;
          res.rows.forEach((item, index) => {
            item.year = null;
            item.year = this.getYearNum(item.jobStartTime, item.jobEndTime);
          });
        }
      });
    },
    PageApi() {
      this.formInline.personIdentity = this.$route.query.id;
      getFindDesign(this.formInline).then((res) => {
        if (res.code === 200) {
          this.Total = res.total;
          this.designPeopleList = res.rows;
          res.rows.forEach((item, index) => {
            item.year = null;
            item.year = this.getYearNum(item.jobStartTime, item.jobEndTime);
          });
        }
      });
    },
    getWellStyle(row) {
      return selectDictLabels(this.styleList, row);
    },
    getYearNum(startTime, endTime) {
      var timestr = startTime.replace(/-/g, "/");
      var startTime1 = new Date(timestr);
      var timestr1 = endTime.replace(/-/g, "/");
      var endTime1 = new Date(timestr1);
      var y = endTime1.getFullYear();
      var y1 = startTime1.getFullYear();
      var m = endTime1.getMonth() + 1;
      var m1 = startTime1.getMonth() + 1;
      if (y === y1) {
        this.year = "年";
        return 1;
      } else {
        this.year = "年";
        return y - y1;
      }
      // let start = startTime.getFullYear()
      // let end = endTime.getFullYear()
      // return year = end - start;
    },
    GoServersTypeActive(e) {
      this.IndexActiveOne = e.dictValue;
      this.formInline.serverType = this.IndexActiveOne;
    },
    GoTutorialLevActive(e) {
      this.IndexActiveTwo = e;
      this.formInline.wellStyle = this.IndexActiveTwo;
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    sort(e) {
      this.sortIndex = e;
      this.formInline.pcSearch = this.sortIndex;
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    designerDetails(e) {
      this.$router.push({
        name: "designerDetails",
        query: { id: e.id, personIdentity: this.formInline.personIdentity },
      });
      // const token = sessionStorage.getItem("token");
      // if (token) {
      // } else {
      //   this.$message({
      //     message: "您还没有登录，请登录后查看",
      //     type: "success",
      //   });
      //   this.$router.push({ path:"/HomeView"});
      //   sessionStorage.setItem("loading", true);
      //   this.$router.go(0);
      // }
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
</style>
<style scoped lang="scss">
::v-deep .btn-prev {
  padding: 0 5px !important;
}
::v-deep .btn-next {
  padding: 0 5px !important;
}
.findDesign {
  width: 1320px;
  margin: 0 auto;
  .navBar {
    padding: 20px 0 20px 0;
    width: 100%;
  }
  .classificationFilter {
    width: 100%;
    display: flex;
    background: #ffffff;
    .serverTit {
      display: inline-block;
      width: 92px;
      min-height: 44px;
      background: #ffffff;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #4a4d4b;
      padding-top: 20px;
      text-align: center;
    }

    .serversType {
      width: 100%;
      min-height: 44px;
      background: #ffffff;
      padding-left: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        cursor: pointer;
      }

      .txt {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        cursor: pointer;
        padding: 20px;
        color: #636665;
      }

      .txtActive {
        background: #188252;
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        cursor: pointer;
        color: #ffffff;
        padding: 5px 20px;

        border-radius: 20px;
      }

      .txt:hover {
        color: #188252;
      }
    }
  }
  .classificationFilter_bom {
    margin-bottom: 20px;
  }
  .sort {
    width: 100%;
    height: 36px;
    background: #f5f5f5;
    border: 1px solid #ebebeb;
    .serversType {
      width: 50%;
      display: flex;
      padding: 0 20px;
      li {
        cursor: pointer;
        margin-right: 30px;
      }

      .txt {
        line-height: 36px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #808080;
        cursor: pointer;
      }

      .txtActive {
        line-height: 36px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #188252;
        cursor: pointer;
      }

      .txt:hover {
        color: #188252;
      }
    }
  }
  .designPeopleList {
    width: 100%;
    padding: 10px;

    .design_box {
      display: flex;
      margin-left: -38px;
      flex-wrap: wrap;
      .peopleInfo {
        margin-left: 38px;
        margin-bottom: 40px;
        .peoImg {
          width: 300px;
          height: 337px;
          background-color: #FFFFFF;
          position: relative;
          cursor: pointer;
          text-align: center;
          img {
            border-radius: 50%;
            width: 80%;
            height: 70%;
            margin-top: 50px;
          }
          .payMoney {
            position: absolute;
            bottom: 0;
            left: 30px;
            text-align: center;
            line-height: 40px;
            width: 236px;
            height: 40px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            opacity: 0;
            color: #ffffff;
            background: #219560;
            border-radius: 20px;
          }

        }
        .peoContent {
          cursor: pointer;
          width: 299px;
          background: #FFFFFF;
          height: 100px;
          .PeopleName {
            font-size: 18px;
            font-family: Source Han Sans CN;
            line-height: 40px;
            font-weight: bold;
            color: #4a4d4b;
            margin: 20px;
          }
          .yearsService {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
          .small_text {
            width: 130px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 25px;
            color: #999999;
            margin: 0 20px;
          }
          .big_txt {
            display: inline-block;
            width: 500px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            line-height: 25px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #4a4d4b;
          }
        }

      }
      .peopleInfo:hover .payMoney {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
  .noData {
    width: 1320px;
    height: 300px;
    margin: 0 auto;
    p {
      line-height: 300px;
      text-align: center;
      color: #999999;
      font-size: 24px;
    }
  }
}
</style>
