<template>
  <div id="app" name="app">
    <el-container>
      <!-- 顶栏容器 -->
      <el-header style="padding: 0; height: auto">
        <advertisement
          @mouseover.native="GoIsShow"
          @click.native="goRoute"
          v-if="wideUrl"
          :wideUrl="wideUrl"
        />
        <NavCon @mouseover.native="GoIsShow" />
        <SearchBox @mouseover.native="GoIsShow" v-if="isSettle == 0" />
        <div class="navFlexd" :class="navBarFixed == true ? 'navBarWrap' : ''">
          <Navigation ref="Navigation" v-if="isSettle == 0 || isSopping == 0" />
        </div>
      </el-header>
      <!-- 顶栏容器END -->

      <!-- 主要区域容器 -->
      <el-main @click.native="GoIsShow" @mouseover.native="GoIsShow">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
      <!-- 主要区域容器END -->

      <!-- 底栏容器 -->
      <el-footer style="padding: 0; height: auto">
        <!--        <div><a href="javascript:window.scrollTo(0,0)">返回顶部</a></div>-->
        <QuotationPage />
        <footerPage />
      </el-footer>
      <!-- 底栏容器END -->
    </el-container>
  </div>
</template>

<script>
import Advertisement from "@/components/advertisement/advertisement";
import NavCon from "@/components/navcon/navCon";
import SearchBox from "@/components/searchBox/searchBox";
import Navigation from "@/components/Navigation/Navigation";
import QuotationPage from "@/components/QuotationPage/QuotationPage";
import footerPage from "@/components/footerPage/footerPage";
import { getBanner } from "@/request/home/home";
export default {
  components: {
    footerPage,
    QuotationPage,
    Navigation,
    SearchBox,
    NavCon,
    Advertisement,
  },
  data() {
    return {
      url: null,
      navBarFixed: false,
      wideUrl: null,
      isSettle: 0,
      isSopping: 0,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   if (to.name == "settleIn") {
  //     this.isSettle = localStorage.getItem("isSettle");
  //     this.isSopping = localStorage.getItem("isSopping");
  //     next({ name: "settleIn" });
  //   } else if (to.name == "settleInE") {
  //     this.isSettle = localStorage.getItem("isSettle");
  //     this.isSopping = localStorage.getItem("isSopping");
  //     next({ name: "settleInE" });
  //   } else {
  //     next();
  //   }
  // },
  watch: {
    //监听路由的信息是否发生变化，如果发生变化，再次发起请求
    $route(newValue, oldValue) {
      immediate: true;
      if (newValue.name == "settleIn" || newValue.name == "settleInE") {
        this.isSettle = localStorage.getItem("isSettle");
        this.isSopping = localStorage.getItem("isSopping");
      } else {
        this.isSettle = 0;
        this.isSopping = 0;
      }
    },
  },
  created() {
    this.getBBannerList();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);

    window.addEventListener("scroll", this.watchScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 2 }).then((res) => {
        // console.log(res);
        this.wideUrl = res.data[0].filePath;
        this.url = res.data[0].routeContent;
      });
    },
    goRoute() {
      if (this.url) {
        window.open(this.url, "_blank");
      } else {
        return;
      }
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  当滚动超过 90 时，实现吸顶效果
      if (scrollTop > 220) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },

    GoIsShow() {
      this.$refs.Navigation;
    },
  },
};
</script>

<style>
.el-card__body,
.navBarWrap {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
}
.el-main {
  padding: 0 !important;
  /*--el-header-height: 60px;*/
}
</style>
