<template>
  <div class="worker">
    <div class="worker_left">
      <div class="navBar">
        <Breadcrumb></Breadcrumb>
      </div>
      <div class="sectch">
        <div class="classificationFilter classificationFilter_bom">
          <span class="serverTit" style="border-right: 0">服务区域</span>
          <ul class="serversType">
            <li
              :class="IndexActiveOne === item.value ? 'txtActive' : 'txt'"
              @click="GoServersTypeActive(item)"
              v-for="item in subject"
              :key="item.value"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <!-- <div class="sort">
          <ul class="serversType">
            <li
              :class="sortIndex === index ? 'txtActive' : 'txt'"
              @click="sort(index)"
              v-for="(item, index) in sortList"
              :key="index"
            >
              {{ item.con }}
            </li>
          </ul>
        </div> -->
      </div>
      <div class="workerList" v-for="(item, index) in workerList" :key="index">
        <div class="workerListLeft">
          <div class="LeftImg" @click="GoWokerDetails(item)">
            <el-image
              class="LeftImgEl"
              fit="cover"
              :src="item.personalImg"
              alt=""
            ></el-image>
          </div>
          <div class="RightText">
            <div class="RightTextTop">
              <p>{{ item.personalName }}</p>
              <p>{{ "施工队长" }}</p>
              <el-rate
                v-model="value"
                disabled
                :colors="[
                  '#F6461D',
                  '#F6461D',
                  '#F6461D',
                  '#F6461D',
                  '#F6461D',
                ]"
                text-color="#F6461D"
                score-template="{value}"
              >
              </el-rate>
            </div>
            <div class="RightTextCenter">
              <p>
                <span>团队成员：</span>
                <span> {{ item.teamNum + "人" }}</span>
              </p>
              <p>
                <span>竣工案例：</span>
                <span> {{ item.caseNums }}</span>
              </p>
            </div>
            <div class="RightTextCenter">
              <p>
                <span>所在区域：</span>
                <span>{{ item.detailArea }}</span>
              </p>
              <!-- <p>
                <span>再施工地：</span>
                <span>10</span>
              </p> -->
            </div>
          </div>
        </div>
        <div class="workerListRight">
          <p @click="GoWokerDetails(item)">立即咨询</p>
        </div>
      </div>
      <Pagination
        v-show="Total > 0"
        :total="Total"
        style="text-align: center; margin: 20px auto"
        :page.sync="form.pageNum"
        :limit.sync="form.pageSize"
        @pagination="PageApi"
      />
    </div>
    <div class="worker_right">
      <div class="advertF">
        <wantQuote />
      </div>
      <div class="advertT">
        <advertisementLeft :wideUrl="wideUrl" @click.native="goRoute" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/Pagination";
import advertisementLeft from "@/components/advertisement/advertisementLeft.vue";
import wantQuote from "@/components/wantQuote/wantQuote.vue";
import Breadcrumb from "@/components//Breadcrumb/Breadcrumb.vue";
import { getFindDesign } from "@/request/findDesign";
import { getBanner, getSeatch } from "@/request/home/home";
export default {
  name: "worker",
  components: { Breadcrumb, Pagination, advertisementLeft, wantQuote },
  data() {
    return {
      value: 5,
      sortIndex: 0,
      wideUrl: null,
      sortList: [
        {
          id: 1,
          con: "综合排序",
        },
        {
          id: 2,
          con: "最新",
        },
      ],
      subject: [],
      IndexActiveOne: 0,
      workerList: [],
      form: {
        pageNum: 1,
        pageSize: 3,
        pcSearch: 1,
        type: null,
        searchInfo: null,
        personIdentity: null,
        serviceArea: sessionStorage.getItem("cityTxt"),
      },
      Total: 0,
      cityTxt: null,
      url: null,
    };
  },
  activated() {
    this.form.personIdentity = this.$route.query.id;
    if (this.$route.query.type) {
      this.form.type = this.$route.query.type;
      this.form.searchInfo = this.$route.query.searchInfo;
      this.getSeatchList();
    } else {
      this.PageApi();
    }
  },
  created() {
    this.cityTxt = sessionStorage.getItem("cityTxt");
    this.subject = JSON.parse(sessionStorage.getItem("cityList"));
    this.subject = this.subject.cityList;
    this.subject.unshift({
      label: "全部",
      value: 0,
    });
    this.getBBannerList();
  },
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 6 }).then((res) => {
        this.wideUrl = res.data[0].filePath;
        this.url = res.data[0].routeContent;
      });
    },
    goRoute() {
      if(this.url){
      window.open(this.url,"_blank");

      }else{
        return 
      }
    },
    getSeatchList() {
      getSeatch(this.form).then((res) => {
        if (res.code == 200) {
          this.workerList = res.rows;
          this.Total = res.total;
          console.log(this.workerList);
        }
      });
    },
    PageApi() {
      this.form.personIdentity = this.$route.query.id;
      getFindDesign(this.form).then((res) => {
        this.workerList = res.rows;
        this.Total = res.total;
        console.log(this.workerList);
      });
    },
    sort(e) {
      this.sortIndex = e;
      this.form.pcSearch = this.sortIndex;
      if (this.$route.query.type) {
        this.form.type = this.$route.query.type;
        this.form.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    GoServersTypeActive(e) {
      this.IndexActiveOne = e.value;
      if (e.label == "全部") {
        this.form.serviceArea = sessionStorage.getItem("cityTxt");
      } else {
        this.form.serviceArea = this.cityTxt + "-" + e.label;
      }
      if (this.$route.query.type) {
        this.form.type = this.$route.query.type;
        this.form.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    GoWokerDetails(e) {
      this.$router.push({
        path: "/workerDetails",
        query: {
          id: e.id,
        },
      });
    },
  },
};
</script>
<style lang="scss">
</style>
<style scoped lang="scss">
::v-deep .btn-prev {
  padding: 0 5px !important;
}
::v-deep .btn-next {
  padding: 0 5px !important;
}
.worker {
  width: 1320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .worker_left {
    width: 80%;
    margin-right: 30px;

    .navBar {
      padding: 20px 0 20px 0;
      width: 100%;
    }

    .sectch {
      width: 100%;
      margin-bottom: 20px;
      

      .classificationFilter {
        width: 100%;
        display: flex;

        .serverTit {
          display: inline-block;
          width: 92px;
          min-height: 44px;
          background: #f7f7f7;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #4a4d4b;
          padding-top: 20px;
          text-align: center;
        }

        .serversType {
          width: 100%;
          min-height: 44px;
          background: #ffffff;
          padding-left: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          li {
            cursor: pointer;
          }
          .txt {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            cursor: pointer;
            padding: 20px;
            color: #636665;
          }

          .txtActive {
            background: #188252;
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: 300;
            cursor: pointer;
            color: #ffffff;
            padding: 5px 20px;

            border-radius: 20px;
          }

          .txt:hover {
            color: #188252;
          }
        }
      }
      .classificationFilter_bom {
        margin-bottom: 20px;
      }
      .sort {
        width: 100%;
        height: 36px;
        background: #f5f5f5;
        border: 1px solid #ebebeb;
        .serversType {
          width: 50%;
          display: flex;
          padding: 0 20px;
          li {
            cursor: pointer;
            margin-right: 30px;
          }

          .txt {
            line-height: 36px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #808080;
            cursor: pointer;
          }

          .txtActive {
            line-height: 36px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #188252;
            cursor: pointer;
          }

          .txt:hover {
            color: #188252;
          }
        }
      }
    }
    .workerList {
      width: 100%;
      background-color: #FFFFFF;
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      .workerListLeft {
        width: 80%;
        display: flex;
        margin: 10px 0 0 27px;

        .LeftImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          padding: 20px 0 0 0;
          .LeftImgEl {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }

        .RightText {
          margin: 0 13px 0 18px;
          .RightTextTop {
            height: 42px;
            display: flex;
            align-items: center;

            p:nth-child(1) {
              line-height: 42px;
              font-size: 16px;
              font-weight: bold;
              color: #333333;
            }

            p:nth-child(2) {
              width: 67px;
              height: 22px;
              margin-left: 10px;
              background: #e0ece7;
              text-align: center;
              line-height: 22px;
              border-radius: 11px;
              font-size: 12px;
              color: #188252;
              margin-right: 10px;
            }
            .el-rate {
              height: 42px;
              .el-rate__item {
                height: 42px;
                .el-rate__icon {
                  font-size: 25px;
                  line-height: 42px;
                  margin-right: 0;
                }
                .el-rate__decimal {
                  font-size: 25px;
                  line-height: 42px;
                  margin-right: 0;
                }
              }
            }
          }

          .RightTextCenter {
            margin: 12px 0;
            display: flex;
            p {
              width: 250px;
              span:nth-child(1) {
                font-size: 14px;
                display: inline-block;

                color: #a8a8a8;
              }
              span:nth-child(2) {
                color: #4d4d4d;
                font-size: 14px;
              }
            }
          }
        }
      }
      .worker_left:last-child {
        border-bottom: 1px solid #ebebeb !important;
      }
      .workerListRight {
        width: 20%;
        height: 100px;
        margin: 32px 30px 0 0;
        text-align: right;
        p:nth-child(1) {
          width: 88px;
          display: inline-block;
          height: 34px;
          text-align: center;
          cursor: pointer;
          background: linear-gradient(-70deg, #f6461d, #f66a1d);
          font-size: 13px;
          line-height: 34px;
          color: #ffffff;
          margin-top: 23px;
        }
      }
    }
  }

  .worker_right {
    width: 21%;
    display: flex;
    flex-direction: column;
    padding: 4% 1%;

    .advertF {
      margin-bottom: 20px;
      //margin-left: 35px;
    }

    .advertT {
      //margin-left: 35px;
      margin-bottom: 20px;
    }
  }
}
</style>
