<!--顶部广告位-->
<template>
  <div class="advertisement" v-if="close">
    <p class="close" @click.stop="clickClose"><i class="el-icon-close"></i></p>
    <img :src="wideUrl" alt="" />
  </div>
</template>

<script>
export default {
  name: "advertisement",
  props: {
    wideUrl: String,
  },
  data() {
    return {
      close:true
    };
  },
  created() {},
  methods: {
      clickClose(){
      this.close = false
    }
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-height: 60px) {
  .advertisement {
    height: 60px;
  }
}
.advertisement {
  position: relative;
  width: 1920px;
  margin: 0 auto;
  background: #188252;
  height: 60px;
  cursor: pointer;
  .close{
    z-index: 999;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 20px;
    i{
      color: #fff;
    }
  }
  img {
    width: 100%;
    height: 60px;
  }
}
</style>
