<template>
  <div id="app" name="app">
    <el-container>
      <!-- 顶栏容器 -->
      <el-header style="padding: 0; height: auto">
        <NavCon />
        <ForgotPassword />
      </el-header>
      <!-- 顶栏容器END -->

      <!-- 主要区域容器 -->
      <el-main>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
      <!-- 主要区域容器END -->

      <!-- 底栏容器 -->
      <el-footer style="padding: 0; height: auto">
        <footerPage />
      </el-footer>
      <!-- 底栏容器END -->
    </el-container>
  </div>
</template>

<script>
import NavCon from "@/components/navcon/navCon";
import footerPage from "@/components/footerPage/footerPage";
import ForgotPassword from "@/components/ForgotPassword/ForgotPassword";

export default {
  components: { ForgotPassword, footerPage, NavCon },
  data() {
    return {};
  },

  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {},
};
</script>

<style>
.el-card__body,
.el-main {
  background-color: #F1F5F9;
  padding: 0 !important;

  /*--el-header-height: 60px;*/
}
</style>

