import request from '@/request/request'

//个人资料

export function getUserData(userId) {
    return request({
        url: "/system/visitor/pc/"+userId,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get",
    })
}
//个人资料保存
export function subUserData(data) {
    return request({
        url: "/system/visitor",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "put",
        data:data
    })
}
//修改密码提交

export function subPassWorld(data) {
    return request({
        url: "/system/user/changePassword",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "put",
        data:data
    })
}

//认证信息

export function getAuthInfo(userId) {
    return request({
        url: "/system/auth/getSettleInfo/"+userId,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get",
    })
}
//我的赛事列表

export function getmyWorkList() {
    return request({
        url: "/system/production/myList",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get",
    })
}

//消息列表接口
 
export function msgInfo(data) {
    return request({
        url: "/system/info/list",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get",
        params:data
    })
}
//消息批量删除

export function msgRemove(data) {
    return request({
        url: "/system/info/batchRemove",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "post",
        data:data
    })
}

//消息一键已读

export function oneClickRead(data) {
    return request({
        url: "/system/info/oneClickRead",
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "post",
        data:data
    })
}

//消息详情接口
export function msgDetail(id) {
    return request({
        url: "/system/info/"+id,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method: "get",
    })
}