import request from '@/request/request'

export function refreshToken() {
  return request({
    url: '/auth/refresh',
    method: 'post'
  })
}
//获取首页banner
export function getBanner(advertiseType) {
  return request({
    url: "/api/web/baseAdvertisePlaceSetting/list",
    method: "get",
    params: advertiseType
  })
}
//优秀案例
export function getHomePageConstructCaseList() {
  return request({
    url: "/api/web/case/homePage/recommendList",
    method: "get",
  })
}

//获取报价
export function getQuotation(data){
  return request({
    url:"/api/web/price/add",
    method:"post",
    data:data
  })
}


//搜索
export function getSeatch(data){
  return request({
    url:"/api/web/homePageSearch/list",
    method:"get",
    params:data
  })
}
//收藏接口
export function collection(data){
  return request({
    url:"/system/collection",
    headers: {
      Authorization: sessionStorage.getItem("token")
  },
    method:"post",
    data:data
  })
}

//取消收藏

export function unCollection(data){
  return request({
    url:"/system/collection/cancelCollection",
    headers: {
      Authorization: sessionStorage.getItem("token")
  },
    method:"post",
    data:data
  })
}