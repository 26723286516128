<template>
  <div class="CasePresentation">
    <Breadcrumb />
    <div class="grid-content">
      <div class="classificationFilter">
        <span class="serverTit" style="border-bottom: 0; border-right: 0"
          >房屋类型</span
        >
        <ul class="serversType" style="border-bottom: 0">
          <li
            :class="IndexActiveOne === index ? 'txtActive' : 'txt'"
            v-for="(item, index) in houseTypeList"
            :key="item.dictValue"
            @click="GoCaseHouseTypeActive(index)"
          >
            {{ item.dictLabel }}
          </li>
        </ul>
      </div>
      <div class="classificationFilter">
        <span class="serverTit" style="border-bottom: 0; border-right: 0"
          >园林风格</span
        >
        <ul class="serversType" style="border-bottom: 0">
          <li
            :class="IndexActiveTwo === index ? 'txtActive' : 'txt'"
            v-for="(item, index) in dardensStyleList"
            :key="item.dictValue"
            @click="GoTutorialLevActive(index)"
          >
            {{ item.dictLabel }}
          </li>
        </ul>
      </div>
      <div class="classificationFilter">
        <span class="serverTit" style="border-bottom: 0; border-right: 0"
          >面积</span
        >
        <ul class="serversType" style="border-bottom: 0">
          <li
            :class="IndexActiveThere === index ? 'txtActive' : 'txt'"
            v-for="(item, index) in areaList"
            :key="item.id"
            @click="GoareaActive(item, index)"
          >
            {{
              item.title
                ? item.title
                : item.startArea + "㎡" + "~" + item.endArea + "㎡"
            }}
          </li>
        </ul>
      </div>
      <div class="classificationFilter classificationFilter_bom">
        <span class="serverTit" style="border-right: 0">预算</span>
        <ul class="serversType">
          <li
            :class="IndexActiveFour === index ? 'txtActive' : 'txt'"
            v-for="(item, index) in budgetList"
            :key="item.id"
            @click="GoBudgetActive(item, index)"
          >
            {{
              item.title
                ? item.title
                : item.startBudget + "万" + "~" + item.endBudget + "万"
            }}
          </li>
        </ul>
      </div>
      <!-- <ul class="serversType2">
        <li
          :class="sortIndex === index ? 'txtActive' : 'txt'"
          @click="txtActive(item.id)"
          v-for="(item, index) in sortList"
          :key="index"
        >
          {{ item.con }}
        </li>
      </ul> -->
    </div>

    <div class="seckill-goods" v-if="caseList.length != 0">
      <ul class="clearfix">
        <li v-for="item in caseList" :key="item.id" @click="GoDetails(item)">
          <div class="ListTop">
            <img :src="item.coverImg" alt=""  />
          </div>
          <div class="ListBottom">
            <div class="ListLeft">
              <p class="pTitle">{{ item.caseName }}</p>
              <p class="pTitle2">
                {{
                  getHouseType(item.houseType) +
                  "丨" +
                  getDardensStyle(item.gardensStyle) +
                  "丨" +
                  item.area +
                  "㎡"
                }}
              </p>
            </div>
            <p class="ListRight">{{ item.budget}}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="noData" v-else>
      <p>暂无数据</p>
    </div>
    <Pagination
      v-show="Total > 0"
      :total="Total"
      style="text-align: center; margin-top: 15px"
      :page.sync="formInline.pageNum"
      :limit.sync="formInline.pageSize"
      @pagination="PageApi()"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Pagination from "@/components/Pagination/Pagination";
import { getDicts } from "@/request/dict/data";
import { selectDictLabel, selectDictLabels, handleMoney } from "@/utils/weihua";
import { getCaseList } from "@/request/case";
export default {
  name: "CasePresentation",
  components: { Breadcrumb, Pagination },
  data() {
    return {
      sortIndex: 0,
      sortList: [
        {
          id: 1,
          con: "综合排序",
        },
        {
          id: 2,
          con: "最新",
        },
      ],
      houseTypeList: [],
      IndexActiveOne: 0,
      dardensStyleList: [],
      IndexActiveTwo: 0,
      areaList: [
        {
          id: 0,
          title: "全部",
        },
        {
          id: 1,
          startArea: "70",
          endArea: "100",
        },
        {
          id: 2,
          startArea: "100",
          endArea: "150",
        },
        {
          id: 3,
          startArea: "150",
          endArea: "200",
        },
        {
          id: 4,
          startArea: "200",
          endArea: "500",
        },
      ],
      IndexActiveThere: 0,
      budgetList: [
        {
          id: 0,
          title: "全部",
        },
        {
          id: 1,
          startBudget: "5",
          endBudget: "10",
        },
        {
          id: 2,
          startBudget: "10",
          endBudget: "30",
        },
        {
          id: 3,
          startBudget: "30",
          endBudget: "50",
        },
        {
          id: 4,
          startBudget: "50",
          endBudget: "80",
        },
      ],
      IndexActiveFour: 0,
      formInline: {
        pageNum: 1,
        pageSize: 9,
        houseType: null,
        gardensStyle: "",
        startArea: null,
        endArea: null,
        pcSearch: 1,
        startBudget: null,
        endBudget: null,
        area: null,
        budget: null,
      },
      Total: 0,
      caseList: [],
    };
  },
  activated() {},
  created() {
    this.PageApi();
    getDicts("msk_sys_house_type").then((res) => {
      this.houseTypeList = res.data;
      this.houseTypeList.unshift({
        dictLabel: "全部",
        dictValue: " ",
      });
    });
    getDicts("msk_sys_gardens_style").then((res) => {
      this.dardensStyleList = res.data;
      this.dardensStyleList.unshift({
        dictLabel: "全部",
        dictValue: " ",
      });
    });
  },
  methods: {
    PageApi() {
      this.formInline.gardensStyle;
      getCaseList(this.formInline).then((res) => {
         const arr = res.rows;
        arr.forEach((item) => {
          item.budget = handleMoney(item.budget);
        });
        this.caseList = arr;
        // this.caseList = res.rows;
        this.Total = res.total;
      });
    },
    getHouseType(value) {
      return selectDictLabel(this.houseTypeList, value);
    },
    getDardensStyle(value) {
      return selectDictLabel(this.dardensStyleList, value);
    },
    GoCaseHouseTypeActive(e) {
      if (e === 0) {
        this.IndexActiveOne = e;
        this.formInline.houseType = null;
        this.PageApi();
      } else {
        this.IndexActiveOne = e;
        this.formInline.houseType = this.IndexActiveOne;
        this.PageApi();
      }
    },
    GoTutorialLevActive(e) {
      if (e === 0) {
        this.IndexActiveTwo = e;
        this.formInline.gardensStyle = null;
        this.PageApi();
      } else {
        this.IndexActiveTwo = e;
        this.formInline.gardensStyle = this.IndexActiveTwo;
        this.PageApi();
      }
    },
    GoBudgetActive(e) {
      this.IndexActiveFour = e.id;
      if (e.id == 0) {
        this.formInline.startBudget = null;
        this.formInline.endBudget = null;
      } else {
        this.formInline.startBudget = e.startBudget;
        this.formInline.endBudget = e.endBudget;
      }
      this.PageApi();
    },
    GoareaActive(e, i) {
      this.IndexActiveThere = e.id;
      if (e.id == 0) {
        this.formInline.gardensStyle = null;
        this.formInline.houseType = null;
        this.formInline.startArea = null;
        this.formInline.endArea = null;
      } else {
        this.formInline.startArea = e.startArea;
        this.formInline.endArea = e.endArea;
      }
      this.PageApi();
    },
    txtActive(e) {
      this.sortIndex = e;
      this.formInline.pcSearch = this.sortIndex;
      this.PageApi();
    },
    GoDetails(e) {
      this.$router.push({
        path: "/CasePresentationDetails",
        query: {
          id: e.id,
        },
      });
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
</style>
<style scoped lang="scss">
::v-deep .btn-prev {
  padding: 0 5px !important;
}
::v-deep .btn-next {
  padding: 0 5px !important;
}
.CasePresentation {
  width: 1320px;
  margin: 20px auto;

  .grid-content {
    width: 100%;
    margin-top: 20px;

    .classificationFilter {
      width: 100%;
      display: flex;

      .serverTit {
        display: inline-block;
        width: 92px;
        min-height: 44px;
        background: #f7f7f7;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #4a4d4b;
        padding-top: 20px;
        text-align: center;
      }

      .serversType {
        width: 100%;
        min-height: 44px;
        background: #ffffff;
        padding-left: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .txt {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          cursor: pointer;
          padding: 20px;
          color: #636665;
        }

        .txtActive {
          background: #188252;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          cursor: pointer;
          color: #ffffff;
          padding: 5px 20px;

          border-radius: 20px;
        }

        .txt:hover {
          color: #188252;
        }
      }
    }
    .classificationFilter_bom {
      margin-bottom: 20px;
    }
    .serversType2 {
      width: 100%;
      height: 34px;
      background: #f5f5f5;
      border: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        line-height: 34px;
      }
      .txt {
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
        padding: 0 20px;
      }

      .txtActive {
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
        color: #188252;
        padding: 0 20px;

        border-radius: 20px;
      }

      .txt:hover {
        color: #188252;
      }
    }
  }

  .seckill-goods {
    display: inline-block;

    .clearfix {
      margin-left: -35px;
      li {
        width: 414px;
        margin-top: 20px;
        float: left;
        flex: 1;
        margin-left: 35px;
        .ListTop {
          width: 414px;
          height: 240px;
          cursor: pointer;
          img {
            width: 414px;
            height: 240px;
            object-fit: cover;
          }
        }

        .ListBottom {
          display: flex;
          cursor: pointer;
          background-color: #ffffff;
          .ListLeft {
            width: 60%;
            padding-left: 20px;
            .pTitle {
              margin-top: 20px;
              font-size: 14px;
              font-weight: bold;
              cursor: pointer;
              overflow: hidden;
              color: #4a4d4b;
              width: 100%;
              display: -webkit-box; /*弹性伸缩盒子*/
              -webkit-box-orient: vertical; /*子元素垂直排列*/
              -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
              text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
            }
            .pTitle2 {
              padding-top: 10px;
              padding-bottom: 10px;
              font-size: 14px;
              color: #999999;
            }
          }

          .ListRight {
            width: 30%;
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #ee6a31;
            text-align: right;
            padding-right: 20px;
          }
        }
      }
    }
  }
  .noData {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    p {
      line-height: 300px;
      text-align: center;
      color: #999999;
      font-size: 24px;
    }
  }
}
</style>
