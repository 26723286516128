<template>
  <div class="bgc">
    <div class="shoppingCart">
      <div class="shopTitle">
        <p>我的购物车</p>
      </div>
      <ul class="order_lists cartTop">
        <li class="list_chk">
          <!--所有商品全选-->
          <input
            type="checkbox"
            label="全选"
            v-model="fetchData.isSelectAll"
            id="all"
            class="whole_check"
            @click="cartchoose()"
          />
          <label for="all" :class="fetchData.status ? 'mark' : ''"></label>
        </li>
        <li class="list_con">商品</li>
        <li class="list_count">数量</li>
        <li class="list_price">单价/元</li>
        <li class="list_info">金额/元</li>
        <li class="list_op">操作</li>
      </ul>
      <div class="cartBox" v-for="(item, i) in fetchData.list" :key="i">
        <div class="shop_info">
          <div class="all_check">
            <input
              type="checkbox"
              v-model="item.check"
              id="shop_a"
              class="shopChoice"
              @change="shopchoose(item)"
            />
          </div>
          <div class="shop_name">
            <strong>{{ item.shop_name }}</strong>
          </div>
        </div>
        <div class="order_content">
          <ul
            class="order_lists order_things"
            v-for="(pro, i) in item.products"
            :key="i"
          >
            <li class="list_chk">
              <input
                type="checkbox"
                v-model="pro.checked"
                id="checkbox_2"
                class="son_check"
                @change="choose(item, pro)"
              />
            </li>
            <li class="list_con">
              {{ pro.text }}
            </li>
            <li class="list_count">
              <div class="amount_box">
                <span class="reduce reSty" @click="reduce(pro)">-</span>
                <input type="text" v-model="pro.num" class="count" />
                <span class="plus" @click="add(pro)">+</span>
              </div>
            </li>
            <li class="list_unitPrice">
              <p>{{ pro.price }}</p>
            </li>
            <li class="list_totalPrice">{{ pro.num * pro.price }}</li>
            <li class="list_op">
              <p class="del" @click="handleDelete(pro.pro_id)">删除</p>
            </li>
          </ul>
        </div>
      </div>
      <!--底部-->
      <el-row class="settlement" type="flex" justify="end" :gutter="20">
        <el-col :span="6">
          <div class="goods">
            已选商品
            <strong class="goods_count"> {{ totalCount }} </strong> 件，共计:
            <strong class="total_text">￥{{ totalPrice }}</strong>
          </div>
        </el-col>
        <el-col :span="3">
          <el-button class="defaultBtn payBtn">去 结 算</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fetchData: {
        list: [
          {
            shop_id: 1,
            shop_name: "搜猎人艺术生活",
            products: [
              {
                pro_id: 101,
                text: "商品名1",
                price: 480,
                num: 1,
                img: "./images/1.png",
                sum: 480,
                checked: false, //商品选中状态
              },
              {
                pro_id: 102,
                text: "筆記本筆記本筆筆記本筆記本筆記本",
                price: 680,
                num: 1,
                img: "./images/2.png",
                sum: 680,
                checked: false,
              },
              {
                pro_id: 103,
                text: "產品2",
                price: 380,
                num: 1,
                img: "./images/3.png",
                sum: 380,
                checked: false,
              },
            ],
            check: false, //店铺选中状态
            chooseNum: 0, //商品选中个数
          },
          {
            shop_id: 2,
            shop_name: "卷卷旗舰店",
            products: [
              {
                pro_id: 201,
                text: "鍵盤鍵盤鍵盤鍵盤鍵盤鍵盤鍵盤鍵盤",
                price: 580,
                num: 1,
                img: "./images/4.png",
                sum: 580,
                checked: false,
              },
              {
                pro_id: 202,
                text: "鼠標鼠標鼠標鼠標鼠標",
                price: 780,
                num: 1,
                img: "./images/5.png",
                sum: 780,
                checked: false,
              },
            ],
            check: false,
            chooseNum: 0,
          },
          {
            shop_id: 3,
            shop_name: "瓜皮的神秘商店",
            products: [
              {
                pro_id: 301,
                text: "眼镜片眼镜片眼镜片眼镜片眼镜片眼镜片眼镜片眼镜片",
                price: 180,
                num: 1,
                img: "./images/6.png",
                sum: 180,
                checked: false,
              },
              {
                pro_id: 302,
                text: "凑数的凑数的凑数的凑数的凑数的凑数的凑数的凑数的",
                price: 280,
                num: 1,
                img: "./images/7.png",
                sum: 280,
                checked: false,
              },
            ],
            check: false,
            chooseNum: 0,
          },
        ],
      },
      isSelectAll: false, //全选选中状态
      allchoose: 0, //店铺选中个数
    };
  },
  computed: {
    totalPrice: function () {
      let totalPrice = 0;
      this.fetchData.list.forEach((item) => {
        item.products.forEach((val) => {
          if (val.pitchOnFlag) {
            totalPrice += val.count * val.price;
          }
        });
      });

      return totalPrice;
    },
    totalCount: function () {
      let totalCount = 0;
      this.fetchData.list.forEach((item) => {
        item.products.forEach((val) => {
          if (val.pitchOnFlag) {
            totalCount += val.count;
          }
        });
      });

      return totalCount;
    },
  },
  methods: {
    choosetrue(item, pro) {
      pro.checked = true; //商品选中
      item.chooseNum++; //选中商品数量先+1
      item.chooseNum === item.products.length ? (item.check = true) : ""; //再与该店铺商品数量比较，若相等則店铺选中

      item.check
        ? this.fetchData.allchoose++ === this.fetchData.list.length
          ? (this.fetchData.isSelectAll = true)
          : (this.fetchData.isSelectAll = false)
        : ""; //如果店铺选中状态改为true，选中店铺数量先+1，再与店铺数量比较，如果相等就更改全选选中状态为true
    },
    choosefalse(item, pro) {
      pro.checked = false; //将商品选中状态改为false
      item.chooseNum--;
      if (item.check) {
        item.check = false; //如果店铺是被选中的，更改店铺选中状态
        this.fetchData.allchoose--; //并且选中店铺数量-1
      }
      this.fetchData.isSelectAll = false; //全选的状态，直接改为false
    },
    choose(item, pro) {
      pro.checked ? this.choosetrue(item, pro) : this.choosefalse(item, pro);
    },
    // 店鋪
    shopchoose(item) {
      item.check ? this.shoptrue(item) : this.shopfalse(item);
    },
    shoptrue(item) {
      item.products.forEach((pro) => {
        pro.checked === false ? this.choosetrue(item, pro) : "";
      });
    },
    shopfalse(item) {
      item.products.forEach((pro) => {
        pro.checked === true ? this.choosefalse(item, pro) : "";
      });
    },
    cartchoose() {
      this.fetchData.isSelectAll = !this.fetchData.isSelectAll; //取反改变状态
      this.fetchData.isSelectAll
        ? this.fetchData.list.forEach((item) => this.shoptrue(item))
        : this.fetchData.list.forEach((item) => this.shopfalse(item));
    }, //根据取反后的状态进行相应的店铺按钮操作
    add(obj) {
      obj.num++;
      obj.sum += obj.price;
    },
    reduce(obj) {
      if (obj.num === 1) {
        obj.num;
      } else {
        obj.num--;
        obj.sum -= obj.price;
      }
    },
    //删除
    handleDelete(i) {
    },
  },
};
</script>

<style lang="scss" scoped>
.bgc {
  background-color: #f5f5f5;
  .shoppingCart {
    width: 1320px;
    margin: 0 auto;

    .shopTitle {
      padding: 20px 0;
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #4a4d4b;
      }
    }
    .cartMain {
      margin-top: 40px;
    }
    .order_lists {
      display: flex;
      li {
        flex: 1;
        padding: 10px 10px;
        line-height: 25px;
        display: flex;
        color: #808080;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
      }
    }
    .cartTop li {
      font-size: 16px;
      // font-weight: bold;
    }
    .list_chk {
      width: 5%;
    }
    .list_totalPrice {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f66a1d;
      line-height: 22px;
  }
    .list_count,
    .list_limit,
    .list_price,
    .list_op {
      width: 11%;
    }
    .list_info,
    .list_con {
      width: 15%;
    }
    .list_way {
      width: 20%;
    }
    .cartTop {
      background: #fff;
    }
    .cartBox {
      margin: 10px 0;
      .shop_info {
        background-color: #fff;
        border: 1px solid #dcdcdc;
        padding: 10px 25px;
        .all_check {
          float: left;
          margin-right: 15px;
        }
      }
      .order_content {
        background-color: #fff;
      }
      .count {
        width: 50px;
      }
      .amount_box {
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          border: 1px solid #cacaca;
          height: 20px;
          line-height: 20px;
          padding: 0 6px;
          cursor: pointer;
        }
        input {
          height: 20px;
          border: 1px solid #cacaca;
          text-align: center;
        }
      }

      .order_things {
        border-bottom: 1px solid #eeeeee;
        border-left: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        .list_op {
          display: block;
          text-align: center;
          p {
            cursor: pointer;
          }
        }
      }
    }

    .payBtn {
      width: 100%;
    }
    .settlement {
      position: static;
      bottom: 100px;
      z-index: 999;
    }
    .goods {
      color: #202020;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      strong {
        font-size: 18px;
        font-weight: bold;
        color: #e52626;
        margin: 0 5px;
      }
    }
  }
}
</style>