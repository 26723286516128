import request from '@/request/request'

//服务列表
export function getConstructService(data){
    return request({
        url:"/api/web/constructService/list",
        method:"get",
        params:data
    })
}

//服务详情
export function getConstructServiceDetail(id){
    return request({
        url:"/api/web/constructService/" + id,
        method:"get",
    })
}
//设计师详情
export function getFindDesignDetail(id){
    return request({
        url:"/api/web/personal/" + id,
        method:"get",
    })
}

//查询 服务所属公司/人

export function getInfoPersonalOrCompany(data){
    return request({
        url:"/api/web/constructService/getInfoPersonalOrCompany",
        method:"post",
        data:data
    })
}