<template>
  <div class="footerPage">
    <div class="footerPageTop">
      <div class="footerTopPageBox">
        <p>友情链接：</p>
        <el-breadcrumb separator="丨">
          <el-breadcrumb-item @click.native="goLink(1)"
            >陕西文华建筑景观工程有限公司</el-breadcrumb-item
          >
          <el-breadcrumb-item @click.native="goLink(2)"
            >西安建筑科技大学艺术学院</el-breadcrumb-item
          >
          <el-breadcrumb-item @click.native="goLink(3)"
            >西安建大城市规划设计研究院</el-breadcrumb-item
          >
          <el-breadcrumb-item @click.native="goLink(4)"
            >陕西省风景园林协会</el-breadcrumb-item
          >
          <el-breadcrumb-item @click.native="goLink(5)"
            >陕西省建筑节能协会</el-breadcrumb-item
          >
          <el-breadcrumb-item @click.native="goLink(6)"
            >陕西省林业局</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
    </div>
    <div class="footerPageBottom">
      <div class="PageBottomBox">
        <div class="BottomBoxLeft">
          <p>陕西博古领今信息科技有限公司</p>
          <p>
            Copyright © 2022 陕西博古领今信息科技有限公司 All Rights Reserved
          </p>
          <p>
            <!-- <span style="margin-right: 40px">陕公网安备2022008842号</span> -->
            <span>陕ICP备2022008842号</span>
            <router-link
              to="/icpImg"
              style="margin-left: 40px; color: #666666"
              target="_blank"
              >增值电信业务经营许可证：陕B2-20230027</router-link
            >
          </p>
        </div>
        <div class="BottomBoxRight">
          <div class="imgO">
            <img src="../../assets/image/QrCode01.png" alt="云尚布查特" />
            <p>云尚布查特</p>
          </div>
          <div class="imgt">
            <img src="../../assets/image/QrCode02.png" alt="文华建筑景观" />
            <p>文华建筑景观</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerPage",
  data() {
    return {};
  },
  created() {},
  methods: {
    goLink(n) {
      switch (n) {
        case 1:
          window.open("http://www.sxwhjz.com/", "_blank");
          break;
        case 2:
          window.open("https://ysxy.xauat.edu.cn/", "_blank");
          break;
        case 3:
          window.open("http://www.jdup.com/", "_blank");
          break;
        case 4:
          window.open("http://www.sxfjyl.org/", "_blank");
          break;
        case 5:
          window.open("https://www.sxjzjn.org/", "_blank");
          break;
        case 6:
          window.open("http://lyj.shaanxi.gov.cn/", "_blank");
      }
    },
  },
};
</script>

<style  lang="scss">
.footerPage {
  // height: 250px;
  // position: sticky;
  // bottom: 0;
  width: 100%;
  .footerPageTop {
    height: 56px;
    width: 100%;
    background: #242424;
    .footerTopPageBox {
      width: 1320px;
      height: 56px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      p {
        color: #b3b3b3;
        font-size: 12px;
        margin-right: 20px;
      }
      .el-breadcrumb {
        .el-breadcrumb__inner {
          color: #b3b3b3;
          font-size: 12px;
        }
        .el-breadcrumb__inner:hover {
          cursor: pointer;
          font-size: 12px;
        }
        .el-breadcrumb__separator {
          color: #b3b3b3;
          font-size: 12px;
        }
      }
    }
  }
  .footerPageBottom {
    width: 100%;
    height: 200px;
    background: #1f1f1f;
    .PageBottomBox {
      width: 1320px;
      height: 200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .BottomBoxLeft {
        width: 80%;
        height: 200px;
        p:nth-child(1) {
          margin-top: 20px;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 40px;
        }
      }
      .BottomBoxRight {
        width: 26%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        img {
          width: 120px;
          height: 120px;
        }
        p {
          margin-top: 10px;
          font-size: 12px;
          text-align: center;
          color: #e1e1e1;
        }
      }
    }
  }
}
</style>
