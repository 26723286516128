<template>
  <div class="uploadWarks">
    <div class="upload" v-if="StudentAuth == 1">
      <Upload />
    </div>
    <div class="StudentAuth" v-else>
      <StudentAuth @studentAuth="getStudentAuth" />
    </div>
  </div>
</template>

<script>
import Upload from "./upload.vue";
import StudentAuth from "./studentAuth.vue";

export default {
  components: { Upload, StudentAuth },
  data() {
    return {
      StudentAuth: 0,
    };
  },
  created() {
    this.StudentAuth = sessionStorage.getItem("StudentAuth")
    if (sessionStorage.getItem("activenum")) {
      this.StudentAuth = sessionStorage.getItem("activenum");
      sessionStorage.removeItem("activenum")
    }
    
  },
  methods: {
    getStudentAuth(i) {
      this.StudentAuth = i;
    },
    
  },
};
</script>

<style lang="scss" scoped>
.uploadWarks {
  width: 100%;
  .upload {
    margin: 0 auto;
  }
}
</style>