<template>
  <div class="IndustryTrends">
    <div class="IndustryTrendsLeft">
      <Breadcrumb />
      <!--      <el-breadcrumb separator-class="el-icon-arrow-right">-->
      <!--        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
      <!--        <el-breadcrumb-item>招采信息</el-breadcrumb-item>-->
      <!--      </el-breadcrumb>-->
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        style="margin-top: 30px"
      >
        <el-tab-pane label="园林景观知识" name="1"></el-tab-pane>
        <el-tab-pane label="招采信息" name="2"></el-tab-pane>
        <el-tab-pane label="政策法规" name="3"></el-tab-pane>
        <el-tab-pane label="技术标准" name="4"></el-tab-pane>
        <el-tab-pane label="平台通知" name="5"></el-tab-pane>
      </el-tabs>
      <div
        class="TrendsBox"
        v-for="item in TrendsList"
        :key="item.id"
        @click="GoTrendsDetails(item)"
      >
        <div class="TrendsBoxLeft">
          <el-image class="elImage" fit="cover" :src="item.coverImg"></el-image>
        </div>
        <div class="TrendsBoxRight">
          <p class="title">{{ item.documentName }}</p>
          <p class="html" v-html="getReplace(item.documentDetail)"></p>
          <p>
            <i class="el-icon-view"></i>
            <span style="margin-left: 8px">{{ item.browserNum }}</span>
          </p>
        </div>
      </div>
      <Pagination
        v-show="Total > 0"
        :total="Total"
        style="text-align: center; margin-top: 15px;margin-bottom: 30px"
        :page.sync="formInline.pageNum"
        :limit.sync="formInline.pageSize"
        @pagination="PageApi"
      />
    </div>
    <div class="IndustryTrendsRight">
      <WantQuote style="margin-left: 35px" />
      <advertisementLeft
        :wideUrl="wideUrl"
        @click.native="goRoute"
        style="margin-top: 20px; margin-left: 35px"
      />
    </div>
  </div>
</template>

<script>
import WantQuote from "@/components/wantQuote/wantQuote";
import AdvertisementLeft from "@/components/advertisement/advertisementLeft";
import Pagination from "@/components/Pagination/Pagination";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
import { getIndustryTrendsList } from "@/request/IndustryTrends";
import { getBanner } from "@/request/home/home";
export default {
  name: "IndustryTrends",
  components: { Breadcrumb, Pagination, AdvertisementLeft, WantQuote },
  data() {
    return {
      htmlDiv: "",
      activeName: "1",
      formInline: {
        pageNum: 1,
        pageSize: 8,
        dynamicsType: 1,
      },
      Total: 0,
      TrendsList: [],
      wideUrl: null,
      url:null,
    };
  },
  created() {
    this.PageApi();
    this.getBBannerList();
  },
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 6 }).then((res) => {
        this.wideUrl = res.data[0].filePath;
        this.url = res.data[0].routeContent;
      });
    },
    goRoute(){
      if(this.url){
      window.open(this.url,"_blank");

      }else{
        return 
      }
    },
    getReplace(val) {
      if (val) {
        return val.replace(
          /\<img/gi,
          '<img style="width:10%;height:10%;margin:10px 0 10px 10px"'
        );
      } else {
        return;
      }
    },
    GoTrendsDetails(e) {
      this.$router.push({
        path: "/TrendsDetails",
        query: {
          id: e.id,
          dynamicsType: e.dynamicsType,
        },
      });
    },
    handleClick(e) {
      this.formInline.dynamicsType = e.name;
      this.activeName = e.name;
      this.PageApi();
    },
    PageApi() {
      getIndustryTrendsList(this.formInline).then((res) => {
        this.TrendsList = res.rows;
        this.Total = res.total;
      });
    },
  },
};
</script>
<style scoped>
::v-deep .btn-prev {
  padding: 0 5px !important;
}
::v-deep .btn-next {
  padding: 0 5px !important;
}
</style>
<style lang="scss">
::v-deep .el-tabs__item {
  height: 45px;
}
::v-deep .el-tabs__active-bar {
  display: none;
}
::v-deep .el-tabs__item.is-active {
  height: 45px;
  color: #188252;
  font-size: 14px;
  font-weight: bold;
}
::v-deep .el-tabs__item:hover {
  color: #188252;
  font-size: 14px;
  font-weight: bold;
}
::v-deep .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: transparent ;
  z-index: 1;
}
</style>
<style   lang="scss">
.IndustryTrends {
  width: 1320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .IndustryTrendsLeft {
    width: 75%;
    margin-top: 20px;
    margin-right: 20px;
    .el-tabs__item {
      height: 45px;
    }
    .el-tabs__active-bar {
      display: none;
    }
    .el-tabs__item.is-active {
      height: 45px;
      color: #188252;
      font-size: 14px;
      font-weight: bold;
    }
    .el-tabs__item:hover {
      color: #188252;
      font-size: 14px;
      font-weight: bold;
    }
    .el-tabs__nav-wrap::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: transparent ;
      z-index: 1;
    }
    .TrendsBox {
      display: flex;
      padding: 22px;
      background-color: #FFFFFF;
      .TrendsBoxLeft {
        width: 220px;
        height: 200px;
        .elImage {
          width: 220px;
          height: 200px;
          img{
            width: 100%;
            height: 100%; 
          }
        }
      }
      .TrendsBoxRight {
        height: 200px;
        overflow: hidden;
        margin-left: 20px;
        cursor: pointer;
        .title {
          font-size: 20px;
          font-weight: 600;
          color: #313332;
          cursor: pointer;
        }
        .html {
          margin-top: 18px;
          margin-bottom: 22px;
          height: 57px;
          font-size: 14px;
          cursor: pointer;
          font-weight: 400;
          color: #7c807e;
          width: 100%;
          word-break: break-all; //只对英文起作用,以字母作为换行依据。
          word-wrap: break-word; //只对英文起作用,以单词作为换行依据。
          white-space: pre-wrap;
          overflow: hidden;
          display: -webkit-box; /*弹性伸缩盒子*/
          -webkit-box-orient: vertical; /*子元素垂直排列*/
          -webkit-line-clamp: 3; /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
          img{
            display: none;
          }
        }
        p:nth-child(3) {
          font-size: 14px;
          font-weight: 400;
          // color: #999999;
        }
      }
    }

    // .TrendsBox{
    //   display: flex;

    //   padding: 22px 0 30px 0;
    //   border-bottom: 1px solid #ebebeb;
    //   .TrendsBoxLeft {
    //     width: 220px;
    //     cursor: pointer;
    //     .elImage {
    //       width: 220px;
    //     }
    //   }
    //   .TrendsBoxRight {
    //     margin-left: 20px;
    //     p:nth-child(1) {
    //       font-size: 20px;
    //       font-weight: 600;
    //       color: #188252;
    //     }
    //     p:nth-child(2) {
    //       margin-top: 18px;
    //       margin-bottom: 22px;
    //       font-size: 14px;
    //       font-weight: 400;
    //       color: #7c807e;
    //       width: 100%;
    //       overflow: hidden;
    //       display: -webkit-box; /*弹性伸缩盒子*/
    //       -webkit-box-orient: vertical; /*子元素垂直排列*/
    //       -webkit-line-clamp: 3; /*可以显示的行数，超出部分用...表示*/
    //       text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
    //     }
    //     p:nth-child(3) {
    //       font-size: 12px;
    //       font-weight: 400;
    //       color: #999999;
    //     }
    //   }
    // }
  }
  .IndustryTrendsRight {
    width: 25%;
    margin-top: 62px;
  }
}
</style>
