<template>
  <div class="FindServiceDetails" ref="div">
    <div class="route">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="myrou" :to="{ path: '/HomeView' }"
          >首页</el-breadcrumb-item
        >
        <!-- <el-breadcrumb-item class="myrou" :to="{ path: '/FindService' }"
          >园林专业服务</el-breadcrumb-item
        > -->
        <el-breadcrumb-item class="myrou" :to="{ path: '/FindService' }"
          >找服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>服务详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="FindService_detail">
      <div class="findServiceDetail_left">
        <div class="findServiceDetail_top">
          <div class="Details_img">
            <div class="LeftTopImg">
              <img :src="mainImgUrl" alt="" />
            </div>
            <ul class="Img_ul">
              <li
                v-for="(
                  item, index
                ) in constructServiceDetailList.baseConstructServiceCarouselImgList"
                :key="index"
                class="Img_li"
                @click="changeImg(item, index)"
              >
                <img
                  :class="index === imgActiveIndex ? 'img_activeBorder' : ''"
                  :src="item.carouselImg"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </li>
            </ul>
          </div>
          <div class="findServiceDetail_info">
            <div class="info_title">
              <p>
                {{ constructServiceDetailList.serviceName }}
              </p>
            </div>
            <div class="service_info">
              <div class="service_region">
                <p class="region">
                  服务区域<span class="region_info">{{
                    constructServiceDetailList.serviceArea
                  }}</span>
                </p>
              </div>
              <div class="service_type">
                <p class="type">
                  服务类型<span class="type_info">{{
                    getServerType(constructServiceDetailList.serviceType)
                  }}</span>
                </p>
              </div>
              <div class="service_price">
                <p class="price">
                  价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格<span
                    class="price_info"
                    >{{
                      constructServiceDetailList.servicePrice +
                      getPrizeType(constructServiceDetailList.priceType)
                    }}<span></span
                  ></span>
                </p>
              </div>
              <!-- <div class="service_area">
                <p class="area">
                  面&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;积
                  <el-input
                    style="width: 100px; height: 36px; margin-left: 28px"
                    v-model="infoarea"
                  ></el-input
                  ><span class="area_info">平米</span>
                </p>
              </div> -->
            </div>
            <div class="info_btn">
              <div class="buy" @click="buy">
                <p>立即咨询</p>
              </div>
              <!-- <div class="buyin" @click="buy">
                <p>加入购物车</p>
              </div> -->
            </div>
          </div>
          <el-dialog
            title="联系客服"
            :show-close="false"
            width="400px"
            :visible.sync="dialogTableVisible"
          >
            <div class="inquiry">
              <div class="DialogTop">
                <div class="title">24小时客服电话</div>
                <div class="title1">029-85233571</div>
                <div class="img">
                  <img src="../../assets/image/baojia03.png" alt="" />
                </div>
                <div class="bom">扫一扫添加客服微信</div>
              </div>
              <p class="colseBox" @click="DialogClose">
                <i class="el-icon-close"></i>
              </p>
            </div>
          </el-dialog>
        </div>
        <div class="findServiceDetail_ter">
          <div class="findServiceDetail_ter_title">
            <p>服务详情</p>
          </div>
          <div class="findServiceDetail_ter_txt">
            <div v-html="htmlDiv"></div>
          </div>
        </div>
      </div>
      <div class="findServiceDetail_right">
        <div
          class="HotSearchBox"
          v-if="personIdentity =='2'||personIdentity =='3'||personIdentity =='4'||personIdentity =='5'"
          @click="goPersionDetail"
        >
          <div class="imgBox" @click="goPersionDetail">
            <img :src="infoPersonalOrCompanyList.img" alt="" />
          </div>
          <p class="name" @click="goPersionDetail">
            {{ infoPersonalOrCompanyList.name }}
          </p>
          <p class="more" @click="goPersionDetail">
            <span>更多案例</span>
            <span>
              {{ infoPersonalOrCompanyList.caseNums }} 套<i
                style="color: #188252"
                class="el-icon-arrow-right"
              ></i
            ></span>
          </p>
        </div>
        <div
          class="companyInfo"
          v-else
          @click="GoCompanyDetails"
        >
          <div class="companyLog">
            <img :src="infoPersonalOrCompanyList.img" alt="" />
          </div>
          <div class="companyNmae">
            <p @click="GoCompanyDetails">
              {{ infoPersonalOrCompanyList.name }}
            </p>
          </div>
          <div class="pingfen">
            <el-rate
              v-model="value"
              disabled
              :colors="['#F6461D', '#F6461D', '#F6461D', '#F6461D', '#F6461D']"
              text-color="#F6461D"
              score-template="{value}"
            >
            </el-rate>
          </div>
          <div class="identification">
            <div class="type_f">
              <i class="el-icon-success"></i><span>企业认证</span>
            </div>
          </div>
          <!-- <div class="address">
            <i class="el-icon-location-outline"></i
            ><span>新城 - 长乐中路 -熙景园林装饰工程</span>
          </div> -->
        </div>
        <div class="guanggao">
          <wantQuote />
        </div>
      </div>
    </div>
    <div class="findServiceDetail_bom">
      <div class="other">
        <p class="otherTitle">其它服务推荐</p>
        <div class="otherService_box">
          <div
            class="otherService"
            v-for="(item, index) in constructServiceList"
            :key="index"
          >
            <div class="otherService_img" @click="getOther(item.id)">
              <img :src="item.coverImg" alt="" />
            </div>
            <p class="pTitle">{{ item.serviceName }}</p>
            <div class="ListBottom">
              <span class="SpanTypeTwo" v-if="item.servicePersonType == 2"
                >企</span
              >
              <span class="SpanTypeOne" v-if="item.servicePersonType == 1"
                >个</span
              >
              <p class="small_text">
                {{ item.servicePersonName }}
              </p>
            </div>

            <div class="btn">
              <p>{{ item.servicePrice + getPrizeType(item.priceType) }}</p>
              <p><span @click="getOther(item.id)">立即咨询</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from "@/components/start/index.vue";
import wantQuote from "@/components/wantQuote/wantQuote.vue";
import {
  getConstructService,
  getConstructServiceDetail,
  getInfoPersonalOrCompany,
} from "@/request/findServer";
import { getGardenArchitectureDetail } from "@/request/GardenArchitecture";
import { getDicts } from "@/request/dict/data";
import { selectDictLabel, selectDictLabels, handleMoney } from "@/utils/weihua";
export default {
  name: "FindServiceDetails",
  components: { Stars, wantQuote },
  data() {
    return {
      personIdentity: null,
      bizId: null,
      dialogTableVisible: false,
      value: 5,
      constructServiceList: [],
      infoarea: 225,
      mainImgUrl: null,
      imgActiveIndex: 0,
      htmlDiv:'',
      constructServiceDetailList: [],
      constructServiceList: [],
      serverTypeList: [],
      prizeTypeList: [],
      infoPersonalOrCompanyList: [],
      formInline: {
        pageNum: 1,
        pageSize: 4,
        serviceArea:sessionStorage.getItem("cityTxt"),
      },
    };
  },
  activated() {
    const ids = this.$route.query.id;
    this.getConstructServiceDetail(ids);
    this.getConstructService();
  },
  created() {
    getDicts("msk_sys_server_type").then((res) => {
      this.serverTypeList = res.data;
    });
    getDicts("msk_price_type").then((res) => {
      this.prizeTypeList = res.data;
    });
  },
  methods: {
    getConstructServiceDetail(ids) {
      getConstructServiceDetail(ids).then((res) => {
        this.bizId = res.data.bizId;
        this.personIdentity = res.data.personIdentity;
        this.constructServiceDetailList = res.data;
        this.constructServiceDetailList.servicePrice = handleMoney(
          res.data.servicePrice
        );
        this.htmlDiv = res.data.serviceDetail.replace(
            /\<img/gi,
            '<img style="width:98%;height:auto;margin:10px 0 10px 10px" '
          );
        this.getInfoServerPeople(this.bizId, this.personIdentity);
        if (res.data.baseConstructServiceCarouselImgList != null) {
          this.mainImgUrl =
            res.data.baseConstructServiceCarouselImgList[0].carouselImg;
        } else {
          this.mainImgUrl = null;
        }
      });
    },
    getInfoServerPeople(bizId, personIdentity) {
      getInfoPersonalOrCompany({
        bizId,
        personIdentity,
      }).then((res) => {
        this.infoPersonalOrCompanyList = res.data;
      });
    },
    getOther(id) {
      this.$router.push({ name: "FindServiceDetails", query: { id: id } });
      getConstructServiceDetail(id).then((res) => {
        this.bizId = res.data.bizId;
        this.personIdentity = res.data.personIdentity;
        this.constructServiceDetailList.servicePrice = handleMoney(
          res.data.servicePrice
        );
        this.constructServiceDetailList = res.data;
        this.getInfoServerPeople(this.bizId, this.personIdentity);
        if (res.data.baseConstructServiceCarouselImgList != null) {
          this.mainImgUrl =
            res.data.baseConstructServiceCarouselImgList[0].carouselImg;
        } else {
          this.mainImgUrl = null;
        }
      });
      document.documentElement.scrollTop = 0;
    },
    getConstructService() {
      getConstructService(this.formInline).then((res) => {
        const arr = res.rows;
        arr.forEach((item) => {
          item.servicePrice = handleMoney(item.servicePrice);
        });
        this.constructServiceList = arr;
        // this.constructServiceList = res.rows;
      });
    },
    getServerType(value) {
      return selectDictLabel(this.serverTypeList, value);
    },
    getPrizeType(value) {
      return selectDictLabel(this.prizeTypeList, value);
    },
    goPersionDetail() {
      if (this.personIdentity == 2) {
        this.$router.push({
          name: "designerDetails",
          query: { id: this.bizId, personIdentity: this.personIdentity },
        });
      } else if (this.personIdentity == 3) {
        this.$router.push({
          name: "costEngineerDetails",
          query: { id: this.bizId },
        });
      } else {
        this.$router.push({
          path: "/workerDetails",
          query: {
            id: this.bizId,
          },
        });
      }
    },
    GoCompanyDetails() {
      this.$router.push({
        path: "/GardenDetails",
        query: {
          id: this.bizId,
        },
      });
    },
    changeImg(item, index) {
      this.mainImgUrl = item.carouselImg;
      this.imgActiveIndex = item.id;
    },
    buy() {
      // this.$message({
      //   message: "功能开发中,敬请期待",
      //   type: "success",
      // });
      const token = sessionStorage.getItem("token");
      if (token) {
        this.dialogTableVisible = true;
      } else {
        this.$message({
          message: "您还没有登录，请登录后查看",
          type: "success",
        });
        this.$router.push({ path: "/HomeView" });
        sessionStorage.setItem("loading", true);
        this.$router.go(0);
      }
    },
    DialogClose() {
      this.dialogTableVisible = false;
    },
    getStarNum(number) {
      this.starNum = number;
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
.myrou /deep/ .el-breadcrumb__inner {
  font-weight: 400;
}
</style>
<style lang="scss">
.el-rate {
  height: 42px;
  .el-rate__item {
    height: 42px;
    .el-rate__icon {
      font-size: 25px;
      line-height: 42px;
      margin-right: 0;
    }
    .el-rate__decimal {
      font-size: 25px;
      line-height: 42px;
      margin-right: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.FindServiceDetails {
  width: 1320px;
  margin: 0 auto;
  .route {
    width: 100%;
    height: 30px;
    padding-top: 20px;
  }
  .FindService_detail {
    display: flex;
    justify-content: space-between;
    .findServiceDetail_left {
      width: 74%;
      background-color: #FFFFFF;
      padding: 20px;
      .findServiceDetail_top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .inquiry {
          width: 347px;
          height: 401px;
          .DialogTop {
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
            }
            .title1 {
              font-size: 30px;
              font-family: DIN;
              font-weight: 500;
              color: #f66a1d;
              line-height: 50px;
            }
            .img {
              width: 258px;
              height: 258px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .bom {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 20px;
            }
          }
          .colseBox {
            cursor: pointer;
            position: absolute;
            top: 4px;
            right: -30px;
            border: 1px solid #bdbdbd;
            border-radius: 50%;
            i {
              font-size: 24px;
              color: #bdbdbd;
            }
          }
        }
        .Details_img {
          width: 40%;
          display: flex;
          flex-direction: column;
          .LeftTopImg {
            width: 398px;
            height: 272px;
            border-radius: 3px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .Img_ul {
            display: flex;
            margin-top: 10px;
            margin-left: -14px;
            .Img_li {
              margin-left: 14px;
              cursor: pointer;
              width: 89px;
              height: 61px;
              background: #bdbdbd;
              border-radius: 3px;

              .img_activeBorder {
                border: 1px solid #0dcc73;
              }
            }
          }
        }
        .findServiceDetail_info {
          width: 58%;
          overflow: hidden;
          padding-top: 10px;
          .info_title {
            width: 98%;
            padding: 0 10px 20px 10px;
            border-bottom: 1px solid #ebebeb;
            p {
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              overflow: hidden;
              display: -webkit-box; /*弹性伸缩盒子*/
              -webkit-box-orient: vertical; /*子元素垂直排列*/
              -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
              text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
            }
          }
          .service_info {
            width: 98%;
            margin: 0 10px 12px 10px;
            .service_region {
              line-height: 50px;
              .region {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                .region_info {
                  margin-left: 30px;
                  color: #333;
                }
              }
            }
            .service_type {
              line-height: 40px;
              .type {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                .type_info {
                  margin-left: 30px;
                  color: #333;
                }
              }
            }
            .service_price {
              line-height: 59px;
              .price {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                .price_info {
                  margin-left: 30px;
                  font-size: 18px;
                  color: #f66a1d;
                  span {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                  }
                }
              }
            }
            .service_area {
              line-height: 40 px;
              .area {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 40px;
                color: #999999;
                .area_info {
                  margin-left: 30px;
                  color: #333;
                }
              }
            }
          }
          .info_btn {
            margin: 0 0 10px 10px;
            display: flex;
            .buy {
              width: 194px;
              height: 50px;
              border: 1px solid #f66a1d;
              border-radius: 3px;
              p {
                cursor: pointer;
                font-family: Source Han Sans CN;
                font-weight: 400;
                background-color: #f66a1d;
                color: #fff;
                line-height: 50px;
                text-align: center;
              }
              // p:hover {
              //   background-color: #f66a1d;
              //   color: #fff;
              // }
            }
            .buyin {
              margin-left: 20px;
              width: 194px;
              height: 50px;
              border: 1px solid #188252;
              border-radius: 3px;
              p {
                cursor: pointer;
                font-family: Source Han Sans CN;
                font-weight: 400;
                background-color: #188252;
                color: #fff;
                line-height: 50px;
                text-align: center;
              }
              // p:hover {
              //   background-color: #f66a1d;
              //   color: #fff;
              // }
            }
          }
        }
      }
      .findServiceDetail_ter {
        width: 100%;
        margin-bottom: 20px;
        .findServiceDetail_ter_title {
          width: 100%;
          height: 40px;
          background: #f5f5f5;
          border: 1px solid #e6e6e6;
          p {
            margin: 0 0 0 10px;
            font-size: 16px;
            line-height: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #4d4d4d;
          }
        }
        .findServiceDetail_ter_txt {
          padding-top: 50px;
          border: 1px solid #f0f0f0;
          padding: 30px 30px;
          .findServiceDetail_ter_img {
            height: 1891px;
            margin: 0 auto;
            display: flex;
            flex-direction: columns;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .findServiceDetail_right {
      width: 22%;
      display: flex;
      flex-direction: column;
      .HotSearchBox {
        width: 300px;
        height: 295px;
       background-color: #FFFFFF;
        margin-bottom: 20px;
        .imgBox {
          cursor: pointer;
          margin-top: 30px;
          text-align: center;
          height: 146px;
          img {
            width: 119px;
            height: 119px;
          }
        }
        .name {
          margin-top: 18px;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          color: #4a4c4b;
          margin-bottom: 10px;
          cursor: pointer;
        }
        .type {
          font-size: 12px;
          margin-top: 8px;
          margin-bottom: 18px;
          text-align: center;
          font-weight: 400;
          color: #949997;
        }
        .more {
          cursor: pointer;
          text-align: center;
          span:nth-child(1) {
            cursor: pointer;
            font-size: 14px;
            color: #949997;
          }
          span:nth-child(2) {
            cursor: pointer;
            font-size: 14px;
            cursor: pointer;
            color: #188252;
            .el-icon-arrow-right {
              font-size: 14px;
              color: #188252;
            }
          }
        }
      }
      .companyInfo {
        height: 240px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 32px;
        background-color: #FFFFFF;
        margin-bottom: 40px;
        cursor: pointer;
        .companyLog {
          cursor: pointer;
          width: 100px;
          height: 100px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .companyNmae {
          p {
            cursor: pointer;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }
        .pingfen {
          height: 42px;
          display: flex;
          margin-bottom: 10px;
          span {
            margin-left: 10px;
            line-height: 20px;
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #f66a1d;
          }
        }
        .identification {
          display: flex;
          margin-bottom: 10px;
          .type_f {
            width: 79px;
            height: 21px;
            background: linear-gradient(-90deg, #fae3cd, #e5bc98);
            border-radius: 10px;
            text-align: center;
            i {
              width: 11px;
              height: 13px;
            }
            span {
              margin-left: 4px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #3b3834;
            }
          }
          .type_t {
            margin-left: 9px;
            width: 65px;
            height: 21px;
            background: linear-gradient(-90deg, #faf2cd, #eee154);
            border-radius: 10px;
            text-align: center;
            i {
              width: 11px;
              height: 13px;
            }
            span {
              margin-left: 4px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #3b3834;
            }
          }
        }

        .address {
          display: flex;
          i {
            color: #2a9261;
            line-height: 20px;
          }
          span {
            margin-left: 10px;
            display: inline-block;
            /* 文字过多换行显示，现在强行让文字在一行显示 */
            white-space: nowrap;
            /* 然后然溢出的部分文字隐藏 */
            overflow: hidden;
            /* 文字溢出显示用省略号。  */
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .findServiceDetail_bom {
    width: 100%;
    display: flex;
    flex-direction: column;
    .otherTitle {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #4a4d4b;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .otherService_box {
      display: flex;
      flex-wrap: wrap;
      margin-left: -28px;
      .otherService {
        width: 311px;
        margin-left: 28px;
        margin-bottom: 20px;
        border: 1px solid #f0f0f0;
        .otherService_img {
          width: 311px;
          height: 194px;
          margin-bottom: 10px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .pTitle {
          font-size: 16px;
          margin-left: 15px;
          color: #333333;
          margin-top: 14px;
          cursor: pointer;
          overflow: hidden;
          width: 90%;
          display: -webkit-box; /*弹性伸缩盒子*/
          -webkit-box-orient: vertical; /*子元素垂直排列*/
          -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
        }
        .ListBottom {
          margin-top: 10px;
          padding-left: 15px;
          display: flex;
          .SpanTypeOne {
            padding: 2px 4px;
            background: #ffefe5;
            border: 1px solid #eb8d67;
            border-radius: 2px;
            font-size: 12px;
            transform: scale(0.6);
            color: #f6611d;
          }

          .SpanTypeTwo {
            padding: 2px 4px;
            border: 1px solid #188252;
            background: #ebf7f2;
            border-radius: 2px;
            font-size: 12px;
            transform: scale(0.6);
            color: #188252;
          }

          .SpanTypeThere {
            padding: 2px 4px;
            background: #e4e7f3;
            border: 1px solid #5a73dd;
            border-radius: 2px;
            font-size: 12px;
            color: #5a73dd;
          }

          .SpanTwo {
            display: inline-block;
            font-size: 12px;
            color: #a8a8a8;
            margin-left: 5px;
          }
        }
        .name {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #a8a8a8;
          line-height: 26px;
          margin-left: 20px;
        }
        .small_text {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          margin: 0 20px 0 0;
          line-height: 26px;
        }
        .black_text {
          font-size: 12px;
          font-family: Source Han Sans CN;
          color: #4a4d4b;
          font-weight: 400;
          margin-left: 20px;
        }
        .btn {
          margin: 0 0 10px 0;
          display: flex;
          justify-content: space-between;
          p:nth-child(1) {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #f66a1d;
            margin-left: 20px;
            line-height: 30px;
          }
          p:nth-child(2) {
            width: 74px;
            height: 26px;
            background: linear-gradient(-70deg, #f6461d, #f66a1d);
            border: 1px solid #f0f0f0;
            margin-right: 20px;

            text-align: center;
            span {
              cursor: pointer;
              line-height: 26px;
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
</style>
