<template>
  <div class="swiper">
    <div class="swiperBox">
      <el-carousel
        height="450px"
        max-height="600px"
        autoplay
        :interval="5000"
        indicator-position="outside"
      >
        <el-carousel-item v-for="item in swiperList" :key="item.id">
          <img :src="item.filePath" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="swiperPosition">
      <!-- <div
        class="swiperPositionItem swiperPositionItem2"
        @click="route(item.id)"
        v-for="item in PositionItemList"
        :key="item.id"
      >
        <div class="ItemLeft">
          <p>{{ item.name }}</p>
          <p>{{ item.title }}</p>
        </div>
        <div class="ItemRight">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div> -->
      <div id="menu">
        <ul>
          <li
            v-for="(item,index) in PositionItemList"
            :key="index"
          >
            <p>{{ item.name }}</p>
            <p style="color:#8e9994;">{{ item.title }}</p>
            <i class="el-icon-arrow-right"></i>
            <div v-if="item.chilren">
              <span
                @click="route(item1.id)"
                v-for="item1 in item.chilren"
                :key="item1.id"
                >{{ item1.name }}<i class="el-icon-arrow-right"></i
              ></span>
              <!-- <div class="ItemRight"> -->
              <!-- </div> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner } from "@/request/home/home";
export default {
  name: "swiper",
  data() {
    return {
      swiperList: [],
      PositionItemList: [
        {
          name: "找设计",
          title: "中式  欧式  现代简约",
          routerName: "",
          chilren: [
            {
              routerName: "",
              name: "园林设计公司",
              id: 11,
            },
            {
              routerName: "FindDesign",
              name: "园林设计师",
              id: 2,
            },
          ],
        },
        {
          name: "找造价",
          title: "工程造价 园林造价",
          routerName: "",
          chilren: [
            {
              routerName: "",
              name: "园林造价公司",
              id: 10,
            },
            { routerName: "costEngineer", name: "园林造价师", id: 3 },
          ],
        },
        {
          routerName: "findMaterials",
          name: "园林材料公司",
          title: "假山 盆景 水泥",
          chilren: [
            {
              routerName: "",
              name: "园林材料公司",
              id: 6,
            },
            {
              routerName: "worker",
              name: "找材料",
              id: 5,
            },
          ],
        },
        {
          name: "找工人",
          title: "施工队 水电工",
          routerName: "",
          chilren: [
            {
              routerName: "",
              name: "园林施工公司",
              id: 12,
            },
            {
              routerName: "worker",
              name: "园林技术工人",
              id: 4,
            },
          ],
        },
        {
          routerName: "FindService",
          name: "找服务",
          title: "园林维护 园林养护",
          chilren: [
            {
              name: "园林养护公司",
              id: 7,
            },
            {
              name: "找服务",
              id: 0,
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getBBannerList();
  },
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 3 }).then((res) => {
        this.swiperList = res.data;
      });
    },
    route(id) {
      switch (id) {
        case 0:
          this.$router.push({ name: "FindService", query: { id: id } });
          break;
        case 2:
          this.$router.push({ name: "FindDesign", query: { id: id } });
          break;
        case 3:
          this.$router.push({ name: "costEngineer", query: { id: id } });
          break;
        case 5:
          this.$router.push({ name: "findMaterials", query: { id: id } });
          break;
        case 4:
          this.$router.push({ name: "worker", query: { id: id } });
          break;
        case 6:
          this.$router.push({ name: "GardenArchitecture", query: { id: id } });
          break;
        case 11:
          this.$router.push({ name: "GardenArchitecture", query: { id: id } });
          break;
        case 10:
          this.$router.push({ name: "GardenArchitecture", query: { id: id } });
          break;
        case 12:
          this.$router.push({ name: "GardenArchitecture", query: { id: id } });
          break;
        case 7:
          this.$router.push({ name: "GardenArchitecture", query: { id: id } });
          break;
      }
    },
    childrenRoute(e) {},
  },
};
</script>

<style lang="scss">
.swiper {
  width: 1320px;
  margin: 0 auto;
  height: 450px;
  position: relative;
  .swiperBox {
    width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
      height: 450px;
    }
    [data-v-9ea40744] .el-carousel__arrow--right{
      left: 0;
    }
    .el-carousel__item.is-animating {
      transition: opacity 10s ease-in-out !important;
    }
    .el-carousel__indicators--outside{
      position: absolute;
      bottom: 10px;
    }
  }

  .swiperPosition {
    z-index: 50;
    width: 221px;
    height: 415px;
    background: linear-gradient(-70deg, #ffffff, #f8fcff);
    position: absolute;
    left: 5%;
    top: 20px;
    .swiperPositionItem:not(:last-child) {
      width: 221px;
      height: 83px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      .ItemLeft {
        width: 90%;
        height: 83px;
        p:nth-child(1) {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #313332;
          margin-left: 18px;
          margin-top: 25px;
        }
        p:nth-child(2) {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8e9994;
          margin-left: 18px;
        }
      }
      .ItemRight {
        width: 10%;
        height: 83px;
        .el-icon-arrow-right {
          line-height: 83px;
        }
      }
    }
    .swiperPositionItem:hover {
      cursor: pointer;
      width: 221px;
      height: 83px;
      background-image: url("@/assets/image/PositionImg.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      .ItemLeft {
        width: 90%;
        cursor: pointer;
        height: 83px;
        p:nth-child(1) {
          cursor: pointer;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin-left: 18px;
          margin-top: 25px;
        }
        p:nth-child(2) {
          cursor: pointer;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin-left: 18px;
        }
      }
      .ItemRight {
        cursor: pointer;
        width: 10%;
        height: 83px;
        .el-icon-arrow-right {
          line-height: 83px;
          color: #ffffff;
        }
      }
    }
    .swiperPositionItem2 {
      width: 221px;
      height: 83px;
      display: flex;
      align-items: center;
      .ItemLeft {
        width: 90%;
        height: 83px;
        p:nth-child(1) {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #313332;
          margin-left: 18px;
          margin-top: 25px;
        }
        p:nth-child(2) {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8e9994;
          margin-left: 18px;
        }
      }
      .ItemRight {
        width: 10%;
        height: 83px;
        .el-icon-arrow-right {
          line-height: 83px;
        }
      }
    }
  }
  #menu {
  }
  #menu ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  #menu ul li {
    padding: 0 10px;
    cursor: pointer;
    width: 221px;
    height: 83px;
    background: #fff;
    text-align: left;
    border-bottom: 1px solid #ebebeb;
    position: relative;

    i {
      position: absolute;
      right: 10px;
      top: 42%;
      color: #313332;
    }
  }

  #menu ul li:hover {
    color: #fff;
    background-image: url("@/assets/image/PositionImg.png");
    background-size: 100% 100%;
    font-size: 13px;
  }
  #menu ul li:hover p {
    cursor: pointer;
    color: #fff;
    font-size: 13px;
  }
  #menu ul li:hover i {
    cursor: pointer;
    color: #fff;
    font-size: 13px;
  }
  p:first-child {
    height: 40px;
    display: block;
    line-height: 60px;
    font-size: 13px;
    font-weight: 700;
    color: #313332;
    margin-bottom: 5px;
  }
  p:last-child {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #313332;
    line-height: 29px;
  }
  p:hover {
    cursor: pointer;
  }
  /*创建二级菜单*/
  #menu ul li div {
    display: none;
    top: 0px;
    width: 200px;
    height: 83px;
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-bottom: none;
    position: absolute;
    left: 240px;
    text-align: center;
  }
  #menu ul li div span {
    line-height: 41px;
    color: #313332;
    position: relative;
  }
  #menu ul li div span:hover {
    line-height: 41px;
    color: #fff;
    background-image: url("@/assets/image/PositionImg.png");
  }
  #menu ul li:hover div {
    display: block;
  }
  #menu ul li:hover div span {
    cursor: pointer;
    display: block;
  }
}
</style>
