<template>
  <div class="designSettle">
    <div class="tishi">
      <el-alert :title="titleText" :closable="false" type="warning" show-icon>
      </el-alert>
    </div>
    <div class="stepBox">
      <div class="stepTitle">{{ title }}</div>
      <div class="steps-box">
        <!-- 步骤条 -->
        <div
            class="Article-steps"
            :class="stepList.length <= activeIndex ? 'step-over' : ''"
        >
          <!-- 步骤条背景进度条 -->
          <div class="line">
            <span
                class="plan"
                :style="`width:${
                activeIndex * (100 / (stepList.length - 1)) -
                100 / (stepList.length - 1) / 2
              }%`"
            ></span>
          </div>
          <!-- 每步部分 -->
          <span
              class="step"
              v-for="(i, index) in stepList"
              :key="index"
              :class="
              activeIndex == i.stepIndex || i.stepIndex <= activeIndex
                ? 'step-active'
                : ''
            "
          >
            <span class="step-num">
              <span class="num">{{ i.stepIndex }}</span>
            </span>
            <p class="title">{{ i.title }}</p>
          </span>
        </div>
      </div>
    </div>
    <div class="form">
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="140px"
          v-if="activeIndex === 1"
      >
        <el-form-item :label="name" prop="personalName">
          <el-input
              style="width: 50%"
              v-model="form.personalName"
              placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item :label="phone" prop="personalPhone">
          <el-input
              type="number"
              disabled
              oninput="if(value.length > 11) value = value.slice(0,11)"
              style="width: 50%"
              v-model="form.personalPhone"
              placeholder="请输入手机号码"
              maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="identityCardNo">
          <el-input
              style="width: 50%"
              v-model="form.identityCardNo"
              placeholder="请输入身份证号码"
              maxlength="18"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱" prop="contactEmail">
          <el-input
              type="email"
              style="width: 50%"
              maxlength="50"
              v-model="form.contactEmail"
              placeholder="请输入联系邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="请选择地址" prop="detailArea">
          <el-checkbox-group
              v-model="form.detailArea"
              v-show="false"
          ></el-checkbox-group>
          <el-cascader
              placeholder="请选择省市区"
              v-model="selectedOptions"
              :options="options1"
              clearable
              filterable
              @change="handleChange"
              style="width: 50%"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="detailAddress">
          <el-input
              style="width: 50%"
              maxlength="50"
              v-model="form.detailAddress"
              placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <p class="ButtonActive" @click="nextStep(1)">下一步</p>
        </el-form-item>
      </el-form>
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="120px"
          v-if="activeIndex === 2"
      >
        <el-form-item label="服务区域" prop="serviceArea">
          <el-checkbox-group
              v-model="form.serviceArea"
              v-show="false"
          ></el-checkbox-group>
          <el-cascader
              placeholder="请选择省市区"
              v-model="selectedOptions1"
              :options="options"
              clearable
              filterable
              @change="handleChange1"
              style="width: 50%"
              popper-class="AreaClass"
              :props="{ checkStrictly: true }"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
            label="擅长风格"
            v-if="personIdentity == 2"
            prop="wellStyle"
        >
          <el-select
              style="width: 50%"
              v-model="form.wellStyle"
              multiple
              filterable
              clearable
              placeholder="请选择"
              @change="change"
          >
            <el-option
                v-for="item in wellStyleList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作年限" prop="jobTime">
          <el-date-picker
              v-model="form.jobTime"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              style="width: 50%"
              :picker-options="expireTimeOPtion"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="设计师级别"
            v-if="personIdentity == 2"
            prop="designerLevel"
        >
          <el-select
              style="width: 50%"
              v-model="form.designerLevel"
              filterable
              clearable
              placeholder="请选择"
          >
            <el-option
                v-for="item in designList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="造价师级别"
            prop="costerLevel"
            v-if="form.personIdentity == '3'"
        >
          <el-select
              style="width: 336px"
              v-model="form.costerLevel"
              placeholder="请选择"
          >
            <el-option
                v-for="item in costengList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="introduce" prop="personalIntroduction">
          <el-input
              style="width: 50%"
              type="textarea"
              maxlength="500"
              v-model="form.personalIntroduction"
              placeholder="请输入1-500字的自我介绍"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="团队实力"
            v-if="personIdentity == 4"
            prop="teamPowerful"
        >
          <el-input
              style="width: 50%"
              type="textarea"
              maxlength="200"
              v-model="form.teamPowerful"
              placeholder="请输入1-200字的团队实力"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="团队成员"
            v-if="personIdentity == 4"
            prop="teamNum"
        >
          <el-input
              type="number"
              min="0"
              :max="999999"
              onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)));"
              style="width: 50%"
              v-model="form.teamNum"
              placeholder="请输入团队成员数量"
          ></el-input>
        </el-form-item>
        <el-form-item
            :label="certificates"
            ref="baseConstructPersonalCertImgssRef"
        >
          <el-input
              v-model="form.baseConstructPersonalCertImgss"
              v-show="false"
          ></el-input>
          <div style="display: flex; margin-bottom: 20px">
            <div
                v-for="(item, index) in fileList"
                :key="index"
                class="ImgSwiperBox"
                style="
                width: 124px;
                height: 124px;
                margin-right: 20px;
                position: relative;
              "
            >
              <img style="width: 124px; height: 124px" :src="item.url" alt=""/>
              <div
                  style="width: 30px;height: 30px;background-color: #FFFFFF;border-radius: 50%;position: absolute;right: 0;top: 0;    text-align: center; line-height: 30px;">
                <i
                    class="el-icon-delete"
                    style="font-size: 16px; color: #fa4543"
                    @click="handleRemove(item)"
                ></i>
              </div>
            </div>

            <el-upload
                action=""
                :limit="4"
                v-if="fileList.length !== 4"
                :file-list="fileList"
                style="width: 124px; height: 124px"
                class="avatar-uploader"
                accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
                :show-file-list="false"
                :before-upload="beforeUploadOne"
                :http-request="UploadImageOne"
            >
              <i
                  v-if="fileList.length !== 4"
                  class="el-icon-plus avatar-uploader-icon"
                  style="width: 124px; height: 124px"
              ></i>
            </el-upload>
          </div>
          <p
              style="
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              line-height: 20px;
            "
          >
            如:注册景观设计师、花艺环境设计师、造价师、施工队、园林工程师等相关证件，支持.jpg,.jpeg,.png，大小不超过5m
          </p>
        </el-form-item>
        <el-form-item :label="photo" prop="personalImg">
          <el-checkbox-group
              v-model="form.personalImg"
              v-show="false"
          ></el-checkbox-group>
          <el-upload
              class="avatar-uploader"
              action=""
              accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
              :http-request="UploadImageTwo"
              :before-upload="beforeUploadTwo"
              :show-file-list="false"
          >
            <img
                v-if="form.personalImg"
                :src="form.personalImg"
                class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p
              style="
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              line-height: 20px;
            "
          >上传彩色照片，要求尺寸280x330px,格式支持<span style="color: red"
          >.jpg,.jpeg,.png</span
          >，大小不超过5m
          </p>
        </el-form-item>
        <div
            style="
            margin: 20px 30px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          "
        >
          银行信息
        </div>
        <el-form-item label="银行名称" prop="openBankName">
          <el-input
              style="width: 50%"
              maxlength="50"
              v-model="form.openBankName"
              placeholder="请输入银行名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="openBankInfo">
          <el-input
              style="width: 50%"
              maxlength="20"
              v-model="form.openBankInfo"
              placeholder="请输入开户行"
          ></el-input>
        </el-form-item>
        <el-form-item label="账户名称" prop="openAccountName">
          <el-input
              style="width: 50%"
              maxlength="50"
              v-model="form.openAccountName"
              placeholder="请输入账户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账户" prop="openBankAccount">
          <el-input
              style="width: 50%"
              maxlength="19"
              v-model="form.openBankAccount"
              placeholder="请输入银行账户"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div style="display: flex; align-items: center">
            <p class="ButtonActive" @click="backStep">上一步</p>
            <p class="ButtonActive" @click="nextStep(2)">提 交</p>
          </div>
        </el-form-item>
      </el-form>
      <div class="ThereBox" v-if="activeIndex === 3">
        <img src="../../assets/image/SuccessImg.png" alt=""/>
        <div class="Right">
          <p>恭喜您提交成功! 请耐心等待1-3个工作日通知您审核结果哦~</p>
          <p style="cursor: pointer">
            <span>{{ TimeNumber }}秒后自动返回首页</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {regionData, CodeToText} from "element-china-area-data";
import {CodeToText1, regionData1, TextToCode1} from "@/utils/address";
import OSS from "ali-oss";
import {nextSettleIn, subSettleIn} from "@/request/settleIn";
import {getDicts} from "@/request/dict/data";
import {
  selectDictLabel,
  selectDictLabels,
  CheckSocialCreditCode,
} from "@/utils/weihua";
import {getOssToken} from "@/request/login";

export default {
  components: {Steps: () => import("@/components/Steps")},
  data() {
    var checkIdNum = (rule, value, callback) => {
      const reg =
          /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (!value) {
        return callback(new Error("身份证号码不能为空"));
      } else if (!reg.test(value)) {
        return callback(new Error("身份证号码不正确"));
      } else {
        callback();
      }
    };
    let addressRule = function (rule, value, callback) {
      if (!value) {
        callback(new Error("请输入详细地址"));
      } else if (!/^[0-9\u4e00-\u9fa5]*$/.test(value)) {
        return callback(new Error("详细地址格式不正确，请重新输入"));
      } else {
        callback();
      }
    };
    let openBankAccount = function (rule, value, callback) {
      if (!value) {
        callback();
      } else if (!/^[0-9]*$/.test(value)) {
        return callback(new Error("格式不正确，请重新输入"));
      } else if (value.length > 19 || value.length < 9) {
        return callback(new Error("格式不正确，请重新输入"));
      }
      {
        callback();
      }
    };
    let openBankName = function (rule, value, callback) {
      if (!value) {
        callback();
      } else if (!/^[\u4e00-\u9fa5]*$/.test(value)) {
        return callback(new Error("格式不正确，请重新输入"));
      } else {
        callback();
      }
    };
    return {
      //限制今天之前时间
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() > Date.now(); //如果没有后面的-8.64e6就是不可以选择今天的
        },
      },
      wellstyle: null,
      isNomarll: false,
      personIdentity: null,
      title: null,
      titleText: null,
      name: "",
      phone: null,
      style: null,
      introduce: null,
      certificates: null,
      fileList: [],
      photo: null,
      //当前位置
      activeIndex:1,
      TimeNumber: 3,
      //步骤条步数
      timer: null,
      timer2: null,
      stepList: [
        {
          stepIndex: 1,
          title: "填写基本信息",
        },
        {
          stepIndex: 2,
          title: "详细资料",
        },
        {
          stepIndex: 3,
          title: "提交成功",
        },
      ],
      //省市区初始化数据
      options: regionData1,
      options1: regionData,
      selectedOptions: [],
      selectedOptions1: [],
      wellStyleList: [],
      OssForm: {},
      costengList: [],
      designList: [],
      form: {
        baseConstructPersonalCertImgs: [],
        baseConstructPersonalCertImgss: null,
        personIdentity: null,
        personalName: null,
        personalPhone: null,
        identityCardNo: null,
        contactEmail: null,
        detailArea: null,
        detailAddress: null,
        serviceArea: null,
        wellStyle: [],
        image: null,
        jobTime: null,
        jobStartTime: null,
        jobEndTime: null,
        personalIntroduction: null,
        teamPowerful: null,
        teamNum: null,
        certImg: null,
        personalImg: null,
        openBankName: null,
        openBankInfo: null,
        openAccountName: null,
        openBankAccount: null,
      },
      rules: {
        personalName: [
          {
            min: 2,
            max: 30,
            required: true,
            message: "请输入2-30位的姓名",
            trigger: "change",
          },
          {
            validator: function (rule, value, callback) {
              var regex = /^[a-zA-Z\u4e00-\u9fa5]+$/;
              if (!regex.test(value)) {
                callback(new Error("请输入正确格式的姓名"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        personalPhone: [
          {required: true, message: "手机号码不能为空", trigger: "change"},
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "change",
          },
        ],
        contactEmail: [
          {
            required: true,
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "change",
          },
        ],
        detailArea: [
          {required: true, message: "地址不能为空", trigger: "change"},
        ],
        detailAddress: [
          {required: true, validator: addressRule, trigger: "change"},
        ],
        openBankName: [
          {required: false, validator: openBankName, trigger: "change"},
        ],
        openBankInfo: [
          {required: false, validator: openBankName, trigger: "change"},
        ],
        openAccountName: [
          {required: false, validator: openBankName, trigger: "change"},
        ],
        openBankAccount: [
          {required: false, validator: openBankAccount, trigger: "change"},
        ],
        identityCardNo: [
          {required: true, message: "身份证不能为空", trigger: "change"},
          {
            validator: checkIdNum,
            trigger: "blur",
          },
        ],
        serviceArea: [
          {required: true, message: "服务区域不能为空", trigger: "change"},
        ],
        wellStyle: [
          {required: true, message: "擅长风格不能为空", trigger: "change"},
        ],
        jobTime: [
          {required: true, message: "工作年限不能为空", trigger: "change"},
        ],
        teamPowerful: [
          {
            min: 10,
            max: 200,
            required: true,
            message: "请输入10-200字的团队实力",
            trigger: "change",
          },
        ],
        teamNum: [
          {required: true, message: "团队成员不能为空", trigger: "change"},
          {
            pattern: /^[1-9]\d{0,4}$/,
            message: "团队成员数量格式不正确",
            trigger: "change",
          },
        ],
        designerLevel: [
          {required: true, message: "设计师级别不能为空", trigger: "change"},
        ],
        baseConstructPersonalCertImgss: [
          {required: true, message: "证件不能为空", trigger: "change"},
        ],
        personalImg: [
          {required: true, message: "照片不能为空", trigger: "change"},
        ],
        personalIntroduction: [
          {
            max: 500,
            required: true,
            message: "自我介绍不能为空且最多不能超过500字",
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal != oldVal) {
        this.reset();
      }
    },
  },
  activated() {
    getOssToken().then((res) => {
      if (res.code === 0) {
        this.OssForm = res.data;
      }
    });
    this.form.personalPhone = sessionStorage.getItem("phone");
    // this.OssForm = JSON.parse(sessionStorage.getItem("aliOss"));
    this.personIdentity = this.$route.query.isShow;
    this.form.personIdentity = this.$route.query.isShow;
    this.activeIndex = 1;
    this.switchLabel();
  },
  created() {
    // console.log(this.fileList);
    // console.log(this.form.personalPhone);
    this.personIdentity = this.$route.query.isShow;
    this.form.personIdentity = this.$route.query.isShow;
    this.switchLabel();
    this.reset();
    this.form.personalPhone = sessionStorage.getItem("phone");
    getDicts("msk_sys_well_style").then((res) => {
      this.wellStyleList = res.data;
    });
    getDicts("sys_design_level").then((res) => {
      this.designList = res.data;
    });
    getDicts("sys_costeng_level").then((res) => {
      this.costengList = res.data;
    });
  },
  methods: {
    UploadImageOne(options) {
      try {
        let nowDate = new Date();
        let dateTime = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
        };
        let that = this;
        let client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: that.OssForm.accessKeyId,
          accessKeySecret: that.OssForm.accessKeySecret,
          stsToken: that.OssForm.token,
          bucket: "fengjingyuanlin",
          timeout: 600000,
          secure: true,
        });
        let file = options.file; // 拿到 file
        if (
            file.type == "image/png" ||
            file.type == "image/jpg" ||
            file.type == "image/jpeg"
        ) {
          let extendName = file.name.substring(file.name.lastIndexOf(".") + 1);
          let fileName = file.name.substr(0, file.name.lastIndexOf("."));
          let date = new Date().getTime();
          let fileNames = `${date}_${file.size}_${Math.round(
              Math.random() * 10000
          )}`;
          let folderName =
              "image/" +
              dateTime.year +
              "-" +
              dateTime.month +
              "-" +
              dateTime.date +
              "/" +
              fileNames +
              "." +
              extendName;
          client.put(folderName, file).then((res) => {
            if (res.res.statusCode === 200) {
              // that.form.baseConstructPersonalCertImgss = res.url;
              that.fileList.push({url: res.url});
              this.$set(this.form, "baseConstructPersonalCertImgss", "1");
            } else {
              options.onError("上传失败");
            }
          });
        } else {
          this.$message({
            message: "仅支持.jpg .png .jpeg",
          });
        }
      } catch (e) {
        options.onError("上传失败");
      }
    },
    change(val) {
      this.$set(this.form, "wellStyle", val);
      this.$forceUpdate();
    },
    //证件删除
    handleRemove(e) {
      let index = this.fileList.indexOf(e);
      if (index > -1) {
        this.fileList.splice(index, 1);
      }
      if (this.fileList.length > 0) {
        // fileList存放图片的数组
        this.$set(this.form, "baseConstructPersonalCertImgss", "1");
      } else {
        this.$set(this.form, "baseConstructPersonalCertImgss", null);
      }
    },
    beforeUploadOne(file) {

      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message({
          message: "上传图片的大小不能超过 5M!",
          type: "warning",
        });
        return false;
      }
    },
    UploadImageTwo(options) {
      try {
        let nowDate = new Date();
        let dateTime = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
        };
        let that = this;
        let client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: that.OssForm.accessKeyId,
          accessKeySecret: that.OssForm.accessKeySecret,
          stsToken: that.OssForm.token,
          bucket: "fengjingyuanlin",
          timeout: 600000,
          secure: true,
        });
        let file = options.file; // 拿到 file
        if (
            file.type == "image/png" ||
            file.type == "image/jpg" ||
            file.type == "image/jpeg"
        ) {
          let extendName = file.name.substring(file.name.lastIndexOf(".") + 1);
          let fileName = file.name.substr(0, file.name.lastIndexOf("."));
          let date = new Date().getTime();
          let fileNames = `${date}_${file.size}_${Math.round(
              Math.random() * 10000
          )}`;
          let folderName =
              "image/" +
              dateTime.year +
              "-" +
              dateTime.month +
              "-" +
              dateTime.date +
              "/" +
              fileNames +
              "." +
              extendName;

          client.put(folderName, file).then((res) => {
            if (res.res.statusCode === 200) {
              this.form.personalImg = res.url;
            } else {
              options.onError("上传失败");
            }
          });
        } else {
          this.$message({
            message: "仅支持.jpg .png .jpeg",
          });
        }
      } catch (e) {
        options.onError("上传失败");
      }
    },
    beforeUploadTwo(file) {
      //图片大小
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message({
          message: "上传图片的大小不能超过 5M!",
          type: "warning",
        });
        return false;
      }
    },
    switchLabel() {
      if (this.personIdentity == 2) {
        this.title = "设计师入驻";
        this.name = "姓名";
        this.phone = "手机号码";
        this.introduce = "自我介绍";
        this.certificates = "设计师证件";
        this.photo = "设计师照片";
        this.isNomarll = false;
        this.msgText = "请上传设计师证件";
        this.titleText =
            "园林设计师：具备生态学、园林植物与观赏园艺、风景园林规划与设计等方面服务的个人。";
      } else if (this.personIdentity == 4) {
        this.name = "施工队负责人姓名";
        this.phone = "负责人手机号码";
        this.introduce = "施工队介绍";
        this.certificates = "施工队相关证件";
        this.photo = "施工队负责人照片";
        this.title = "施工队入驻";
        this.isNomarll = false;
        this.msgText = "请上传施工队相关证件";
        this.titleText =
            "具备园林绿化工程相关篆刻、雕塑、插花工艺、石刻、打磨等特殊工艺技法服务的个人。";
      } else if (this.personIdentity == 5) {
        this.name = "培训讲师姓名";
        this.phone = "培训讲师手机号码";
        this.introduce = "培训讲师介绍";
        this.certificates = "培训讲师相关证件";
        this.photo = "培训讲师照片";
        this.title = "培训讲师入驻";
        this.isNomarll = false;
        this.msgText = "请上传培训讲师相关证件";
        this.titleText =
            "具备风景园林行业专业培训课程规划的专业培训的讲师个人。";
      } else {
        this.name = "造价师名称";
        this.phone = "手机号码";
        this.introduce = "自我介绍";
        this.certificates = "造价师证件";
        this.msgText = "请上传造价师证件";
        this.photo = "造价师照片";
        this.title = "造价师入驻";
        this.isNomarll = true;
        this.titleText =
            "熟悉园林市场材料价格，了解准确的市场价格和预算价格的风景园林行业专业工程造价服务的个人。";
      }
    },
    // 表单重置
    reset() {
      this.form = {
        baseConstructPersonalCertImgs: [],
        personIdentity: null,
        personalName: null,
        personalPhone: null,
        identityCardNo: null,
        contactEmail: null,
        detailArea: null,
        detailAddress: null,
        serviceArea: null,
        wellStyle: [],
        jobTime: null,
        jobStartTime: null,
        jobEndTime: null,
        personalIntroduction: null,
        teamPowerful: null,
        teamNum: null,
        certImg: null,
        personalImg: null,
        openBankName: null,
        openBankInfo: null,
        openAccountName: null,
        openBankAccount: null,
      };
      this.selectedOptions = [];
      this.selectedOptions1 = [];
    },
    backStep() {
      if (this.activeIndex === 2) {
        this.activeIndex--;
        this.$refs.form.clearValidate();
        this.wellstyle = this.form.wellStyle.join();
      }
    },
    nextStep(n) {
      this.$refs.form.validate((valid) => {
        // console.log(valid);
        if (valid) {
          let _this = this;
          _this.form.personIdentity = _this.$route.query.isShow;
          if (n == 1) {
            _this.form.wellStyle = null;
            nextSettleIn(_this.form).then((res) => {
              if (res.code == 200) {
                _this.next();
                _this.form.wellStyl = [];
                if (_this.wellstyle) {
                  _this.form.wellStyle = _this.wellstyle.split(",");
                } else {
                  _this.form.wellStyle = [];
                }
              } else {
                _this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
          } else {
            // console.log(this.form.jobTime);
            _this.form.jobStartTime = _this.form.jobTime[0];
            _this.form.jobEndTime = _this.form.jobTime[1];
            if (_this.form.wellStyle) {
              _this.form.wellStyle = _this.form.wellStyle.join();
            }
            // console.log(_this.form.wellStyle);
            _this.fileList.forEach((item, index) => {
              _this.form.baseConstructPersonalCertImgs.push({
                certImg: item.url,
                indexNo: index + 1,
              });
            });
            subSettleIn(_this.form).then((res) => {
              if (res.code == 200) {
                _this.next();
                _this.fileList = [];
                _this.reset();
                _this.selectedOptions = null;
                _this.selectedOptions1 = null;
              } else {
                _this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
          }
          // this.next();
        }
      });
    },
    //省市区选择后，form表单数据保存
    handleChange(arr) {
      this.form.detailArea =
          CodeToText[arr[0]] +
          "-" +
          CodeToText[arr[1]] +
          "-" +
          CodeToText[arr[2]];
    },
    handleChange1(arr) {
      if (
          CodeToText1[arr[1]] == undefined &&
          CodeToText1[arr[2]] == undefined
      ) {
        this.form.serviceArea = CodeToText1[arr[0]];
      } else if (
          CodeToText1[arr[1]] != undefined &&
          CodeToText1[arr[2]] == undefined
      ) {
        this.form.serviceArea = CodeToText1[arr[0]] + "-" + CodeToText1[arr[1]];
      } else {
        this.form.serviceArea =
            CodeToText1[arr[0]] +
            "-" +
            CodeToText1[arr[1]] +
            "-" +
            CodeToText1[arr[2]];
      }
    },
    next() {
      this.activeIndex += 1;
      if (this.activeIndex === 3) {
        this.timer2 = setInterval(() => {
          if (this.TimeNumber === 0) {
            this.TimeNumber = 3;
            this.activeIndex = 1;
            clearInterval(this.timer2);
            this.timer2 = null;
            this.$router.push({
              path: "/HomeView",
            });
          } else {
            this.TimeNumber -= 1;
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
  line-height: 38px;
}

// .avatar-uploader {
//   width: 124px;
//   height: 124px;
//   display: flex;
//   border: 1px dashed #ccc;
// }
.avatar-uploader .el-upload {
  border: 1px dashed #ccc;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  border: 1px dashed #c0ccda;
  width: 148px;
  height: 148px;
  line-height: 148px;
  border-radius: 6px;
  text-align: center;
}

.avatar-uploader-icon:hover {
  border-color: #409eff;
}

.avatar {
  width: 124px;
  height: 124px;
  display: block;
  border: 1px dashed #ccc;
}

.designSettle {
  width: 1320px;
  min-height: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding-top: 30px;

  .tishi {
    width: 1100px;
    margin: 0 auto 50px auto;
  }

  .stepBox {
    .stepTitle {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 700;
      color: #333333;
      line-height: 24px;
      margin-bottom: 10px;
      text-align: center;
    }

    .steps-box {
      user-select: none;
      width: 800px;
      height: 100px;
      padding-top: 50px;
      margin: 0 auto;
      position: relative;
      // <!-- 步骤条背景进度条 -->
      .line {
        display: block;
        margin: 0 auto;
        position: absolute;
        top: 75px;
        left: 15%;
        background: #ccc;
        width: 594px;
        height: 2px;
        z-index: 1;
        overflow: hidden;

        .plan {
          position: absolute;
          top: 0;
          left: 0;
          height: 2px;
          transition: 0.5s;
          background: #219560;
        }
      }

      .Article-steps {
        display: flex;
        justify-content: space-between;

        .step {
          z-index: 999;
          text-align: center;
          width: 200px;

          .title {
            font-size: 14px;
            color: #c0c0c0;
            margin-top: 5px;
          }

          .step-num {
            width: 30px;
            height: 30px;
            display: inline-block;
            line-height: 30px;
            background: #c0c0c0;
            text-align: center;
            border-radius: 50%;
            margin-top: 10px;

            //clip-path: polygon(50% 0, 100% 85%, 0 85%);
            color: white;
            //font-weight: bold;
            transition: 0.5s;

            .num {
              transition: 0.5s;
              display: inline-block;
            }
          }
        }
      }

      //当前所在位置样式
      .step-active {
        .step-num {
          background: #219560 !important;
          //transform: rotate(90deg);
          .num {
            //transform: rotate(-90deg);
          }
        }

        .title {
          color: #219560 !important;
        }
      }

      //全部完成样式
      .step-over {
        .plan {
          background: #219560 !important;
        }

        .step-num {
          background: #219560 !important;
        }

        .title {
          color: #219560 !important;
        }
      }
    }
  }

  .form {
    width: 60%;
    margin: 0 auto;

    .ButtonActive {
      margin: 0 auto;
      width: 138px;
      height: 38px;
      background: #219560;
      border-radius: 3px;
      font-size: 14px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .ThereBox {
    width: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .Right {
      margin-left: 10px;

      p {
        font-size: 14px;
        color: #4d4d4d;

        span:nth-child(2) {
          color: #219560;
          cursor: pointer;
          margin-left: 5px;
          display: inline-block;
        }
      }
    }
  }
}
</style>