var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"messageManagement"},[_vm._m(0),(_vm.auth == 0)?_c('div',{staticClass:"authImg"},[_c('div',{staticClass:"imgBox",on:{"click":_vm.goSettle}},[_c('img',{attrs:{"src":require("../../../assets/image/authAdministration.png"),"alt":""}})])]):_c('div',{staticClass:"authBox"},[(_vm.auditStatus == 3)?_c('div',{staticClass:"authTitle"},[_c('img',{attrs:{"src":require("../../../assets/image/authIcon.png"),"alt":""}}),_c('p',{staticClass:"titleCon"},[_vm._v("身份实名认证成功!")])]):_vm._e(),(_vm.auditStatus == 2)?_c('div',{staticClass:"authTitle2"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{attrs:{"src":require("../../../assets/image/authIcon_1.png"),"alt":""}}),_c('p',{staticClass:"titleCon2"},[_vm._v(" 身份实名认证审核失败(驳回原因："+_vm._s(_vm.isPeoCompany == "1" ? _vm.companyList.auditOpinion : _vm.peopleList.auditOpinion)+") ")])]),_c('p',{staticClass:"authBtn",on:{"click":_vm.goAuth}},[_vm._v("重新申请")])]):_vm._e(),(_vm.auditStatus == 1)?_c('div',{staticClass:"authTitle1"},[_c('img',{attrs:{"src":require("../../../assets/image/authIcon_2.png"),"alt":""}}),_c('p',{staticClass:"titleCon1"},[_vm._v("身份实名认证审核中")])]):_vm._e(),(_vm.isPeoCompany == 1)?_c('el-descriptions',{staticStyle:{"margin-bottom":"30px"},attrs:{"title":"基本信息","column":1,"border":true,"labelStyle":{
        'text-align': 'left',
        background: '#F6F7FB',
        height: '50px',
      }}},[_c('el-descriptions-item',{attrs:{"label":"认证类型","label-class-name":"my-label","content-class-name":"my-content","labelStyle":"width: 150px"}},[_vm._v(" 企业 ")]),_c('el-descriptions-item',{attrs:{"label":"企业类型","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.companyFarmat(_vm.companyList))+" ")]),_c('el-descriptions-item',{attrs:{"label":"企业名称","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.companyList.companyName)+" ")]),_c('el-descriptions-item',{attrs:{"label":"统一社会信用代码","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.companyList.socialCreditCode)+" ")]),_c('el-descriptions-item',{attrs:{"label":"营业执照原件图片","labelStyle":"width: 150px"}},[_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.companyList.businessLicense,"preview-src-list":[_vm.companyList.businessLicense]}})],1),_c('el-descriptions-item',{attrs:{"label":"法人名称","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.companyList.legalName)+" ")]),_c('el-descriptions-item',{attrs:{"label":"法人联系方式","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.companyList.legalPhone)+" ")]),_c('el-descriptions-item',{attrs:{"label":"身份证号码","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.companyList.identityCardNo)+" ")])],1):_vm._e(),(_vm.isPeoCompany == 1)?_c('el-descriptions',{attrs:{"title":"银行信息","column":1,"border":true,"labelStyle":{
        'text-align': 'left',
        background: '#F6F7FB',
        height: '50px',
        width: '200px',
      }}},[_c('el-descriptions-item',{attrs:{"label":"银行名称","label-class-name":"my-label","content-class-name":"my-content","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.companyList.openBankName)+" ")]),_c('el-descriptions-item',{attrs:{"label":"开户行","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.companyList.openBankInfo)+" ")]),_c('el-descriptions-item',{attrs:{"label":"账户名称","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.companyList.openAccountName)+" ")]),_c('el-descriptions-item',{attrs:{"label":"银行账号","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.companyList.openBankAccount)+" ")])],1):_vm._e(),(_vm.isPeoCompany == 2)?_c('el-descriptions',{staticStyle:{"margin-bottom":"30px"},attrs:{"title":"基本信息","column":1,"border":true,"labelStyle":{
        'text-align': 'left',
        background: '#F6F7FB',
        height: '50px',
      }}},[_c('el-descriptions-item',{attrs:{"label":"认证类型","label-class-name":"my-label","content-class-name":"my-content","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.getPersonIdentity(_vm.peopleList.personIdentity))+" ")]),_c('el-descriptions-item',{attrs:{"label":"设计师名称","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.personalName)+" ")]),_c('el-descriptions-item',{attrs:{"label":"手机号码","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.personalPhone)+" ")]),_c('el-descriptions-item',{attrs:{"label":"身份证号码","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.identityCardNo)+" ")]),_c('el-descriptions-item',{attrs:{"label":"联系邮箱","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.contactEmail)+" ")]),_c('el-descriptions-item',{attrs:{"label":"地址","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.detailArea)+" ")]),_c('el-descriptions-item',{attrs:{"label":"详细地址","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.detailAddress)+" ")]),_c('el-descriptions-item',{attrs:{"label":"服务区域","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.serviceArea)+" ")]),_c('el-descriptions-item',{attrs:{"label":"工作年限","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.getYearNum(_vm.peopleList.jobStartTime, _vm.peopleList.jobEndTime))+"年 ")]),_c('el-descriptions-item',{attrs:{"label":"自我介绍","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.personalIntroduction)+" ")])],1):_vm._e(),(_vm.isPeoCompany == 2)?_c('el-descriptions',{attrs:{"title":"银行信息","column":1,"border":true,"labelStyle":{
        'text-align': 'left',
        background: '#F6F7FB',
        height: '50px',
        width: '200px',
      }}},[_c('el-descriptions-item',{attrs:{"label":"银行名称","label-class-name":"my-label","content-class-name":"my-content","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.openBankName)+" ")]),_c('el-descriptions-item',{attrs:{"label":"开户行","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.openBankInfo)+" ")]),_c('el-descriptions-item',{attrs:{"label":"账户名称","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.openAccountName)+" ")]),_c('el-descriptions-item',{attrs:{"label":"银行账号","labelStyle":"width: 150px"}},[_vm._v(" "+_vm._s(_vm.peopleList.openBankAccount)+" ")])],1):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('p',{staticClass:"message"},[_vm._v("认证管理")])])}]

export { render, staticRenderFns }