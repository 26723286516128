<template>
  <div class="worksDetali">
    <div class="route">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="myrou" :to="{ path: '/HomeView' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item class="myrou" @click.native="goEvent"
          >赛事活动</el-breadcrumb-item
        >
        <el-breadcrumb-item>作品详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="workDetailBox">
      <div class="workDetail_title">
        <div class="workName">
          <p>{{ productionDetail.productName }}</p>
        </div>
        <div class="work_info">
<!--          <div class="workImg">-->
<!--            <img-->
<!--              v-if="productionDetail.personalImg"-->
<!--              :src="productionDetail.personalImg"-->
<!--              alt=""-->
<!--            />-->
<!--            <img v-else src="../../../assets/image/tx.png" alt="">-->
<!--            <div class="name">{{ productionDetail.studentName }}  </div>-->
<!--          </div>-->
          <div class="workTime">
            {{ productionDetail.createTime }}<span>发布作品</span>
            <span>作品编码：{{ productionDetail.productNo }}</span>
          </div>
        </div>
      </div>
      <div class="workDetail_info">
        <div
          class="productionImg"
          v-for="(
            item, index
          ) in productionDetail.baseRaceStudentProductionImgs"
          :key="index"
        >
          <img :src="item.procuctImg" alt="" />
        </div>
        <div class="productionInfo">
          <div>{{ productionDetail.productIntroduction }}</div>
        </div>
      </div>
      <div class="dz">
        <div class="ydz" v-if="agreeType == 1" @click="hanlddz">
          <img src="../../../assets/image/ic_dz_normat.png" alt="" />
          <p>{{ productionDetail.agreeNum }}</p>
        </div>
        <div class="wdz" v-if="agreeType == 0" @click="hanlddz">
          <img src="../../../assets/image/ic_dz_pressed.png" alt="" />
          <p>{{ productionDetail.agreeNum }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductionDetail, agree } from "@/request/activity";
export default {
  data() {
    return {
      id: null,
      productionDetail: [],
      agreeType: 1,
      dzValue: 25,
      bizId: null,
      studentId: null,
    };
  },
  watch: {},
  activated() {
    this.id = this.$route.query.raceId;
    this.getProductionDetailList();
  },
  created() {},
  methods: {
    getProductionDetailList() {
      const id = this.$route.query.id;
      getProductionDetail(id).then((res) => {
        this.productionDetail = res.data;
        this.agreeType = res.data.agreeType;
        this.agreePersonId = res.data.studentId;
        this.bizId = res.data.id;
      });
    },
    goEvent() {
      this.$router.push({
        name: "eventsDetail",
        query: { id: this.id ? this.id : this.$route.query.raceId },
      });
      this.$router.go(0);
    },
    hanlddz() {
      const data = {
        bizType: 1,
        agreePersonId: this.agreePersonId,
        bizId: this.bizId,
        agreeType: this.agreeType,
      };
      agree(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getProductionDetailList();
        }
      });
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
.myrou /deep/ .el-breadcrumb__inner {
  font-weight: 400;
}
</style>
<style lang="scss" scoped>
.worksDetali {
  width: 1320px;
  margin: 0 auto;
  .route {
    width: 100%;
    height: 30px;
    padding-top: 20px;
  }
  .workDetailBox {
    display: flex;
    flex-direction: column;
    .workDetail_title {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      .workName {
        text-align: center;

        p {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 60px;
        }
      }
      .work_info {
        display: flex;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
        .workImg {
          display: flex;
          height: 26px;
          margin-left: 20px;
          img {
            width: 26px;
            height: 100%;
          }
          .name {
            margin-left: 10px;
            margin-right: 20px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 26px;
          }
        }
        .workTime {
          height: 26px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a3a8a6;
          line-height: 26px;
          span {
            margin-left: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a3a8a6;
            line-height: 26px;
          }
        }
      }
    }
    .workDetail_info {
      padding-top: 38px;
      margin: 0 auto;
      .productionImg {
        padding: 20px 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .productionInfo {
        width: 100%;
        word-break: break-all;
        white-space: pre-wrap;
        margin: 0 auto;
        padding: 30px 0;
      }
    }
    .dz {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .ydz {
        cursor: pointer;
        width: 130px;
        height: 70px;
        background: #1cac6a;
        border-radius: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 20px;
          height: 22px;
          cursor: pointer;
        }
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 23px;
        }
      }
      .wdz {
        cursor: pointer;
        width: 130px;
        height: 70px;
        background: #fff;
        border: 1px solid #1cac6a;
        border-radius: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 20px;
          height: 22px;
          cursor: pointer;
        }
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1cac6a;
          line-height: 23px;
        }
      }
    }
  }
}
</style>