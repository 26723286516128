<template>
  <div id="app" name="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>

  </div>
</template>

<script>

import * as sysTool from "@/utils/mobile-detect";
export default {

  data() {
    return {

    };
  },
  created() {

    this.os = sysTool.GetOs();
    if(this.os == 'Android' || this.os == 'iPhone' || this.os == 'SymbianOS' || this.os == 'Windows Phone' || this.os == 'iPad' || this.os == 'iPod'){
      this.$router.push({
        path:"/Mobile",replace:true
      })
    }else {
      if(this.$route.path === '/'){
        this.$router.push({
          path:"/HomeView"
        })
      }
      this.$nextTick(() => {
        // 禁用右键
        document.oncontextmenu = new Function('event.returnValue=false')
        // 禁用选择
        document.onselectstart = new Function('event.returnValue=false')
        // 禁止f12
        document.οnkeydοwn = new Function('event.returnValue=false')

      })

      // 上面的禁止f12那段代码没有生效，但是加了下面这段就能生效。
      document.onkeydown = function (e) {
        if (e && e.keyCode === 123) {
          e.returnValue = false
          // e.keyCode = 0   //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错
          return false
        }
        if (window.event && window.event.keyCode == 123) {
          return false;
          //禁用ctrl+shift+i,
        } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
          return false;
          //屏蔽Shift+F10
        } else if (window.event.shiftKey && window.event.keyCode == 121) {
          return false;
        }
      }

    }





  }
};
</script>

<style>

</style>
