<template>
  <div class="costEngineer">
    <div class="navBar">
      <Breadcrumb></Breadcrumb>
    </div>
    <div class="costEngineer_bigBox">
      <div style="display: flex; flex-direction: column; width: 75%">
        <div class="classificationFilter classificationFilter_bom">
          <span class="serverTit" style="border-right: 0">服务区域</span>
          <ul class="serversType">
            <li
              :class="IndexActiveTwo === item.value ? 'txtActive' : 'txt'"
              @click="GoTutorialLevActive(item)"
              v-for="item in filterlist"
              :key="item.value"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <!-- <div class="sort">
          <ul class="serversType">
            <li
              :class="IndexActiveTwo === index ? 'txtActive' : 'txt'"
              @click="sort(item.id)"
              v-for="(item, index) in sortList"
              :key="index"
            >
              {{ item.con }}
            </li>
          </ul>
        </div> -->
        <div class="costEngineSon" v-if="costEngineeList.length != 0">
          <div
            class="costEngineer_box"
            v-for="(item, index) in costEngineeList"
            :key="index"
          >
            <div class="costEngineer_top" @click="costEngineerDetails(item)">
              <img :src="item.personalImg" alt="" />
            </div>
            <div class="costEngineer_center">
              <div class="costEngineerName">
                <p>{{ item.personalName }}</p>
              </div>
              <p class="consultNowOne">
                <span>好评度：</span>
                <el-rate
                  v-model="value"
                  disabled
                  :colors="[
                    '#F6461D',
                    '#F6461D',
                    '#F6461D',
                    '#F6461D',
                    '#F6461D',
                  ]"
                  text-color="#F6461D"
                  score-template="{value}"
                >
                </el-rate>
              </p>
              <div class="center_cen">
                <div class="workExperience">
                  工作经验
                  <span>{{ item.year + year }}</span>
                </div>
                <div class="case">
                  造价案例
                  <span>{{ item.caseNums }}</span>
                </div>
              </div>

              <div class="cost_info">
                <p>
                  {{ item.personalIntroduction }}
                </p>
              </div>
            </div>
            <div class="costEngineer_bottom">
              <div class="consultNow">
                <p @click="costEngineerDetails(item)">立即咨询</p>
              </div>
            </div>
          </div>
        </div>
        <div class="noData" v-else>
          <p>暂无数据</p>
        </div>
        <Pagination
          v-show="Total > 0"
          :total="Total"
          style="text-align: center; margin: 30px auto"
          :page.sync="form.pageNum"
          :limit.sync="form.pageSize"
          @pagination="PageApi"
        />
      </div>
      <div class="guanggao">
        <div class="advertF">
          <wantQuote />
        </div>
        <div class="advertT">
          <advertisementLeft :wideUrl="wideUrl" @click.native="goRoute" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components//Breadcrumb/Breadcrumb.vue";
import Pagination from "@/components/Pagination/Pagination";
import advertisementLeft from "@/components/advertisement/advertisementLeft.vue";
import wantQuote from "@/components/wantQuote/wantQuote.vue";
import { getFindDesign } from "@/request/findDesign";
import { getBanner, getSeatch } from "@/request/home/home";
export default {
  name: "costEngineer",
  components: { Breadcrumb, Pagination, advertisementLeft, wantQuote },
  data() {
    return {
      year: null,
      value: 5,
      sortIndex: 1,
      IndexActiveOne: 0,
      IndexActiveTwo: 0,
      wideUrl: null,
      form: {
        pageNum: 1,
        pageSize: 5,
        pcSearch: 1,
        type: null,
        searchInfo: null,
        personIdentity: null,
        serviceArea: sessionStorage.getItem("cityTxt"),
      },
      Total: 0,
      sortList: [
        {
          id: 1,
          con: "综合排序",
        },
        {
          id: 2,
          con: "最新",
        },
      ],
      subject: [
        {
          id: 1,
          title: "全部",
        },
        {
          id: 2,
          title: "一级造价师",
        },
        {
          id: 3,
          title: "二级造价师",
        },
      ],
      filterlist: [],
      costEngineeList: [],
      cityTxt: null,
      url: null,
    };
  },
  activated() {
    this.form.personIdentity = this.$route.query.id;
    if (this.$route.query.type) {
      this.form.type = this.$route.query.type;
      this.form.searchInfo = this.$route.query.searchInfo;
      this.getSeatchList();
    } else {
      this.PageApi();
    }
  },
  created() {
    this.cityTxt = sessionStorage.getItem("cityTxt");
    this.getBBannerList();
    this.filterlist = JSON.parse(sessionStorage.getItem("cityList"));
    this.filterlist = this.filterlist.cityList;
    this.filterlist.unshift({
      label: "全部",
      value: 0,
    });
  },
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 6 }).then((res) => {
        if (res.data[0]) {
          this.wideUrl = res.data[0].filePath;
          this.url = res.data[0].routeContent;
        }
      });
    },
    goRoute() {
      if(this.url){
      window.open(this.url,"_blank");

      }else{
        return 
      }
    },
    getSeatchList() {
      getSeatch(this.form).then((res) => {
        if (res.code == 200) {
          this.costEngineeList = res.rows;
          this.Total = res.total;
          res.rows.forEach((item, index) => {
            item.year = null;
            item.year = this.getYearNum(item.jobStartTime, item.jobEndTime);
          });
        }
      });
    },
    PageApi() {
      this.form.personIdentity = this.$route.query.id;
      getFindDesign(this.form).then((res) => {
        res.rows.forEach((item, index) => {
          item.year = null;
          item.year = this.getYearNum(item.jobStartTime, item.jobEndTime);
        });
        this.costEngineeList = res.rows;
        this.Total = res.total;
      });
    },
    getYearNum(startTime, endTime) {
      var timestr = startTime.replace(/-/g, "/");
      var startTime1 = new Date(timestr);
      var timestr1 = endTime.replace(/-/g, "/");
      var endTime1 = new Date(timestr1);
      var y = endTime1.getFullYear();
      var y1 = startTime1.getFullYear();
      var m = endTime1.getMonth() + 1;
      var m1 = startTime1.getMonth() + 1;
      if (y == y1) {
        this.year = "年";
        return 1;
      } else {
        this.year = "年";
        return y - y1;
      }
    },
    costEngineerDetails(item) {
      this.$router.push({
        name: "costEngineerDetails",
        query: { id: item.id },
      });
      const token = sessionStorage.getItem("token");
    },
    sort(e) {
      this.sortIndex = e;
      this.form.pcSearch = this.sortIndex;
      if (this.$route.query.type) {
        this.form.type = this.$route.query.type;
        this.form.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    GoTutorialLevActive(e) {
      this.IndexActiveTwo = e.value;
      if (e.label == "全部") {
        this.form.serviceArea = sessionStorage.getItem("cityTxt");
      } else {
        this.form.serviceArea = this.cityTxt + "-" + e.label;
      }
      if (this.$route.query.type) {
        this.form.type = this.$route.query.type;
        this.form.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .btn-prev {
  padding: 0 5px !important;
}
::v-deep .btn-next {
  padding: 0 5px !important;
}
</style>
<style lang="scss">
.el-rate {
  height: 42px;
  .el-rate__item {
    height: 42px;
    .el-rate__icon {
      font-size: 25px;
      line-height: 42px;
      margin-right: 0;
    }
    .el-rate__decimal {
      font-size: 25px;
      line-height: 42px;
      margin-right: 0;
    }
  }
}
</style>
<style scoped lang="scss">
.costEngineer {
  width: 1320px;
  margin: 0 auto;
  .navBar {
    padding: 20px 0 20px 0;
    width: 100%;
  }
  .costEngineer_bigBox {
    display: flex;
    justify-content: space-between;
    .classificationFilter {
      width: 100%;
      display: flex;
      .serverTit {
        display: inline-block;
        width: 92px;
        min-height: 44px;
        background: #f7f7f7;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #4a4d4b;
        padding-top: 20px;
        text-align: center;
      }

      .serversType {
        width: 100%;
        min-height: 44px;
        background: #ffffff;
        padding-left: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
          cursor: pointer;
        }

        .txt {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          cursor: pointer;
          padding: 20px;
          color: #636665;
        }

        .txtActive {
          background: #188252;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          cursor: pointer;
          color: #ffffff;
          padding: 5px 20px;

          border-radius: 20px;
        }

        .txt:hover {
          color: #188252;
        }
      }
    }
    .classificationFilter_bom {
      margin-bottom: 20px;
    }
    .sort {
      width: 100%;
      height: 36px;
      background: #f5f5f5;
      border: 1px solid #ebebeb;
      .serversType {
        width: 50%;
        display: flex;
        padding: 0 20px;
        li {
          cursor: pointer;
          margin-right: 30px;
        }

        .txt {
          line-height: 36px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #808080;
          cursor: pointer;
        }

        .txtActive {
          line-height: 36px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #188252;
          cursor: pointer;
        }

        .txt:hover {
          color: #188252;
        }
      }
    }
    .costEngineSon {
      width: 96%;
      padding: 20px;
      display: flex;
      background-color: #FFFFFF;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 20px;
      .costEngineer_box {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-right: 20px;
        .costEngineer_top {
          width: 20%;
          height: 120px;
          margin-right: 20px;
          cursor: pointer;
          img {
            width: 100px;
            height: 100%;
          }
        }
        .costEngineer_center {
          width: 130%;
          // margin-left: 10px;
          .costEngineerName {
            height: 30px;
            margin-bottom: 0px;
            p {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 700;
              line-height: 32px;
              color: #4d4d4d;
            }
          }
          .center_cen {
            height: 32px;
            display: flex;
            .costEngCertificate {
              width: 300px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              line-height: 32px;
              font-weight: 400;
              color: #999999;

              span {
                margin-left: 10px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #4d4d4d;
              }
            }
            .workExperience {
              width: 200px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              line-height: 32px;
              font-weight: 400;
              color: #999999;
              span {
                margin-left: 10px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #4d4d4d;
              }
            }
            .case {
              width: 200px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              line-height: 32px;
              font-weight: 400;
              color: #999999;
              span {
                margin-left: 10px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #4d4d4d;
              }
            }
          }
          .consultNowOne {
            height: 42px;
            display: flex;
            line-height: 42px;
            font-size: 14px;

            span:nth-child(1) {
              color: #999999;
            }
            span:nth-child(2) {
              color: #f66a1d;
            }
          }
          .cost_info {
            height: 30px;
            P {
              color: #808080;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .costEngineer_bottom {
          margin-left: 13%;
          display: flex;
          justify-content: center;
          align-items: center;
          .consultNow {
            width: 88px;
            height: 34px;
            background: linear-gradient(-70deg, #f6461d, #f66a1d);
            p {
              cursor: pointer;
              text-align: center;
              color: #ffffff;
              line-height: 34px;
            }
          }
        }
      }
    }
    .noData {
      width: 100%;
      height: 300px;
      margin: 0 auto;
      p {
        line-height: 300px;
        text-align: center;
        color: #999999;
        font-size: 24px;
      }
    }
  }
  .guanggao {
    width: 25%;
    display: flex;
    flex-direction: column;
    .advertF {
      margin: 0 0 20px 40px;
    }
    .advertT {
      margin: 0 0 20px 40px;
    }
  }
}
</style>
