import request from '@/request/request'

//园林公司列表
export function getGardenArchitectureList(data){
    return request({
        url:"/api/web/company/list",
        method:"get",
        params:data
    })
}
//园林公司详情
export function getGardenArchitectureDetail(id){
    return request({
        url:"/api/web/company/"+id,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        method:"get",
    })
}