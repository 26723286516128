<template>
  <div class="wantQuote">
    <div class="wantQuoteTitleBox">
      <img src="../../assets/image/wantQuote.png" alt="" />
      <p>免费获取报价</p>
    </div>
    <div class="QuotationPageBoxTwo">
      <el-form
        style="
          width: 260px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto 20px;
        "
        ref="form"
        :model="form"
        :rules="rules"
      >
        <el-form-item
          prop="subscribePersonName"
          style="width: 220px; margin: 10px 20px"
        >
          <el-input
            onkeyup="value=value.replace(/[\d]/g,'') "
            onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
            style="width: 220px"
            placeholder="请输入您的姓名"
            v-model="form.subscribePersonName"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="area"
          style="width: 220px; margin: 10px 20px; position: relative"
        >
          <el-input
            style="width: 220px"
            placeholder="请输入您的面积"
            v-model="form.area"
            maxlength="10"
            onkeyup="this.value=this.value.replace(/[^[0-9]/g,'').replace(/^0[0-9]*/g, '')"
            onafterpaste="this.value=this.value.replace(/[^[0-9]/g,'').replace(/^0[0-9]*/g, '')"
          ></el-input>
          <p class="pingmi" style="position: absolute; top: 0px; right: 10px">
            ㎡
          </p>
        </el-form-item>

        <el-form-item
          prop="subscribePhone"
          style="width: 220px; margin: 10px 20px"
        >
          <el-input
            style="width: 220px"
            placeholder="请输入您的联系电话"
            v-model="form.subscribePhone"
            maxlength="11"
            type="number"
            oninput="if(value.length > 11) value = value.slice(0,11)"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn" style="margin: 0 auto">
        <el-button @click="getQuotation">获取报价</el-button>
      </div>
    </div>
    <el-dialog
      width="705px"
      :show-close="false"
      :visible.sync="dialogTableVisible"
    >
      <div class="prop">
        <div class="windowLeft">
          <div class="title">收费标椎</div>
          <div class="fileOne">
            <div class="fileImg">
              <img src="../../assets/image/baojia01.png" alt="" />
            </div>
            <div class="fileList">
              <div class="fileName">陕西省工程造价咨询服务收费标准</div>
              <div class="bon">
                <el-button
                  type="success"
                  size="mini"
                  plain
                  @click="tollBeaconOne"
                  >在线预览</el-button
                >
              </div>
            </div>
          </div>

          <div class="fileTow">
            <div class="fileImg">
              <img src="../../assets/image/baojia01.png" alt="" />
            </div>
            <div class="fileList">
              <div class="fileName">景观设计收费标准</div>
              <div class="bon">
                <el-button
                  type="success"
                  size="mini"
                  plain
                  @click="tollBeaconTwo"
                  >在线预览</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="bor"></div>
        <div class="windowRight">
          <div class="titile1">添加风景园林云平台客服</div>
          <div class="ljie">了解更多园林设计细节</div>
          <div class="codeImg">
            <img src="../../assets/image/baojia03.png" alt="" />
          </div>
        </div>
        <div class="close" @click="close"><i class="el-icon-close"></i></div>
      </div>
    </el-dialog>
    <!-- <div class="wantQuoteInput" style="background-color: #ffffff">
      <el-button>获取报价</el-button>
    </div> -->
  </div>
</template>

<script>
import { getQuotation } from "@/request/home/home";
export default {
  name: "wantQuote",
  data() {
    let checkArea = (rule, value, callback) => {
      if (!value) {
        callback(new Error("面积不能为空"));
        return;
      }
      if (/^[1-9]{1}/.test(value) && /[.]/.test(value)) {
        callback(new Error("面积只能为整数"));
        return;
      }
      callback();
    };

    return {
      value: "",
      img: "0",
      dialogTableVisible: false,
      form: {
        subscribePersonName: null,
        area: null,
        subscribePhone: null,
        offerSource: 1,
      },
      rules: {
        subscribePersonName: [
          {
            min: 2,
            max: 30,
            required: true,
            message: "请输入2-30位的姓名",
            trigger: "change",
          },
          {
            validator: function (rule, value, callback) {
              var regex = /^[a-zA-Z\u4e00-\u9fa5]+$/;
              if (!regex.test(value)) {
                callback(new Error("请输入正确格式的姓名"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        area: [{ required: true, validator: checkArea, trigger: "change" }],
        subscribePhone: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    getQuotation() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (
            this.form.subscribePersonName &&
            this.form.area &&
            this.form.subscribePhone
          ) {
            getQuotation(this.form).then((res) => {
              if (res.code == 200) {
                this.dialogTableVisible = true;
                this.form.subscribePersonName = null;
                this.form.area = null;
                this.form.subscribePhone = null;
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
                this.dialogTableVisible = false;
              }
            });
          } else {
            this.$message({
              message: "请填写您的报价信息",
              type: "warning",
            });
          }
        }
      });
    },
    tollBeaconOne() {
      window.open(
        "http://fengjingyuanlin.oss-cn-beijing.aliyuncs.com/image/2022-7-26/1658799340878_%E9%99%95%E8%A5%BF%E7%9C%81%E5%B7%A5%E7%A8%8B%E9%80%A0%E4%BB%B7%E5%92%A8%E8%AF%A2%E6%9C%8D%E5%8A%A1%E6%94%B6%E8%B4%B9%E6%A0%87%E5%87%86.pdf"
      );
    },
    tollBeaconTwo() {
      window.open(
        "http://fengjingyuanlin.oss-cn-beijing.aliyuncs.com/image/2022-7-26/1658799540855_%E6%99%AF%E8%A7%82%E8%AE%BE%E8%AE%A1%E6%94%B6%E8%B4%B9%E6%A0%87%E5%87%86.pdf"
      );
    },
    close() {
      this.dialogTableVisible = false;
    },
    imgBtn() {
      this.img = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
  line-height: 38px;
}
.wantQuote {
  width: 300px;
  height: 365px;
  background-color: #FFFFFF;
  .el-input-group__append,
  .el-input-group__prepend {
    border: 0;
  }
  .wantQuoteTitleBox {
    text-align: center;
    margin-bottom: 30px;
    img {
      width: 120px;
      margin-top: 30px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #188252;
    }
  }
  .QuotationPageBoxTwo {
    width: 100%;
    height: 250px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    .PageBoxTwoInput {
      width: 260px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        width: 40px;
        margin-left: 10px;
      }
      .el-input__inner {
        border: 0;
      }
    }
    .el-button {
      width: 130px;
      height: 40px;
      background: #f66a1d;
      border-radius: 0;
      border: 1px solid #f66a1d;
      color: #ffffff;
    }
  }
  .el-dialog__header {
    padding: 0 20px 10px;
  }
  .el-dialog__body {
    padding: 0 20px;
  }
  .prop {
    width: 645px;
    height: 212px;
    display: flex;
    align-items: center;
    padding: 0 30px 30px;
    .windowLeft {
      display: flex;
      flex-direction: column;

      margin-right: 20px;
      .title {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-bottom: 20px;
      }
      .fileOne {
        width: 276px;
        height: 40px;
        background: #f2f5fa;
        border-radius: 10px;
        padding: 24px;
        display: flex;
        margin-bottom: 20px;
        .fileImg {
          width: 32px;
          height: 32px;
          line-height: 36px;
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .fileList {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .fileName {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
          }
          .bon {
            display: flex;
          }
          .el-button--success.is-plain:focus,
          .el-button--success.is-plain:hover {
            background-color: #3f926b !important;
            color: #fff !important;
          }
          .el-button--success.is-plain {
            border-color: #3f926b !important;
            color: #3f926b !important;
          }
        }
      }

      .fileTow {
        width: 276px;
        height: 40px;
        background: #f2f5fa;
        border-radius: 10px;
        padding: 24px;
        display: flex;
        .fileImg {
          width: 32px;
          height: 32px;
          line-height: 36px;
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .fileList {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .fileName {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
          }
          .bon {
            display: flex;
          }
          .el-button--success.is-plain:focus,
          .el-button--success.is-plain:hover {
            background-color: #3f926b !important;
            color: #fff !important;
          }
          .el-button--success.is-plain {
            border-color: #3f926b !important;
            color: #3f926b !important;
          }
        }
      }
    }

    .bor {
      width: 1px;
      height: 244px;
      background: #f0f0f0;
    }
    .windowRight {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .titile1 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
      .ljie {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #f66a1d;
        line-height: 28px;
        margin-bottom: 20px;
      }
    }
    .close {
      cursor: pointer;
      position: absolute;
      right: -40px;
      top: 5px;
      width: 30px;
      height: 30px;
      border: 1px solid #bdbdbd;
      border-radius: 50%;
      text-align: center;
      i {
        color: #f2f2f2;
        line-height: 32px;
        font-size: 24px;
      }
    }
  }
}
</style>
