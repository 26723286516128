<template>
  <div class="EventsDetail">
    <div class="EventsImg">
      <img :src="eventDetailsList.bannerImg" alt=""/>
    </div>

    <div class="tabs">
      <div class="tab_box">
        <el-tabs
            v-model="activeName"
            type="none"
            @tab-click="handleClick"
            :before-leave="beforeLeave"
        >
          <el-tab-pane label="赛事详情" name="1"
          >
            <MatchDetailPage
            />
          </el-tab-pane>
          <el-tab-pane label="获奖公示" name="2">
            <AwardAnnoun :stageName="eventDetailsList.stageName"/>
          </el-tab-pane>
          <el-tab-pane label="上传作品" v-if="eventDetailsList.raceStatus!=2&&eventDetailsList.stageName==1" name="3">
            <UploadWarks/>
          </el-tab-pane>
          <el-tab-pane label="全部作品" name="4">
            <Allworks/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components//Breadcrumb/Breadcrumb.vue";
import MatchDetailPage from "./matchDetailPage/matchDetailPage.vue";
import UploadWarks from "./matchDetailPage/uploadWarks.vue";
import Allworks from "./matchDetailPage/allworks.vue";
import AwardAnnoun from "./matchDetailPage/awardAnnoun.vue";
import {getActivityDetail} from "@/request/activity";
import {getRaceStudentInfo} from "@/request/activity";

export default {
  name: "EventsDetail",
  components: {
    Breadcrumb,
    MatchDetailPage,
    UploadWarks,
    Allworks,
    AwardAnnoun,
  },
  data() {
    return {
      id: null,
      activeName: "1",
      oldActiveName: "",
      sortIndex: 1,
      eventDetailsList: [],
      formInline: {
        page: 1,
        Size: 10,
      },
      Total: 999,
    };
  },
  activated() {
    this.getActivityDetails();
    if (sessionStorage.getItem("activeName")) {
      this.activeName = sessionStorage.getItem("activeName");
      sessionStorage.removeItem("activeName");
    } else {
      this.activeName = "1";
    }
    this.getAuth()
  },
  methods: {
    getAuth() {
      const userId = sessionStorage.getItem("userId");
      getRaceStudentInfo(userId).then((res) => {
        if (res.data.flag == 1) {
          let StudentAuth = ''
          if (res.data.baseRaceStudentAuth.isAuth == 0) {
            StudentAuth = 2
          }else {
            StudentAuth = res.data.baseRaceStudentAuth.isAuth
          }
          sessionStorage.setItem("StudentAuth", StudentAuth);
          if (res.data.baseRaceStudentAuth.id) {
            sessionStorage.setItem("studentId", res.data.baseRaceStudentAuth.id);
          }
        } else {
          this.$message({
            message: "请先进行学生认证",
            type: "warning",
          });
          sessionStorage.setItem("StudentAuth", 0);
        }
      });
    },
    getActivityDetails() {
      this.id = this.$route.query.id;
      getActivityDetail(this.id).then((res) => {
        this.eventDetailsList = res.data;
      });
    },
    PageApi(parm) {
    },
    GoServersTypeActive(e) {
      this.IndexActiveOne = e;
    },
    GoTutorialLevActive(e) {
      this.IndexActiveTwo = e;
    },
    sort(e) {
      this.sortIndex = e;
    },
    beforeLeave(activeName, oldActiveName) {
      if (activeName == 3 || activeName == 4) {
        const token = sessionStorage.getItem("token");
        if (token) {
          this.activeName = activeName;
        } else {
          this.$router.push({path: "/HomeView"});
          this.$message({
            message: "您还没有登录,请先登录",
            type: "success",
          });
        }
        if (activeName == "3") {
          this.getAuth();
        }
        if (activeName == 3 && this.eventDetailsList.raceStatus == 2) {
          this.$message({
            message: "当前活动已结束",
            type: "success",
          });
          this.activeName = 1
        }
      } else {
        this.activeName = activeName;
      }
    },
    handleClick(index) {
      console.log(index)
      this.currentIndex = index;
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}

::v-deep .el-tabs__header {
  margin: 0;
}

::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}

::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}

.el-tabs__nav-wrap.is-scrollable {
  padding: 0;
}
</style>
<style scoped lang="scss">
::v-deep .btn-prev {
  padding: 0 5px !important;
}

::v-deep .btn-next {
  padding: 0 5px !important;
}

.EventsDetail {
  width: 100%;
  margin: 0 auto;

  .EventsImg {
    width: 100%;
    height: 480px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tabs {
    width: 100%;

    .tab_box {
      margin: 0 auto;

      ::v-deep .el-tabs__header {
        width: 100%;
        height: 70px;
        background: #f5f5f5;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
        margin: 0 auto;
        line-height: 70px;
        text-align: center;
        // margin-bottom: 30px;
      }

      ::v-deep .el-tabs__content {
        background-color: #f5f7f6;

        .el-tab-pane {
          width: 100%;
          margin: 0 auto;
        }
      }

      ::v-deep .el-tabs__nav {
        margin: 0 auto;
        float: none;
      }

      ::v-deep .el-tabs__nav-wrap::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0px;
        background-color: #e4e7ed;
        z-index: 1;
      }

      ::v-deep .el-tabs__nav-prev {
        display: none;
      }

      ::v-deep .el-tabs__nav-next {
        display: none;
      }

      ::v-deep .el-tabs__item {
        text-align: center;
        height: 70px;
        padding: 0;
        line-height: 70px;
        width: 240px;
        color: #3d3d3d;
        background-color: #f5f5f5;
        font-size: 16px;
        margin: 0 58px;
      }

      ::v-deep .is-active {
        text-align: center;
        height: 70px;
        line-height: 70px;
        width: 240px;
        color: #fff;
        background-color: #219560;
      }
    }
  }
}
</style>
