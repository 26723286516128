<template>
  <div class="Events">
    <div class="navBar">
      <Breadcrumb></Breadcrumb>
    </div>
    <div class="eventsList" v-if="eventsList.length != 0">
      <div class="events_box" v-for="(item, index) in eventsList" :key="index">
        <div class="match_img" @click="eventsDetail(item)">
          <img :src="item.coverImg" alt=""/>
          <p
              style="
              position: absolute;
              top: 20px;
              right: 20px;
              background-color: #f66a1d;
              border-radius: 20px;
              width: 126px;
              height: 32px;

              line-height: 32px;
              color: #4a4d4b;
              text-align: center;
              color: #fff;
            "
          >
            <span v-if="item.raceStatus == 0">阶段：未开始</span>
            <span v-if="item.raceStatus != 0 && item.raceStatus != 2">阶段：{{ item.currentStage }}</span>
            <span v-if="item.raceStatus == 2">阶段：已结束</span>


          </p>
        </div>
        <div class="match_infoBox" @click="eventsDetail(item)">
          <div class="match_info">
            <div class="matchName">
              {{ item.raceName }}
            </div>
            <div class="matchTime">
              <p>比赛时间</p>
              <span>{{ item.startTime + " ~ " + item.endTime }}</span>
            </div>
          </div>
          <div
              class="info_box"
              :class="item.matchStatus == 3 ? 'black' : 'info_box'"
          >
            <p :class="item.matchStatus == 3 ? 'black' : 'suatus'">
              {{ matchStatus(item.raceStatus) }}
            </p>
          </div>
        </div>
        <div class="match_btnBox" @click="eventsDetail(item)">
          <div class="match_peo">
<!--            <p>{{ item.studentNum }}</p>-->
<!--            <span>人已参与</span>-->
          </div>
          <div class="match_btn">
            <p @click="eventsDetail(item)">
              查看详情 <i class="el-icon-arrow-right"></i>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="noData" v-else>
      <p class="noText">暂无赛事活动信息</p>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components//Breadcrumb/Breadcrumb.vue";
import Pagination from "@/components/Pagination/Pagination";
import {getDicts} from "@/request/dict/data";
import {selectDictLabel, selectDictLabels} from "@/utils/weihua";
import {getActivityList} from "@/request/activity";

export default {
  name: "Events",
  components: {Breadcrumb, Pagination},
  data() {
    return {
      eventsList: [],
      eventStatusList: [],
      raceScheduleList: [],
    };
  },
  created() {
    this.getList();
    getDicts("msk_sys_race_status").then((res) => {
      this.eventStatusList = res.data;
    });
    getDicts("msk_sys_schedule_stage").then((res) => {
      this.raceScheduleList = res.data;
    });
  },
  methods: {
    getList() {
      getActivityList().then((res) => {
        if (res.code === 200) {
          let currentTime = new Date();
          res.rows.forEach(item => {
            item.currentStage = null
            if (item.raceStatus != 0 && item.raceStatus != 2) {
              let targetTimeOne = new Date(item.baseRaceStages[0].setStartTime);
              if (currentTime.getTime() >= targetTimeOne.getTime()) {
                item.currentStage = '赛事投稿'
              }
              let targetTimeTwo = new Date(item.baseRaceStages[1].setStartTime);
              if (currentTime.getTime() >= targetTimeTwo.getTime()) {
                item.currentStage = '赛事评审'
              }
              let targetTimeThere = new Date(item.baseRaceStages[2].setStartTime);
              if (currentTime.getTime() >= targetTimeThere.getTime()) {
                item.currentStage = '获奖公示'
              }

              if(currentTime.getTime() <= targetTimeOne.getTime() ){
                item.currentStage = '进行中'
              }
            }
          })
          console.log(res.rows);







          this.eventsList = res.rows;
        }
      });
    },
    famterRaceSchedule(value) {
      return selectDictLabel(this.raceScheduleList, value);
    },
    matchStatus(value) {
      return selectDictLabel(this.eventStatusList, value);
    },
    eventsDetail(e) {
      const id = e.id;
      this.$router.push({name: "eventsDetail", query: {id}});
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}

::v-deep .el-tabs__header {
  margin: 0;
}

::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}

::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.noData {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  .noText {
    color: #999;
    font-size: 30px;
  }
}

.Events {
  width: 100%;
  background-color: #fafafa;

  .navBar {
    width: 1320px;
    margin: 0 auto;
    padding: 20px 0;
  }

  .eventsList {
    width: 1320px;
    min-height: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .events_box {
      width: 48%;
      background-color: #fff;
      margin-bottom: 40px;

      .match_img {
        cursor: pointer;
        width: 100%;
        height: 328px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .match_infoBox {
        display: flex;
        justify-content: space-between;
        padding: 20px 10px;
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;

        .match_info {
          .matchName {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #4d4d4d;
            line-height: 23px;
            margin-bottom: 10px;
            cursor: pointer;
          }

          .matchTime {
            display: flex;

            P {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #a3a8a6;
              line-height: 23px;
              cursor: pointer;
              margin-right: 10px;
            }

            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #4a4d4b;
              line-height: 23px;
            }
          }
        }

        .black {
          width: 81px;
          height: 32px;
          background: #ededed;
          border-radius: 16px;

          .black {
            margin-bottom: 5px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 32px;
          }
        }

        .info_box {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .suatus {
            margin-bottom: 5px;
            width: 81px;
            text-align: center;
            background: #fff4df;
            border-radius: 16px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #eca419;
            line-height: 32px;
            cursor: pointer;
          }
        }
      }

      .match_btnBox {
        height: 32px;
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;
        cursor: pointer;

        .match_peo {
          display: flex;

          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a3a8a6;
            line-height: 32px;
          }

          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a3a8a6;
            line-height: 32px;
          }
        }

        .match_btn {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4d4d4d;
          line-height: 32px;

          p {
            cursor: pointer;

            i {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #4d4d4d;
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}
</style>