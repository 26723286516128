<template>
  <div class="designerDetails">
    <div class="route">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="myrou" :to="{ path: '/HomeView' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item class="myrou" @click.native="findDesign"
          >找设计</el-breadcrumb-item
        >
        <el-breadcrumb-item>设计师详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="bigBox">
      <div class="designer_bigBox">
        <div class="designerDetails_top">
          <div class="designerImg">
            <img :src="designDetail.personalImg" alt="设计师" />
          </div>
          <div class="designerTxt">
            <div class="txt_top">
              <div class="txt_left">
                <div class="txt_title">
                  <div style="margin-bottom: 10px">
                    <div>
                      <p>{{ designDetail.personalName }}</p>
                      <el-rate
                        v-model="value"
                        disabled
                        :colors="[
                          '#F6461D',
                          '#F6461D',
                          '#F6461D',
                          '#F6461D',
                          '#F6461D',
                        ]"
                        text-color="#F6461D"
                        score-template="{value}"
                      >
                      </el-rate>
                    </div>
                  </div>
                  <div class="pingfen"></div>
                </div>
                <div class="txt_style">
                  <p>设计级别<span> 高级设计师</span></p>
                  <p>
                    设计案例<span> {{ designDetail.caseNums + "套" }}</span>
                  </p>
                </div>

                <div class="txt_style">
                  <p>
                    擅长风格<span>{{
                      getWellStyle(designDetail.wellStyle)
                    }}</span>
                  </p>
                  <p v-if="designDetail.year!=null">
                    从业年限<span>{{ designDetail.year  + year}}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="txt_bottom">
              <p>个人简介</p>
              <p>
                {{ designDetail.personalIntroduction }}
              </p>
            </div>
            <div class="txt_right">
              <div class="followDesigner" @click="follow(designDetail)">
                <p>{{ designDetail.isCollect == 0 ? "收藏" : "已收藏" }}</p>
              </div>
              <div class="subscribe" @click="Tconsulting">
                <p>立即咨询</p>
              </div>
            </div>
          </div>
          <el-dialog
            title="联系客服"
            :show-close="false"
            width="400px"
            :visible.sync="dialogTableVisible"
          >
            <div class="inquiry">
              <div class="DialogTop">
                <div class="title">24小时客服电话</div>
                <div class="title1">029-85233571</div>
                <div class="img">
                  <img src="../../assets/image/baojia03.png" alt="" />
                </div>
                <div class="bom">扫一扫添加客服微信</div>
              </div>
              <p class="colseBox" @click="DialogClose">
                <i class="el-icon-close"></i>
              </p>
            </div>
          </el-dialog>
        </div>
        <div class="designerDetails_center">
          <div class="design_left">
            <el-tabs
              class="ServiceTopBoxTabs"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane label="上架服务" name="1">
                <div class="seckill-goods" v-if="serviceList.length != 0">
                  <ul class="clearfix">
                    <li
                      v-for="item in serviceList"
                      :key="item.id"
                      @click="goServerDetail(item.id)"
                    >
                      <div class="ListTop">
                        <img :src="item.coverImg" alt="" />
                      </div>
                      <p class="pTitle">{{ item.serviceName }}</p>
                      <div class="ListBottom">
                        {{ item.servicePrice + getPrizeType(item.priceType) }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="emptyList" v-else>
                  <p>暂无数据</p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="设计案例" name="2">
                <div class="seckill-goods" v-if="engCase.length != 0">
                  <ul class="clearfix">
                    <li
                      style="cursor: pointer"
                      v-for="itemer in engCase"
                      :key="itemer.id"
                    >
                      <div class="ListTop" @click="goCaseDetail(itemer)">
                        <img :src="itemer.coverImg" alt="" />
                      </div>
                      <div class="ListBottom">
                        <div class="ListLeft">
                          <p class="pTitle">{{ itemer.caseName }}</p>
                          <p class="pTitle2">{{ itemer.budget }}</p>
                        </div>
                        <p class="ListRight">
                          {{
                            getHouseType(itemer.houseType) +
                            "丨" +
                            getDardensStyle(itemer.gardensStyle) +
                            "丨" +
                            itemer.area +
                            "㎡"
                          }}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="emptyList" v-else>
                  <p>暂无数据</p>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <div class="design_right">
        <div class="tab_sp"></div>
        <div class="advertF">
          <wantQuote />
        </div>
        <div class="advertT">
          <advertisementLeft :wideUrl="wideUrl" @click.native="goRoute" />
        </div>
      </div>
    </div>
    <div class="designerDetails_bottom">
      <div class="other" v-if="designPeopleList.length != 0">
        <p class="otherTitle">其他设计师</p>
        <div class="otherDisgin">
          <div
            class="otherDesign"
            v-for="(item1, index1) in designPeopleList"
            :key="index1"
            @click="designDetailer(item1.id)"
          >
            <div class="otherDesign_img">
              <img :src="item1.personalImg" alt="" />
            </div>
            <p class="name">
              {{ item1.personalName
              }}<span v-if="item1.year!=null" class="small_text">{{ item1.year + year }}</span>
            </p>
            <p class="small_text">
              擅长风格<span class="black_text">{{
                getWellStyles(item1.wellStyle)
              }}</span>
            </p>
            <p class="small_text">
              设计案例<span class="black_text">{{ item1.caseNums }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="emptyList" v-else>
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components//Breadcrumb/Breadcrumb.vue";
import advertisementLeft from "@/components/advertisement/advertisementLeft.vue";
import wantQuote from "@/components/wantQuote/wantQuote.vue";
import { getDicts } from "@/request/dict/data";
import { getCaseList } from "@/request/case";
import { getConstructService } from "@/request/findServer";
import { selectDictLabel, selectDictLabels, handleMoney } from "@/utils/weihua";
import { getFindDesign, getFindDesignDetail } from "@/request/findDesign";
import { getBanner, collection, unCollection } from "@/request/home/home";
export default {
  name: "designerDetails",
  components: { Breadcrumb, advertisementLeft, wantQuote },
  data() {
    return {
      url: null,
      dialogTableVisible: false,
      value: 5,
      activeName: "1",
      year: '年',
      designDetail: [],
      styleList: [],
      serviceList: [],
      engCase: [],
      designPeopleList: [],
      prizeTypeList: [],
      wideUrl: null,
      formInline: {
        pageNum: 1,
        pageSize: 4,
        id: null,
        wellStyle: null,
        personIdentity: 2,
      },
    };
  },
  activated() {
    this.getDetail();
    this.getDesignList();
    this.getCaseListApi(this.$route.query.id);
    this.getServersList();
    this.getBBannerList();
  },
  created() {
    getDicts("msk_sys_well_style").then((res) => {
      this.styleList = res.data;
      this.styleList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });

    getDicts("msk_price_type").then((res) => {
      this.prizeTypeList = res.data;
    });
    getDicts("msk_sys_house_type").then((res) => {
      this.houseTypeList = res.data;
      this.houseTypeList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
    getDicts("msk_sys_gardens_style").then((res) => {
      this.dardensStyleList = res.data;
      this.dardensStyleList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
  },
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 7 }).then((res) => {
        if (res.data[0]) {
          this.wideUrl = res.data[0].filePath;
          this.url = res.data[0].routeContent;
        } else {
          this.wideUrl = "";
        }
      });
    },
    goRoute() {
      if(this.url){
      window.open(this.url,"_blank");

      }else{
        return 
      }
    },
    getDetail() {
      const id = this.$route.query.id;
      this.formInline.id = this.$route.query.id;
      getFindDesignDetail(id).then((res) => {
        this.designDetail = res.data;
        this.designDetail.year = this.getYearNum(
          res.data.jobStartTime,
          res.data.jobEndTime
        );
      });
    },
    designDetailer(e) {
      this.$router.push({
        name: "designerDetails",
        query: { id: e, personIdentity: this.formInline.personIdentity },
      });
      getFindDesignDetail(e).then((res) => {
        this.designDetail = res.data;
      });
      document.documentElement.scrollTop = 0;
      this.getCaseListApi(e);
      this.getServersList(e);
    },
    getDesignList() {
      getFindDesign(this.formInline).then((res) => {
        res.rows.forEach((item, index) => {
          item.year = null;
          item.year = this.getYearNum(item.jobStartTime, item.jobEndTime);
        });
        this.designPeopleList = res.rows.slice(0, 4);
      });
    },
    //设计案例
    getCaseListApi(e) {
      if (e) {
        const bizId = e;
        getCaseList({ bizId }).then((res) => {
          const arr = res.rows.slice(0, 6);
          arr.forEach((item) => {
            item.budget = handleMoney(item.budget);
          });
          this.engCase = arr;
        });
      } else {
        const bizId = e;
        getCaseList({ bizId }).then((res) => {
          const arr = res.rows.slice(0, 6);
          arr.forEach((item) => {
            item.budget = handleMoney(item.budget);
          });
          this.engCase = arr;
        });
      }
    },
    //上架服务
    getServersList(e) {
      if (e) {
        const bizId = e;
        getConstructService({ bizId }).then((res) => {
          this.serviceList = res.rows.slice(0, 6);
        });
      } else {
        const bizId = this.$route.query.id;
        getConstructService({ bizId }).then((res) => {
          this.serviceList = res.rows.slice(0, 6);
        });
      }
    },
    findDesign() {
      this.$router.push({ path: "/findDesign", query: { id: 2 } });
    },
    goServerDetail(e) {
      this.$router.push({ name: "FindServiceDetails", query: { id: e } });
    },
    follow(e) {
      const data = {
        bizId: this.$route.query.id,
        bizType: 2,
      };
      if (e.isCollect == 0) {
        collection(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "收藏成功，请登录小程序查看",
              type: "success",
            });
            this.getDetail();
          }
        });
      } else {
        unCollection(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "取消成功",
              type: "success",
            });
            this.getDetail();
          }
        });
      }

      // this.$message({
      //   message: "功能开发中,敬请期待",
      //   type: "success",
      // });
    },
    getWellStyle(row) {
      return selectDictLabels(this.styleList, row);
    },
    getWellStyles(row) {
      return selectDictLabels(this.styleList, row);
    },
    getHouseType(value) {
      return selectDictLabel(this.houseTypeList, value);
    },
    getPrizeType(value) {
      return selectDictLabel(this.prizeTypeList, value);
    },
    getDardensStyle(value) {
      return selectDictLabel(this.dardensStyleList, value);
    },
    getYearNum(startTime, endTime) {
      var timestr = startTime.replace(/-/g, "/");
      var startTime1 = new Date(timestr);
      var timestr1 = endTime.replace(/-/g, "/");
      var endTime1 = new Date(timestr1);
      var y = endTime1.getFullYear();
      var y1 = startTime1.getFullYear();
      var m = endTime1.getMonth() + 1;
      var m1 = startTime1.getMonth() + 1;
      if (y === y1) {
        this.year = "年";
        return 1;
      } else {
        this.year = "年";
        return y - y1;
      }
      // let start = startTime.getFullYear()
      // let end = endTime.getFullYear()
      // return year = end - start;
    },
    getYearNum(startTime, endTime) {
      if (startTime && endTime) {
        var timestr = startTime.replace(/-/g, "-");
        var startTime1 = new Date(timestr);
        var timestr1 = endTime.replace(/-/g, "-");
        var endTime1 = new Date(timestr1);
        var y = endTime1.getFullYear();
        var y1 = startTime1.getFullYear();
        var m = endTime1.getMonth() + 1;
        var m1 = startTime1.getMonth() + 1;
        // if (y == y1) {
        //   this.year = "个月";
        //   return m - m1;
        // } else if (m == m1) {
        //   this.year = "个月";
        //   return "不足1";
        // } else {
        //   this.year = "年";
        //   return y - y1;
        // }
        if (y === y1) {
          this.year = "年";
          return 1;
        } else {
          this.year = "年";
          return y - y1;
        }
      } else {
        return;
      }
    },
    Tconsulting() {
      const token = sessionStorage.getItem("token");
      if (token) {
        this.dialogTableVisible = true;
      } else {
        this.$message({
          message: "您还没有登录，请登录后查看",
          type: "success",
        });
        this.$router.push({ path: "/HomeView" });
        sessionStorage.setItem("loading", true);
        this.$router.go(0);
      }
    },
    DialogClose() {
      this.dialogTableVisible = false;
    },

    goCaseDetail(e) {
      this.$router.push({
        path: "/CasePresentationDetails",
        query: {
          id: e.id,
        },
      });
    },
    handleClick(tab) {},
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
.myrou /deep/ .el-breadcrumb__inner {
  font-weight: 400;
}
</style>
<style lang="scss">
.designerDetails {
  width: 1320px;
  margin: 0 auto;
  .route {
    width: 100%;
    height: 30px;
    padding-top: 20px;
  }
  .bigBox {
    display: flex;
    margin-bottom: 30px;
    .designer_bigBox {
      width: 76%;
      display: flex;
      flex-direction: column;
      .designerDetails_top {
        width: 97%;
        display: flex;
        justify-content: space-between;
        background: #fafafa;
        margin-bottom: 30px;
        .designerImg {
          width: 30%;
          height: 318px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .el-dialog__body {
          padding: 0 20px;
        }
        .inquiry {
          width: 347px;
          height: 401px;
          .DialogTop {
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
            }
            .title1 {
              font-size: 30px;
              font-family: DIN;
              font-weight: 500;
              color: #f66a1d;
              line-height: 50px;
            }
            .img {
              width: 258px;
              height: 258px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .bom {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 20px;
            }
          }
          .colseBox {
            cursor: pointer;
            position: absolute;
            top: 4px;
            right: -30px;
            border: 1px solid #bdbdbd;
            border-radius: 50%;
            i {
              font-size: 24px;
              color: #bdbdbd;
            }
          }
        }
        .designerTxt {
          width: 66%;
          display: flex;
          flex-direction: column;
          padding-top: 34px;
          .txt_top {
            display: flex;
            justify-content: space-between;
            .txt_left {
              .txt_title {
                margin: 0 0 14px 0;
                div {
                  div {
                    display: flex;
                    align-items: center;
                    p {
                      font-size: 24px;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      color: #4a4c4b;
                      margin-right: 10px;
                    }
                  }
                }
              }
              .txt_style {
                display: flex;
                margin: 0 0 12px 0;
                p {
                  width: 300px;
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                  line-break: 24px;
                  span {
                    font-size: 14px;
                    margin-left: 19px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #4c4c4c;
                  }
                }
              }

              .txt_case {
                margin: 0 0 18px 0;
                p {
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                  line-break: 24px;
                  span {
                    font-size: 14px;
                    margin-left: 19px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #4a4c4b;
                  }
                }
              }
              .txt_workerYear {
                margin: 0 0 18px 0;
                p {
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                  line-break: 24px;
                  span {
                    font-size: 14px;
                    margin-left: 19px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #4a4c4b;
                  }
                }
              }
            }
          }
          .txt_bottom {
            width: 93%;
            margin: 0 auto;
            margin: 0 0 20px 0;

            p:nth-child(1) {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #636664;
              margin-bottom: 10px;
            }
            p:nth-child(2) {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #636664;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              word-break: break-all;
            }
          }
          .txt_right {
            display: flex;
            .followDesigner {
              width: 194px;
              height: 50px;
              background: #f66a1d;
              border-radius: 3px;
              p {
                line-height: 50px;
                cursor: pointer;
                text-align: center;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }
            .subscribe {
              margin-left: 20px;
              width: 194px;
              height: 50px;
              background: #219560;
              border-radius: 3px;
              p {
                line-height: 50px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
      }
      .designerDetails_center {
        width: 97%;
        display: flex;
        justify-content: space-between;
        .design_left {
          width: 100%;
          display: flex;
          flex-direction: column;

          .ServiceTopBoxTabs {
            width: 100%;
            .el-tabs__header {
              margin: 0;
            }
            .el-tabs__item.is-active {
              font-size: 16px;
              color: #188252;
              font-weight: 600;
            }

            .el-tabs__active-bar {
              background-color: #188252;
              height: 0;
              border-radius: 2px;
            }

            .el-tabs__item {
              font-size: 16px;
              color: #4a4a4a;
            }

            .el-tabs__item:hover {
              font-size: 16px;
              color: #188252;
            }

            .el-tabs__nav-wrap::after {
              height: 0;
              border-color: transparent !important;
            }
            .emptyList {
              width: 100%;
              height: 300px;
              p {
                color: #ccc;
                font-size: 24px;
                text-align: center;
                line-height: 300px;
              }
            }
            .seckill-goods {
              display: inline-block;
              .clearfix {
                margin-left: -23px;
                li {
                  width: 304px;
                  margin-top: 20px;
                  float: left;
                  flex: 1;
                  margin-left: 23px;
                  background-color: #FFFFFF;
                  .ListTop {
                    cursor: pointer;
                    width: 304px;
                    height: 186px;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .pTitle {
                    font-size: 14px;
                    cursor: pointer;
                    overflow: hidden;
                    width: 95%;
                    margin: 10px auto 10px;
                    color: #000000;
                    display: -webkit-box; /*弹性伸缩盒子*/
                    -webkit-box-orient: vertical; /*子元素垂直排列*/
                    -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                    text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                  }
                  .ListBottom {
                    width: 95%;
                    margin: 5px auto;
                    font-size: 14px;
                    color: #f66a1d;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .ListLeft {
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      flex-wrap: nowrap;
                      .pTitle {
                        margin-left: -2px;
                        width: 200px;
                        text-align: left;
                        font-size: 14px;
                        cursor: pointer;
                        overflow: hidden;
                        color: #000000;
                        display: -webkit-box; /*弹性伸缩盒子*/
                        -webkit-box-orient: vertical; /*子元素垂直排列*/
                        -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                        text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                      }
                      .pTitle:hover {
                        font-size: 14px;
                        cursor: pointer;
                        overflow: hidden;
                        color: #188252;
                        display: -webkit-box; /*弹性伸缩盒子*/
                        -webkit-box-orient: vertical; /*子元素垂直排列*/
                        -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                        text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                      }
                      .pTitle2 {
                        width: 100px;
                        text-align: right;
                        padding-bottom: 10px;
                        font-size: 14px;
                        padding-top: 5px;
                        color: #ee6a31;
                      }
                    }

                    .ListRight {
                      font-size: 14px;
                      margin-top: 10px;
                      color: #999;
                      text-align: left;
                      //padding-right: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .design_right {
      width: 21%;
      display: flex;
      flex-direction: column;
      padding: 0 1%;
      // .tab_sp {
      //   width: 100%;
      //   height: 50px;
      // }
      .advertF {
        margin-bottom: 20px;
      }
      .advertT {
        margin-bottom: 20px;
      }
    }
  }

  .designerDetails_bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    .otherTitle {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #4a4d4b;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .otherDisgin {
      display: flex;
      flex-wrap: wrap;
      margin-left: -38px;
      .otherDesign {
        width: 22%;
        margin-left: 38px;
       background-color: #FFFFFF;
        .otherDesign_img {
          width: 100%;
          height: 326px;
          margin-bottom: 10px;
          img {
            cursor: pointer;
            width: 100%;
            height: 100%;
          }
        }
        .name {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #4a4d4b;
          line-height: 26px;
          margin-left: 20px;
        }
        .small_text {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          margin: 0 20px 0 20px;
          line-height: 26px;
        }
        .black_text {
          font-size: 12px;
          font-family: Source Han Sans CN;
          color: #4a4d4b;
          font-weight: 400;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
