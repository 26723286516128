<template>
  <div class="settleIn">
    <div class="topHome">
      <div class="btnHome" @click="goHome">
        <img
          src="../../assets/image/logo2.png"
          alt=""
        />
      </div>
      <div class="border"></div>
      <div class="settleTit">入驻平台</div>
    </div>
    <div class="settleBanner">
      <img src="../../assets/image/settleBanner.jpg" alt="" />
    </div>
    <div class="settleBigBox">
      <div class="companyBox">
        <div class="companyTitle">公司入驻</div>
        <div class="companyBom">
          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg02.png" alt="" />
              </div>
              <div class="settleTitle">建筑公司入驻</div>
            </div>
            <div class="settleCen">
              <p>
                具备城市园林绿化工程设计、造价、材料、施工、养护等一体化服务的企业。
              </p>
            </div>
            <div class="settleBtn" @click="settle(1)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>
          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg06.png" alt="" />
              </div>
              <div class="settleTitle">设计公司入驻</div>
            </div>
            <div class="settleCen">
              <p>
                具备生态学、园林植物与观赏园艺、风景园林规划与设计等方面服务的企业。
              </p>
            </div>
            <div class="settleBtn" @click="settle(11)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>
          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg07.png" alt="" />
              </div>
              <div class="settleTitle">造价公司入驻</div>
            </div>
            <div class="settleCen">
              <p>
                熟悉园林市场材料价格，了解准确的市场价格和预算价格的风景园林行业专业工程造价服务的企业。
              </p>
            </div>
            <div class="settleBtn" @click="settle(10)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>
          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg11.png" alt="" />
              </div>
              <div class="settleTitle">材料公司入驻</div>
            </div>
            <div class="settleCen">
              <p>
                具备园林常用的四大类材料，园林常用的地材材料、铺装材料、安装材料、苗木花卉等的企业。
              </p>
            </div>
            <div class="settleBtn" @click="settle(6)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>

          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg08.png" alt="" />
              </div>
              <div class="settleTitle">施工公司入驻</div>
            </div>
            <div class="settleCen">
              <p>
                具备园林绿化工程相关篆刻、雕塑、插花工艺、石刻、打磨等特殊工艺技法服务人才类型丰富的企业。
              </p>
            </div>
            <div class="settleBtn" @click="settle(12)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>
          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg02.png" alt="" />
              </div>
              <div class="settleTitle">养护公司入驻</div>
            </div>
            <div class="settleCen">
              <p>
                具备园林绿化养护操作规程及园林绿化养护质量标准，拥有专业的园艺师保质保量完成养护服务的企业。
              </p>
            </div>
            <div class="settleBtn" @click="settle(7)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>
          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg09.png" alt="" />
              </div>
              <div class="settleTitle">培训公司入驻</div>
            </div>
            <div class="settleCen">
              <p>具备风景园林行业专业培训课程规划及专业培训讲师的企业。</p>
            </div>
            <div class="settleBtn" @click="settle(8)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="personBox">
        <div class="personTitle">个人入驻</div>
        <div class="personBom">
          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg01.png" alt="" />
              </div>
              <div class="settleTitle">设计师入驻</div>
            </div>
            <div class="settleCen">
              <p>
                园林设计师：具备生态学、园林植物与观赏园艺、风景园林规划与设计等方面服务的个人。
              </p>
            </div>
            <div class="settleBtn" @click="settle(2)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>

          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg03.png" alt="" />
              </div>
              <div class="settleTitle">施工团队入驻</div>
            </div>
            <div class="settleCen">
              <p>
                具备园林绿化工程相关篆刻、雕塑、插花工艺、石刻、打磨等特殊工艺技法服务的个人。
              </p>
            </div>
            <div class="settleBtn" @click="settle(3)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>
          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg04.png" alt="" />
              </div>
              <div class="settleTitle">造价师入驻</div>
            </div>
            <div class="settleCen">
              <p>
                熟悉园林市场材料价格，了解准确的市场价格和预算价格的风景园林行业专业工程造价服务的个人。
              </p>
            </div>
            <div class="settleBtn" @click="settle(4)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>
          <div class="settleBox">
            <div class="settleTop">
              <div class="settleImg">
                <img src="../../assets/image/settleImg04.png" alt="" />
              </div>
              <div class="settleTitle">培训讲师入驻</div>
            </div>
            <div class="settleCen">
              <p>
                具备风景园林行业专业培训课程规划的专业培训的讲师个人。
              </p>
            </div>
            <div class="settleBtn" @click="settle(5)">
              <p>立即入驻</p>
              <div class="img">
                <img src="../../assets/image/settleImg05.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isSettle } from "@/request/login";
export default {
  data() {
    return {
      phone: null,
    };
  },
  created() {
    this.phone = sessionStorage.getItem("phone");
  },
  methods: {
    settle(n) {
      switch (n) {
        case 1:
          this.goSettle(1);
          break;
        case 2:
          this.goSettle(2);
          break;
        case 3:
          this.goSettle(3);
          break;
        case 4:
          this.goSettle(4);
          break;
         case 5:
          this.goSettle(5);
          break;
        case 6:
          this.goSettle(6);
          break;
        case 7:
          this.goSettle(7);
          break;
        case 8:
          this.goSettle(8);
          break;
        case 10:
          this.goSettle(10);
          break;
        case 11:
          this.goSettle(11);
          break;
        case 12:
          this.goSettle(12);
          break;
      }
    },
    //入驻
    goSettle(m) {
      isSettle({ phone: this.phone })
        .then((res) => {
          if (
            (res.data.flag == 1 || res.data.flag == 2) &&
            res.data.auditStatus == 1
          ) {
            this.$message({
              message:
                "您的入驻信息已提交，请耐心等待1-3个工作日通知您审核结果",
              type: "success",
            });
          } else if (
            (res.data.flag == 1 || res.data.flag == 2) &&
            res.data.auditStatus == 3
          ) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.$router.push({ path: "/auth" });
          } else {
            if (m == 5) {
              this.$router.push({ name: "settleInE", query: { isShow: 5 } });
              sessionStorage.setItem("personIdentity", 5);
            } else if (m == 2) {
              this.$router.push({ name: "settleInE", query: { isShow: 2 } });
              sessionStorage.setItem("personIdentity", 2);
            } else if (m == 3) {
              this.$router.push({ name: "settleInE", query: { isShow: 4 } });
              sessionStorage.setItem("personIdentity", 3);
            } else if (m == 4) {
              this.$router.push({ name: "settleInE", query: { isShow: 3 } });
              sessionStorage.setItem("personIdentity", 4);
            } else if (m != 4 && m != 2 && m != 3 && m != 5) {
              this.$router.push({ name: "settleInE", query: { isShow: m } });
              sessionStorage.setItem("personIdentity", m);
            } else {
              this.$router.push({ path: "/HomeView" });
              this.LoginRegistration = true;
            }
          }
        })
        .catch((res) => {
          this.message({
            message: "当前登录人未完成学员认证，请前往上传作品页进行学员认证",
            type: "warning",
          });
        });
    },
    goHome() {
      this.$router.push({ name: "HomeView" });
    },
  },
};
</script>

<style lang="scss" scoped>
.settleIn {
  width: 100%;
  background-color: #fafafa;
  .topHome {
    width: 1320px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .btnHome {
      height: 50px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .border {
      width: 2px;
      height: 30px;
      background-color: #e6e6e6;
      margin: 0 30px;
      align-self: center;
    }
    .settleTit {
      line-height: 80px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #4d4d4d;
    }
  }
  .settleBanner {
    width: 100%;
    height: 371px;
    background: #219560;
    margin-bottom: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .settleBigBox {
    width: 1320px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    .companyBox {
      .companyTitle {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 20px;
      }
      .companyBom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .settleBox {
          width: 27%;
          height: 168px;
          background: #ffffff;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          margin-bottom: 30px;
          padding: 30px;
          .settleTop {
            display: flex;
            margin-bottom: 30px;
            .settleImg {
              width: 45px;
              height: 45px;
              margin-right: 20px;
            }
            .settleTitle {
              line-height: 45px;
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 700;
              color: #333333;
            }
          }
          .settleCen {
            margin-bottom: 30px;
            p {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #808080;
              line-height: 24px;
            }
          }
          .settleBtn {
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            line-height: 44px;
            p {
              cursor: pointer;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #f66a1d;
              line-height: 44px;
              margin-right: 6px;
            }
            .img {
              width: 14px;
              height: 14px;
              line-height: 30px;
            }
          }
        }
      }
    }
    .personBox {
      .personTitle {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 20px;
      }
      .personBom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .settleBox {
          width: 27%;
          height: 168px;
          background: #ffffff;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          margin-bottom: 30px;
          padding: 30px;
          .settleTop {
            display: flex;
            margin-bottom: 30px;
            .settleImg {
              width: 45px;
              height: 45px;
              margin-right: 20px;
            }
            .settleTitle {
              line-height: 45px;
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 700;
              color: #333333;
            }
          }
          .settleCen {
            margin-bottom: 30px;
            p {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #808080;
              line-height: 24px;
            }
          }
          .settleBtn {
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            line-height: 44px;
            p {
              cursor: pointer;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #f66a1d;
              line-height: 44px;
              margin-right: 6px;
            }
            .img {
              width: 14px;
              height: 14px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
</style>