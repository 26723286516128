<template>
  <div class="FindMaintenance">
    <div class="FindServiceTop">
      <div class="ServiceTopBox">
        <Breadcrumb class="ServiceTopBreadcrumb"/>
        <el-tabs class="ServiceTopBoxTabs" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="园林设计" name="1"></el-tab-pane>
          <el-tab-pane label="园林施工" name="2"></el-tab-pane>
          <el-tab-pane label="园林造价" name="3"></el-tab-pane>
          <el-tab-pane label="维护养护" name="4"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="FindServiceBottom">
      <div class="ServiceBottom">
        <div class="ServiceBottomSelect">
          <el-select style="width: 100px" v-model="value" placeholder="服务区域">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-select style="width: 100px;margin-left: 50px" v-model="value" placeholder="园林风格">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-select style="width: 100px;margin-left: 50px" v-model="value" placeholder="设计风格">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-select style="width: 100px;margin-left: 50px" v-model="value" placeholder="商户类型">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>

      </div>
      <div class="seckill-goods">
        <ul class="clearfix">
          <li v-for="item in LandscapeList" :key="item.id">
            <div class="ListTop">
              <img src="../../assets/image/anli_04.png" alt="">
            </div>
            <p class="pTitle">{{ item.title }}</p>
            <div class="ListBottom">

              <span class="SpanTypeTwo">企</span>
              <span class="SpanTwo">{{ item.type2 }}</span>


            </div>
            <div class="ListBottomTwo">
              <p>￥{{ item.jeishu }}/平米</p>
              <p>立即咨询</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Pagination
        v-show="Total > 0"
        :total="Total"
        style="text-align: center; margin-top: 15px"
        :page.sync="formInline.page"
        :limit.sync="formInline.Size"
        @pagination="PageApi(formInline)"
    />

  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Pagination from "@/components/Pagination/Pagination";

export default {
  name: "FindMaintenance",
  components: {Breadcrumb, Pagination},
  data() {
    return {
      activeName: '4',
      value: "",
      options: [],
      LandscapeList: [
        {
          id: 1,
          title: "园林景观设计方案(带你做项目)秋园林景观设计方案(带你做项目)秋",
          jeishu: 99,
          type: "1",
          type2: "SGGC上海园林"
        },
        {
          id: 2,
          title: "园林景观设计方案(带你做项目)秋园林景观设计方案(带你做项目)秋",
          jeishu: 99,
          type: "2",
          type2: "SGGC上海园林"
        },
        {
          id: 3,
          title: "园林景观设计方案(带你做项目)秋园林景观设计方案(带你做项目)秋",
          jeishu: 99,
          type: "3",
          type2: "SGGC上海园林"
        },
        {
          id: 4,
          title: "园林景观设计方案(带你做项目)秋园林景观设计方案(带你做项目)秋",
          jeishu: 99,
          type: "1",
          type2: "SGGC上海园林"
        },
        {
          id: 5,
          title: "园林景观设计方案(带你做项目)秋园林景观设计方案(带你做项目)秋",
          jeishu: 99,
          type: "2",
          type2: "SGGC上海园林"
        },

      ],
      formInline: {
        page: 1,
        Size: 10,
      },
      Total: 999,
    }
  },
  created() {

  },
  methods: {
    handleClick() {

    },
    PageApi() {
    }

  }
}
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
    color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
    color: #219560;
    cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
</style>
<style lang="scss">
.FindMaintenance {
  width: 100%;

  .FindServiceTop {
    width: 100%;
    background: #F5F5F5;

    .ServiceTopBox {
      width: 1320px;
      margin: 0 auto;

      .ServiceTopBreadcrumb {
        padding-top: 20px;
      }

      .ServiceTopBoxTabs {
        margin-top: 42px;
      }

      .el-tabs__header {
        margin: 0;
      }

      .el-tabs__item.is-active {
        font-size: 16px;
        color: #188252;
      }

      .el-tabs__active-bar {
        background-color: #188252;
        height: 3px;
        border-radius: 2px;
      }

      .el-tabs__item {
        font-size: 16px;
        color: #4A4A4A;
      }

      .el-tabs__item:hover {
        font-size: 16px;
        color: #188252;
      }

      .el-tabs__nav-wrap::after {
        height: 0;
        border-color: transparent !important;
      }
    }
  }
  .FindServiceBottom{
    width: 1320px;
    margin: 20px auto;
    .ServiceBottom {

      .ServiceBottomSelect {
        border-bottom: 1px solid #EBEBEB;

        .el-input__inner {
          border: 0;
          padding: 0;
        }

        input::-webkit-input-placeholder {
          color: #4A4D4B !important;
        }

        input::-moz-input-placeholder {
          color: #4A4D4B !important;
        }

        input::-ms-input-placeholder {
          color: #4A4D4B !important;
        }

      }


    }

    .seckill-goods {
      display: inline-block;

      .clearfix {

        margin-left: -35px;

        li {
          width: 300px;
          margin-top: 20px;
          float: left;
          flex: 1;
          margin-left: 35px;
          border: 1px solid #E6E6E6;

          .ListTop {
            width: 300px;
            height: 194px;

            img {
              width: 300px;
              height: 194px;
            }
          }

          .pTitle {
            font-size: 16px;
            margin-left: 15px;
            color: #333333;
            margin-top: 20px;
            cursor: pointer;
            overflow: hidden;
            width: 90%;
            display: -webkit-box; /*弹性伸缩盒子*/
            -webkit-box-orient: vertical; /*子元素垂直排列*/
            -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
            text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
          }

          .ListBottom {
            margin-top: 15px;
            padding-left: 15px;

            .SpanTypeOne {
              padding: 2px 4px;
              background: #FFEFE5;
              border: 1px solid #EB8D67;
              border-radius: 2px;
              font-size: 12px;
              color: #F6611D;
            }

            .SpanTypeTwo {
              padding: 2px 4px;
              border: 1px solid #188252;
              background: #EBF7F2;
              border-radius: 2px;
              font-size: 12px;
              color: #188252;
            }

            .SpanTypeThere {
              padding: 2px 4px;
              background: #E4E7F3;
              border: 1px solid #5A73DD;
              border-radius: 2px;
              font-size: 12px;
              color: #5A73DD;
            }

            .SpanTwo {
              display: inline-block;
              font-size: 12px;
              color: #A8A8A8;
              margin-left: 5px;
            }

          }

          .ListBottomTwo {
            display: flex;
            align-items: center;
            margin: 15px;
            justify-content: space-between;

            p:nth-child(1) {
              font-size: 14px;
              color: #F66A1D;
            }

            p:nth-child(2) {
              width: 74px;
              cursor: pointer;
              height: 26px;
              background: linear-gradient(-70deg, #F6461D, #F66A1D);
              font-size: 14px;
              color: #FFFFFF;
              text-align: center;
              line-height: 26px;
            }
          }
        }
      }

    }
  }

}
</style>
