<template>
  <div class="GardenDetails">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myrou" :to="{ path: '/HomeView' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item class="myrou" :to="{ path: '/GardenArchitecture' }"
        >园林入驻公司</el-breadcrumb-item
      >
      <el-breadcrumb-item>园林入驻公司详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="GardenDetailsTop">
      <div class="DetailsTopLeft">
        <div class="LeftTopImg">
          <img :src="mainImgUrl" alt="" />
        </div>
        <ul class="Img_ul">
          <li
            v-for="(
              item, index
            ) in GardenArchitectureDetailList.baseConstructCompanyCarouselImgList"
            :key="index"
            class="Img_li"
            @click="changeImg(item, index)"
          >
            <img
              :class="index === imgActiveIndex ? 'img_activeBorder' : ''"
              :src="item.carouselImg"
              style="width: 100%; height: 100%"
              alt=""
            />
          </li>
        </ul>
      </div>
      <div class="DetailsTopRight">
        <div class="RightTopBox">
          <div class="BoxLeft">
            <p>{{ GardenArchitectureDetailList.companyName }}</p>
            <img
              style="margin: 0 10px"
              src="../../assets/image/authentication.png"
              alt=""
            />
          </div>
          <div class="BoxRight">
            <div class="wujiaoxing" @click="follow(GardenArchitectureDetailList)">
              <!-- <i class="iconfont icon-wujiaoxing-"></i> -->
              <p class="shareP">{{ GardenArchitectureDetailList.isCollect == 0 ? "收藏" : "已收藏" }}</p>
            </div>
            <!-- <div class="share">
              <i class="el-icon-share"></i>
              <p class="shareP">分享</p>
            </div> -->
          </div>
        </div>
        <div class="RightCenterBox">
          <span>用户评价</span>
          <el-rate
            class="rate"
            v-model="numStart"
            disabled
            :colors="['#F6461D', '#F6461D', '#F6461D', '#F6461D', '#F6461D']"
            text-color="#F6461D"
            score-template="{value}"
          >
          </el-rate>
        </div>
        <div class="RightBottomBox">
          <i class="iconfont icon-dingweixiao"></i>
          <span>{{ GardenArchitectureDetailList.serviceArea }}</span>
        </div>
        <div class="RightTopDetails">
          {{ GardenArchitectureDetailList.companyIntroduction }}
        </div>
        <div class="RightTopLabel">
          <p v-for="(item, index) in LabelList" :key="index">{{ item }}</p>
        </div>
        <div class="RightTopNumber">
          <div>
            <p>{{ GardenArchitectureDetailList.caseNums?GardenArchitectureDetailList.caseNums:"0" }}</p>
            <p>服务案例</p>
          </div>
          <div>
            <p>{{ GardenArchitectureDetailList.designerNum?GardenArchitectureDetailList.designerNum:"0" }}</p>
            <p>成员数量</p>
          </div>
          <div>
            <p>{{GardenArchitectureDetailList.serviceNums?GardenArchitectureDetailList.serviceNums:"0"}}</p>
            <p>正在施工</p>
          </div>
        </div>
        <div class="RightTopButton" @click="consulting">立即咨询</div>
      </div>
      <el-dialog
        title="联系客服"
        :show-close="false"
        width="400px"
        :visible.sync="dialogTableVisible"
      >
        <div class="inquiry">
          <div class="DialogTop">
            <div class="title">24小时客服电话</div>
            <div class="title1">029-85233571</div>
            <div class="img">
              <img src="../../assets/image/baojia03.png" alt="" />
            </div>
            <div class="bom">扫一扫添加客服微信</div>
          </div>
          <p class="colseBox" @click="DialogClose">
            <i class="el-icon-close"></i>
          </p>
        </div>
      </el-dialog>
    </div>
    <div class="ArchitectureDetailsBox">
      <div class="ArchitectureDetailsLeft">
        <el-tabs
          class="ServiceTopBoxTabs"
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane label="上架服务" name="1">
            <div class="seckill-goods" v-if="serviceList.length != 0">
              <ul class="clearfix">
                <li
                  v-for="(item, index1) in serviceList"
                  :key="index1"
                  @click="goServerDetail(item.id)"
                >
                  <div class="ListTop">
                    <img :src="item.coverImg" alt="" />
                  </div>
                  <p class="pTitle">{{ item.serviceName }}</p>
                  <div class="ListBottom">
                    {{
                      "￥" + item.servicePrice + getPrizeType(item.priceType)
                    }}
                  </div>
                </li>
              </ul>
            </div>
            <div class="emptyList" v-else>
              <p>暂无数据</p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="服务案例" name="2">
            <div class="seckill-goods" v-if="engCase.length != 0">
              <ul class="clearfix">
                <li
                  v-for="item in engCase"
                  :key="item.id"
                  @click="goCaseDetail(item)"
                >
                  <div class="ListTop">
                    <img :src="item.coverImg" alt="" />
                  </div>
                  <div class="ListBottom">
                    <div class="ListLeft">
                      <p class="pTitle">{{ item.caseName }}</p>
                      <p class="pTitle2">
                        {{
                          getHouseType(item.houseType) +
                          "丨" +
                          getDardensStyle(item.gardensStyle) +
                          "丨" +
                          item.area +
                          "㎡"
                        }}
                      </p>
                    </div>
                    <p class="ListRight">{{ item.budget }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="emptyList" v-else>
              <p>暂无数据</p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="商品列表" name="3">
            <div class="material_list" v-if="materialsList.length != 0">
              <ul class="material_clearfix">
                <li
                  v-for="(item, index) in materialsList"
                  :key="index"
                  @click="shopDetail(item.id)"
                >
                  <div class="material_ListTop">
                    <img :src="item.coverImg" alt="" />
                  </div>
                  <div class="ListBottom">
                    <div class="ListLeft">
                      <p class="pTitle">{{ item.goodsName }}</p>
                    </div>
                    <p
                      class="materialPrice"
                      style="
                        line-height: 28px;
                        color: #f66a1d;
                      "
                    >
                      {{ item.skuPrice }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="emptyList" v-else>
              <p>暂无数据</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import advertisementLeft from "@/components/advertisement/advertisementLeft.vue";
import wantQuote from "@/components/wantQuote/wantQuote.vue";
import { getGardenArchitectureDetail } from "@/request/GardenArchitecture";
import { getCaseList } from "@/request/case";
import { getConstructService } from "@/request/findServer";
import { getDicts } from "@/request/dict/data";
import { selectDictLabel, selectDictLabels, handleMoney } from "@/utils/weihua";
import { getFindShopGoods } from "@/request/findDesign";
import { getBanner, collection, unCollection } from "@/request/home/home";
export default {
  name: "GardenDetails",
  components: {
    advertisementLeft,
    wantQuote,
  },

  data() {
    return {
      dialogTableVisible: false,
      numStart: 5,
      mainImgUrl: null,
      imgActiveIndex: 0,
      GardenArchitectureDetailList: [],
      LabelList: [],
      activeName: "1",
      houseTypeList: [],
      dardensStyleList: [],
      engCase: [],
      serviceList: [],
      prizeTypeList: [],
      materialsList: [],
    };
  },
  activated() {
    const id = this.$route.query.id;
    this.getGardenArchitectureDetail(id);
    this.getCaseList();
    this.getServersList();
  },
  created() {
    const id = this.$route.query.id;
    this.getGardenArchitectureDetail(id);

    getDicts("msk_sys_house_type").then((res) => {
      this.houseTypeList = res.data;
      this.houseTypeList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
    getDicts("msk_price_type").then((res) => {
      this.prizeTypeList = res.data;
    });
    getDicts("msk_sys_gardens_style").then((res) => {
      this.dardensStyleList = res.data;
      this.dardensStyleList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
  },
  methods: {
    getGardenArchitectureDetail(id) {
      getGardenArchitectureDetail(id).then((res) => {
        this.GardenArchitectureDetailList = res.data;

        if(res.data.serverTypes != null){
          this.LabelList = res.data.serverTypes.split(",")
        }

        this.mainImgUrl =
          res.data.baseConstructCompanyCarouselImgList[0].carouselImg;
      });
      const companyId = this.$route.query.id;
      getFindShopGoods({ companyId }).then((res) => {
        const arr = res.rows.slice(0, 5);
        arr.forEach((item) => {
          item.skuPrice = handleMoney(item.skuPrice);
        });
        this.materialsList = arr;
      });
    },
    changeImg(item, index) {
      this.mainImgUrl = item.carouselImg;
      this.imgActiveIndex = item.id;
    },
    //服务案例
    getCaseList() {
      const bizId = this.$route.query.id;
      getCaseList({ bizId }).then((res) => {
        const arr = res.rows.slice(0, 6);
        arr.forEach((item) => {
          item.budget = handleMoney(item.budget);
        });
        this.engCase = arr;
      });
    },
    goCaseDetail(e) {
      this.$router.push({
        path: "/CasePresentationDetails",
        query: {
          id: e.id,
        },
      });
    },
    follow(e) {
      const data = {
        bizId: this.$route.query.id,
        bizType: e.companyIdentity,
      };
      const id =this.$route.query.id
      if (e.isCollect == 0) {
        collection(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "收藏成功，请登录小程序查看",
              type: "success",
            });
            this.getGardenArchitectureDetail(id)
          }
        });
      } else {
        unCollection(data).then((res) => {
          if(res.code == 200){
            this.$message({
              message: "取消成功",
              type: "success",
            });
            this.getGardenArchitectureDetail(id)
          }
          
        });
      }

      // this.$message({
      //   message: "功能开发中,敬请期待",
      //   type: "success",
      // });
    },
    shopDetail(e) {
      this.$router.push({ name: "findMaterialsDetails", query: { id: e } });
    },
    goServerDetail(e) {
      this.$router.push({ name: "FindServiceDetails", query: { id: e } });
    },
    //上架服务
    getServersList() {
      const bizId = this.$route.query.id;
      getConstructService({ bizId }).then((res) => {
        this.serviceList = res.rows.slice(0, 6);
      });
    },
    getHouseType(value) {
      return selectDictLabel(this.houseTypeList, value);
    },
    getDardensStyle(value) {
      return selectDictLabel(this.dardensStyleList, value);
    },
    getPrizeType(value) {
      return selectDictLabel(this.prizeTypeList, value);
    },
    consulting() {
      const token = sessionStorage.getItem("token");
      if (token) {
        this.dialogTableVisible = true;
      } else {
        this.$message({
          message: "您还没有登录，请登录后查看",
          type: "success",
        });
        this.$router.push({ path: "/HomeView" });
        sessionStorage.setItem("loading", true);
        this.$router.go(0);
      }
    },
    DialogClose() {
      this.dialogTableVisible = false;
    },
    handleClick() {},
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #fff;
}
::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
.myrou /deep/ .el-breadcrumb__inner {
  font-weight: 400;
}
</style>
<style lang="scss" scoped>
.GardenDetails {
  width: 1320px;
  margin: 20px auto;
  background-color: #FFFFFF;
  padding: 20px;
  .GardenDetailsTop {
    margin-top: 20px;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 30px;
    margin-bottom: 20px;
    .DetailsTopLeft {
      width: 437px;

      .LeftTopImg {
        width: 437px;
        height: 305px;
        border-radius: 3px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .Img_ul {
        display: flex;
        margin-top: 10px;
        margin-left: -14px;
        .Img_li {
          width: 98px;
          height: 68px;
          margin-left: 14px;
          background: #bdbdbd;
          border-radius: 3px;

          .img_activeBorder {
            border: 1px solid #0dcc73;
          }
        }
      }
    }
    .DetailsTopRight {
      width: 100%;
      margin-left: 20px;

      .RightTopBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .BoxLeft {
          display: flex;
          align-items: center;
          p {
            font-size: 20px;
            font-weight: bold;
            color: #4d4d4d;
          }
        }
        .BoxRight {
          display: flex;
          align-items: center;
          margin-right: 20px;

          .wujiaoxing {
          height: 34px;
          width: 100px;
          margin-right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(-70deg, #f6461d, #f66a1d);

          .shareP {
            cursor: pointer;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fff;
          }
        }

          .share {
            padding-left: 15px;
            border-left: 1px solid #ebebeb;
            text-align: center;

            .el-icon-share {
              color: #949997;
              font-size: 14px;
              cursor: pointer;
            }

            .shareP {
              font-size: 12px;
              color: #adb2b0;
            }
          }
        }
      }
      .RightCenterBox {
        height: 42px;
        display: flex;
        align-items: center;
        position: relative;
        span {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          font-size: 14px;
          line-height: 42px;
          color: #a8a8a8;
        }
        .el-rate {
          position: absolute;
          top: 0;
          left: 60px;
          .el-rate__item {
            height: 42px;
            .el-rate__icon {
              font-size: 25px;
              line-height: 42px;
              margin-right: 0;
            }
            .el-rate__decimal {
              font-size: 25px;
              line-height: 42px;
              margin-right: 0;
            }
          }
        }
      }
      .RightBottomBox {
        margin-top: 5px;
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 20px;
        .icon-dingweixiao {
          color: #188252;
          font-size: 14px;
          margin-right: 5px;
        }
        span {
          font-size: 14px;
          color: #a8a8a8;
        }
      }
      .RightTopDetails {
        font-size: 14px;
        line-height: 2;
        overflow: hidden;
        color: #666666;
        margin-top: 10px;
        width: 100%;
        display: -webkit-box; /*弹性伸缩盒子*/
        -webkit-box-orient: vertical; /*子元素垂直排列*/
        -webkit-line-clamp: 2; /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
      .RightTopLabel {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        p {
          padding: 0 10px;
          margin-bottom: 10px;
          margin-right: 5px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          border: 1px solid #e6e6e6;
          border-radius: 3px;
          font-size: 14px;
          color: #7a807d;
        }
      }
      .RightTopNumber {
        display: flex;
        margin-top: 20px;
        div {
          margin-right: 60px;
          p:nth-child(1) {
            font-size: 20px;
            text-align: center;
            font-weight: bold;
            color: #333333;
          }
          p:nth-child(2) {
            font-size: 12px;
            color: #666666;
          }
        }
      }
      .RightTopButton {
        width: 194px;
        height: 50px;
        background: #f66a1d;
        border-radius: 3px;
        font-size: 16px;
        text-align: center;
        line-height: 50px;
        color: #ffffff;
        margin-top: 32px;
        cursor: pointer;
      }
    }
  }
  .ArchitectureDetailsBox {
    display: flex;
    justify-content: space-between;

    .ArchitectureDetailsLeft {
      width: 100%;
      .el-tabs__header {
        margin: 0;
      }
      .el-tabs__item.is-active {
        font-size: 16px;
        color: #188252;
        font-weight: 600;
      }

      .el-tabs__active-bar {
        background-color: #188252;
        height: 0;
        border-radius: 2px;
      }

      .el-tabs__item {
        font-size: 16px;
        color: #4a4a4a;
      }

      .el-tabs__item:hover {
        font-size: 16px;
        color: #188252;
      }

      .el-tabs__nav-wrap::after {
        height: 0;
        border-color: transparent !important;
      }
      .ServiceTopBoxTabs {
        .emptyList {
          width: 100%;
          height: 300px;
          p {
            color: #ccc;
            font-size: 24px;
            text-align: center;
            line-height: 300px;
          }
        }
        .seckill-goods {
          display: inline-block;

          .clearfix {
            margin-left: -40px;
            li {
              width: 298px;
              margin-top: 20px;
              float: left;
              flex: 1;
              margin-left: 38px;
              border: 1px solid #e6e6e6;
              .ListTop {
                cursor: pointer;
                width: 298px;
                height: 153px;
                img {
                  width: 298px;
                  height: 153px;
                }
              }
              .pTitle {
                font-size: 14px;
                cursor: pointer;
                overflow: hidden;
                width: 95%;
                margin: 10px auto 0;
                color: #000000;
                display: -webkit-box; /*弹性伸缩盒子*/
                -webkit-box-orient: vertical; /*子元素垂直排列*/
                -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
              }
              .ListBottom_th {
                display: flex;
                flex-direction: column;
              }
              .ListBottom {
                width: 95%;
                margin: 5px auto;
                font-size: 14px;
                color: #f66a1d;
                display: flex;
                justify-content: space-between;
                .ListLeft {
                  width: 80%;
                  .pTitle {
                    font-size: 14px;
                    cursor: pointer;
                    overflow: hidden;
                    color: #000000;
                    width: 100%;
                    display: -webkit-box; /*弹性伸缩盒子*/
                    -webkit-box-orient: vertical; /*子元素垂直排列*/
                    -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                    text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                  }
                  .pTitle:hover {
                    font-size: 14px;
                    cursor: pointer;
                    overflow: hidden;
                    color: #188252;
                    width: 100%;
                    display: -webkit-box; /*弹性伸缩盒子*/
                    -webkit-box-orient: vertical; /*子元素垂直排列*/
                    -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                    text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                  }
                  .pTitle2 {
                    padding-bottom: 10px;
                    font-size: 14px;
                    padding-top: 5px;
                    color: #999999;
                  }
                }
                .materialPrice {
                  line-height: 40px;
                  color: #ee6a31;
                }
                .ListRight {
                  width: 30%;
                  font-size: 16px;
                  font-weight: bold;
                  margin-top: 10px;
                  color: #ee6a31;
                  text-align: right;
                  //padding-right: 20px;
                }
              }
            }
          }
        }
        .material_list {
          display: inline-block;

          .material_clearfix {
            margin-left: -36px;
            li {
              width: 228px;
              margin-top: 20px;
              float: left;
              flex: 1;
              margin-left: 40px;
              background-color: #FFFFFF;
              .material_ListTop {
                cursor: pointer;
                width: 228px;
                height: 180px;
                display: flex;
                justify-content: center;
                img {
                  width: 228px;
                  height: 180px;
                }
              }
              .pTitle {
                font-size: 14px;
                cursor: pointer;
                overflow: hidden;
                width: 95%;
                margin: 10px auto 0;
                color: #000000;
                display: -webkit-box; /*弹性伸缩盒子*/
                -webkit-box-orient: vertical; /*子元素垂直排列*/
                -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
              }
              .ListBottom {
                display: flex;
                flex-direction: column;
              }
              .ListBottom {
                width: 85%;
                margin: 5px auto;
                font-size: 14px;
                color: #f66a1d;
                display: flex;
                justify-content: space-between;
                .ListLeft {
                  width: 80%;
                  .pTitle {
                    font-size: 14px;
                    cursor: pointer;
                    overflow: hidden;
                    color: #000000;
                    width: 100%;
                    display: -webkit-box; /*弹性伸缩盒子*/
                    -webkit-box-orient: vertical; /*子元素垂直排列*/
                    -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                    text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                  }
                  .pTitle:hover {
                    font-size: 14px;
                    cursor: pointer;
                    overflow: hidden;
                    color: #188252;
                    width: 100%;
                    display: -webkit-box; /*弹性伸缩盒子*/
                    -webkit-box-orient: vertical; /*子元素垂直排列*/
                    -webkit-line-clamp: 1; /*可以显示的行数，超出部分用...表示*/
                    text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                  }
                  .pTitle2 {
                    padding-bottom: 10px;
                    font-size: 14px;
                    padding-top: 5px;
                    color: #999999;
                  }
                }
                .materialPrice {
                  line-height: 40px;
                  color: #ee6a31;
                }
                .ListRight {
                  width: 30%;
                  font-size: 16px;
                  font-weight: bold;
                  margin-top: 10px;
                  color: #ee6a31;
                  text-align: right;
                  //padding-right: 20px;
                }
              }
            }
          }
        }
      }

      .el-tabs__header {
        margin: 0;
      }

      .el-tabs__item.is-active {
        font-size: 16px;
        color: #188252;
        font-weight: 600;
      }

      .el-tabs__active-bar {
        background-color: #188252;
        height: 0;
        border-radius: 2px;
      }

      .el-tabs__item {
        font-size: 16px;
        color: #4a4a4a;
      }

      .el-tabs__item:hover {
        font-size: 16px;
        color: #188252;
      }

      .el-tabs__nav-wrap::after {
        height: 0;
        border-color: transparent !important;
      }
    }

    .ArchitectureDetailsRight {
      width: 20%;
      margin-top: 50px;
    }
  }
}
</style>
