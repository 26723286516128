<template>
  <div class="GardenArchitecture">
    <div class="navBar">
      <Breadcrumb></Breadcrumb>
    </div>
    <el-row class="main_ty">
      <el-col class="main_content" :span="18">
        <div class="grid-content bg-purple">
          <div class="classificationFilter">
            <span class="serverTit" style="border-bottom: 0; border-right: 0"
              >企业类型</span
            >
            <ul
              class="serversType"
              style="border-bottom: 0; padding: 0 0 15px 20px"
            >
              <li
                style="margin-top: 15px"
                :class="
                  IndexActiveThree == item1.dictValue ? 'txtActive' : 'txt'
                "
                v-for="(item1, index) in companyTypeList"
                :key="index"
                @click="GoCompanyType(item1)"
              >
                {{ item1.dictLabel }}
              </li>
            </ul>
          </div>
          <div class="classificationFilter">
            <span class="serverTit" style="border-bottom: 0; border-right: 0"
              >服务类型</span
            >
            <ul class="serversType" style="border-bottom: 0">
              <li
                :class="IndexActiveOne === item.dictValue ? 'txtActive' : 'txt'"
                v-for="(item, index) in serverTypeList"
                :key="index"
                @click="GoServersTypeActive(item)"
              >
                {{ item.dictLabel }}
              </li>
            </ul>
          </div>
          <div class="classificationFilter">
            <span class="serverTit" style="border-right: 0">服务区域</span>
            <ul class="serversType" style="background-color: #ffffff">
              <li
                :class="IndexActiveTwo === item.value ? 'txtActive' : 'txt'"
                @click="GoTutorialLevActive(item)"
                v-for="item in filterlist"
                :key="item.value"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
          <!-- <div class="sort">
            <ul class="serversType">
              <li
                :class="sortIndex === index ? 'txtActive' : 'txt'"
                @click="sort(item.id)"
                v-for="(item, index) in sortList"
                :key="index"
              >
                {{ item.con }}
              </li>
            </ul> -->
          <!-- <div class="checkBox">
              <el-checkbox-group v-model="checkedCities">
                <el-checkbox v-for="city in cities" :label="city" :key="city">{{
                  city
                }}</el-checkbox>
              </el-checkbox-group>
            </div> -->
          <!-- </div> -->
          <div class="content" v-if="GardenArchitectureList.lenght != 0">
            <div
              class="content_box"
              v-for="(item, index) in GardenArchitectureList"
              :key="index"
            >
              <div class="con_box_left">
                <div class="con_img">
                  <img :src="item.companyLogo" alt="" />
                </div>
              </div>
              <div class="con_box_right">
                <div class="box_top">
                  <div class="title_text">
                    <p class="title" @click="GoDetails(item)">
                      {{ item.companyName }}
                    </p>
                    <div class="pingfen">
                      <p>用户评价：</p>
                      <el-rate
                        v-model="startValue"
                        disabled
                        :colors="[
                          '#F6461D',
                          '#F6461D',
                          '#F6461D',
                          '#F6461D',
                          '#F6461D',
                        ]"
                        text-color="#F6461D"
                        score-template="{value}"
                      >
                      </el-rate>
                    </div>
                    <div class="type">
                      <span
                        class="typeBtn"
                        v-for="(item1, index) in item.serverTypes"
                        :key="index"
                        >{{ item1 }}</span
                      >
                    </div>
                    <span class="dizhi">{{ item.serviceArea }}</span>
                  </div>
                  <div class="lijiyueyue">
                    <p class="yuyue_text" @click="GoDetails(item)">立即了解</p>
                  </div>
                </div>
                <div
                  class="box_bottom"
                  v-if="item.baseConstructCaseList.length != 0"
                >
                  <div
                    class="title_con"
                    v-for="(item2, index) in item.baseConstructCaseList"
                    :key="index"
                  >
                    <img :src="item2.coverImg" alt="img" />
                  </div>
                </div>
                <div
                  class="box_bottom"
                  v-if="item.baseConstructCaseList.length == 0"
                >
                  <div
                    style="text-align: center; line-height: 50px; color: #ccc"
                  >
                    暂无案例数据
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="noData" v-else>
            <p>暂无数据</p>
          </div>
          <Pagination
            v-show="Total > 0"
            :total="Total"
            style="text-align: center; margin-top: 15px; margin-bottom: 30px;"
            :page.sync="formInline.pageNum"
            :limit.sync="formInline.pageSize"
            @pagination="PageApi"
          />
        </div>
      </el-col>
      <el-col class="advertisingSpace" :span="6">
        <div style="margin-left: 35px" class="Advertising_first">
          <div class="hot">
            <div class="hotSeatch_img">
              <img src="../../assets/image/hotSearch.png" alt="热搜" /><span
                >热门园林设计排行</span
              >
            </div>
            <div class="hotSeatch_content">
              <ul class="seatch_list">
                <li
                  v-for="(item, index) in hotGardenArchitectureList"
                  @click="gardenDetail(item)"
                  :key="index"
                >
                  <span> {{ item.companyName }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="Advertising_tow">
          <wantQuote style="width: 300px; margin-left: 35px" />
        </div>
        <div class="Advertising_three">
          <advertisementLeft
            :wideUrl="wideUrl"
            @click.native="goRoute"
            style="width: 300.4px; margin-left: 35px"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.vue";
import advertisementLeft from "@/components/advertisement/advertisementLeft.vue";
import wantQuote from "@/components/wantQuote/wantQuote.vue";
import Pagination from "@/components/Pagination/Pagination";
import { getDicts } from "@/request/dict/data";
import { selectDictLabel, selectDictLabels } from "@/utils/weihua";
import { getGardenArchitectureList } from "@/request/GardenArchitecture";
import { getCaseList } from "@/request/case";
import { getSeatch, getBanner } from "@/request/home/home";
export default {
  name: "GardenArchitecture",
  components: {
    Breadcrumb,
    Pagination,
    advertisementLeft,
    wantQuote,
  },
  data() {
    return {
      startValue: 5,
      serverTypes: [],
      checkedCities: ["企业认证"],
      // cities: ["企业认证", "保证金"],
      checkAll: false,
      isIndeterminate: true,
      sortIndex: 0,
      caseImgList: [],
      wideUrl: null,
      sortList: [
        {
          id: 1,
          con: "综合排序",
        },
        {
          id: 2,
          con: "最新",
        },
      ],
      hotSeatch: [
        {
          id: 0,
          seatchTitle: "西安三禾文华园林景观设计",
        },
        {
          id: 1,
          seatchTitle: "共振园林景观集团",
        },
        {
          id: 2,
          seatchTitle: "西安三禾文华园林景观设计",
        },
        {
          id: 3,
          seatchTitle: "国际怡境景观设计有限公司",
        },
        {
          id: 4,
          seatchTitle: "西安三禾文华园林景观设计",
        },
        {
          id: 5,
          seatchTitle: "北京土人景观与建筑规划设计研究院",
        },
        {
          id: 6,
          seatchTitle: "西安三禾文华园林景观设计",
        },
        {
          id: 6,
          seatchTitle: "西安三禾文华园林景观设计",
        },
      ],
      IndexActiveOne: "",
      filterlist: [],
      companyTypeList: [],
      IndexActiveTwo: 0,
      IndexActiveThree: "",
      hotGardenArchitectureList: [],
      GardenArchitectureList: [],
      serverTypeList: [],
      formInline: {
        pageNum: 1,
        pageSize: 4,
        pcSearch: 1,
        serverTypes: null,
        companyIdentity: "",
        serviceArea: sessionStorage.getItem("cityTxt"),
      },
      url: null,
      Total: 0,
      cityTxt: null,
    };
  },
  activated() {
    // const typeList = JSON.parse(localStorage.getItem("type"));
    if (this.$route.query.type) {
      this.formInline.type = this.$route.query.type;
      this.formInline.searchInfo = this.$route.query.searchInfo;
      if (this.$route.query.id) {
        this.formInline.companyIdentity = this.$route.query.id;
        this.IndexActiveThree = this.$route.query.id;
      } else {
        this.formInline.companyIdentity = "";
        this.IndexActiveThree = "";
      }
      this.cityTxt = sessionStorage.getItem("cityTxt");
      this.getBBannerList();
      this.filterlist = JSON.parse(sessionStorage.getItem("cityList"));
      this.filterlist = this.filterlist.cityList;
      this.getSeatchList();
    } else {
      if (this.$route.query.id) {
        this.formInline.companyIdentity = this.$route.query.id;
        this.IndexActiveThree = this.$route.query.id;
      } else {
        this.formInline.companyIdentity = "";
        this.IndexActiveThree = "";
      }
      this.cityTxt = sessionStorage.getItem("cityTxt");
      this.getBBannerList();
      this.filterlist = JSON.parse(sessionStorage.getItem("cityList"));
      this.filterlist = this.filterlist.cityList;
      this.PageApi();
    }
  },
  created() {
    this.filterlist.unshift({
      label: "全部",
      value: 0,
    });
    
    getDicts("msk_sys_server_type").then((res) => {
      this.serverTypeList = [];
      this.serverTypeList = res.data;
      this.serverTypeList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
    getDicts("msk_sys_company_settle_type").then((res) => {
      this.companyTypeList = [];
      this.companyTypeList = res.data;
      this.companyTypeList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
  },
  methods: {
    getBBannerList() {
      getBanner({ advertiseType: 6 }).then((res) => {
        this.wideUrl = res.data[0].filePath;
        this.url = res.data[0].routeContent;
      });
    },
    getSeatchList() {
      let that = this;
      getSeatch(that.formInline).then((res) => {
        if (res.code == 200) {
          that.GardenArchitectureList = [];
          res.rows.forEach((item) => {
            if (item.baseConstructCaseList == null) {
              item.baseConstructCaseList = [];
            }
          });

          that.GardenArchitectureList = res.rows;
          let arr = [];
          arr = res.rows.map((item, index) => {
            if (item.serverTypes != null) {
              that.GardenArchitectureList[index].serverTypes =
                item.serverTypes.split(",");
            }
          });
          that.Total = res.total;
        }
      });
    },
    PageApi() {
      getGardenArchitectureList(this.formInline).then((res) => {
        if (res.code == 200) {
          this.hotGardenArchitectureList = res.rows;
          this.GardenArchitectureList = res.rows;
          let arr = [];
          arr = res.rows.map((item, index) => {
            if (item.serverTypes != null) {
              this.GardenArchitectureList[index].serverTypes =
                item.serverTypes.split(",");
            }
          });
          this.Total = res.total;
        }
      });
    },
    sort(e) {
      this.sortIndex = e;
      this.formInline.pcSearch = this.sortIndex;
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    getServerType(value) {
      return selectDictLabel(this.serverTypeList, value);
    },
    GoServersTypeActive(e) {
      this.IndexActiveOne = e.dictValue;
      this.formInline.serverTypes = this.IndexActiveOne;
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    GoCompanyType(e) {
      this.IndexActiveThree = e.dictValue;
      this.formInline.companyIdentity = this.IndexActiveThree;
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    GoTutorialLevActive(e) {
      this.IndexActiveTwo = e.value;
      if (e.label == "全部") {
        this.formInline.serviceArea = sessionStorage.getItem("cityTxt");
      } else {
        this.formInline.serviceArea = this.cityTxt + "-" + e.label;
      }
      if (this.$route.query.type) {
        this.formInline.type = this.$route.query.type;
        this.formInline.searchInfo = this.$route.query.searchInfo;
        this.formInline.serviceType = this.$route.query.serviceType;
        this.getSeatchList();
      } else {
        this.PageApi();
      }
    },
    goRoute() {
      if(this.url){
      window.open(this.url,"_blank");

      }else{
        return 
      }
    },
    GoDetails(e) {
      this.$router.push({
        path: "/GardenDetails",
        query: {
          id: e.id,
        },
      });
    },
    gardenDetail(e) {
      this.$router.push({
        path: "/GardenDetails",
        query: {
          id: e.id,
        },
      });
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f64a1d !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f64a1d !important;
  border-color: #f64a1d !important;
}
</style>
<style  lang="scss" scoped>
::v-deep .btn-prev {
  padding: 0 5px !important;
}
::v-deep .btn-next {
  padding: 0 5px !important;
}
::v-deep .el-form-item {
  width: 200px !important;
  margin: 10px 0px !important;
}
::v-deep .el-input {
  width: 200px !important;
}
.GardenArchitecture {
  width: 1320px;
  margin: 0 auto;

  .navBar {
    padding: 20px 0 20px 0;
    width: 100%;
  }

  .main_ty {
    display: flex;
    justify-content: space-between;

    .main_content {
      margin-right: 0px;

      .grid-content {
        width: 100%;

        .classificationFilter {
          width: 100%;
          display: flex;

          .serverTit {
            display: inline-block;
            width: 92px;
            min-height: 44px;
            background: #f7f7f7;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #4a4d4b;
            padding-top: 20px;
            text-align: center;
          }

          .serversType {
            width: 100%;
            min-height: 44px;
            background: #ffffff;
            padding-left: 20px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .txt {
              font-size: 14px;
              font-weight: 400;
              cursor: pointer;
              padding: 5px 20px;
              color: #636665 !important;
            }

            .txtActive {
              background: #188252;
              font-size: 14px;
              font-weight: 400;
              cursor: pointer;
              color: #ffffff;
              padding: 5px 20px;
              border-radius: 20px;
            }

            .txt:hover {
              color: #188252;
            }
          }
        }
        .sort {
          width: 100%;
          height: 36px;
          display: flex;
          margin-top: 20px;
          justify-content: space-between;
          background: #f5f5f5;
          border: 1px solid #ebebeb;
          .serversType {
            width: 50%;
            display: flex;
            padding: 0 20px;
            li {
              cursor: pointer;
              margin-right: 30px;
            }

            .txt {
              line-height: 36px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #808080;
              cursor: pointer;
            }

            .txtActive {
              line-height: 36px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #188252;
              cursor: pointer;
            }

            .txt:hover {
              color: #188252;
            }
          }
          .checkBox {
            margin-right: 30px;
            height: 36px;
            line-height: 36px;
          }
        }
        .noData {
          height: 300px;
          margin: 0 auto;
          p {
            line-height: 300px;
            text-align: center;
            color: #999999;
            font-size: 24px;
          }
        }
        .content {
          margin-top: 30px;
          width: 95%;
          display: flex;
          flex-direction: column;
          padding: 20px;
          background-color: #FFFFFF;
          .content_box {

            margin-top: 30px;
            width: 100%;
            display: flex;

            justify-content: space-between;
            align-items: flex-start;

            .con_box_left {
              width: 10%;
              margin-right: 2%;

              .con_img {
                width: 100px;
                height: 100px;
              }

              img {
                width: 100%;
                height: 100%;
              }
            }

            .con_box_right {
              width: 88%;
              display: flex;
              flex-direction: column;

              .box_top {
                display: flex;
                justify-content: space-between;
                margin-left: 10px;
                .title_text {
                  display: flex;
                  flex-direction: column;

                  .title {
                    cursor: pointer;
                    width: 350px;
                    height: 20px;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #4d4d4d;
                    margin-bottom: 8px;
                  }

                  .pingfen {
                    height: 20px;
                    margin-bottom: 20px;
                    display: flex;
                    p {
                      height: 42px;
                      font-size: 12px;
                      line-height: 42px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #a8a8a8;
                    }
                    .el-rate {
                      .el-rate__item {
                        height: 42px;
                        .el-rate__icon {
                          font-size: 25px;
                          line-height: 25px;
                          margin-right: 0;
                        }
                        .el-rate__decimal {
                          font-size: 25px;
                          line-height: 42px;
                          margin-right: 0;
                        }
                      }
                    }
                  }

                  .type {
                    width: 100%;
                    margin-bottom: 15px;

                    .typeBtn {
                      display: inline-block;
                      width: 66px;
                      height: 24px;
                      border: 1px solid #e6e6e6;
                      text-align: center;
                      margin-right: 10px;
                      line-height: 24px;
                      font-size: 12px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #7a807d;
                    }
                  }

                  .dizhi {
                    font-size: 13px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #a8a8a8;
                    margin-bottom: 20px;
                  }
                }

                .lijiyueyue {
                  width: 104px;
                  height: 32px;
                  background: #219560;
                  text-align: center;
                  margin-right: 10px;
                  .yuyue_text {
                    cursor: pointer;
                    line-height: 32px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #ffffff;
                  }
                }
              }

              .box_bottom {
                width: 100%;
                display: flex;
                margin-bottom: 30px;

                .title_con {
                  padding: 10px;
                  img {
                    width: 100%;
                    max-height: 130px;
                    max-width: 198px;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .advertisingSpace {
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      .Advertising_first {
        background-color: #FFFFFF;
        margin: 0 auto 20px auto;
        width: 90%;
        min-height: 260px;

        .hot {
          display: flex;
          flex-direction: column;
          margin: 0 auto;

          .hotSeatch_img {
            background: linear-gradient(-53deg, #f66a1d, #ff6b53);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 59px;
            width: 100%;
            margin-bottom: 10px;

            img {
              width: 6%;
              height: 40%;
              margin-right: 10px;
            }

            span {
              font-size: 20px;
              font-family: Fontquan-XinYiGuanHeiTi;
              font-weight: 400;
              color: #ffffff;
            }
          }

          .hotSeatch_content {
            padding: 10px 30px;
            background-color: #FFFFFF;
            .seatch_list {
              li {
                list-style: decimal;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #4d4d4d;
                margin-bottom: 26px;
                span {
                  cursor: pointer;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #4d4d4d;
                }
              }
              li:hover {
                color: #188252;
                span {
                  color: #188252;
                }
              }
              li:nth-of-type(1) {
                color: #ff1111;
              }

              li:nth-of-type(2) {
                color: #f6501d;
              }

              li:nth-of-type(3) {
                color: #f6a21d;
              }
            }
          }
        }
      }

      .Advertising_tow {
        margin: 0 auto 20px auto;
      }

      .Advertising_three {
        margin: 0 auto 20px auto;
      }
    }
  }
}
</style>
