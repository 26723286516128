<template>
  <div class="ForgotPassword">
    <div class="searchBoxTitle">
      <img src="../../assets/image/logo.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  data() {
    return {

    }
  },
  created() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">
.ForgotPassword {
  width: 1320px;
  margin: 0 auto;
  background: #ffffff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .searchBoxTitle {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;

    span:nth-child(1) {
      color: #188252;
    }

    span:nth-child(2) {
      color: #3C4340;
    }
  }



}
</style>
