import request from '@/request/request'


//案例列表
export function getCaseList(data){
    return request({
        url:"/api/web/case/list",
        method:"get",
        params:data
    })
}
//案例详情
export function getCaseDetail(id){
    return request({
        url:"/api/web/case/" + id,
        method:"get",
    })
}
//案例所属设计、公司查询
export function getInfoPersonalOrCompany(data){
    return request({
        url:"/api/web/case/getInfoPersonalOrCompany",
        method:"post",
        data:data
    })
}